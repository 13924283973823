import { DeviceInterface } from '@/models/devicesAndSensors/types'
import { getRowBackgroundColor } from '@/shared/utils'
import { COLORS } from '@skf-design-system/ui-components-react'
import { MRT_TableInstance } from 'material-react-table'
/******************Device table sx props******************** */
export const deviceTablePaperProps = {
  sx: {
    boxShadow: 'none',
  },
}

export const deviceTableHeaderProps = {
  sx: {
    opacity: 1,
    '& th': {
      backgroundColor: COLORS.gray100,
      padding: '0.5rem 0.25rem',
      textAlign: 'left',
      justifyContent: 'center',
      alignItems: 'center',
      '& div': {
        fontWeight: 700,
        marginRight: '0.05rem',
        display: 'flex',
        alignItems: 'center',
      },
      '& div:nth-of-type(5)': {
        margin: '0.2rem 0',
      },
    },
    '& th:first-of-type': {
      '& div': {
        justifyContent: 'center',
      },
    },
    '& th:last-of-type': {
      padding: 0,
      marginRight: 0,
      '& button': {
        '&:hover': {
          backgroundColor: 'transparent',
          cursor: 'default',
        },
      },
      '& span': {
        cursor: 'default',
        pointerEvents: 'none',
      },
    },
  },
}

export const deviceTableDetailsPanelProps = {
  sx: {
    padding: 0,
  },
}

export const deviceTableContainerProps = (table: MRT_TableInstance<DeviceInterface>) => ({
  sx: {
    maxHeight: 'calc(100vh - 21rem)',
    overflow: table.getState().showSkeletons ? 'hidden' : 'auto',
    overflowX: 'hidden',
    marginTop: '1.5rem',
  },
})

export const deviceTableBodyCellProps = {
  sx: {
    padding: '0.5rem',
    '&:focus-visible': {
      outline: 'none',
    },
    '&:nth-of-type(3)': {
      padding: ' 0 1rem',
    },
  },
}

export const deviceTableHeadCellProps = (isActive: boolean) => ({
  sx: {
    '& .MuiTableSortLabel-root': {
      color: COLORS.gray500,
      pointerEvents: isActive ? 'auto' : 'none',
      cursor: isActive ? 'pointer' : 'not-allowed',
    },
    '& button': {
      pointerEvents: isActive ? 'auto' : 'none',
      cursor: isActive ? 'pointer' : 'not-allowed',
    },
  },
})

export const deviceTableExpandButtonProps = {
  sx: {
    color: COLORS.brand,
  },
}

export const deviceTableBodyRowProps = (isDetailPanel: boolean, rowIndex: number, isSelected: boolean) => ({
  sx: {
    backgroundColor: () => {
      let rowColor = 'transparent'

      if (!isDetailPanel) {
        rowColor = getRowBackgroundColor(!!(rowIndex % 2), isSelected)
      }
      return rowColor
    },
    '& td:last-of-type': {
      justifyContent: 'center',
    },
    ':hover': {
      cursor: 'pointer',
      backgroundColor: !isSelected && COLORS.primary200,
      border: !isSelected && `1px solid ${COLORS.brand}`,
      '& td': {
        ':after': {
          backgroundColor: 'transparent',
        },
      },
    },
    '& .MuiTableCell-root': {
      '&:focus-visible': {
        outline: 'none',
      },
    },
  },
})
/****************************************************** */

/**************Channels table sx props */
export const channelTableContainerProps = {
  sx: { maxHeight: 'unset', overflow: 'hidden' },
}

export const channelTablePaperProps = {
  sx: {
    width: '100vw',
    padding: 0,
  },
}

export const channelTableBodyRowProps = (isDetailPanel: boolean, rowIndex: number, isSelected: boolean) => ({
  sx: {
    backgroundColor: () => {
      let rowColor = 'transparent'

      if (!isDetailPanel) {
        rowColor = getRowBackgroundColor(!!(rowIndex % 2), isSelected)
      }
      return rowColor
    },
    ':hover': {
      cursor: 'pointer',
      backgroundColor: !isSelected && COLORS.primary200,
      '& td': {
        ':after': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
})

export const channelTableBodyCellProps = {
  sx: {
    padding: '0.5rem',
    '&:focus-visible': {
      outline: 'none',
    },
    '&:nth-of-type(3)': {
      padding: ' 0 1rem',
    },
  },
}

import { FileDropzoneImageData } from '@/shared/components/dropzone/types'
import {
  StyledFaultHistoryEvidenceCommentBG,
  StyledFaultHistoryEvidenceComment,
  StyledFaultHistoryEvidenceFilesContent,
  StyledFaultHistoryEvidenceFile,
} from '@/modules/reportStatus/styled'
import { FC } from 'react'
import { useReportStatusContext } from '@/contexts/reportStatus'
import { useFaultEvidenceContext } from '@/contexts/moduleContexts/evidence'
import { dataTestId } from '@/tests/testid'
import TextEllipsis, { TextEllipsisType } from '@/shared/components/textEllipsis'

interface EvidenceHistoryContentProps {
  evidence: FileDropzoneImageData['imageInfo']
}

const EvidenceHistoryContent: FC<EvidenceHistoryContentProps> = ({ evidence }) => {
  const { faultEditModeData, setIsEvidenceFaultImageModalOpen, setEvidenceDialogData, setFaultEditModeData } =
    useReportStatusContext()
  const { faultId } = useFaultEvidenceContext()

  const handleEvidenceHistoryModal = () => {
    setFaultEditModeData({ ...faultEditModeData, [faultId]: false })
    setEvidenceDialogData({ imageInfo: evidence } as FileDropzoneImageData)
    setIsEvidenceFaultImageModalOpen(true)
  }

  return (
    <StyledFaultHistoryEvidenceFilesContent data-testid={dataTestId.evidenceHistoryContent}>
      <StyledFaultHistoryEvidenceFile
        src={evidence.fileURL}
        alt={`evidenceHistoryImage${evidence.fileID}`}
        onClick={handleEvidenceHistoryModal}
      />
      <TextEllipsis
        type={TextEllipsisType.MultiLine}
        value={evidence.fileName}
        characterLength={20}
        fontSizeInRem={'0.8rem'}
      />
      <StyledFaultHistoryEvidenceCommentBG>
        {evidence.comment && (
          <StyledFaultHistoryEvidenceComment
            type={TextEllipsisType.MultiLine}
            value={evidence.comment}
            rowsUntilEllipsis={5}
          />
        )}
      </StyledFaultHistoryEvidenceCommentBG>
    </StyledFaultHistoryEvidenceFilesContent>
  )
}
export default EvidenceHistoryContent

import { useCallback } from 'react'
import { useHierarchyTreeContext } from '@/contexts/moduleContexts/hierarchyTree'

const useTreeController = (nodeId: string) => {
  const context = useHierarchyTreeContext()

  if (!context) {
    throw new Error('useTreeController must be used inside HierarchyTreeContext')
  }

  const state = context.getState(nodeId)
  const isDisabled = context.isDisabled ? context.isDisabled(nodeId) : false

  // Memoized toggle handler to prevent unnecessary re-renders
  const handleToggle = useCallback(() => {
    if (!context || isDisabled) return

    const currentState = context.getState(nodeId)

    switch (currentState) {
      case 'deselected':
        context.select(nodeId)
        break
      case 'indeterminate':
      case 'selected':
        context.deselect(nodeId)
        break
    }
  }, [context, nodeId, isDisabled])

  return {
    selected: state === 'selected',
    indeterminate: state === 'indeterminate',
    disabled: isDisabled,
    onToggle: handleToggle,
  }
}

export default useTreeController

/* eslint-disable react-compiler/react-compiler */
import { useReportGeneratorContext } from '@/contexts/reportGenerator'
import ModuleHeader from '@/shared/components/moduleHeader'
import { setModuleHeaderActionPanelData, setModuleHeaderConfigData } from '@/store/moduleHeader/actions'
import { useAppDispatch } from '@/store/store'
import { batchActions } from '@/store/batch/actions'
import { initialReportFiltersState } from '@/modules/reportGenerator/utils/constants'
import { isEqual } from '@/shared/utils'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { useEffect } from 'react'

const ReportGeneratorHeader = () => {
  const reportGeneratorHeaderDispatch = useAppDispatch()
  const { setAssetReportFilters, assetReportFilters, isPdfGenerationInProgress } = useReportGeneratorContext()

  const isButtonDisabled = useDeepCompareMemo(() => {
    return isPdfGenerationInProgress || isEqual(assetReportFilters, initialReportFiltersState)
  }, [assetReportFilters, isPdfGenerationInProgress])

  useEffect(() => {
    setTimeout(() => {
      reportGeneratorHeaderDispatch(
        batchActions([
          setModuleHeaderConfigData({
            showHeader: true,
            title: 'Report Generator',
            showInfoPanel: false,
            showActionPanel: true,
            subtitle: '',
            showSubtitle: false,
          }),
          setModuleHeaderActionPanelData({
            withLoader: true,
            withCSVDownloadButton: false,
            csvDownLoadButtonSize: 'lg',
            isClearFiltersButtonDisabled: isButtonDisabled,
            withClearFiltersButton: true,
            clearFiltersButtonFn: () => {
              setAssetReportFilters(initialReportFiltersState)
            },
            withRefreshButton: false,
            withTableStateChangeSwitch: false,
          }),
        ])
      )
    }, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isButtonDisabled])

  return <ModuleHeader />
}

export default ReportGeneratorHeader

/* eslint-disable react-compiler/react-compiler */
import { ReportGeneratorLoaderData } from '@/app/routes/types'
import { useReportGeneratorContext } from '@/contexts/reportGenerator'
import ReportGeneratorHeader from '@/modules/reportGenerator/components/header'
import ReportFilters from '@/modules/reportGenerator/components/reportFilters'
import ReportPreview from '@/modules/reportGenerator/components/reportPreview'
import type { WorkerType as InfoPageWorkerType } from '@/modules/reportGenerator/workers/infoPageWorker/infoPage.pdf.worker'
import InfoPageWorker from '@/modules/reportGenerator/workers/infoPageWorker/infoPage.pdf.worker?worker'
import { useWorker } from '@/modules/reportGenerator/workers/useWorker'
import {
  StyledGenerateReportContainer,
  StyledGenerateReportExportPdfButton,
  StyledGenerateReportFooter,
  StyledGenerateReportWrap,
  StyledReportFilters,
  StyledReportGeneratorBackButton,
} from '@/modules/reportGenerator/styled'
import { initialReportFiltersState } from '@/modules/reportGenerator/utils/constants'
import { ROUTES } from '@/shared/constants'
import { downloadFile } from '@/shared/fileUtils'
import { stopLoading } from '@/store/loading/action'
import { useAppDispatch } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import React, { useCallback, useEffect } from 'react'
import { useLoaderData, useNavigate, useParams } from 'react-router'
import { filterAssetsByCriteria } from '../../utils'

const GenerateReport: React.FC = () => {
  const navigate = useNavigate()
  const { assetHierarchyData } = useLoaderData() as ReportGeneratorLoaderData
  const { sid } = useParams()
  const reportGeneratorDispatch = useAppDispatch()
  const {
    setAssetReportFilters,
    assetReportFilters,
    setStaticReportPartsBlobs,
    previewReportUrl,
    setIncludedFilteredAssets,
    isPdfGenerationInProgress,
  } = useReportGeneratorContext()

  const { execute: generateInfoPagePdf } = useWorker<InfoPageWorkerType, 'renderInfoPagePdfInWorker'>({
    WorkerClass: InfoPageWorker,
    methodName: 'renderInfoPagePdfInWorker',
  })

  useEffect(() => {
    const infoPageGeneration = async () => {
      const infoPageBlob = await generateInfoPagePdf()
      setStaticReportPartsBlobs({
        infoPage: infoPageBlob,
      })
    }

    infoPageGeneration()
  }, [generateInfoPagePdf, setStaticReportPartsBlobs])

  const handleBackToOverviewClick = () => {
    navigate(`${ROUTES.OVERVIEW}`)
  }

  useEffect(() => {
    setAssetReportFilters(initialReportFiltersState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sid])

  useEffect(() => {
    setIncludedFilteredAssets(filterAssetsByCriteria(assetReportFilters, assetHierarchyData))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetReportFilters, assetHierarchyData])

  useEffect(() => {
    if (assetHierarchyData.siteId) {
      reportGeneratorDispatch(stopLoading())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetHierarchyData])

  const handleExportPdfClick = useCallback(() => {
    previewReportUrl && downloadFile('site-report.pdf', previewReportUrl)
  }, [previewReportUrl])

  return (
    <StyledGenerateReportContainer data-testid={dataTestId.generateReport} feFlexDirection="column">
      <StyledGenerateReportWrap>
        <StyledReportGeneratorBackButton
          feIcon={{
            feIcon: 'arrowLeft',
            position: 'left',
          }}
          feType="secondary"
          data-testid={dataTestId.siteDetailsBackButton}
          onClick={handleBackToOverviewClick}
          feSize="sm"
          backgroundColor="transparent"
          disabled={isPdfGenerationInProgress}
        >
          Back to overview
        </StyledReportGeneratorBackButton>
        <ReportGeneratorHeader />
        <StyledReportFilters>
          <ReportFilters />
        </StyledReportFilters>
        <ReportPreview />
      </StyledGenerateReportWrap>
      <StyledGenerateReportFooter feJustifyContent="right">
        <StyledGenerateReportExportPdfButton
          feSize="sm"
          disabled={!previewReportUrl}
          onClick={handleExportPdfClick}
          data-testid={dataTestId.generateReportExportButton}
        >
          Export PDF
        </StyledGenerateReportExportPdfButton>
      </StyledGenerateReportFooter>
    </StyledGenerateReportContainer>
  )
}

export default GenerateReport

import useContextWrapper from '@/shared/hooks/useContextWrapper'
import { createContext, useMemo, useState } from 'react'
import { FileDropzoneImageData } from '@/shared/components/dropzone/types'

interface IFaultEvidenceContext {
  faultImages: FileDropzoneImageData[]
  setFaultImages: React.Dispatch<React.SetStateAction<IFaultEvidenceContext['faultImages']>>
  faultId: string
  setFaultId: React.Dispatch<React.SetStateAction<IFaultEvidenceContext['faultId']>>
}

export const FaultEvidenceContext = createContext<IFaultEvidenceContext | null>(null)

export const useFaultEvidenceContext = () =>
  useContextWrapper(FaultEvidenceContext, {
    contextName: useFaultEvidenceContext.name,
    providerName: FaultEvidenceContextProvider.name,
  })

export const FaultEvidenceContextProvider: React.FC<{
  faultId: string
  children: React.ReactNode
}> = ({ faultId: currentFaultId, children }) => {
  const [faultImages, setFaultImages] = useState<IFaultEvidenceContext['faultImages']>([])
  const [faultId, setFaultId] = useState<IFaultEvidenceContext['faultId']>(currentFaultId)

  const value = useMemo(
    () => ({
      faultImages,
      setFaultImages,
      faultId,
      setFaultId,
    }),
    [faultImages, faultId]
  )

  return <FaultEvidenceContext value={value}>{children}</FaultEvidenceContext>
}

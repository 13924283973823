import { DeviceInterface, DeviceStatusEnum } from '@/models/devicesAndSensors/types'
import { deviceFaultText, deviceStatusIcon, deviceStatusString } from '@/modules/devicesSensors/utils/constants'
import { dataTestId } from '@/tests/testid'
import { Flexbox, Icon, Text } from '@skf-design-system/ui-components-react'
import { FC } from 'react'

const DeviceDataFault: FC<{
  status: DeviceStatusEnum
  fault?: DeviceInterface['faults'][number]
}> = ({ status, fault }) => {
  const iconData = deviceStatusIcon[status]
  const isFaulted = fault && status

  return (
    <Flexbox data-testid={dataTestId.deviceDataFault} feFlexDirection="row" feGap="xs" feAlignItems="center">
      <Icon feIcon={iconData.icon} feColor={iconData.color} feSize="sm" />
      <Text feColor={isFaulted ? 'purpleBase' : 'greenBase'} feFontWeight="bold">
        {isFaulted ? deviceFaultText[fault.fault] : deviceStatusString['normal']}
      </Text>
    </Flexbox>
  )
}

export default DeviceDataFault

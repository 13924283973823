import { AxiosError } from 'axios'
import networkClient from '@/api/NetworkClient'
import { PATHS } from '@/api/types'
import {
  GetAssetHierarchyRequest,
  GetAssetHierarchyResponse,
  GetAssetHierarchyResult,
} from '@/api/paths/assetHierarchy/types'
import { AssetHierarchy } from '@/models/reportGenerator/types'

const getAssetHierarchy = async (
  handlers: GetAssetHierarchyResult,
  params: Record<string, string | undefined>
): Promise<AssetHierarchy | void> => {
  try {
    const response = await networkClient.get<GetAssetHierarchyRequest, GetAssetHierarchyResponse>(
      PATHS.GET_ASSET_HIERARCHY,
      {
        params,
      }
    )
    handlers.onSuccess && (await handlers.onSuccess(response.data))
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

export { getAssetHierarchy }

import { postRecommendedActionsDueDatesStatus } from '@/api/paths/analytics/dueDatesStatus'
import { useDashboardContext } from '@/contexts/dashboard'
import { SelectFilterType } from '@/models/dashboard/globalFiltering/types'
import { WidgetType } from '@/models/dashboard/types'
import { RecommendedActionsDueDatesStatusType } from '@/models/widgets/recommended-actions/types'
import { DarwinWidgetError } from '@/shared/components/errorBoundary/darwinError'
import { useApi } from '@/shared/hooks/useApi'
import { AxiosError } from 'axios'
import { useParams } from 'react-router'

const useRecommendedActionsDueDatesStatus = (globalFilter: SelectFilterType) => {
  const { sid } = useParams()
  const { setRecommendedActionsDueDatesStatus } = useDashboardContext()
  return useApi(async () => {
    return await postRecommendedActionsDueDatesStatus(
      globalFilter,
      {
        onSuccess: async (result) => {
          setRecommendedActionsDueDatesStatus(result as unknown as RecommendedActionsDueDatesStatusType[])
        },
        onError: async (error: AxiosError) => {
          return Promise.reject(new DarwinWidgetError(error, WidgetType.RA_DUE_DATES_STATUS))
        },
      },
      { sid }
    )
  })
}

export default useRecommendedActionsDueDatesStatus

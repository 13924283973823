import { isEqual } from '@/shared/utils'
import { DependencyList, useCallback, useRef } from 'react'

/**
 * A custom hook that returns a memoized callback function, similar to `useCallback`,
 * but with deep comparison of dependencies. It ensures that the callback is only recreated
 * if the deeply compared dependencies have changed.
 *
 * @template Args - The arguments the callback function takes.
 * @template Return - The return type of the callback function.
 *
 * @param {(...args: Args) => Return} callback - The callback function to be memoized.
 * @param {DependencyList} dependencies - The list of dependencies for the callback,
 * which will be deeply compared to decide whether the callback should be recreated.
 *
 * @returns {(...args: Args) => Return} - The memoized callback function.
 */

function useDeepCompareCallback<Args extends unknown[], Return>(
  callback: (...args: Args) => Return,
  dependencies: DependencyList
): (...args: Args) => Return {
  const lastDependenciesRef = useRef<DependencyList>(undefined)

  // Check if dependencies have changed deeply
  if (!lastDependenciesRef.current || !isEqual(lastDependenciesRef.current, dependencies)) {
    lastDependenciesRef.current = dependencies
  }

  // eslint-disable-next-line react-compiler/react-compiler
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(callback, [lastDependenciesRef.current])
}

export default useDeepCompareCallback

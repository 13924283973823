import { ReasonIndicatorData } from '@/models/analysisBacklog/types'
import ReasonIndicator from '@/modules/analysisBacklog/components/reasonIndicator'
import { COLORS } from '@skf-design-system/ui-components-react'
import { FC } from 'react'
import { trackAIStrikerClick } from '@/app/datadogConfig'
import { ROUTES } from '@/shared/constants'

const reasonConfigMap = {
  band: (severity = '') => ({
    reasonText: `Band ${severity}`,
  }),
  diagnostics: (severity = '') => ({
    reasonText: `DiagX ${severity}`,
  }),
  protean: () => ({
    reasonText: 'Protean alarm',
    reasonIcon: {
      iconColor: 'red',
      iconName: 'danger',
    },
  }),
  ai: (_: unknown, externalURL: string = '') => ({
    reasonText: 'AI alarm',
    reasonIcon: {
      iconColor: 'red',
      iconName: 'danger',
    },
    withRedirect: true,
    redirectUrl: externalURL,
    onClick: trackAIStrikerClick,
  }),
  overall: (severity = '') => ({
    reasonText: `Overall ${severity}`,
  }),
  feedback: () => ({
    reasonText: 'Feedback',
    reasonIcon: {
      iconColor: 'blue',
      iconName: 'email',
    },
    withBadge: true,
  }),
  device_fault: () => ({
    reasonText: 'Sensor fault',
    reasonIcon: { iconColor: 'purple', iconName: 'sensor' },
    innerRedirectModule: ROUTES.DEVICES_AND_SENSORS,
    reasonCount: 0,
  }),
  no_sensor_data: () => ({
    reasonText: 'No sensor data',
    reasonIcon: {
      iconColor: 'white',
      iconName: 'unknownOutline',
    },
    customStyle: {
      backgroundColor: COLORS.gray600,
    },
  }),
  no_data_24h: () => ({
    reasonText: 'No Measurements 24h',
    reasonIcon: { iconColor: 'purple', iconName: 'sensor' },
    innerRedirectModule: ROUTES.DEVICES_AND_SENSORS,
    reasonCount: 0,
  }),
}

interface AnalysisReasonProps {
  reason: ReasonIndicatorData
}

const AnalysisReason: FC<AnalysisReasonProps> = ({ reason }) => {
  const { type, count, severity, externalURL, faultedData } = reason

  const config = reasonConfigMap[type]?.(severity, externalURL)

  return (
    config && <ReasonIndicator faultedData={faultedData} reasonCount={count} reasonSeverity={severity} {...config} />
  )
}

export default AnalysisReason

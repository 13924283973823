import { FileDropzoneImageData, FilesDropzoneCategory } from '@/shared/components/dropzone/types'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { convertFileNameExtensionToLowercase } from '@/shared/fileUtils'
import { STORAGE_REPORT_STATUS_ORIGIN } from '@/shared/localStorageUtils'
import { generateUUID, getSidFromPathname } from '@/shared/utils'
import { FileRejection } from 'react-dropzone'

/**
 * Converts an array of files or file rejections to evidence image data.
 *
 * This function processes an array of files or file rejections, converts them to a specific data format,
 * and then converts the file content to a Base64 string.
 *
 * @param {File[] | FileRejection[]} filesArray - An array of files or file rejections to be processed.
 * @param {string} [faultId=''] - An optional fault ID to be associated with the evidence data.
 * @param {string} [category=''] - An optional category to be associated with the evidence data.
 *
 * @returns {Promise<any[]>} A promise that resolves to an array of converted evidence image data.
 *
 * @async
 */
const convertToEvidenceImageData = async (
  filesArray: File[] | FileRejection[],
  faultId: string = '',
  category: FilesDropzoneCategory
) => {
  const convertedData = convertDropZoneData(filesArray, faultId, category)

  const handleAsyncOperations = async () => {
    const promises = convertedData.map(async (data) => {
      const content = await convertFileToBase64(data.imageInfo.originFile)
      data.imageInfo.content = content
      return data
    })
    await Promise.all(promises)
  }
  await handleAsyncOperations()
  return convertedData
}

/**
 * Converts data from a file drop zone into an array of `FileDropzoneImageData` objects.
 *
 * @param {File[] | FileRejection[]} filesArray - An array containing either `File` objects or `FileRejection` objects.
 *   - `File`: Represents a file selected by the user.
 *   - `FileRejection`: Represents a rejected file selection with an error message.
 * @param {string} faultId - The fault ID associated with the uploaded files.
 * @returns {FileDropzoneImageData[]} An array of `FileDropzoneImageData` objects, each representing a processed file.
 *
 * @throws {TypeError} - If `filesArray` is not an array of `File` or `FileRejection` objects.
 */
const convertDropZoneData = (
  filesArray: File[] | FileRejection[],
  faultId: string,
  category: string
): FileDropzoneImageData[] => {
  return filesArray.map((file) => {
    let errorMessages = undefined
    let fileData: File | undefined = undefined
    if ((file as FileRejection).errors) {
      errorMessages = (file as FileRejection).errors.map((error) => error.message)
    }
    if (file instanceof File) {
      fileData = file
    } else {
      fileData = file.file
    }
    return {
      imageInfo: {
        fileID: generateUUID(), // we cannot use fileID as unique sine in case of new image igt will be replaced with one from bucket upload
        contentType: fileData.type,
        fileURL: URL.createObjectURL(fileData),
        fileName: convertFileNameExtensionToLowercase(fileData.name),
        fileCreatedAt: formatDate(new Date(), DateFormats.AmericanDateFormat),
        comment: '',
        size: fileData.size,
        content: '', // Assuming base64ArrayBuffer is synchronous
        originFile: fileData,
        uniqueId: generateUUID(),
      },
      bucketInfo: {
        category,
      },
      appInfo: {
        nodeID: faultId,
        siteID: getSidFromPathname(sessionStorage.getItem(STORAGE_REPORT_STATUS_ORIGIN)),
        isUploaded: false,
        errors: errorMessages,
      },
    } as FileDropzoneImageData
  })
}
/**
 * Converts a file object to a base64 encoded string asynchronously.
 *
 * @param {File} file - The File object to be converted.
 * @returns {Promise<string>} A promise that resolves to the base64 encoded string of the file content, or rejects with an error.
 *
 * @throws {TypeError} - If the provided argument is not a `File` object.
 */
const convertFileToBase64 = async (file: File): Promise<string> => {
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.onload = (event) => resolve(event.target?.result as string)
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

export { convertToEvidenceImageData }

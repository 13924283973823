import networkClient from '@/api/NetworkClient'
import {
  GetUsersPermissionsRequest,
  GetUsersPermissionsResponse,
  GetUsersPermissionsResult,
} from '@/api/paths/users/userPermissions/types'
import { PATHS } from '@/api/types'
import { SitePermissions } from '@/modules/azureAuth/types'
import { AxiosError } from 'axios'

const getSitesPermissions = async (handlers: GetUsersPermissionsResult): Promise<SitePermissions | void> => {
  try {
    const response = await networkClient.get<GetUsersPermissionsRequest, GetUsersPermissionsResponse>(
      PATHS.GET_USERS_PERMISSIONS
    )
    return response.data
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

export { getSitesPermissions }

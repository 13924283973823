import {
  StyledMultilineContainer,
  StyledMultilineLabel,
  StyledMultilineValue,
  StyledMultilineValueContainer,
  StyledSingleLineContainer,
  StyledSingleLineContentWrapper,
  StyledTextEllipsisTooltip,
} from '@/shared/components/textEllipsis/styled'
import {
  constructMultilineEllipsisValue,
  constructSingleLineEllipsisValue,
  isMultilineOverflow,
  isSingleLineOverflow,
} from '@/shared/components/textEllipsis/utils'
import { removeHtmlTags } from '@/shared/stringUtils'
import { dataTestId } from '@/tests/testid'
import { Popup, useTooltip } from '@skf-design-system/ui-components-react'
import { FC, useCallback, useMemo } from 'react'

export enum TextEllipsisType {
  SingleLine,
  MultiLine,
}

interface TextEllipsisProps {
  value: string | undefined
  type: TextEllipsisType
  className?: string
  label?: string
  characterLength?: number
  fontSizeInRem?: string
  rowsUntilEllipsis?: number
}
/**
 * A versatile text ellipsis component that supports both single-line and multi-line truncation.

 * @param {TextEllipsisType} type - The type of ellipsis to apply (single-line or multi-line).
 * @param {ReactNode} label - An optional label to display before the truncated text.
 * @param {number} rowsUntilEllipsis - The number of rows to display before truncating multi-line text.
 * @param {string | null | undefined} value - The text to be truncated.
 * @param {string} className - An optional CSS class name for the component.
 * @param {number} characterLength - The maximum number of characters to display for single-line truncation.
 * @param {string} fontSizeInRem - The font size of the text in rem units.
 * @returns {JSX.Element} The rendered component.
 */
const TextEllipsis: FC<TextEllipsisProps> = ({
  type = TextEllipsisType.MultiLine,
  label,
  rowsUntilEllipsis = 1,
  value,
  className,
  characterLength,
  fontSizeInRem = '1rem',
}) => {
  const [hoverRef, isHovered] = useTooltip()

  const isOverflowing = useCallback(() => {
    return (
      (type === TextEllipsisType.MultiLine && isMultilineOverflow(hoverRef)) ||
      isSingleLineOverflow(value, characterLength)
    )
  }, [characterLength, hoverRef, type, value])

  const formattedText = useMemo(() => {
    return removeHtmlTags(
      (type === TextEllipsisType.MultiLine && constructMultilineEllipsisValue(value)) ||
        constructSingleLineEllipsisValue(value, characterLength, !!isOverflowing())
    )
  }, [characterLength, isOverflowing, type, value])

  return (
    <>
      {(type === TextEllipsisType.MultiLine && (
        <StyledMultilineContainer
          data-testid={dataTestId.multilineTextEllipsis}
          rowsUntilEllipsis={rowsUntilEllipsis}
          className={className}
        >
          {label !== undefined && <StyledMultilineLabel>{label}: </StyledMultilineLabel>}
          <StyledMultilineValueContainer>
            <StyledMultilineValue
              ref={hoverRef}
              dangerouslySetInnerHTML={{ __html: formattedText }}
            ></StyledMultilineValue>
          </StyledMultilineValueContainer>
        </StyledMultilineContainer>
      )) || (
        <StyledSingleLineContainer data-testid={dataTestId.singleLineTextEllipsis}>
          <StyledSingleLineContentWrapper ref={hoverRef} fontSizeInRem={fontSizeInRem}>
            {formattedText}
          </StyledSingleLineContentWrapper>
        </StyledSingleLineContainer>
      )}
      {isHovered && isOverflowing() && (
        <Popup isHovered={isHovered} triggerElement={hoverRef.current}>
          <StyledTextEllipsisTooltip data-testid={dataTestId.textEllipsisPopup}>
            {(type === TextEllipsisType.MultiLine && formattedText) || value}
          </StyledTextEllipsisTooltip>
        </Popup>
      )}
    </>
  )
}

export default TextEllipsis

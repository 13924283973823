import { ReportGeneratorLoaderData } from '@/app/routes/types'
import { useReportGeneratorContext } from '@/contexts/reportGenerator'
import {
  StyledAssetHierarchyContainer,
  StyledAssetHierarchyHeader,
  StyledAssetHierarchySearch,
  StyledAssetHierarchySearchContainer,
  StyledAssetHierarchySearchResultCount,
  StyledAssetHierarchySelect,
  StyledAssetSelectionContainer,
  StyledCompanyLogo,
  StyledCompanyLogoContainer,
  StyledReportGeneratorLogo,
} from '@/modules/reportGenerator/styled'
import {
  buildTreeMaps,
  countLeafOrSingleNodesAssetHierarchy,
  getFilteredTreeData,
  getVisibleSelectedIds,
} from '@/modules/reportGenerator/utils'
import { emptyTreeNodeData } from '@/modules/reportGenerator/utils/constants'
import placeholderLogoSVG from '@/modules/siteDetails/assets/attachment_failed_4x.webp'
// import { HierarchyTree } from '@/shared/components/hierarchyTree'
import NewHierarchyTree from '@/shared/components/newHierarchyTree'
import useDeepCompareCallback from '@/shared/hooks/useDeepCompareCallback'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { dataTestId } from '@/tests/testid'
import { Text } from '@skf-design-system/ui-components-react'
import React, { useCallback } from 'react'
import { useLoaderData } from 'react-router'

const AssetSelection: React.FC = () => {
  const { setAssetReportFilters, assetReportFilters, isPdfGenerationInProgress } = useReportGeneratorContext()

  const { mappedAssetHierarchyData, reportGeneratorLogoUrl } = useLoaderData() as ReportGeneratorLoaderData

  // Filter data without affecting selection state
  const filteredData = useDeepCompareMemo(() => {
    const lowerTerm = assetReportFilters.hierarchySearchText.toLowerCase().trim()

    // If the search term is empty, return the entire treeData as is.
    if (!lowerTerm) return mappedAssetHierarchyData
    return getFilteredTreeData(mappedAssetHierarchyData, lowerTerm) || emptyTreeNodeData
  }, [mappedAssetHierarchyData, assetReportFilters])

  // Get currently visible selected IDs
  const visibleSelectedIds = useDeepCompareMemo(() => {
    const selectedAssetsSet = new Set(assetReportFilters.assets)
    const result = getVisibleSelectedIds(filteredData, selectedAssetsSet)
    return result
  }, [filteredData, assetReportFilters])

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAssetReportFilters((prev) => ({ ...prev, hierarchySearchText: event.target.value }))
    },
    [setAssetReportFilters]
  )

  const handleNodeSelection = useDeepCompareCallback(
    (selectedIds: string[]) => {
      // Step 1: Build maps with a single pass
      const { /*nodeMap,*/ assetMap } = buildTreeMaps(filteredData)

      // Step 2: Collect selected assets
      const newAssetIds = new Set<string>()
      selectedIds.forEach((id) => assetMap.get(id)?.forEach((assetId) => newAssetIds.add(assetId)))

      // Step 3: Keep existing selections not in the filtered view
      // assetReportFilters.assets.forEach((assetId) => {
      //   if (!nodeMap.has(assetId)) {
      //     newAssetIds.add(assetId)
      //   }
      // })

      setAssetReportFilters((prev) => ({ ...prev, assets: Array.from(newAssetIds) }))
    },
    [filteredData, assetReportFilters]
  )

  return (
    <StyledAssetSelectionContainer data-testid={dataTestId.reportGeneratorAssetSelection}>
      <StyledCompanyLogoContainer data-testid={dataTestId.reportGeneratorCompanyLogo}>
        <StyledCompanyLogo>
          <StyledReportGeneratorLogo src={reportGeneratorLogoUrl || placeholderLogoSVG} alt="Company Logo" />
        </StyledCompanyLogo>
      </StyledCompanyLogoContainer>

      <StyledAssetHierarchyContainer
        data-testid={dataTestId.reportGeneratorAssetSelectionHierarchyContainer}
        isPdfGenerationInProgress={isPdfGenerationInProgress}
        feJustifyContent="center"
        feGap="xs"
        feAlignItems="center"
      >
        <StyledAssetHierarchyHeader feAlignItems="top" feJustifyContent="center">
          <Text feFontSize="lg">Select Assets to Report</Text>
        </StyledAssetHierarchyHeader>

        <StyledAssetHierarchySearchContainer feJustifyContent="center" feFlexDirection="column">
          <StyledAssetHierarchySearch
            feLabel=""
            value={assetReportFilters.hierarchySearchText}
            onChange={handleSearchChange}
            placeholder="Search..."
            data-testid={dataTestId.reportGeneratorAssetSelectionSearch}
          />
          <StyledAssetHierarchySearchResultCount feFontSize="sm">
            {`Number of assets: ${countLeafOrSingleNodesAssetHierarchy(filteredData)}`}
          </StyledAssetHierarchySearchResultCount>
        </StyledAssetHierarchySearchContainer>

        <StyledAssetHierarchySelect feJustifyContent="start">
          {filteredData.id && (
            <NewHierarchyTree
              nodeData={filteredData}
              value={visibleSelectedIds}
              onChange={handleNodeSelection}
              data-testid={dataTestId.reportGeneratorAssetSelectionHierarchyTree}
            />
          )}
        </StyledAssetHierarchySelect>
      </StyledAssetHierarchyContainer>
    </StyledAssetSelectionContainer>
  )
}

export default AssetSelection

import { useFaultEvidenceContext } from '@/contexts/moduleContexts/evidence'
import { useFaultsFormStateContext } from '@/contexts/moduleContexts/faultsForm'
import { useReportStatusContext } from '@/contexts/reportStatus'
import { ImageFileNameData } from '@/models/reportStatus/evidenceTypes'
import useUpdateFaultEvidenceImageData from '@/modules/reportStatus/hooks/useUpdateFaultEvidenceImageData'
import { UPDATE_EVIDENCE_IMAGE_DATA } from '@/modules/reportStatus/reducer/actions.types'
import { StyledEvidenceImageDetails } from '@/modules/reportStatus/styled'
import { FileDropzoneImageData } from '@/shared/components/dropzone/types'
import TextEllipsis, { TextEllipsisType } from '@/shared/components/textEllipsis'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import useDeepCompareCallback from '@/shared/hooks/useDeepCompareCallback'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import useOutsideClick from '@/shared/hooks/useOutsideClick'
import { extractFileNameAndExtension } from '@/shared/fileUtils'
import { dataTestId } from '@/tests/testid'
import { Button, Flexbox, TextField } from '@skf-design-system/ui-components-react'
import { FC, useRef, useState } from 'react'

interface ImageCardDetailsProps {
  imageDetails: FileDropzoneImageData
}

const ImageCardDetails: FC<ImageCardDetailsProps> = ({ imageDetails }) => {
  const [enableEditFileName, setEnableEditFileName] = useState(false)
  const [editedFileData, setEditedFileData] = useState<ImageFileNameData>(() =>
    extractFileNameAndExtension(imageDetails.imageInfo.fileName)
  )

  const { faultEditModeData } = useReportStatusContext()
  const { faultId } = useFaultEvidenceContext()
  const { faultsFormDispatch } = useFaultsFormStateContext()

  const fullFileName = useDeepCompareMemo(
    () => Object.values(editedFileData).filter(Boolean).join('.'),
    [editedFileData]
  )

  const { execute: updateImage } = useUpdateFaultEvidenceImageData(imageDetails, fullFileName)

  const submitEditedFileName = useDeepCompareCallback(async () => {
    await updateImage()
    const updatedImageDetails = { ...imageDetails, imageInfo: { ...imageDetails.imageInfo, fileName: fullFileName } }

    faultsFormDispatch({
      type: UPDATE_EVIDENCE_IMAGE_DATA,
      payload: updatedImageDetails,
    })
    setEnableEditFileName(false)
  }, [editedFileData, imageDetails, updateImage])

  const outsideClickRef = useRef<HTMLDivElement>(null)

  useOutsideClick(
    () => {
      setEnableEditFileName(false)
      const { fileName } = extractFileNameAndExtension(imageDetails.imageInfo.fileName)
      setEditedFileData((prevState) => ({
        ...prevState,
        fileName: fileName,
      }))
    },
    {
      classNames: [],
      refs: [outsideClickRef],
    }
  )

  return (
    <StyledEvidenceImageDetails data-testid={dataTestId.evidenceUploadedImageDetails}>
      {enableEditFileName && faultEditModeData[faultId] ? (
        <Flexbox feAlignItems="flex-end" feGap="sm" ref={outsideClickRef}>
          <TextField
            feLabel=""
            feSize="sm"
            value={editedFileData.fileName}
            data-testid={dataTestId.evidenceUploadedImageCardEditNameInput}
            onChange={(e) => setEditedFileData({ ...editedFileData, fileName: e.target.value })}
          />
          <Button
            feSize="sm"
            onClick={() => submitEditedFileName()}
            data-testid={dataTestId.evidenceUploadedImageCardEditNameSaveButton}
            disabled={Object.values(editedFileData).filter(Boolean).length < 2}
          >
            Save
          </Button>
        </Flexbox>
      ) : (
        <>
          <div onClick={() => setEnableEditFileName(true)}>
            <TextEllipsis
              type={TextEllipsisType.SingleLine}
              value={fullFileName}
              characterLength={30}
              fontSizeInRem={'0.75rem'}
            />
          </div>
          <span>{formatDate(imageDetails.imageInfo.fileCreatedAt, DateFormats.AmericanDateFormat)}</span>
        </>
      )}
    </StyledEvidenceImageDetails>
  )
}

export default ImageCardDetails

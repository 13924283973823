import { AnalysisReasonFaultData, ReasonIndicatorProps } from '@/models/analysisBacklog/types'
import { StyledFaultedAlarmsPopup, StyledReasonIndicator } from '@/modules/analysisBacklog/styled'
import { deviceNumberFormatter } from '@/modules/devicesSensors/utils/constants'
import Badge from '@/shared/components/badge'
import { ROUTES } from '@/shared/constants'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import useRedirectToModuleWithSelectedCustomer from '@/shared/hooks/useRedirectToModuleWithSelectedCustomer'
import useScrollDetector from '@/shared/hooks/useScrollDetection'
import { pushRedirectData } from '@/store/redirectData/actions'
import { useAppDispatch } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { Divider, Flexbox, Icon, Text, useTooltip } from '@skf-design-system/ui-components-react'
import { FC, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'

const ReasonIndicator: FC<ReasonIndicatorProps> = ({
  reasonIcon,
  reasonSeverity,
  reasonText,
  reasonCount,
  withRedirect = false,
  redirectUrl,
  withBadge = false,
  customStyle,
  onClick,
  innerRedirectModule,
  faultedData,
  droppedFaultsDescription,
}) => {
  const { sid } = useParams()
  const { pathname } = useLocation()
  const [showTooltip, setShowTooltip] = useState(false)

  const redirectToInnerModule = useRedirectToModuleWithSelectedCustomer()
  const reasonIndicatorDispatch = useAppDispatch()

  const navigateToInnerModule = (innerRedirectModule: ROUTES, faultedData: AnalysisReasonFaultData[]) => {
    if (sid) {
      reasonIndicatorDispatch(
        pushRedirectData({
          sid,
          fromModule: ROUTES.ANALYSIS_BACKLOG,
          toModule: innerRedirectModule,
          columnFilters: [{ id: 'deviceNumber', value: faultedData.map((data) => data.deviceNumber) }],
        })
      )

      redirectToInnerModule(innerRedirectModule, sid)
    }
  }

  const iconData = useDeepCompareMemo(() => {
    let result: typeof reasonIcon = reasonIcon

    if (!result) {
      switch (reasonSeverity) {
        case 'alarm':
          result = { iconName: 'danger', iconColor: 'red' }
          break
        case 'alert':
          result = { iconName: 'warning', iconColor: 'orange' }
      }
    }
    return result
  }, [reasonIcon])

  const enableRedirect = !!(withRedirect && redirectUrl)

  const iconElement = useDeepCompareMemo(() => {
    let result = <Icon feIcon={iconData.iconName} feColor={iconData.iconColor} feSize="sm" />
    if (withBadge) {
      result = (
        <Badge badgeContent="" bgColor="red">
          {result}
        </Badge>
      )
    }
    return result
  }, [iconData, withBadge])

  const onReasonIndicatorClick = () => {
    onClick && onClick()
    enableRedirect &&
      Object.assign(document.createElement('a'), {
        target: '_blank',
        href: redirectUrl,
      }).click()
    faultedData &&
      faultedData.length > 0 &&
      faultedData[0].deviceNumber &&
      innerRedirectModule &&
      navigateToInnerModule(innerRedirectModule, faultedData)
  }

  const [hoveredRef, isHovered] = useTooltip()
  const { isScrolling } = useScrollDetector(hoveredRef.current)

  useEffect(() => {
    setShowTooltip(isHovered && !isScrolling)
  }, [isHovered, isScrolling])

  return (
    <>
      <StyledReasonIndicator
        style={{ ...customStyle }}
        withRedirect={enableRedirect || !!(innerRedirectModule && sid && faultedData && faultedData.length > 0)}
        onClick={onReasonIndicatorClick}
        feNoPadding
        data-testid={dataTestId.reasonIndicator}
        ref={hoveredRef}
      >
        {iconElement}
        <span>{`${reasonText}`}</span>
        {reasonCount > 0 && (
          <>
            <Divider data-testid={dataTestId.reasonIndicatorCount} feVertical={true} as="div" feSpacing="xxs" />{' '}
            <span>{reasonCount}</span>
          </>
        )}

        {enableRedirect && <Icon feIcon="openInNew" feSize="sm" />}
      </StyledReasonIndicator>
      {innerRedirectModule && pathname.includes(ROUTES.ANALYSIS_BACKLOG) && (
        <StyledFaultedAlarmsPopup isHovered={showTooltip} triggerElement={hoveredRef.current}>
          <Flexbox feFlexDirection="column" feGap="sm" data-testid={dataTestId.reasonIndicatorFilterPopover}>
            {(faultedData &&
              faultedData.length > 0 &&
              faultedData.map(({ deviceNumber, deviceName, channelNumber, channelName, faults }) => (
                <Text key={deviceNumber}>
                  {`${deviceNumberFormatter.format(deviceNumber)} 
                ${deviceName}, 
                ${deviceNumberFormatter.format(channelNumber)} 
                ${channelName} 
                ${(faults && faults?.map((f) => (droppedFaultsDescription?.includes(f) ? '' : `,${f}`))) || ''}`}
                </Text>
              ))) ||
              'Measurement point not associated with an IMX8 / IMX16'}
          </Flexbox>
        </StyledFaultedAlarmsPopup>
      )}
    </>
  )
}

export default ReasonIndicator

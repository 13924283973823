import { ChannelFaultEnum, ChannelInterface, ChannelStatusEnum } from '@/models/devicesAndSensors/types'
import ChannelDataFault from '@/modules/devicesSensors/components/sideMenu/channelSection/channelDataFault'
import { dataTestId } from '@/tests/testid'
import { Flexbox } from '@skf-design-system/ui-components-react'
import { FC } from 'react'

const ChannelStatusIndicator: FC<{
  faults: ChannelInterface['faults']
  status: ChannelStatusEnum
}> = ({ faults, status }) => {
  return (
    <Flexbox feFlexDirection="column" feGap="xxs" data-testid={dataTestId.channelStatusIndicator}>
      {status === ChannelStatusEnum.ChannelNotConnected ? (
        <ChannelDataFault status={ChannelStatusEnum.ChannelNotConnected} />
      ) : faults.length > 0 ? (
        faults.map((f) => (
          <ChannelDataFault
            status={
              f.fault === ChannelFaultEnum.NoMeasurementEver
                ? ChannelStatusEnum.NoSensorData
                : ChannelStatusEnum.NotNormal
            }
            fault={f.fault}
            key={f.fault}
          />
        ))
      ) : (
        status === ChannelStatusEnum.Normal && <ChannelDataFault status={ChannelStatusEnum.Normal} />
      )}
    </Flexbox>
  )
}

export default ChannelStatusIndicator

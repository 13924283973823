import ModuleHeader from '@/shared/components/moduleHeader'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { setModuleHeaderConfigData } from '@/store/moduleHeader/actions'
import { useAppDispatch } from '@/store/store'

const OverviewHeader = () => {
  const overviewHeaderDispatch = useAppDispatch()
  useDeepCompareEffect(() => {
    overviewHeaderDispatch(
      setModuleHeaderConfigData({
        showHeader: true,
        showInfoPanel: false,
        showActionPanel: false,
        title: 'Overview',
        showBackButton: false,
        infoPanelData: [],
        infoPanelElement: undefined,
        subtitle: '',
        showSubtitle: false,
      })
    )
  }, [])

  return <ModuleHeader />
}

export default OverviewHeader

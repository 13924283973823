import React from 'react'
import { dataTestId } from '@/tests/testid'
import './bearingSpinner.css'

type BearingSpinnerProps = {
  large?: boolean
  color?: string
}
/**
 * The Bearing spinner component is a animated div located in the module’s header.
 * To display it, you must configure its appearance using the module header action panel reducer.
 *
 * @param {boolean} [props.large=false] - Indicates whether the spinner should be large.
 * @param {string} [props.color='#676F7C'] - The color of the spinner. `color` should be a hex value
 * @returns {JSX.Element} - The rendered bearing spinner component.
 */
const BearingSpinner: React.FC<BearingSpinnerProps> = ({ large = false, color = '#676F7C' }) => {
  return (
    <div data-testid={dataTestId.bearingSpinner} className={`bearing-spinner${large ? '-large' : ''}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1"
        width="100%"
        height="100%"
        viewBox="0 0 52 52"
        xmlSpace="preserve"
        preserveAspectRatio="none"
      >
        <g id="Bearing" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="balls" className="skf-bearing-balls" fill={color}>
            <circle id="10" cx="14.25" cy="9.8" r="4" />
            <circle id="9" cx="7" cy="19.8" r="4" />
            <circle id="8" cx="7" cy="32.2" r="4" />
            <circle id="7" cx="14.25" cy="42.2" r="4" />
            <circle id="6" cx="26" cy="46" r="4" />
            <circle id="5" cx="37.75" cy="42.2" r="4" />
            <circle id="4" cx="45" cy="32.2" r="4" />
            <circle id="3" cx="45" cy="19.8" r="4" />
            <circle id="2" cx="37.75" cy="9.8" r="4" />
            <circle id="1" cx="26" cy="6" r="4" />
          </g>
          <path
            d="M26,10 C34.836556,10 42,17.163444 42,26 C42,34.836556 34.836556,42 26,42 C17.163444,42 10,34.836556 10,26 C10,17.163444 17.163444,10 26,10 Z M26,12 C18.2680135,12 12,18.2680135 12,26 C12,33.7319865 18.2680135,40 26,40 C33.7319865,40 40,33.7319865 40,26 C40,18.2680135 33.7319865,12 26,12 Z"
            id="Inner"
            fill={color}
          />
          <path
            d="M26,0 C40.3594035,0 52,11.6405965 52,26 C52,40.3594035 40.3594035,52 26,52 C11.6405965,52 0,40.3594035 0,26 C0,11.6405965 11.6405965,0 26,0 Z M26,2 C12.745166,2 2,12.745166 2,26 C2,39.254834 12.745166,50 26,50 C39.254834,50 50,39.254834 50,26 C50,12.745166 39.254834,2 26,2 Z"
            id="Outer"
            fill={color}
          />
        </g>
      </svg>
    </div>
  )
}

export default BearingSpinner

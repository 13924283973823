import { ReportStatusLoaderData } from '@/app/routes/types'
import {
  StyledHistoryBlock,
  StyledHistoryContainer,
  StyledHistoryRowLeftContainer,
  StyledHistoryRowLeftInnerContainer,
  StyledHistoryRowPrefix,
  StyledWrapperHistoryRow,
} from '@/modules/reportStatus/styled'
import { convertStructure } from '@/modules/reportStatus/utils'
import { uniqueIdPrefixable } from '@/shared/utils'
import { FC, Fragment, useMemo } from 'react'
import { useLoaderData } from 'react-router'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { HistoryDataContainer } from './HistoryDataContainer'
import { FaultHistoryContainer } from './FaultHistoryContainer'
import { ReconfirmHistoryContainer } from './ReconfirmHistoryContainer'
import HistoryLinePrefix from './HistoryLinePrefix'
import { SPACINGS } from '@skf-design-system/ui-components-react'
import { dataTestId } from '@/tests/testid'
import { AssetStatus } from '@/modules/reportStatus/components/history/AssetStatus'

const History: FC<{ assetId: string }> = ({ assetId }) => {
  const { assetFaultsAndHistory } = useLoaderData() as ReportStatusLoaderData
  const { history } = assetFaultsAndHistory

  const convertedHistory = useDeepCompareMemo(() => convertStructure(history), [history])
  const faultIds = useMemo(
    () => assetFaultsAndHistory.history.map((_) => uniqueIdPrefixable('fault_')),
    [assetFaultsAndHistory]
  )

  if (assetFaultsAndHistory === undefined || assetFaultsAndHistory.history.length === 0) {
    return null
  }

  return (
    <StyledHistoryContainer feFlexDirection="column" feGap="xxl">
      {convertedHistory.map((snapshot, index) => (
        <Fragment key={index}>
          <ReconfirmHistoryContainer snapshot={snapshot} />
          <StyledHistoryBlock
            key={faultIds[index]}
            feAlignItems="flex-start"
            feGap="sm"
            feJustifyContent="space-between"
            data-testid={dataTestId.historyContainer}
          >
            <StyledHistoryRowLeftContainer feJustifyContent="space-between" feFlexDirection="column">
              <StyledHistoryRowLeftInnerContainer feFlexDirection="row">
                <StyledWrapperHistoryRow
                  data-testid={dataTestId.historyRowWrapper}
                  isCondensed={Array.isArray(snapshot)}
                >
                  <StyledHistoryRowPrefix feFlexDirection="column" feAlignItems="center">
                    <AssetStatus snapshot={snapshot} />
                    {index !== convertedHistory.length - 1 && <HistoryLinePrefix marginTop={SPACINGS.xs} />}
                  </StyledHistoryRowPrefix>
                  <HistoryDataContainer snapshot={snapshot} />
                </StyledWrapperHistoryRow>
              </StyledHistoryRowLeftInnerContainer>
            </StyledHistoryRowLeftContainer>
            <FaultHistoryContainer snapshot={snapshot} assetId={assetId} />
          </StyledHistoryBlock>
        </Fragment>
      ))}
    </StyledHistoryContainer>
  )
}
export default History

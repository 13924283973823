import { useEffect, useState } from 'react'
import { useDashboardContext } from '@/contexts/dashboard'
import { FaultsApiData } from '@/models/widgets/asset-health-fault/types'
import { useEffectOnce } from '@/shared/hooks/useEffectOnce'
import { Flexbox, GridItem, Heading, Text } from '@skf-design-system/ui-components-react'
import { useLocation } from 'react-router'
import StackedBar from './stacked-bar'
import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { dataTestId } from '@/tests/testid'
import { mapOpenFaultsData, updateFaultTypeCounts } from '@/modules/dashboard/utils/asset-health-fault'
import { Action, FaultTypeSelectOption } from '@/models/dashboard/globalFiltering/types'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import useWidgets from '@/modules/dashboard/hooks/widgets/useWidgetsHook'
import { addWidgetType, removeWidgetLoader } from '@/store/loading/action'
import GenericLoader, { GenericLoaderType } from '@/shared/components/genericLoader'
import {
  AssetFaultsWrapper,
  AssetMessageWrapper,
  FaultWrapper,
  HeaderWidgetWrapper,
  TextEllipsisStyled,
  WidgetAssetHealthFaultDataWrapper,
  WidgetContainer,
} from './styled'
import { WidgetType } from '@/models/dashboard/types'
import { TextEllipsisType } from '@/shared/components/textEllipsis'
import { loadersSelector } from '@/store/selectors/loadersSelector'

const AssetHealthFault = () => {
  const [selectedFault, setSelectedFault] = useState<FaultsApiData>()
  const [selected, setSelected] = useState<number>(0)
  const location = useLocation()

  const { fetchDataStatus } = useFetchDataContext()
  const { widgetAssetHealthFaultData, modifiedOpenFaultsData, faultTypeSelectOptions, setFaultTypeSelectOptions } =
    useDashboardContext()

  const { widgetTypes } = useTypedSelector((state) => loadersSelector(state))

  const reportFaultTypes = useTypedSelector((state) => state.reportFaultTypes)

  const {
    widgetFaults: { status },
  } = useWidgets()
  const openFaultsDispatch = useAppDispatch()

  const handleOpenFaultClick = (index: number) => {
    setSelected(index)
  }

  useEffectOnce(() => {
    if (location?.state?.selected) {
      setSelected(location?.state?.selected)
    }
  })

  useEffect(() => {
    setSelectedFault(widgetAssetHealthFaultData[selected])
  }, [selected, widgetAssetHealthFaultData])

  useDeepCompareEffect(() => {
    if (widgetAssetHealthFaultData.length === 0) {
      setSelectedFault(undefined)
    } else if (widgetAssetHealthFaultData.length <= selected) {
      setSelected(0)
    } else {
      setSelectedFault(widgetAssetHealthFaultData[selected])
    }
  }, [widgetAssetHealthFaultData])

  useDeepCompareEffect(() => {
    if (modifiedOpenFaultsData.length !== 0) {
      if (faultTypeSelectOptions.length === 0) {
        const optionsData = mapOpenFaultsData(modifiedOpenFaultsData, reportFaultTypes, Action.FaultTypeSelectOptions)
        setFaultTypeSelectOptions(optionsData as FaultTypeSelectOption[])
      }
      const updatedOptions = updateFaultTypeCounts(modifiedOpenFaultsData, faultTypeSelectOptions)
      if (updatedOptions.length !== 0) {
        setFaultTypeSelectOptions(updatedOptions as FaultTypeSelectOption[])
      }
    }
  }, [modifiedOpenFaultsData])

  useDeepCompareEffect(() => {
    if ((status === 'success' || status === 'not-executed') && widgetTypes.includes(WidgetType.ASSET_OPEN_FAULTS)) {
      openFaultsDispatch(removeWidgetLoader(WidgetType.ASSET_OPEN_FAULTS))
    }

    if (fetchDataStatus === 'loading') {
      openFaultsDispatch(addWidgetType(WidgetType.ASSET_OPEN_FAULTS))
    }
  }, [status, widgetTypes, fetchDataStatus])

  const NoAssetsMessage = () => (
    <AssetMessageWrapper feFlexDirection="column" feGap="xxs" feAlignItems="center" feJustifyContent="center">
      <Flexbox feFlexDirection="row" feJustifyContent="center">
        <Text feColor="gray300" feFontWeight="bold" feFontSize="xl">
          No assets
        </Text>
      </Flexbox>
    </AssetMessageWrapper>
  )

  if (widgetTypes.includes(WidgetType.ASSET_OPEN_FAULTS)) return <GenericLoader loaderType={GenericLoaderType.WIDGET} />

  return (
    <WidgetContainer data-testid={dataTestId.assetHealthFaultWidget}>
      <HeaderWidgetWrapper>
        <Flexbox feFlexDirection="column" feGap="xs">
          {/* <StyledWrapperOpenFaults> */}
          <Heading as="h2">Open Faults</Heading>
          {/* <WidgetRefreshButton<GetFaultsResponse> executeFn={execute} widgetType={WidgetType.ASSET_OPEN_FAULTS} /> */}
          {/* </StyledWrapperOpenFaults> */}
        </Flexbox>
      </HeaderWidgetWrapper>
      <AssetFaultsWrapper>
        {Array.from({ length: 10 }).map((_, index) => (
          <GridItem key={index} data-testid={`gridItem-${index}`}>
            <WidgetAssetHealthFaultDataWrapper
              isSelected={selected === index}
              hasFaultData={!!widgetAssetHealthFaultData[index]}
              feFlexDirection="column"
              onClick={() => widgetAssetHealthFaultData[index] && handleOpenFaultClick(index)}
            >
              {widgetAssetHealthFaultData[index] && (
                <Flexbox feFlexDirection="column" feGap="xxs" feAlignItems="center">
                  <Flexbox feFlexDirection="row" feJustifyContent="center">
                    <Text feColor="blueDark" feFontWeight="bold" feFontSize="xxl">
                      {widgetAssetHealthFaultData[index].count || '-'}
                    </Text>
                  </Flexbox>
                  <Flexbox feFlexDirection="row" feJustifyContent="center">
                    <TextEllipsisStyled
                      type={TextEllipsisType.MultiLine}
                      isSelected={selected === index}
                      rowsUntilEllipsis={
                        !widgetAssetHealthFaultData[index]?.faultType?.includes(' ') &&
                        widgetAssetHealthFaultData[index]?.faultType?.length > 12
                          ? 1
                          : 3
                      }
                      value={widgetAssetHealthFaultData[index].faultType || '-'}
                    />
                  </Flexbox>
                </Flexbox>
              )}
            </WidgetAssetHealthFaultDataWrapper>
          </GridItem>
        ))}
      </AssetFaultsWrapper>
      <FaultWrapper feFlexDirection="column">
        {selectedFault ? (
          selectedFault.assets.map((asset, i) => (
            <StackedBar key={i} asset={asset} faultType={selectedFault.faultType} selected={selected} />
          ))
        ) : (
          <NoAssetsMessage />
        )}
      </FaultWrapper>
    </WidgetContainer>
  )
}

export default AssetHealthFault

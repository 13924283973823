import { getRowBackgroundColor } from '@/shared/utils'
import { COLORS } from '@skf-design-system/ui-components-react'

export const openRecommendationsTableHeaderProps = {
  sx: {
    opacity: 1,
    '& th': {
      backgroundColor: COLORS.gray100,
      padding: '1rem 0.5rem',
      justifyContent: 'center',
      alignItems: 'center',
      '& div': {
        fontWeight: 700,
        marginRight: '0.1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        minHeight: '1.5rem',
      },
      '& .Mui-TableHeadCell-Content-Wrapper > div': {
        marginTop: '0.22rem',
      },
    },
    '& th:last-of-type': {
      paddingLeft: 0,
    },
  },
}

export const openRecommendationsTableContainerProps = {
  sx: {
    maxHeight: 'calc(100vh - 20.5rem)',
    marginTop: '1.5rem',
  },
}

export const openRecommendationsTablePaperProps = {
  sx: {
    boxShadow: 'none',
  },
}

export const openRecommendationsTableHeadCellProps = (isActive: boolean) => ({
  sx: {
    '& .MuiTableSortLabel-root': {
      color: COLORS.gray500,
      pointerEvents: isActive ? 'auto' : 'none',
      cursor: isActive ? 'pointer' : 'not-allowed',
      '& :nth-of-type(1)': {
        marginTop: '0.1rem',
      },
    },
    '& button': {
      pointerEvents: isActive ? 'auto' : 'none',
      cursor: isActive ? 'pointer' : 'not-allowed',
    },
  },
})

export const openRecommendationsTableBodyCellProps = {
  sx: {
    lineHeight: '1.2rem',
    padding: '0.5rem',
    justifyContent: 'flex-start',
    ':nth-of-type(1)': {
      paddingLeft: '1.5rem',
    },
    '&:focus-visible': {
      outline: 'none',
    },
  },
}

export const openRecommendationsTableBodyRowProps = (
  isDetailPanel: boolean,
  rowIndex: number,
  isSelected: boolean,
  isLoading: boolean
) => ({
  sx: {
    border: `1px solid transparent`,
    backgroundColor: () => {
      let rowColor = 'transparent'

      if (isLoading) {
        rowColor = getRowBackgroundColor(!!(rowIndex % 2), false)
      }

      if (!isDetailPanel && !isLoading) {
        rowColor = getRowBackgroundColor(!!(rowIndex % 2), isSelected)
      }
      return rowColor
    },
    ':hover': {
      cursor: 'pointer',
      backgroundColor: !isSelected && !isLoading && COLORS.primary200,
      border: !isLoading && `1px solid ${COLORS.brand}`,
      '& td': {
        ':after': {
          backgroundColor: 'transparent',
        },
      },
    },
    '& .MuiTableCell-root': {
      '&:focus-visible': {
        outline: 'none',
      },
    },
  },
})

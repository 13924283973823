import styled from '@emotion/styled'
import { COLORS, SPACINGS } from '@skf-design-system/ui-components-react'
import { severities } from '@/modules/dashboard/utils/constants'
import { Boldness } from '@/shared/constants'
import { Severity } from '@/models/widgets/asset-health-condition/types'
import { severityColor } from '@/shared/utils'

export const StyledAHCWidgetTable = styled.table<{
  headerWidth?: number | string
}>`
  width: 100%;
  table-layout: fixed;
  font-weight: ${Boldness.Bold};
  font-size: 14px;
  text-align: center;
  border-spacing: 0;
  margin-bottom: -0.136rem;

  & th {
    width: ${(props) => props.headerWidth};
    padding-bottom: 5px;

    & div {
      width: 100%;
      display: flex;
      place-content: end right;
      padding-right: ${SPACINGS.xs};
    }
  }
`
export const StyledAHCWidgetContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${SPACINGS.md};
`

export const StyledAHCWidgetContent = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: 4fr 2fr;
`

export const StyledAHCNoDataContainer = styled.div`
  display: flex;
  height: 50%;
  background-color: ${COLORS.gray050};
  justify-content: center;
  font-weight: ${Boldness.Bold};
  font-size: 14px;
  color: #aaafb8;
  margin-left: 78px;
  padding-top: 40px;
`
export const StyledAHCWidgetHeading = styled.div`
  display: flex;
  justify-content: space-between;
`
export const StyledAHCTrendViewContainer = styled.div`
  display: flex;
  padding-right: 20px;
  width: 50.9rem;
  height: 20.5rem;
`
export const StyledAHCTrendViewContent = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`

export const StyledAHCCurrentMonthStatusContainer = styled.div`
  padding: 1.5rem;
  padding-left: 2rem;
  background-color: #e6eefb;
  height: 17.7rem;
  width: 24.5rem;
  margin-left: 1rem;
`

const graphTitleHeight = SPACINGS.md
const graphHeight = SPACINGS.xxl
const spacing = SPACINGS.xs

export const StyledAHCCurrentMonthStatusContent = styled.div`
  display: grid;
  column-gap: ${SPACINGS.xs};
  grid-template-columns: 1fr 3fr;
  grid-template-rows:
    repeat(${severities.length - 1}, ${graphTitleHeight} ${graphHeight} ${spacing})
    ${graphTitleHeight} ${graphHeight};
`

export const StyledAHCContentRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 0.7rem;
  column-gap: ${SPACINGS.xs};
`
export const StyledAHCContentStatusRow = styled.div`
  font-size: 14px;
  font-weight: 700;
`
export const StyledAHCContentStatusRowName = styled.div`
  font-size: 10px;
  font-weight: bold;
`
export const StyledAHCPieChartContainer = styled.div`
  width: 100%;
  height: 210px;
`
export const StyledAHCTableCell = styled.td<{ isEmpty: boolean; isNonNegative: boolean; severity: Severity }>`
  background-color: ${(props) => (props.isEmpty ? COLORS.gray050 : '')};
  color: ${(props) => (props.isNonNegative ? severityColor(props.severity) : COLORS.gray500)};
  text-align: center;
  vertical-align: middle;
`
export const StyledAHCTableHeader = styled.th`
  text-align: right;
  padding-right: 0.4rem;
`

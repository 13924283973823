import { ACCEPTED_FILE_TYPES, MAX_FILE_SIZE } from '@/shared/components/dropzone/constants'
import { FileDropzoneImageData, FileDropzoneProps, FilesDropzoneCategory } from '@/shared/components/dropzone/types'
import { STORAGE_REPORT_STATUS_ORIGIN } from '@/shared/localStorageUtils'
import { getSidFromPathname } from '@/shared/utils'
import { createContext, FC, PropsWithChildren, useContext, useMemo, useState } from 'react'

interface IFilesDropzoneProps {
  dropzoneFiles: Record<string, FileDropzoneImageData[]>
  setDropzoneFiles: React.Dispatch<React.SetStateAction<IFilesDropzoneProps['dropzoneFiles']>>
  faultId: string
  setFaultId: React.Dispatch<React.SetStateAction<IFilesDropzoneProps['faultId']>>
  category: FilesDropzoneCategory
  setCategory: React.Dispatch<React.SetStateAction<IFilesDropzoneProps['category']>>
  dropzoneProps: FileDropzoneProps
  setDropzoneProps: React.Dispatch<React.SetStateAction<IFilesDropzoneProps['dropzoneProps']>>
  inputRef: React.RefObject<HTMLInputElement>
  setInputRef: React.Dispatch<React.SetStateAction<React.RefObject<HTMLInputElement>>>
  acceptFiles: string[]
  maxFileSize: number
}

export const useFilesDropzoneContext = () => {
  const context = useContext(FilesDropzoneContext)
  if (!context) {
    throw new Error('useDropDownArea must be used within an DropDownAreaProvider')
  }
  return context
}

const FilesDropzoneContext = createContext<IFilesDropzoneProps | null>(null)

interface FilesDropzoneProviderProps extends PropsWithChildren {
  category: FilesDropzoneCategory
  acceptFiles: string[]
  maxFileSize: number
}

export const FilesDropzoneProvider: FC<FilesDropzoneProviderProps> = ({
  children,
  category: currentCategory,
  acceptFiles: currentAcceptFiles = ACCEPTED_FILE_TYPES,
  maxFileSize: currentMaxFileSize = MAX_FILE_SIZE,
}) => {
  const [dropzoneFiles, setDropzoneFiles] = useState<Record<string, FileDropzoneImageData[]>>({})
  const [faultId, setFaultId] = useState(() => getSidFromPathname(sessionStorage.getItem(STORAGE_REPORT_STATUS_ORIGIN)))
  const [category, setCategory] = useState(currentCategory)
  const [dropzoneProps, setDropzoneProps] = useState<FileDropzoneProps>({})
  const [inputRef, setInputRef] = useState({} as React.RefObject<HTMLInputElement>)
  const [acceptFiles] = useState<string[]>(() => currentAcceptFiles)
  const [maxFileSize] = useState<number>(() => currentMaxFileSize)

  const value = useMemo(
    () => ({
      dropzoneFiles,
      setDropzoneFiles,
      faultId,
      setFaultId,
      category,
      setCategory,
      dropzoneProps,
      setDropzoneProps,
      inputRef,
      setInputRef,
      acceptFiles,
      maxFileSize,
    }),
    [dropzoneFiles, category, faultId, dropzoneProps, inputRef, acceptFiles, maxFileSize]
  )

  return <FilesDropzoneContext value={value}>{children}</FilesDropzoneContext>
}

import { AnalysisBacklogContextProvider, useAnalysisBacklogContext } from '@/contexts/analysisBacklog'
import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { InitialAsset, ResponseAssets } from '@/models/analysisBacklog/types'
import useGetAnalyticsBacklogDataNew from '@/modules/analysisBacklog/hooks/useGetAnalyticsBacklogDataNew'
import { StyledAnalysisBacklogRoot } from '@/modules/analysisBacklog/styled'
import useAnalysisBacklogTable from '@/modules/analysisBacklog/table/useAnalysisBacklogTable'
import AsyncPoller from '@/shared/components/asyncPoller'
import DataRefresher from '@/shared/components/dataRefresher'
import { DarwinError } from '@/shared/components/errorBoundary/darwinError'
import useResetTableFilters from '@/shared/components/tableComponents/hooks/useResetTableFilters'
import ThemeConsumer from '@/shared/components/themeConsumer'
import { ROUTES } from '@/shared/constants'
import { LoadingType } from '@/shared/hooks/useApi'
import { useAsyncThrow } from '@/shared/hooks/useAsyncThrow'
import useCleanupStorage from '@/shared/hooks/useCleanupStorage'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { stopAsyncPoller } from '@/store/asyncPoller/actions'
import { batchActions } from '@/store/batch/actions'
import { openToast } from '@/store/genericToast/actions'
import { setModuleHeaderActionPanelData } from '@/store/moduleHeader/actions'
import { deleteRedirectData, pushRedirectData } from '@/store/redirectData/actions'
import { asyncPollerSelector } from '@/store/selectors/asyncPollerSelector'
import { redirectDataBySidSelector } from '@/store/selectors/redirectDataSelector'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { MaterialReactTable } from 'material-react-table'
import { useEffect } from 'react'
import { useParams } from 'react-router'

const AnalysisBacklog = () => {
  return (
    <StyledAnalysisBacklogRoot>
      <AnalysisBacklogContextProvider>
        <InnerAnalysisBacklog />
      </AnalysisBacklogContextProvider>
    </StyledAnalysisBacklogRoot>
  )
}

const InnerAnalysisBacklog = () => {
  const { sid } = useParams()

  const previousPageData = useTypedSelector((state) => redirectDataBySidSelector(state, sid))
  const { isAsyncPollerActive, toastMessages } = useTypedSelector((state) => asyncPollerSelector(state))

  const analysisBacklogDispatch = useAppDispatch()
  const { setAnalysisBacklogData } = useAnalysisBacklogContext()

  const throwError = useAsyncThrow()
  const { setFetchDataFn, setFetchDataStatus } = useFetchDataContext()

  const analysisBacklogTable = useAnalysisBacklogTable()
  const resetTableFilters = useResetTableFilters<InitialAsset>(analysisBacklogTable)

  const { status, execute } = useGetAnalyticsBacklogDataNew(
    async (response: ResponseAssets) => {
      /* v8 ignore next 23 */
      if (isAsyncPollerActive) {
        if (response.handled.find((handledAsset) => handledAsset.id === previousPageData?.assetId)) {
          analysisBacklogDispatch(stopAsyncPoller())
          setAnalysisBacklogData(response)
          if (
            previousPageData &&
            previousPageData.toModule === undefined &&
            previousPageData.fromModule === undefined
          ) {
            analysisBacklogDispatch(
              pushRedirectData({
                ...previousPageData,
                sid: sid as string,
                toModule: ROUTES.REPORT_STATUS,
                fromModule: ROUTES.REPORT_STATUS,
              })
            )
          }
          setTimeout(() => {
            analysisBacklogDispatch(deleteRedirectData(sid as string))
          }, 1000)
        }
      } else {
        setAnalysisBacklogData(response)
      }
    },
    async (error) => {
      /* v8 ignore next 11 */
      if (isAsyncPollerActive) {
        analysisBacklogDispatch(
          batchActions([
            setModuleHeaderActionPanelData({ isAsyncIndicatorShown: false }),
            stopAsyncPoller(),
            openToast({ children: toastMessages.failedPollingMessage, feSeverity: 'error', timeout: 3 }),
          ])
        )
      } else {
        throwError(new DarwinError(error))
      }
    }
  )

  useCleanupStorage()

  useEffect(() => {
    setFetchDataFn(() => execute)
  }, [execute, setFetchDataFn])

  useDeepCompareEffect(() => {
    setFetchDataStatus(status)
  }, [status])

  useEffect(() => {
    if (sid && !isAsyncPollerActive && !previousPageData) {
      execute({ loaderType: LoadingType.NONE })
      resetTableFilters()
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sid])

  useEffect(() => {
    if (previousPageData && previousPageData.fromModule === ROUTES.REPORT_STATUS && previousPageData.assets) {
      setAnalysisBacklogData(previousPageData.assets)
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousPageData])

  return (
    <ThemeConsumer>
      <MaterialReactTable table={analysisBacklogTable} />
      <DataRefresher<ResponseAssets> functionToExecute={execute} />
      <AsyncPoller<ResponseAssets> pollerFn={execute} />
      <div data-testid={dataTestId.analysisBacklogModule} />
    </ThemeConsumer>
  )
}

export default AnalysisBacklog

import { getUsername } from '@/api/baseClientUtils'
import { useFaultsFormStateContext } from '@/contexts/moduleContexts/faultsForm'
import { useReportStatusContext } from '@/contexts/reportStatus'
import { Fault, RecommendedAction, RecommendedActionState } from '@/models/reportStatus/faultsFormStateTypes'
import FormMenu from '@/modules/reportStatus/components/formMenu'
import { MenuItem } from '@/modules/reportStatus/components/formMenu/types'
import { UPDATE_RECOMMENDED_ACTION } from '@/modules/reportStatus/reducer/actions.types'
import { StyledFaultMenu } from '@/modules/reportStatus/styled'
import { getEntityId } from '@/modules/reportStatus/utils/formUtils'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { STORAGE_REPORT_STATUS_ORIGIN } from '@/shared/localStorageUtils'
import { getSidFromPathname } from '@/shared/utils'
import { reportStatusPublishBySidSelector } from '@/store/selectors/sitesPermissionsSelector'
import { useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { FC, useRef } from 'react'

interface RecommendedActionMenuProps {
  recommendedAction: RecommendedAction
  parentFault: Fault
}

const RecommendedActionMenu: FC<RecommendedActionMenuProps> = ({ recommendedAction, parentFault }) => {
  const { state } = recommendedAction
  const faultId = useRef<string>(getEntityId(parentFault))

  const isInEditMode = useDeepCompareMemo(() => {
    return [RecommendedActionState.EDIT, RecommendedActionState.PENDING_CLOSE, RecommendedActionState.NEW].includes(
      state
    )
  }, [state])

  const { faultsFormDispatch } = useFaultsFormStateContext()
  const { setAnalyticsCancelButtonBehavior } = useReportStatusContext()

  const sid = getSidFromPathname(sessionStorage.getItem(STORAGE_REPORT_STATUS_ORIGIN))
  const shouldShowRecommendedActionMenu = useTypedSelector((state) => reportStatusPublishBySidSelector(state, sid))

  const editRecommendedAction = (state: RecommendedActionState) => {
    faultsFormDispatch({
      type: UPDATE_RECOMMENDED_ACTION,
      payload: {
        id: getEntityId(recommendedAction),
        state,
        faultId: faultId.current,
      },
    })
  }

  const closeRecommendedAction = async () => {
    if (state === RecommendedActionState.OPEN) {
      const username = await getUsername()
      faultsFormDispatch({
        type: UPDATE_RECOMMENDED_ACTION,
        payload: {
          id: getEntityId(recommendedAction),
          state: RecommendedActionState.PENDING_CLOSE,
          faultId: faultId.current,
          username,
          closeDate: formatDate(new Date(), DateFormats.ISO8601Format),
        },
      })
    }
  }

  const menuItems = useDeepCompareMemo<MenuItem[]>(() => {
    const items: MenuItem[] = []

    if (state === RecommendedActionState.OPEN && !isInEditMode) {
      items.push({
        label: 'Edit Recommended Action',
        icon: 'edit',
        onClick: () => {
          editRecommendedAction(RecommendedActionState.EDIT)
          setAnalyticsCancelButtonBehavior({ isDisabled: false })
        },
      })
      if (parentFault.recommendedActions.filter((ra) => ra.state === RecommendedActionState.OPEN).length > 1) {
        items.push({
          label: 'Close Recommended Action',
          icon: 'close',
          onClick: () => {
            setAnalyticsCancelButtonBehavior({ isDisabled: false })
            closeRecommendedAction()
          },
        })
      }
    }

    return items
  }, [isInEditMode, state, parentFault])

  return (
    <StyledFaultMenu feAlignItems="center" feJustifyContent="center" data-testid={dataTestId.recommendedActionMenu}>
      {menuItems.length > 0 && shouldShowRecommendedActionMenu && (
        <FormMenu items={menuItems} data-testid={dataTestId.recommendedActionFromMenu} />
      )}
    </StyledFaultMenu>
  )
}

export default RecommendedActionMenu

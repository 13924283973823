import {
  AutomaticDiagnosticsChartData,
  AutomaticDiagnosticsData,
  AutomaticDiagnosticsMappedChartData,
  MonthlyTrend,
} from '@/models/automaticDiagnostics/types'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { circularStringify, generateUUID, roundTo } from '@/shared/utils'
import { COLORS, Color, IconName } from '@skf-design-system/ui-components-react'
import { EChartsOption } from 'echarts'

const svgRichData: Partial<Record<IconName, string>> = {
  pin: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIGhlaWdodD0iNzUiIHZpZXdCb3g9IjAgMCAxMDAgNzUiIGZpbGw9IiNmNmY2ZjYiPgogIDxyZWN0IHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIC8+CiAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDAsIDI1KSI+CjxwYXRoIGZpbGw9IiMwMDAwMDAiIGQ9Ik0xOS42NTYgMTIuOTA2djIuMjgxaC0yMC4wOTR2LTIuMjgxaDIwLjA5NHoiLz4KPC9nPgo8L3N2Zz4=',
  arrowUp:
    'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iNzUiIHZpZXdCb3g9IjAgMCAzMCA3NSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSIjZjZmNmY2Ii8+CiAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOSwgMjYpIj4KPHBhdGggZD0iTTExLjk5OTggMjJDMTEuNzE2NSAyMiAxMS40NzkgMjEuOTA0MiAxMS4yODczIDIxLjcxMjVDMTEuMDk1NiAyMS41MjA4IDEwLjk5OTggMjEuMjgzMyAxMC45OTk4IDIxVjUuODI0OTlMNy4wOTk4IDkuNjk5OTlDNi45MTY0NyA5Ljg4MzMyIDYuNjg3MyA5Ljk3OTE1IDYuNDEyMyA5Ljk4NzQ5QzYuMTM3MyA5Ljk5NTgyIDUuODk5OCA5Ljg5OTk5IDUuNjk5OCA5LjY5OTk5QzUuNTE2NDcgOS41MTY2NSA1LjQyNDggOS4yODMzMiA1LjQyNDggOC45OTk5OUM1LjQyNDggOC43MTY2NSA1LjUxNjQ3IDguNDgzMzIgNS42OTk4IDguMjk5OTlMMTEuMjk5OCAyLjY5OTk5QzExLjM5OTggMi41OTk5OSAxMS41MDgxIDIuNTI5MTUgMTEuNjI0OCAyLjQ4NzQ5QzExLjc0MTUgMi40NDU4MiAxMS44NjY1IDIuNDI0OTkgMTEuOTk5OCAyLjQyNDk5QzEyLjEzMzEgMi40MjQ5OSAxMi4yNTgxIDIuNDQ1ODIgMTIuMzc0OCAyLjQ4NzQ5QzEyLjQ5MTUgMi41MjkxNSAxMi41OTk4IDIuNTk5OTkgMTIuNjk5OCAyLjY5OTk5TDE4LjI5OTggOC4yOTk5OUMxOC40ODMxIDguNDgzMzIgMTguNTc0OCA4LjcxMjQ5IDE4LjU3NDggOC45ODc0OUMxOC41NzQ4IDkuMjYyNDkgMTguNDgzMSA5LjQ5OTk5IDE4LjI5OTggOS42OTk5OUMxOC4wOTk4IDkuODk5OTkgMTcuODYyMyA5Ljk5OTk5IDE3LjU4NzMgOS45OTk5OUMxNy4zMTIzIDkuOTk5OTkgMTcuMDc0OCA5Ljg5OTk5IDE2Ljg3NDggOS42OTk5OUwxMi45OTk4IDUuODI0OTlWMjFDMTIuOTk5OCAyMS4yODMzIDEyLjkwNCAyMS41MjA4IDEyLjcxMjMgMjEuNzEyNUMxMi41MjA2IDIxLjkwNDIgMTIuMjgzMSAyMiAxMS45OTk4IDIyWiIgZmlsbD0iIzA0ODk0MiIvPgoKPC9nPgo8L3N2Zz4K',
  arrowDown:
    'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iNzUiIHZpZXdCb3g9IjAgMCAzMCA3NSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSIjZjZmNmY2Ii8+CiAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOSwgMjYpIj4KPHBhdGggZD0iTTExLjk5OTggMjEuNTc1QzExLjg2NjUgMjEuNTc1IDExLjc0MTUgMjEuNTU0MiAxMS42MjQ4IDIxLjUxMjVDMTEuNTA4MSAyMS40NzA4IDExLjM5OTggMjEuNCAxMS4yOTk4IDIxLjNMNS42OTk4IDE1LjdDNS41MTY0NyAxNS41MTY3IDUuNDI0OCAxNS4yODc1IDUuNDI0OCAxNS4wMTI1QzUuNDI0OCAxNC43Mzc1IDUuNTE2NDcgMTQuNSA1LjY5OTggMTQuM0M1Ljg5OTggMTQuMSA2LjEzNzMgMTQgNi40MTIzIDE0QzYuNjg3MyAxNCA2LjkyNDggMTQuMSA3LjEyNDggMTQuM0wxMC45OTk4IDE4LjE3NVYzQzEwLjk5OTggMi43MTY2NyAxMS4wOTU2IDIuNDc5MTcgMTEuMjg3MyAyLjI4NzVDMTEuNDc5IDIuMDk1ODMgMTEuNzE2NSAyIDExLjk5OTggMkMxMi4yODMxIDIgMTIuNTIwNiAyLjA5NTgzIDEyLjcxMjMgMi4yODc1QzEyLjkwNCAyLjQ3OTE3IDEyLjk5OTggMi43MTY2NyAxMi45OTk4IDNWMTguMTc1TDE2Ljg5OTggMTQuM0MxNy4wODMxIDE0LjExNjcgMTcuMzEyMyAxNC4wMjA4IDE3LjU4NzMgMTQuMDEyNUMxNy44NjIzIDE0LjAwNDIgMTguMDk5OCAxNC4xIDE4LjI5OTggMTQuM0MxOC40ODMxIDE0LjQ4MzMgMTguNTc0OCAxNC43MTY3IDE4LjU3NDggMTVDMTguNTc0OCAxNS4yODMzIDE4LjQ4MzEgMTUuNTE2NyAxOC4yOTk4IDE1LjdMMTIuNjk5OCAyMS4zQzEyLjU5OTggMjEuNCAxMi40OTE1IDIxLjQ3MDggMTIuMzc0OCAyMS41MTI1QzEyLjI1ODEgMjEuNTU0MiAxMi4xMzMxIDIxLjU3NSAxMS45OTk4IDIxLjU3NVoiIGZpbGw9IiNjYzAwMDAiLz4KPC9nPgo8L3N2Zz4K',
  removeMinus:
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3MCIgaGVpZ2h0PSI3NSIgdmlld0JveD0iMCAwIDcwIDc1IiBmaWxsPSJub25lIj4KICA8cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSIjZjZmNmY2Ii8+CiAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjUsIDI1KSI+CjxwYXRoIGZpbGw9IiMwMDAwMDAiIGQ9Ik0xOS42NTYgMTIuOTA2djIuMjgxaC0yMC4wOTR2LTIuMjgxaDIwLjA5NHoiLz4KPC9nPgo8L3N2Zz4=',
}

const findMarkAreaIndicies = (mappedChartData: AutomaticDiagnosticsMappedChartData[]): [number, number] | null => {
  if (mappedChartData.length === 0 || !isNaN(mappedChartData[0].value)) return null

  const end = mappedChartData.findIndex((chartData) => !isNaN(chartData.value))
  return [0, end === -1 ? mappedChartData.length - 1 : end - 1]
}

const calculateMarkArea = (markAreaIndicies: [number, number] | null) => {
  let markArea = null
  if (markAreaIndicies) {
    markArea = {
      data: [
        [
          {
            name: 'No data',
            coord: [markAreaIndicies[0]],
            label: {
              // <--- adjust label of markedArea here
              position: 'inside',
              fontSize: 16,
              color: COLORS.gray500,
              distance: 12,
            },
          },
          {
            coord: [markAreaIndicies[1]],
          },
        ],
      ],
      itemStyle: {
        color: COLORS.gray300,
        opacity: 0.3,
      },
      emphasis: {
        disabled: true,
      },
    }
  }
  return markArea
}

const generateAutomaticDiagnosticsChartData = (responseData: AutomaticDiagnosticsData) => {
  const result: AutomaticDiagnosticsChartData = {
    barData: [],
    monthlyTrend: [],
    automaticallyDiagnosedAssets: [],
    averageMonthlyTrend: 0,
    averageMonthlyAutomaticallyDiagnosedAssetsAmount: 0,
    averageMonthlyTrendDirection: 'arrowUp',
    barDate: [],
    id: generateUUID(),
  }
  const dataToWork = responseData.data.slice(-13)
  const prevMonthData = dataToWork.shift()

  dataToWork.reduce((acc, current, currentIndex) => {
    const barPercentage = current.automaticAssets / current.reportedAssets
    let arrowType = 'arrowUp' as MonthlyTrend['direction']
    let magnitude = 0
    if (currentIndex > 0) {
      const monthlyMagnitudeDivergence = barPercentage - result.barData[currentIndex - 1].value
      arrowType = (monthlyMagnitudeDivergence >= 0 ? 'arrowUp' : 'arrowDown') as MonthlyTrend['direction']

      magnitude = roundTo(Math.abs(monthlyMagnitudeDivergence) / result.barData[currentIndex - 1].value) * 100
      if (!(isFinite(magnitude) || isNaN(magnitude))) {
        magnitude = -100
      }
    } else if (prevMonthData) {
      const prevMonthDataBarData = prevMonthData.automaticAssets / prevMonthData.reportedAssets
      const monthlyMagnitudeDivergence = barPercentage - prevMonthDataBarData
      arrowType = (monthlyMagnitudeDivergence >= 0 ? 'arrowUp' : 'arrowDown') as MonthlyTrend['direction']

      magnitude = roundTo(Math.abs(monthlyMagnitudeDivergence) / prevMonthDataBarData) * 100
    }
    let barColor: Color = 'redBase'
    if (barPercentage >= 0.7) {
      barColor = 'greenBase'
    } else if (barPercentage > 0.5) {
      barColor = 'yellowDarker'
    }

    result.barData.push({
      value: barPercentage,
      color: COLORS[barColor],
      normalizedValue: +(roundTo(barPercentage) * 100).toFixed(0),
    })
    result.monthlyTrend.push({ magnitude, direction: arrowType })
    if (current.automaticAssets === 0 && current.reportedAssets === 0) {
      result.automaticallyDiagnosedAssets.push(NaN)
    } else {
      result.automaticallyDiagnosedAssets.push(current.automaticAssets)
    }

    result.barDate.push(formatDate(current.timeframeStart, DateFormats.MonthShortNameYearFormat).split(' ').join('\n'))
    return acc
  }, result)

  const monthsWithData = dataToWork.filter((data) => data.reportedAssets !== 0)
  const monthWithTrends = result.monthlyTrend.filter((data) => !isNaN(data.magnitude) && data.magnitude >= 0)

  result.averageMonthlyTrend = roundTo(
    Math.abs(
      monthWithTrends.reduce((acc, current) => {
        if (current.direction === 'arrowUp') {
          acc += current.magnitude
        } else {
          acc -= current.magnitude
        }
        return acc
      }, 0) / monthWithTrends.length
    )
  )

  result.averageMonthlyAutomaticallyDiagnosedAssetsAmount = Math.ceil(
    result.automaticallyDiagnosedAssets.filter((assets) => assets > 0).reduce((acc, current) => current + acc, 0) /
      monthsWithData.length
  )

  result.averageMonthlyTrendDirection =
    result.monthlyTrend
      .filter((trend) => !isNaN(trend.magnitude))
      .reduce((acc, current) => {
        if (current.direction === 'arrowUp') {
          acc = acc + current.magnitude
        } else {
          acc = acc - current.magnitude
        }
        return acc
      }, 0) > 0
      ? 'arrowUp'
      : 'arrowDown'

  return result
}

const getAutomaticDiagnosticsChartOptions = (automaticsDiagnosticsData: AutomaticDiagnosticsChartData) => {
  const chartData = automaticsDiagnosticsData.barData.map((barData) => {
    return {
      value: barData.normalizedValue,
      itemStyle: {
        color: barData.color,
      },
    }
  })

  const markAreaIndicies = findMarkAreaIndicies(chartData)

  const richData = [
    automaticsDiagnosticsData.barDate,
    automaticsDiagnosticsData.monthlyTrend,
    automaticsDiagnosticsData.automaticallyDiagnosedAssets,
  ].reduce((a, b) => a.map((v, i) => `${v}|${circularStringify(b[i])}`))

  return {
    grid: {
      top: '10%',
      left: '0',
      right: '0',
      bottom: '3%',
      containLabel: true,
    },

    legend: {
      right: 30,
      selectedMode: false,
      data: [
        {
          name: '0-50%',
          // compulsorily set icon as a circle
          icon: 'circle',
          // set up the text in red
          itemStyle: {
            color: COLORS.redBase,
          },
        },
        {
          name: '51-70%',
          // compulsorily set icon as a circle
          icon: 'circle',
          // set up the text in red
          itemStyle: {
            color: COLORS.yellowDarker,
          },
        },
        {
          name: '>70%',
          // compulsorily set icon as a circle
          icon: 'circle',
          // set up the text in red
          itemStyle: {
            color: COLORS.greenBase,
          },
        },
      ],
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: '{value}%',
      },
      max: 100,
      boundaryGap: ['0%', '100%'],
      splitNumber: 10,
    },
    xAxis: [
      {
        type: 'category',
        data: richData,
        axisLabel: {
          interval: 0,
          overflow: 'breakAll',
          formatter: (richDataIndexString, index) => {
            const [date, trend, autoDiagAsset] = richDataIndexString.split('|')
            const trendData = JSON.parse(trend) as MonthlyTrend
            let trendsDirection = `{arrowUpIcon|}`
            if (trendData.direction === 'arrowDown') {
              trendsDirection = `{arrowDownIcon|}`
            }

            if (
              (markAreaIndicies && markAreaIndicies.length > 0 && index <= markAreaIndicies[1]) ||
              isNaN(automaticsDiagnosticsData.monthlyTrend[index].magnitude)
            ) {
              return [
                `{date|${date}}`,
                `{space|}`,
                `{pinIcon|}`,

                `${JSON.parse(autoDiagAsset) !== null ? `{space2|}\n{assets|${autoDiagAsset}}` : `{space|}\n{pinIcon|}`}`,
              ].join('\n')
            } else if (automaticsDiagnosticsData.monthlyTrend[index].magnitude < 0) {
              return [
                `{date|${date}}`,
                `{space|}`,
                `${trendsDirection}{pinIconShort|}`,
                `${JSON.parse(autoDiagAsset) !== null ? `{space2|}\n{assets|${autoDiagAsset}}` : `{space|}\n{pinIcon|}`}`,
              ].join('\n')
            } else {
              const trendsValue = roundTo(trendData.magnitude)
              const trends = [trendsDirection, `{trends|${trendsValue}%}`]
              return [`{date|${date}}`, `{space|}`, trends.join(''), `{space|}`, `{assets|${autoDiagAsset}}`].join('\n')
            }
          },

          rich: {
            date: {
              padding: [0, 0, 5, 0],
            },
            trends: {
              backgroundColor: COLORS.gray050,
              fontWeight: 'bold',
              fontSize: '0.8rem',
              width: 45,
              height: 50,
              align: 'left',
            },
            assets: {
              backgroundColor: COLORS.gray050,
              fontWeight: 'bolder',
              fontSize: '0.8rem',
              width: 65,
              height: 50,
            },
            arrowUpIcon: {
              height: 15,
              width: 20,
              padding: [20, 0],
              backgroundColor: {
                image: svgRichData.arrowUp,
              },
            },
            arrowDownIcon: {
              height: 15,
              width: 20,
              padding: [20, 0],
              backgroundColor: {
                image: svgRichData.arrowDown,
              },
            },
            pinIcon: {
              height: 15,
              padding: [20, 33],
              backgroundColor: {
                image: svgRichData.pin,
              },
            },
            pinIconShort: {
              height: 12,
              width: 20,
              padding: [20, 13],
              backgroundColor: {
                image: svgRichData.removeMinus,
              },
            },
            space: {
              padding: [0, 0, 0, 0],
            },
            space2: {
              padding: [0, 0, 4, 0],
            },
          },
        },
        axisTick: {
          length: 0,
        },
      },
    ],
    series: [
      {
        data: chartData,
        type: 'bar',
        barCategoryGap: '60px',
        barMinHeight: 1,
        barWidth: '12px',
        silent: true,
        label: {
          show: true,
          position: 'top',
          formatter: ({ value }: { value: number }) => `${value}%`,
          fontWeight: 'bold',
        },

        name: '0-50%',
        markArea: calculateMarkArea(markAreaIndicies),
      },
      { name: '51-70%', data: [], type: 'bar' },
      { name: '>70%', data: [], type: 'bar' },
    ],
  } as EChartsOption
}

const generateAutomaticDiagnosticsCSVData = (
  automaticsDiagnosticsData: AutomaticDiagnosticsChartData,
  customerName: string
) =>
  [
    ['Site Name:', customerName].join(','),
    ['Dashboard:', 'Automatic Diagnostics'].join(','),
    ['Export Date:', formatDate(new Date(), DateFormats.AmericanDateTimeFormat)].join(','),
    [],
    [],
    [],
    [
      'Average monthly change',
      (automaticsDiagnosticsData.averageMonthlyTrend && `${automaticsDiagnosticsData.averageMonthlyTrend}%`) || 'N/A',
    ].join(','),
    [
      'Average monthly amount of automatically diagnosed assets',
      `${automaticsDiagnosticsData.averageMonthlyAutomaticallyDiagnosedAssetsAmount || 'N/A'}`,
    ].join(','),
    [],
    ['Month', 'Monthly automatic diagnostics [%]', 'Monthly change [%]', '# of Automatically diagnosed assets'].join(
      ','
    ),
    automaticsDiagnosticsData?.barDate
      .map((date, index) => {
        return [
          formatDate(date, DateFormats.MonthShortNameYearFormat),
          (isNaN(automaticsDiagnosticsData.barData[index].normalizedValue) && 'N/A') ||
            automaticsDiagnosticsData.barData[index].normalizedValue,
          ((isNaN(automaticsDiagnosticsData.monthlyTrend[index].magnitude) ||
            automaticsDiagnosticsData.monthlyTrend[index].magnitude < 0) &&
            'N/A') ||
            automaticsDiagnosticsData.monthlyTrend[index].magnitude,
          (isNaN(automaticsDiagnosticsData.automaticallyDiagnosedAssets[index]) && 'N/A') ||
            automaticsDiagnosticsData.automaticallyDiagnosedAssets[index],
        ].join(',')
      })
      .join('\n'),
  ].join('\n')

export {
  generateAutomaticDiagnosticsChartData,
  getAutomaticDiagnosticsChartOptions,
  generateAutomaticDiagnosticsCSVData,
}

import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { useOpenRecommendationsContext } from '@/contexts/openRecommendations'
import { RecommendationData } from '@/models/openRecommendations/types'
import EmptyAssetTableState from '@/shared/components/tableComponents/emptyState'
import useOpenRecommendationsColumns from '@/modules/openRecommendations/columns/useOpenRecommendationsColumns'
import { RedirectButton } from '@/modules/openRecommendations/components/cells/redirectButton'
import {
  openRecommendationsTableBodyCellProps,
  openRecommendationsTableBodyRowProps,
  openRecommendationsTableContainerProps,
  openRecommendationsTableHeadCellProps,
  openRecommendationsTableHeaderProps,
  openRecommendationsTablePaperProps,
} from '@/modules/openRecommendations/styled/tableSx'
import useHandleTableDataChange from '@/shared/components/tableComponents/hooks/useHandleTableDataChange'
import usePreviousData from '@/shared/components/tableComponents/hooks/usePreviousData'
import useTableIcons from '@/shared/components/tableComponents/hooks/useTableIcons'
import useDeepCompareCallback from '@/shared/hooks/useDeepCompareCallback'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { createCustomSortHandler } from '@/shared/utils'
import { headerActionPanelConfigSelector } from '@/store/selectors/moduleHeaderSelector'
import { useTypedSelector } from '@/store/store'
import {
  MRT_ColumnFiltersState,
  MRT_Row,
  MRT_SortingState,
  MRT_TableOptions,
  useMaterialReactTable,
} from 'material-react-table'
import { useCallback, useMemo, useState } from 'react'
import AllProcessedState from '@/shared/components/tableComponents/allProcessedState'
import OpenRecommendationsHeader from '@/modules/openRecommendations/components/header'

const useOpenRecommendationsTable = () => {
  const { openRecommendations } = useOpenRecommendationsContext()
  const { fetchDataStatus } = useFetchDataContext()

  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const [currentSelectedRow, setCurrentSelectedRow] = useState<RecommendationData | undefined>(undefined)

  const sortingChangeHandler = useMemo(() => createCustomSortHandler(3), [])

  const { tableStateChangeSwitchCurrentState: showClosed } = useTypedSelector((state) =>
    headerActionPanelConfigSelector(state)
  )
  const tableIcons = useTableIcons()

  const openRecommendationColumns = useOpenRecommendationsColumns()

  const tableData = useDeepCompareMemo(() => {
    const openFormatData = openRecommendations.open as unknown as RecommendationData[]
    const closedFormatData = openRecommendations.closed as unknown as RecommendationData[]

    return showClosed ? [...openFormatData, ...closedFormatData] : openFormatData
  }, [openRecommendations, showClosed, fetchDataStatus])

  const isAllClosed = useMemo(
    () => openRecommendations.open.length === 0 && openRecommendations.closed.length > 0,
    [openRecommendations.open.length, openRecommendations.closed.length]
  )

  const showSkeletons = useDeepCompareMemo(() => {
    let result = false
    if (
      fetchDataStatus === 'loading' ||
      (tableData.length === 0 && fetchDataStatus !== 'success' && fetchDataStatus !== 'error' && !isAllClosed)
    ) {
      result = true
    }

    return result
  }, [fetchDataStatus, tableData])

  let sortedRows: MRT_Row<RecommendationData>[] = []
  let tableColumnFilters: MRT_ColumnFiltersState = []
  let tableSorting: MRT_SortingState = []

  const openRecommendationsTable = useMaterialReactTable({
    columns: openRecommendationColumns,
    data: tableData,
    enableRowVirtualization: false,
    enableTopToolbar: true,
    enablePagination: tableData.length !== 0,
    enableBottomToolbar: true,
    enableColumnActions: false,
    enableStickyHeader: true,
    enableMultiSort: true,
    maxMultiSortColCount: 3,
    enableColumnFilters: true,
    columnFilterDisplayMode: 'custom',
    enableKeyboardShortcuts: false,
    initialState: { pagination: { pageIndex: 0, pageSize: 100 } },
    isMultiSortEvent: () => true,
    onSortingChange: (updater) => {
      setSorting(sortingChangeHandler(updater))
    },
    state: {
      showSkeletons,
      sorting,
    },
    renderEmptyRowsFallback: useCallback<
      Required<MRT_TableOptions<RecommendationData>>['renderEmptyRowsFallback'] //TS needed to get the correct type of the inner arrow function below
    >(
      ({ table }) => {
        let result = <EmptyAssetTableState<RecommendationData> table={table} />
        if (isAllClosed) {
          result = <AllProcessedState />
        }
        return result
      },
      [isAllClosed]
    ),
    icons: tableIcons,
    muiTableHeadProps: openRecommendationsTableHeaderProps,
    muiTablePaperProps: openRecommendationsTablePaperProps,
    muiTableContainerProps: openRecommendationsTableContainerProps,
    muiSkeletonProps: {
      height: 50,
    },
    defaultColumn: {
      muiTableBodyCellProps: openRecommendationsTableBodyCellProps,
      muiTableHeadCellProps: () => openRecommendationsTableHeadCellProps(fetchDataStatus !== 'loading'),
    },
    muiTableBodyRowProps: ({ row }) => {
      const rowIndex: number = sortedRows.findIndex((item) => item.id === row.id)

      const nextKeydownIndex: number = sortedRows.findIndex((item) => item.original.raID === currentSelectedRow?.raID)

      const isSelected = nextKeydownIndex === row.index

      return {
        ...openRecommendationsTableBodyRowProps(false, rowIndex, isSelected, showSkeletons),
        tabIndex: 0,
        onKeyDown: (e: React.KeyboardEvent<HTMLTableRowElement>) => onTableRowKeyDown(e, nextKeydownIndex),
        onClick: () => setCurrentSelectedRow(row.original),
      }
    },
    muiPaginationProps: {
      color: 'primary',
      shape: 'rounded',
      showRowsPerPage: false,
      variant: 'text',
      onClick: () => {
        openRecommendationsTable.refs.tableContainerRef.current?.scrollTo({ top: 0 })
      },
    },
    paginationDisplayMode: 'pages',
    enableRowActions: true,
    positionActionsColumn: 'last',
    layoutMode: 'grid',
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: '',
        size: 50,
        grow: false,
      },
    },
    renderRowActions: useCallback<
      Required<MRT_TableOptions<RecommendationData>>['renderDetailPanel'] //TS needed to get the correct type of the inner arrow function below
    >(({ row: { original, index }, table }) => {
      return <RedirectButton assetId={original.assetID} currentSelectedRowIndex={index} table={table} />
    }, []),
    renderTopToolbar: ({ table }) => <OpenRecommendationsHeader table={table} />,
  })

  const tableState = openRecommendationsTable.getState()

  tableSorting = useDeepCompareMemo(() => tableState.sorting, [tableState])
  tableColumnFilters = useDeepCompareMemo(() => tableState.columnFilters, [tableState])

  sortedRows = useDeepCompareMemo(
    () => openRecommendationsTable.getSortedRowModel().rows as MRT_Row<RecommendationData>[],
    [tableData, tableSorting, tableColumnFilters]
  )

  const onTableRowKeyDown = useDeepCompareCallback(
    (event: React.KeyboardEvent<HTMLTableRowElement>, rowIndex: number) => {
      if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
        if (event.key === 'ArrowUp' && rowIndex === 0) return
        if (event.key === 'ArrowDown' && rowIndex === sortedRows.length - 1) return

        if (event.key === 'ArrowDown') {
          setCurrentSelectedRow(sortedRows[rowIndex + 1].original)
        }
        if (event.key === 'ArrowUp') {
          setCurrentSelectedRow(sortedRows[rowIndex - 1].original)
        }
      }
    },
    [openRecommendations, tableSorting, tableColumnFilters]
  )

  usePreviousData<RecommendationData, RecommendationData[]>({
    table: openRecommendationsTable,
    setCurrentSelectedRow,
    tableData,
    setSorting,
  })

  useHandleTableDataChange<RecommendationData, RecommendationData[]>({
    table: openRecommendationsTable,
    setCurrentSelectedRow,
    tableData,
  })

  return openRecommendationsTable
}

export default useOpenRecommendationsTable

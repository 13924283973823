import { Site } from '@/models/overview/types'
import OverviewCell from '@/modules/overview/components/overviewCell'
import {
  StyledOverviewAutomaticDiagnosticsTooltip,
  StyledOverviewDevicesSensorsColumnWrapper,
} from '@/modules/overview/styled'
import { calculateAutoDiagnosticsIconMarginValue } from '@/modules/overview/utils'
import { overviewCellTooltips } from '@/modules/overview/utils/constants'
import { ROUTES } from '@/shared/constants'
import { MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'

const useAutoDiagnosticsData = () => {
  const autoDiagnosticsData = useMemo<MRT_ColumnDef<Site>[]>(
    () => [
      {
        accessorKey: 'autoDiagnostics',
        id: 'autoDiagnosticsData',
        header: '',
        size: 200,
        grow: false,
        Cell: ({ cell, row }) => (
          <OverviewCell
            siteId={row.original.siteId}
            value={cell.getValue<string | number | null>()}
            routeTo={ROUTES.AUTOMATIC_DIAGNOSTICS}
            stringSuffixAdornment="%"
            message={overviewCellTooltips[cell.column.columnDef.id as keyof typeof overviewCellTooltips]}
            prefixIndicator={
              <StyledOverviewDevicesSensorsColumnWrapper
                marginLeft={calculateAutoDiagnosticsIconMarginValue(cell.getValue<string | number | null>())}
              >
                <StyledOverviewAutomaticDiagnosticsTooltip threshold={parseFloat(cell.getValue<string>())} />
              </StyledOverviewDevicesSensorsColumnWrapper>
            }
          />
        ),
      },
    ],
    []
  )
  return autoDiagnosticsData
}

export default useAutoDiagnosticsData

import { Node, SelectionState, TreeNodeData } from '@/models/newHierarchyTree/types'
import { useCallback, useEffect, useMemo, useState } from 'react'

// Custom hook for tree selection logic
const useTreeSelector = (initialNodeData?: TreeNodeData) => {
  // Using a flat map structure for O(1) node lookup
  const nodeMap = useMemo(() => new Map<string, Node>(), [])
  const originalStructure = useMemo(() => new Map<string, TreeNodeData>(), [])
  const [rootId, setRootId] = useState<string | undefined>(undefined)

  // Initialize the tree from node data
  const load = useCallback(
    (nodeData: TreeNodeData) => {
      // Store currently selected nodes
      const oldSelected = Array.from(nodeMap.entries())
        .filter(([_, node]) => node.selected)
        .map(([id]) => id)

      // Clear and rebuild tree
      nodeMap.clear()
      originalStructure.clear()

      // Build the tree structure
      buildTree(nodeData)
      setRootId(nodeData.id)

      // Reapply selections
      oldSelected.forEach((id) => {
        select(id)
      })
    },
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodeMap, originalStructure]
  )

  // Helper function to build the tree structure
  const buildTree = useCallback(
    (nodeData: TreeNodeData, parentId?: string): Node => {
      const { id, children, disabled, ...rest } = nodeData

      // Create node with optimized structure
      const node: Node = {
        id,
        parent: parentId, // Set parent to the current node's parent ID
        children: children.map((child) => child.id),
        selected: false,
        numDescendants: 0,
        numSelectedDescendants: 0,
        disabled, // Add the disabled property
      }

      // Store original node data
      originalStructure.set(id, { id, children, disabled, ...rest })
      nodeMap.set(id, node)

      // Process children
      let descendantCount = 0
      for (const child of children) {
        const childNode = buildTree(child, id) // Pass current node's ID as parent
        descendantCount += childNode.numDescendants + 1
      }

      node.numDescendants = descendantCount
      return node
    },
    [nodeMap, originalStructure]
  )

  // Get the selection state of a node
  const getState = useCallback(
    (nodeId: string): SelectionState => {
      const node = nodeMap.get(nodeId)

      if (!node) {
        return 'deselected'
      }

      if (node.selected) {
        return 'selected'
      }

      if (node.numSelectedDescendants > 0) {
        return 'indeterminate'
      }

      return 'deselected'
    },
    [nodeMap]
  )

  // Check if a node is a leaf node
  const isLeafNode = useCallback(
    (nodeId: string): boolean => {
      const node = nodeMap.get(nodeId)
      return node ? node.children.length === 0 : false
    },
    [nodeMap]
  )

  // Check if a node is disabled
  const isDisabled = useCallback(
    (nodeId: string): boolean => {
      const node = nodeMap.get(nodeId)
      return node ? !!node.disabled : false
    },
    [nodeMap]
  )

  // Select a node
  const select = useCallback(
    (nodeId: string) => {
      const node = nodeMap.get(nodeId)
      if (!node || node.selected || node.disabled) return

      // Special handling for leaf nodes for better performance
      if (isLeafNode(nodeId)) {
        node.selected = true
        if (node.parent) {
          updateAncestors(node.parent, 1)
        }
        return
      }

      // Update subtree first
      const subtreeDiff = updateSubtree(node, true)

      // Then update ancestors
      if (node.parent) {
        updateAncestors(node.parent, subtreeDiff)
      }
    },
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodeMap, isLeafNode]
  )

  // Deselect a node
  const deselect = useCallback(
    (nodeId: string) => {
      const node = nodeMap.get(nodeId)
      if (!node || node.disabled) return

      // Update subtree first
      const subtreeDiff = updateSubtree(node, false)

      // Then update ancestors
      if (node.parent) {
        updateAncestors(node.parent, subtreeDiff)
      }
    },
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodeMap]
  )

  // Update the subtree of a node
  const updateSubtree = useCallback(
    (node: Node, selected: boolean): number => {
      const prevState = {
        selected: node.selected,
        numSelectedDescendants: node.numSelectedDescendants,
      }

      // Update current node if not disabled
      if (!node.disabled) {
        node.selected = selected
      }

      // Update all children that aren't disabled
      let totalDiff = 0
      for (const childId of node.children) {
        const childNode = nodeMap.get(childId)
        if (childNode && !childNode.disabled) {
          totalDiff += updateSubtree(childNode, selected)
        }
      }

      // Calculate descendant changes
      if (!node.disabled) {
        node.numSelectedDescendants = selected ? node.numDescendants : 0
      }

      // Calculate total difference including this node
      let nodeDiff = node.numSelectedDescendants - prevState.numSelectedDescendants
      if (!node.disabled) {
        if (!prevState.selected && selected) nodeDiff += 1
        else if (prevState.selected && !selected) nodeDiff -= 1
      }

      return nodeDiff + totalDiff
    },
    [nodeMap]
  )

  // Update ancestors of a node
  const updateAncestors = useCallback(
    (nodeId: string, diff: number) => {
      const node = nodeMap.get(nodeId)
      if (!node) return

      const prevSelected = node.selected

      // Update selected descendants count
      node.numSelectedDescendants += diff

      // Update selected state
      // Fixed: The parent should be selected when all its descendants are selected or disabled
      // We check if the node has children and if all of them are selected or disabled
      const allChildrenSelectedOrDisabled =
        node.children.length > 0 &&
        node.children.every((childId) => {
          const childNode = nodeMap.get(childId)
          return childNode && (childNode.selected || childNode.disabled)
        })

      // Set the node as selected if all children are selected or disabled and node itself is not disabled
      if (!node.disabled) {
        node.selected = allChildrenSelectedOrDisabled
      }

      // Calculate difference for parent
      let totalDiff = diff
      if (!node.disabled) {
        if (!prevSelected && node.selected) totalDiff += 1
        else if (prevSelected && !node.selected) totalDiff -= 1
      }

      // Propagate changes upward
      if (node.parent) {
        updateAncestors(node.parent, totalDiff)
      }
    },
    [nodeMap]
  )

  // Helper function to get selected roots under a node
  const getSelectedRootsUnderNode = useCallback(
    (node: Node): string[] => {
      if (node.selected) return [node.id]

      const selectedRoots: string[] = []
      for (const childId of node.children) {
        const childNode = nodeMap.get(childId)
        if (childNode) {
          selectedRoots.push(...getSelectedRootsUnderNode(childNode))
        }
      }
      return selectedRoots
    },
    [nodeMap]
  )

  // Get selected subtree roots
  const getSelectedSubtreeRoots = useCallback((): string[] => {
    if (!rootId) return []
    const rootNode = nodeMap.get(rootId)
    if (!rootNode) return []

    return getSelectedRootsUnderNode(rootNode)
  }, [getSelectedRootsUnderNode, nodeMap, rootId])

  // Use useEffect to initialize with data if provided
  useEffect(() => {
    if (initialNodeData) {
      load(initialNodeData)
    }
  }, [initialNodeData, load])

  return {
    load,
    getState,
    isLeafNode,
    isDisabled,
    select,
    deselect,
    getSelectedSubtreeRoots,
  }
}

export default useTreeSelector

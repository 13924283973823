import { Card, SPACINGS } from '@skf-design-system/ui-components-react'
import React, { HTMLProps } from 'react'
import { css } from '@emotion/react'
import ErrorBoundary from '@/shared/components/errorBoundary'

export const WidgetWrapper = React.forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(
  ({ style, className, onMouseDown, onMouseUp, onTouchEnd, children }, ref) => (
    <Card
      style={{ ...style }}
      className={className}
      ref={ref}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchEnd={onTouchEnd}
      feNoPadding
      css={css`
        padding: ${SPACINGS.xl};
      `}
    >
      <ErrorBoundary fallbackComponent="Something went wrong">{children}</ErrorBoundary>
    </Card>
  )
)

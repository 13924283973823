import { FileDropzoneProps } from '@/shared/components/dropzone/types'
import { convertToMegaBytes } from '@/shared/fileUtils'
import { useCallback } from 'react'
import { ErrorCode, useDropzone } from 'react-dropzone'

const useFilesDropzone = (dropZoneProps: FileDropzoneProps, acceptFiles: string[], maxFileSize: number) => {
  const validator = useCallback(
    (file: File) => {
      if (file.name) {
        const fileExtension = file.name?.split('.').pop()?.toLowerCase()

        if (fileExtension && !acceptFiles.includes(fileExtension)) {
          return {
            code: ErrorCode.FileInvalidType,
            message: 'Invalid file format.',
          }
        }
        if (file.size > maxFileSize) {
          return {
            code: ErrorCode.FileTooLarge,
            message: `File size exceeds ${convertToMegaBytes(maxFileSize)}MB. Please upload a smaller image.`,
          }
        }
      } else {
        return {
          code: ErrorCode.FileInvalidType,
          message: 'Invalid file format. No file was found',
        }
      }

      return null
    },
    [acceptFiles, maxFileSize]
  )

  return useDropzone({ ...dropZoneProps, validator })
}

export default useFilesDropzone

import MainHeader from '@/app/mainHeader'
import AppTabs from '@/app/tabs'
import { FetchDataContextProvider } from '@/contexts/common/fetchDataContext'
import AppDataInitializer from '@/shared/components/appDataInitializer'
import ErrorConsumer from '@/shared/components/errorConsumer'
import GenericConfirmationDialog from '@/shared/components/genericConfirmationDialog'
import GenericToast from '@/shared/components/genericToast'
import { dataTestId } from '@/tests/testid'
import { ToastProvider } from '@skf-design-system/ui-components-react'

const ProtectedRoutes = () => {
  return (
    <FetchDataContextProvider>
      <ErrorConsumer>
        <AppDataInitializer>
          <MainHeader />
          <AppTabs />
          <GenericConfirmationDialog />
          <ToastProvider feErrorTimeout={true} feTimeout={7}>
            <GenericToast />
          </ToastProvider>
          <div data-testid={dataTestId.protectedRoutes} />
        </AppDataInitializer>
      </ErrorConsumer>
    </FetchDataContextProvider>
  )
}

export default ProtectedRoutes

import ChannelDataSection from '@/modules/devicesSensors/components/sideMenu/channelSection'
import DeviceDataSection from '@/modules/devicesSensors/components/sideMenu/deviceSection'
import { SideMenuCard } from '@/modules/devicesSensors/styled'
import {
  showDeviceChannelsBySidSelector,
  showDeviceDetailsBySidSelector,
} from '@/store/selectors/sitesPermissionsSelector'
import { useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { Flexbox } from '@skf-design-system/ui-components-react'
import { FC } from 'react'
import { useParams } from 'react-router'

// =================================================================================================
// COMPONENT
// =================================================================================================

export const DevicesSensorsSideMenu: FC = () => {
  const { sid } = useParams()
  const hasShowDeviceChannels = useTypedSelector((state) => showDeviceChannelsBySidSelector(state, sid))

  const hasShowDeviceDetails = useTypedSelector((state) => showDeviceDetailsBySidSelector(state, sid))

  return (
    <SideMenuCard>
      <Flexbox data-testid={dataTestId.devicesSensorsSideMenu} feFlexDirection="column" feStretch={true}>
        {hasShowDeviceDetails && <DeviceDataSection />}
        {hasShowDeviceChannels && <ChannelDataSection />}
      </Flexbox>
    </SideMenuCard>
  )
}

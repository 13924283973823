import { Site } from '@/models/overview/types'
import useAnalysisBacklog from '@/modules/overview/columns/secondLevel/useAnalysisBacklog'
import useAutoDiagnostics from '@/modules/overview/columns/secondLevel/useAutoDiagnostics'
import useCompanySite from '@/modules/overview/columns/secondLevel/useCompanySite'
import useDevicesAndSensors from '@/modules/overview/columns/secondLevel/useDevicesAndSensors'
import useOpenRecommendations from '@/modules/overview/columns/secondLevel/useOpenRecommendations'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { MRT_ColumnDef } from 'material-react-table'

const useOverviewColumns = () => {
  const companySiteColumn = useCompanySite()
  const analysisBacklogColumns = useAnalysisBacklog()
  const openRecommendationsColumns = useOpenRecommendations()
  const devicesAndSensorsColumns = useDevicesAndSensors()
  const autoDiagnosticsColumns = useAutoDiagnostics()
  const overviewColumns = useDeepCompareMemo<MRT_ColumnDef<Site>[]>(
    () => [
      {
        id: 'companySite',
        header: 'Site',
        columns: companySiteColumn,
        size: 240,
        grow: false,
      },
      {
        id: 'analysisBacklog',
        header: 'Analysis Backlog',
        columns: analysisBacklogColumns,
        size: 320,
      },
      {
        id: 'openRecommendations',
        header: 'Open Recommendations',
        size: 160,
        columns: openRecommendationsColumns,
      },
      {
        id: 'deviceAndSensors',
        header: 'Devices & Sensors Management',
        size: 190,
        columns: devicesAndSensorsColumns,
      },
      {
        id: 'autoDiagnostics',
        header: 'Automatic Diagnostics',
        size: 200,
        grow: false,
        columns: autoDiagnosticsColumns,
      },
    ],
    [
      companySiteColumn,
      analysisBacklogColumns,
      openRecommendationsColumns,
      devicesAndSensorsColumns,
      autoDiagnosticsColumns,
    ]
  )
  return overviewColumns
}

export default useOverviewColumns

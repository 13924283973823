import { Roundness } from '@/shared/constants'
import { COLORS, SIZES, styled } from '@skf-design-system/ui-components-react'

export const StyledHierarchyTreeContainer = styled.ul`
  overflow-y: auto;
  width: 100%;
  padding: 0.0625rem;
  padding-right: 1rem;

  &::-webkit-scrollbar {
    width: 0.7rem;
  }

  &::-webkit-scrollbar-thumb {
    min-height: 8rem;
    background-color: #ccc;
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
`

export const StyledExpandButtonStyled = styled.button`
  position: absolute;
  top: 0.9375rem;
  left: 0.0625rem;
  width: 1.35rem;
  height: 1.35rem;
  padding: 0;
  outline: none;
  background: transparent;
  border: none;

  & svg {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: -1px;
    top: 0;
    left: 0;
    color: ${COLORS.gray600};
    background-color: ${COLORS.white};
    border-radius: 50%;
  }
`
export const StyledTreeNodeContentContainer = styled.div<{ isRootNode: boolean; disabled: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: ${SIZES.s32};

  align-items: center;
  background-color: ${({ disabled }) => (disabled ? COLORS.gray050 : COLORS.white)};

  margin-top: ${({ isRootNode }) => (isRootNode ? '0' : '0.5rem')};
  padding: 0.5rem;
  gap: 0.5rem;

  border-radius: ${Roundness.Base};
  box-shadow:
    0 0.0625rem 0.0625rem 0 rgb(39 51 66 / 10%),
    0 0.0625rem 0.3125rem 0 rgb(39 51 66 / 10%),
    0 0 0.375rem 0 rgb(39 51 66 / 12%);
`
export const StyledTreeNodeList = styled.li<{ isRootNode: boolean }>`
  display: grid;
  grid-template-columns: ${({ isRootNode }) => (isRootNode ? 'auto' : '1.875rem auto')};
`

export const StyledTreeNodeBranch = styled.div`
  position: relative;
  width: 1.875rem;

  & svg {
    position: absolute;
    height: 100%;

    & path,
    & line {
      stroke: ${COLORS.gray700};
    }
  }
`

export const StyledTreeNodeContent = styled.div`
  width: 100%;
`

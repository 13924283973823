import { ResponseAssets } from '@/models/analysisBacklog/types'
import { UploadedEvidenceFilesStatus } from '@/models/reportStatus/evidenceTypes'
import { FileDropzoneImageData } from '@/shared/components/dropzone/types'
import useContextWrapper from '@/shared/hooks/useContextWrapper'
import { ToastProps } from '@skf-design-system/ui-components-react/dist/components/toast/Toast'
import { createContext, useMemo, useState } from 'react'

type ReportStatusDialogData = {
  isOpen: boolean
  closeDialog?: (toastProps?: ToastProps | undefined, assetData?: ResponseAssets) => void
}

type AnalyticsCancelButtonBehavior = {
  isDisabled: boolean
}

interface IReportStatusContext {
  reportStatusDialogData: ReportStatusDialogData
  setReportStatusDialogData: React.Dispatch<React.SetStateAction<IReportStatusContext['reportStatusDialogData']>>
  analyticsCancelButtonBehavior: AnalyticsCancelButtonBehavior
  setAnalyticsCancelButtonBehavior: React.Dispatch<
    React.SetStateAction<IReportStatusContext['analyticsCancelButtonBehavior']>
  >
  isConflictDialogOpen: boolean
  setIsConflictDialogOpen: React.Dispatch<React.SetStateAction<IReportStatusContext['isConflictDialogOpen']>>

  uploadedEvidenceFilesStatus: UploadedEvidenceFilesStatus[]
  setUploadedEvidenceFilesStatus: React.Dispatch<
    React.SetStateAction<IReportStatusContext['uploadedEvidenceFilesStatus']>
  >

  isEvidenceFaultImageModalOpen: boolean
  setIsEvidenceFaultImageModalOpen: React.Dispatch<
    React.SetStateAction<IReportStatusContext['isEvidenceFaultImageModalOpen']>
  >
  assetsData: ResponseAssets
  setAssetsData: React.Dispatch<React.SetStateAction<IReportStatusContext['assetsData']>>
  faultEditModeData: Record<string, boolean>
  setFaultEditModeData: React.Dispatch<React.SetStateAction<IReportStatusContext['faultEditModeData']>>
  evidenceDialogData: FileDropzoneImageData
  setEvidenceDialogData: React.Dispatch<React.SetStateAction<IReportStatusContext['evidenceDialogData']>>
}

export const ReportStatusContext = createContext<IReportStatusContext | null>(null)

export const useReportStatusContext = () =>
  useContextWrapper(ReportStatusContext, {
    contextName: useReportStatusContext.name,
    providerName: ReportStatusContextProvider.name,
  })

export const ReportStatusContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [reportStatusDialogData, setReportStatusDialogData] = useState<IReportStatusContext['reportStatusDialogData']>({
    isOpen: false,
    closeDialog: () => {},
  })

  const [analyticsCancelButtonBehavior, setAnalyticsCancelButtonBehavior] = useState<
    IReportStatusContext['analyticsCancelButtonBehavior']
  >({
    isDisabled: true,
  })

  const [isConflictDialogOpen, setIsConflictDialogOpen] = useState<IReportStatusContext['isConflictDialogOpen']>(false)

  const [uploadedEvidenceFilesStatus, setUploadedEvidenceFilesStatus] = useState<
    IReportStatusContext['uploadedEvidenceFilesStatus']
  >([])

  const [isEvidenceFaultImageModalOpen, setIsEvidenceFaultImageModalOpen] =
    useState<IReportStatusContext['isEvidenceFaultImageModalOpen']>(false)

  const [assetsData, setAssetsData] = useState<IReportStatusContext['assetsData']>({
    unhandled: [],
    handled: [],
  })
  const [faultEditModeData, setFaultEditModeData] = useState<IReportStatusContext['faultEditModeData']>({})
  const [evidenceDialogData, setEvidenceDialogData] = useState<IReportStatusContext['evidenceDialogData']>(
    {} as FileDropzoneImageData
  )

  const value = useMemo(
    () => ({
      reportStatusDialogData,
      setReportStatusDialogData,
      analyticsCancelButtonBehavior,
      setAnalyticsCancelButtonBehavior,
      isConflictDialogOpen,
      setIsConflictDialogOpen,
      isEvidenceFaultImageModalOpen,
      setIsEvidenceFaultImageModalOpen,
      assetsData,
      setAssetsData,
      faultEditModeData,
      setFaultEditModeData,
      evidenceDialogData,
      setEvidenceDialogData,
      uploadedEvidenceFilesStatus,
      setUploadedEvidenceFilesStatus,
    }),
    [
      reportStatusDialogData,
      analyticsCancelButtonBehavior,
      isConflictDialogOpen,
      isEvidenceFaultImageModalOpen,
      assetsData,
      faultEditModeData,
      evidenceDialogData,
      uploadedEvidenceFilesStatus,
    ]
  )

  return <ReportStatusContext value={value}>{children}</ReportStatusContext>
}

import { Icon } from '@skf-design-system/ui-components-react'
import { useMemo } from 'react'

const useTableIcons = () => {
  const tableAppIcons = useMemo(
    () => ({
      KeyboardDoubleArrowDownIcon: () => <div />,
      SyncAltIcon: () => <Icon feSize="sm" feIcon="caretUpDown" />,
      ArrowDownwardIcon: ({ className }: { className: string }) => {
        let result = <Icon feSize="sm" feIcon="caretUp" />
        if ((className as string).includes('DirectionDesc')) {
          result = <Icon feSize="sm" feIcon="caretDown" />
        }
        return result
      },
      FilterAltIcon: () => <div id="fake-filter-icon-id" />,
    }),
    []
  )
  return tableAppIcons
}

export default useTableIcons

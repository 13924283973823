import { useFilesDropzoneContext } from '@/contexts/moduleContexts/filesDropzone'
import { useSiteDetailsContext } from '@/contexts/siteDetails'
import { WidgetType } from '@/models/dashboard/types'
import useUploadSiteDetailsLogo from '@/modules/siteDetails/hooks/useUploadSiteDetailsLogo'
import { StyledDashedBorder, StyledLabel, StyledParagraph } from '@/modules/siteDetails/styled'
import { ACCEPTED_MIME_TYPES, MAX_FILE_SIZE } from '@/shared/components/dropzone/constants'
import FilesDropzone from '@/shared/components/dropzone'
import { FileDropzoneImageData } from '@/shared/components/dropzone/types'
import GenericLoader, { GenericLoaderType } from '@/shared/components/genericLoader'
import { LoadingType } from '@/shared/hooks/useApi'
import { dataTestId } from '@/tests/testid'
import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { Flexbox } from '@skf-design-system/ui-components-react'
import { convertToMegaBytes } from '@/shared/fileUtils'

const SiteDetailsDropzone: React.FC = () => {
  const { sid } = useParams()
  const { execute: uploadLogo, status: uploadLogoStatus } = useUploadSiteDetailsLogo()
  const { setIsSiteDetailsEdited } = useSiteDetailsContext()
  const { setFaultId, setDropzoneProps, setDropzoneFiles } = useFilesDropzoneContext()

  useEffect(() => {
    if (sid) {
      setFaultId(sid)
      setDropzoneFiles({ [sid]: [] })
    }
    setDropzoneProps({
      multiple: false,
      maxFiles: 1,
      // only png and jpeg for site details
      accept: Object.fromEntries(Object.entries(ACCEPTED_MIME_TYPES).slice(0, 2)),
    })
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sid])

  const onFilesProcessed = (convertedValidFiles: FileDropzoneImageData[]) => {
    uploadLogo({
      loaderType: LoadingType.WIDGET,
      widgetType: WidgetType.LOADER,
      postData: convertedValidFiles[0],
    })
    setIsSiteDetailsEdited(true)
  }

  return (
    <Flexbox feFlexDirection="column" data-testid={dataTestId.siteDetailsDropzone}>
      <StyledLabel htmlFor="companyLogo">Company logo</StyledLabel>
      {uploadLogoStatus === 'loading' ? (
        <StyledDashedBorder data-testid={dataTestId.dragAndDropLogo}>
          <GenericLoader loaderType={GenericLoaderType.WIDGET} />
        </StyledDashedBorder>
      ) : (
        <FilesDropzone onFilesProcessed={onFilesProcessed} />
      )}
      <StyledParagraph>JPEG or PNG file, up to {convertToMegaBytes(MAX_FILE_SIZE) / 2} mb</StyledParagraph>
    </Flexbox>
  )
}

export default SiteDetailsDropzone

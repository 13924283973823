import EditDetails from '@/modules/siteDetails/components/contractDetails/editDetails'
import { StyledContractDetailsTabs } from '@/modules/siteDetails/styled'
import { dataTestId } from '@/tests/testid'

const ContractDetailsTabs = () => {
  const tabsItemsRef = [
    {
      label: 'Edit Details',
      id: 'edit-details',
      children: <EditDetails />,
    },
  ]
  return (
    <StyledContractDetailsTabs
      feType="expanded"
      feItems={tabsItemsRef}
      data-testid={dataTestId.siteDetailsContractDetailsTabs}
    />
  )
}

export default ContractDetailsTabs

import { useAutomaticsDiagnosticsContext } from '@/contexts/automaticDiagnostics'
import {
  StyledAutomaticDiagnosticsAverageBox,
  StyledAutomaticsDiagnosticsNoWrapSpan,
  StyledAutomaticsDiagnosticsRightPanel,
  StyledAutomaticsDiagnosticsSpan,
  StyledAverageLabel,
  StyledAverageMonthlyTrendDirectionIcon,
  StyledAverageNumber,
} from '@/modules/automaticDiagnostics/styled'
import { dataTestId } from '@/tests/testid'

const AutomaticDiagnosticsAverageBox = () => {
  const { automaticsDiagnosticsData } = useAutomaticsDiagnosticsContext()

  return (
    <StyledAutomaticsDiagnosticsRightPanel feFlexDirection="column">
      {automaticsDiagnosticsData && (
        <StyledAutomaticDiagnosticsAverageBox>
          <div data-testid={dataTestId.automaticDiagnosticsAverageBox}>Average monthly change</div>
          {(automaticsDiagnosticsData.averageMonthlyTrend && (
            <StyledAverageNumber>
              <StyledAverageMonthlyTrendDirectionIcon
                feIcon={automaticsDiagnosticsData.averageMonthlyTrendDirection}
                feSize="md"
                feColor={automaticsDiagnosticsData.averageMonthlyTrendDirection === 'arrowUp' ? 'green' : 'red'}
              />
              {automaticsDiagnosticsData.averageMonthlyTrend}%
            </StyledAverageNumber>
          )) || <StyledAverageNumber>N/A</StyledAverageNumber>}
          <StyledAverageLabel>Avg. monthly amount of automatically diagnosed assets</StyledAverageLabel>
          {(automaticsDiagnosticsData.averageMonthlyAutomaticallyDiagnosedAssetsAmount && (
            <StyledAverageNumber>
              {automaticsDiagnosticsData.averageMonthlyAutomaticallyDiagnosedAssetsAmount}
            </StyledAverageNumber>
          )) || <StyledAverageNumber>N/A</StyledAverageNumber>}
        </StyledAutomaticDiagnosticsAverageBox>
      )}
      {/* <Flexbox
        feFlexDirection="column"
        feJustifyContent="center"
      > */}
      <StyledAutomaticsDiagnosticsSpan>Monthly change</StyledAutomaticsDiagnosticsSpan>
      <StyledAutomaticsDiagnosticsNoWrapSpan># of Automatically diagnosed assets</StyledAutomaticsDiagnosticsNoWrapSpan>
      {/* </Flexbox> */}
    </StyledAutomaticsDiagnosticsRightPanel>
  )
}

export default AutomaticDiagnosticsAverageBox

import { DeviceStatusEnum } from '@/models/devicesAndSensors/types'
import { deviceStatusIcon, deviceStatusString } from '@/modules/devicesSensors/utils/constants'
import { FilterCheckboxProps } from '@/shared/components/tableComponents/genericTableFilter/types'
import { dataTestId } from '@/tests/testid'
import { Checkbox, Flexbox, Icon } from '@skf-design-system/ui-components-react'
import { FC } from 'react'

const DeviceStatusCheckbox: FC<FilterCheckboxProps<DeviceStatusEnum>> = ({ status, checked, onChange }) => {
  const { icon, color } = deviceStatusIcon[status]

  return (
    <Flexbox feAlignItems="center" feJustifyContent="space-between" feGap="md">
      <Checkbox
        name={status}
        checked={checked}
        feLabel={deviceStatusString[status]}
        onChange={(_, checked) => onChange(status, checked)}
        data-testid={dataTestId.filterDeviceStatusCheckbox}
      />
      <Icon feIcon={icon} feColor={color} feSize="sm" />
    </Flexbox>
  )
}

export default DeviceStatusCheckbox

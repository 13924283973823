import { FC, useCallback } from 'react'
import { FileDropzoneImageData } from '@/shared/components/dropzone/types'
import { StyledIconContainer } from '@/modules/reportStatus/styled'
import { UseApiProps } from '@/shared/hooks/useApi'
import { dataTestId } from '@/tests/testid'
import { IconButton } from '@skf-design-system/ui-components-react'
import { useReportStatusContext } from '@/contexts/reportStatus'
import useFetchFaultEvidenceImage from '@/modules/reportStatus/hooks/useFetchFaultEvidenceImage'
import { FetchFaultImageResponse } from '@/api/paths/documents/files/types'
import { useFaultEvidenceContext } from '@/contexts/moduleContexts/evidence'
import { downloadFile } from '@/shared/fileUtils'

interface ImageActionsProps {
  imageDetails: FileDropzoneImageData
  deleteFaultEvidenceImage: (props?: UseApiProps | undefined) => Promise<void | FileDropzoneImageData>
}

const ImageActions: FC<ImageActionsProps> = ({ imageDetails, deleteFaultEvidenceImage }) => {
  const { faultEditModeData, setEvidenceDialogData, setIsEvidenceFaultImageModalOpen } = useReportStatusContext()
  const { execute } = useFetchFaultEvidenceImage(imageDetails)
  const { faultId } = useFaultEvidenceContext()

  const {
    imageInfo: { fileID, fileURL, fileName },
  } = imageDetails

  const downloadImageFile = useCallback(async () => {
    const fileData = (await execute()) as FetchFaultImageResponse
    downloadFile(fileName, fileData.url)
  }, [execute, fileName])

  const showEvidenceModalDialog = useCallback(() => {
    setEvidenceDialogData(imageDetails)
    setIsEvidenceFaultImageModalOpen(true)
  }, [imageDetails, setEvidenceDialogData, setIsEvidenceFaultImageModalOpen])

  return (
    <>
      <img src={fileURL} alt={`Uploaded image ${fileID}`} data-testid={dataTestId.evidenceUploadedImage} />
      <StyledIconContainer
        isFaultInEditMode={faultEditModeData[faultId]}
        onClick={() => !faultEditModeData[faultId] && showEvidenceModalDialog()}
        className="icon-container"
        data-testid={dataTestId.evidenceUploadedImageActions}
      >
        {faultEditModeData[faultId] && (
          <IconButton
            aria-label="expand"
            as="button"
            feIcon="draftOutline"
            feColor="white"
            feSize="sm"
            data-testid={dataTestId.evidenceUploadedImageCardEditNameIcon}
            onClick={(e) => {
              e.stopPropagation()
              showEvidenceModalDialog()
            }}
          />
        )}

        <IconButton
          aria-label="expand"
          as="button"
          feIcon="download"
          feColor="white"
          feSize="sm"
          data-testid={dataTestId.evidenceUploadedImageCardDownloadImageIcon}
          onClick={(e) => {
            e.stopPropagation()
            downloadImageFile()
          }}
        />
        {faultEditModeData[faultId] && (
          <IconButton
            aria-label="expand"
            as="button"
            feIcon="deleteOutline"
            feColor="white"
            feSize="sm"
            data-testid={dataTestId.evidenceUploadedImageCardDeleteImageIcon}
            onClick={(e) => {
              e.stopPropagation()
              deleteFaultEvidenceImage()
            }}
          />
        )}
      </StyledIconContainer>
    </>
  )
}

export default ImageActions

import { headerConfigSelector } from '@/store/selectors/moduleHeaderSelector'
import { useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { css } from '@emotion/react'
import { Heading } from '@skf-design-system/ui-components-react'

/**
 * HeaderTitle is the span that located in the module header
 *
 * HeaderTitle configured via headerConfig redux
 *
 * @returns {JSX.Element} The rendered component.
 */
const HeaderTitle = () => {
  const { title } = useTypedSelector((store) => headerConfigSelector(store))

  return (
    <Heading
      as="h1"
      data-testid={dataTestId.moduleHeaderTitle}
      css={css`
        margin-right: 10px;
        margin-top: 20px;
      `}
    >
      {title}
    </Heading>
  )
}

export default HeaderTitle

import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { OpenRecommendationsContextProvider } from '@/contexts/openRecommendations'
import { RecommendationData, OpenRecommendationData } from '@/models/openRecommendations/types'
import useGetOpenRecommendations from '@/modules/openRecommendations/hooks/useGetOpenRecommendations'
import { StyledOpenRecommendationsRoot } from '@/modules/openRecommendations/styled'
import useOpenRecommendationsTable from '@/modules/openRecommendations/table/useOpenRecommendationsTable'
import DataRefresher from '@/shared/components/dataRefresher'
import useResetTableFilters from '@/shared/components/tableComponents/hooks/useResetTableFilters'
import ThemeConsumer from '@/shared/components/themeConsumer'
import { LoadingType } from '@/shared/hooks/useApi'
import useCleanupStorage from '@/shared/hooks/useCleanupStorage'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { dataTestId } from '@/tests/testid'
import { MaterialReactTable } from 'material-react-table'
import { useEffect } from 'react'
import { useParams } from 'react-router'

const OpenRecommendations = () => {
  return (
    <StyledOpenRecommendationsRoot>
      <OpenRecommendationsContextProvider>
        <InnerOpenRecommendations />
      </OpenRecommendationsContextProvider>
    </StyledOpenRecommendationsRoot>
  )
}

const InnerOpenRecommendations = () => {
  const { sid } = useParams()
  const { setFetchDataFn, setFetchDataStatus } = useFetchDataContext()
  const { execute, status } = useGetOpenRecommendations()

  useEffect(() => {
    setFetchDataFn(() => execute)
  }, [execute, setFetchDataFn])

  useDeepCompareEffect(() => {
    setFetchDataStatus(status)
  }, [status])

  useCleanupStorage()

  const openRecommendationsTable = useOpenRecommendationsTable()
  const resetTableFilters = useResetTableFilters<RecommendationData>(openRecommendationsTable)
  useEffect(() => {
    if (sid) {
      execute({ loaderType: LoadingType.NONE })
      resetTableFilters()
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sid])

  return (
    <ThemeConsumer>
      {/* <OpenRecommendationsHeader table={openRecommendationsTable} /> */}
      <MaterialReactTable table={openRecommendationsTable} />
      <DataRefresher<OpenRecommendationData> functionToExecute={execute} />
      <div data-testid={dataTestId.openRecommendationsModule} />
    </ThemeConsumer>
  )
}

export default OpenRecommendations

import { StatusCode } from '@/api/constants'
import {
  DarwinDetachedModuleError,
  DarwinError,
  DarwinErrorType,
  DarwinNotFoundError,
} from '@/shared/components/errorBoundary/darwinError'
import GenericError from '@/shared/components/genericError'
import { ROLE, ROUTES } from '@/shared/constants'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { STORAGE_ERROR_COUNT, STORAGE_ERROR_PATH_NAME } from '@/shared/localStorageUtils'
import { clearError, setError } from '@/store/errorHandler/actions'
import { useAppDispatch } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { AxiosError } from 'axios'
import { PropsWithChildren, useState } from 'react'

/**
 * AuthConsumer component manages authentication state and handles the login process
 * using Azure MSAL (Microsoft Authentication Library). It ensures that only authenticated users
 * with the appropriate role can access the children components.
 *
 * @component
 * @param {PropsWithChildren} props - The props for the component.
 * @param {ReactNode} props.children - The child components to be rendered if the user is authenticated and authorized.
 *
 * @returns {JSX.Element} The rendered component which either displays the children if the user is authenticated,
 * or a GenericError component if there is an authentication error.
 *
 * @throws {DarwinDetachedModuleError} Throws an error if the user is unauthorized during the login process.
 *
 * @example
 * <AuthConsumer>
 *   <YourProtectedComponent />
 * </AuthConsumer>
 */

const AuthConsumer = ({ children }: PropsWithChildren) => {
  const authConsumerDispatch = useAppDispatch()

  const isAuthenticated = useIsAuthenticated()
  const { instance: msalInstance, inProgress } = useMsal()
  const [isAuthError, setIsAuthError] = useState(false)

  const scopes = import.meta.env.VITE_AZURE_SILENCE_REQUEST_SCOPES.split(',')

  const signIn = async () => {
    const storedUrl = sessionStorage.getItem(STORAGE_ERROR_PATH_NAME)
    authConsumerDispatch(clearError())
    await msalInstance.loginRedirect({
      scopes,
      redirectStartPage: storedUrl || ROUTES.HOME,
    })
  }

  const throwDarwinError = <T extends DarwinErrorType>(
    DarwinErrorConstructor: new (error: AxiosError) => T,
    status: StatusCode,
    message: string,
    name: string
  ) => {
    authConsumerDispatch(
      setError({
        error: new DarwinErrorConstructor({
          status,
          message,
          name,
          isAxiosError: false,
          /* v8 ignore next 1 */
          toJSON: () => ({}),
        }),
      })
    )
    setIsAuthError(true)
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult
      const account = payload.account
      const hasRole = account?.idTokenClaims?.roles?.includes(ROLE)
      sessionStorage.removeItem(STORAGE_ERROR_COUNT)

      if (!hasRole) {
        throwDarwinError(DarwinDetachedModuleError, StatusCode.NoRoleFound, 'NoRoleFound', '901')
      } else {
        msalInstance.setActiveAccount(account)
        setIsAuthError(false)
      }
    } else if (event.error?.message.includes('AADB2C90077')) {
      throwDarwinError(DarwinError, StatusCode.NotAccessible, 'UnknownSSOError', '907')
    } else if (
      (event.eventType === EventType.LOGIN_FAILURE && event.error?.name !== 'BrowserAuthError') ||
      (event.eventType === EventType.SSO_SILENT_FAILURE && event.error?.message.includes('monitor_window_timeout'))
    ) {
      throwDarwinError(DarwinNotFoundError, StatusCode.NotFound, 'NotFound', '404')
    } else if (
      [
        EventType.ACQUIRE_TOKEN_FAILURE,
        EventType.LOGIN_FAILURE,
        EventType.LOGOUT_FAILURE,
        EventType.ACQUIRE_TOKEN_FAILURE,
        EventType.ACQUIRE_TOKEN_BY_CODE_FAILURE,
      ].includes(
        event.eventType as
          | 'msal:loginFailure'
          | 'msal:acquireTokenFailure'
          | 'msal:acquireTokenByCodeFailure'
          | 'msal:logoutFailure'
      )
    ) {
      throwDarwinError(DarwinDetachedModuleError, StatusCode.UnknownSSOError, 'UnknownSSOError', '977')
    }
  })

  useDeepCompareEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      signIn()
    }
  }, [isAuthenticated, inProgress])

  return (
    <>
      {isAuthenticated && msalInstance.getActiveAccount() && !isAuthError && (
        <div data-testid={dataTestId.authConsumer}>{children}</div>
      )}
      {isAuthError && <GenericError />}
    </>
  )
}
export default AuthConsumer

import { RecommendationData } from '@/models/openRecommendations/types'
import OpenRecommendationAssetName from '@/modules/openRecommendations/components/cells/assetName'
import OpenRecommendationAssetStatus from '@/modules/openRecommendations/components/cells/assetStatus'
import OpenRecommendationBelongsTo from '@/modules/openRecommendations/components/cells/belongsTo'
import OpenRecommendationDate from '@/modules/openRecommendations/components/cells/date'
import DaysInDue from '@/modules/openRecommendations/components/cells/daysInDue'
import OpenRecommendationsFaultType from '@/modules/openRecommendations/components/cells/faultType'
import Observation from '@/modules/openRecommendations/components/cells/observation'
import OpenRecommendedAction from '@/modules/openRecommendations/components/cells/recommendedAction'
import { filterRegistry } from '@/shared/components/tableComponents/genericTableFilter/constants'
import { createTableSortingOrderMap, sortingRegistry } from '@/shared/components/tableComponents/sortingFunction'
import { openRecommendationAssetStatusSortingOrder } from '@/shared/components/tableComponents/sortingFunction/constants'
import TableColumn from '@/shared/components/tableComponents/tableColumn'
import { getMappedValue } from '@/shared/utils'
import { useTypedSelector } from '@/store/store'
import { MRT_ColumnDef, MRT_Row } from 'material-react-table'
import { useMemo } from 'react'

const useOpenRecommendationsColumns = () => {
  const reportFaultTypes = useTypedSelector((state) => state.reportFaultTypes)

  const columns = useMemo<MRT_ColumnDef<RecommendationData>[]>(
    () => [
      {
        id: 'assetStatus',
        accessorKey: 'assetStatus',
        header: '',
        Cell: ({ cell, row }) => (
          <OpenRecommendationAssetStatus
            openRecommendationOutcome={row.original.raOutcome}
            assetStatus={cell.getValue<RecommendationData['assetStatus']>()}
            rowIndex={row.index}
          />
        ),
        Header: ({ column }) => <TableColumn column={column} />,
        grow: false,
        size: 110,
        enableSorting: true,
        sortingFn: (rowA, rowB, columnId: string) =>
          sortingRegistry.status<RecommendationData>(
            rowA as MRT_Row<RecommendationData>,
            rowB as MRT_Row<RecommendationData>,
            columnId,
            createTableSortingOrderMap(openRecommendationAssetStatusSortingOrder)
          ),

        enableColumnFilter: true,
        enableFilterMatchHighlighting: false,
        enableColumnFilterModes: false,
        filterFn: (row, columnId, searchValue) =>
          filterRegistry.open_recommendation_status.filterFn(row as MRT_Row<RecommendationData>, columnId, searchValue),
        meta: {
          filterType: 'open_recommendation_status',
        },
      },
      {
        id: 'assetName',
        accessorKey: 'assetName',
        header: 'Asset',
        Header: ({ column }) => <TableColumn column={column} />,
        Cell: ({ cell }) => <OpenRecommendationAssetName assetName={cell.getValue<string>()} />,
        size: 200,
        grow: false,
        enableColumnFilter: true,
        enableFilterMatchHighlighting: false,
        enableColumnFilterModes: false,
        filterFn: (row, columnId, searchValue) =>
          filterRegistry.text.filterFn(row as MRT_Row<RecommendationData>, columnId, searchValue),
        meta: {
          filterType: 'text',
        },
      },
      {
        id: 'belongsTo',
        header: 'Belongs To',
        accessorKey: 'assetParent',
        Cell: ({ cell }) => <OpenRecommendationBelongsTo belongsTo={cell.getValue<string>()} />,
        Header: ({ column }) => <TableColumn column={column} />,
        size: 190,
        enableSorting: true,
        enableColumnFilter: true,
        enableFilterMatchHighlighting: false,
        enableColumnFilterModes: false,
        filterFn: (row, columnId, searchValue) =>
          filterRegistry.text.filterFn(row as MRT_Row<RecommendationData>, columnId, searchValue),
        meta: {
          filterType: 'text',
        },
      },
      {
        id: 'faultType',
        header: 'Fault',
        accessorKey: 'faultType',
        accessorFn: ({ faultType }) => getMappedValue(reportFaultTypes, faultType),
        grow: false,
        Cell: ({ cell }) => <OpenRecommendationsFaultType faultType={cell.getValue<string>()} />,
        Header: ({ column }) => <TableColumn column={column} />,
        size: 150,
        enableSorting: true,
        enableColumnFilter: true,
        enableFilterMatchHighlighting: false,
        enableColumnFilterModes: false,
        filterFn: (row, columnId, searchValue) =>
          filterRegistry.text.filterFn(row as MRT_Row<RecommendationData>, columnId, searchValue),
        meta: {
          filterType: 'text',
        },
      },
      {
        id: 'observation',
        header: 'Observation',
        accessorKey: 'observation',
        Cell: ({ cell }) => <Observation observation={cell.getValue<string>()} />,
        Header: ({ column }) => <TableColumn column={column} />,
        size: 200,
        enableSorting: true,
        enableColumnFilter: true,
        enableFilterMatchHighlighting: false,
        enableColumnFilterModes: false,
        filterFn: (row, columnId, searchValue) =>
          filterRegistry.text.filterFn(row as MRT_Row<RecommendationData>, columnId, searchValue),
        meta: {
          filterType: 'text',
        },
      },
      {
        id: 'recommendedAction',
        header: 'Recommended Action',
        accessorKey: 'recommendedAction',
        Cell: ({ cell }) => <OpenRecommendedAction recommendedAction={cell.getValue<string>()} />,
        Header: ({ column }) => <TableColumn column={column} />,
        size: 400,
        enableSorting: true,
        enableColumnFilter: true,
        enableFilterMatchHighlighting: false,
        enableColumnFilterModes: false,
        filterFn: (row, columnId, searchValue) =>
          filterRegistry.text.filterFn(row as MRT_Row<RecommendationData>, columnId, searchValue),
        meta: {
          filterType: 'text',
        },
      },
      {
        id: 'raOpenDate',
        header: 'Created',
        accessorKey: 'raOpenDate',
        Cell: ({ cell }) => <OpenRecommendationDate date={cell.getValue<string>()} />,
        Header: ({ column }) => <TableColumn column={column} />,
        sortingFn: (rowA, rowB, column) =>
          sortingRegistry.date<RecommendationData>(
            rowA as MRT_Row<RecommendationData>,
            rowB as MRT_Row<RecommendationData>,
            column
          ),
        enableSorting: true,
        enableColumnFilter: true,
        enableFilterMatchHighlighting: false,
        enableColumnFilterModes: false,
        meta: {
          filterType: 'date',
        },
        filterFn: (row, columnId, searchString) =>
          filterRegistry.date.filterFn(row as MRT_Row<RecommendationData>, columnId, searchString),
        size: 155,
        grow: false,
      },
      {
        id: 'raDueDate',
        header: 'Due',
        accessorKey: 'raDueDate',
        Cell: ({ cell }) => <OpenRecommendationDate date={cell.getValue<string>()} />,
        Header: ({ column }) => <TableColumn column={column} />,
        sortingFn: (rowA, rowB, column) =>
          sortingRegistry.date<RecommendationData>(
            rowA as MRT_Row<RecommendationData>,
            rowB as MRT_Row<RecommendationData>,
            column
          ),
        enableSorting: true,
        enableColumnFilter: true,
        enableFilterMatchHighlighting: false,
        enableColumnFilterModes: false,
        meta: {
          filterType: 'date',
        },
        filterFn: (row, columnId, searchString) =>
          filterRegistry.date.filterFn(row as MRT_Row<RecommendationData>, columnId, searchString),
        size: 145,
        grow: false,
      },
      {
        id: 'raDaysToDue',
        header: 'Days',
        accessorKey: 'raDaysToDue',
        accessorFn: ({ raDaysToDue }) => (raDaysToDue ? raDaysToDue : ''),
        Cell: ({ cell }) => <DaysInDue daysInDue={cell.getValue<number>()} />,
        Header: ({ column }) => <TableColumn column={column} />,
        size: 145,
        grow: false,
        sortingFn: (rowA, rowB, columnId) =>
          sortingRegistry.number<RecommendationData>(
            rowA as MRT_Row<RecommendationData>,
            rowB as MRT_Row<RecommendationData>,
            columnId
          ),
        enableColumnFilter: true,
        enableSorting: true,
        filterFn: (row, columnId, searchString) =>
          filterRegistry.number.filterFn(row as MRT_Row<RecommendationData>, columnId, searchString),
        meta: {
          filterType: 'number',
        },
      },
    ],
    [reportFaultTypes]
  )

  return columns
}

export default useOpenRecommendationsColumns

import { getReports } from '@/api/paths/reports'
import { ReportGeneratorLoaderData } from '@/app/routes/types'
import { useReportGeneratorContext } from '@/contexts/reportGenerator'
import { ReportGeneratorApiPayload } from '@/models/reportGenerator/types'
import { collectAssetIds, mapAssetHealthDataFaultTypes } from '@/modules/reportGenerator/utils'
import { DarwinDetachedModuleError } from '@/shared/components/errorBoundary/darwinError'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { useApi } from '@/shared/hooks/useApi'
import { useAsyncThrow } from '@/shared/hooks/useAsyncThrow'
import { useTypedSelector } from '@/store/store'
import { AxiosError } from 'axios'
import { useLoaderData, useParams } from 'react-router'

const useGenerateReports = () => {
  const reportFaultTypes = useTypedSelector((state) => state.reportFaultTypes)
  const throwError = useAsyncThrow()
  const { assetReportFilters, setGeneratedReportData, includedFilteredAssets } = useReportGeneratorContext()

  const { sid } = useParams()

  const { mappedAssetHierarchyData } = useLoaderData() as ReportGeneratorLoaderData

  return useApi(() => {
    const payload: ReportGeneratorApiPayload = {
      ...assetReportFilters,
      assets:
        collectAssetIds(mappedAssetHierarchyData).size === includedFilteredAssets.length ? [] : includedFilteredAssets,
      reportDate: formatDate(assetReportFilters.reportDate, DateFormats.ISO8601Date),
      reportTypes: assetReportFilters.reportTypes,
    }

    return getReports(
      payload,
      {
        onSuccess: async (result) => {
          const mappedData = mapAssetHealthDataFaultTypes(result.detailedAssetHealth, reportFaultTypes)
          setGeneratedReportData({ ...result, detailedAssetHealth: mappedData })
        },
        onError: async (error: AxiosError) => {
          throwError(new DarwinDetachedModuleError(error))

          //TBD
          /*
            setShowPreview(false)
            reportGeneratorDispatch(
              openToast({
                feSeverity: 'error',
                children: error.message,
              })
            )
          */
        },
      },
      {
        sid,
      }
    )
  })
}

export default useGenerateReports

export type TreeNodeData = {
  id: string
  children: TreeNodeData[]
  label: string
  type: NodeType
  disabled?: boolean
}

export enum NodeType {
  Company,
  Site,
  FunctionalLocation,
  Asset,
}

export type SelectionState = 'selected' | 'indeterminate' | 'deselected'

export type Node = {
  id: string
  parent?: string
  children: string[]
  selected: boolean
  numDescendants: number
  numSelectedDescendants: number
  disabled?: boolean
}

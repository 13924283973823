import { StyledAppTabs } from '@/app/tabs/styled'
import { DarwinError } from '@/shared/components/errorBoundary/darwinError'
import { ROUTES } from '@/shared/constants'
import { STORAGE_DARWIN_ERROR, STORAGE_ERROR_PATH_NAME, STORAGE_ERROR_SID } from '@/shared/localStorageUtils'
import { getRouteFromPathname } from '@/shared/utils'
import { clearError } from '@/store/errorHandler/actions'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router'
import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { errorDataSelector } from '@/store/selectors/errorDataSelector'
import { tabsItems } from '@/app/tabs/constants'

const AppTabs = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const tabsDispatch = useAppDispatch()
  const errorData = useTypedSelector((state) => errorDataSelector(state))
  const storedError = useRef<DarwinError>(JSON.parse(localStorage.getItem(STORAGE_DARWIN_ERROR) || '{}') as DarwinError)
  const { sid } = useParams()
  const { fetchDataStatus } = useFetchDataContext()
  const [selectedTab, setSelectedTab] = useState(-1)
  const routeName = useMemo(() => getRouteFromPathname(pathname), [pathname])

  useEffect(() => {
    let tabIndex = tabsItems.findIndex((item) => item.route === routeName)
    if ((errorData || Object.keys(storedError.current).length > 0) && routeName === ROUTES.TAB_ERROR) {
      const prevUrl = sessionStorage.getItem(STORAGE_ERROR_PATH_NAME)
      if (prevUrl) {
        tabIndex = tabsItems.findIndex((item) => item.route === prevUrl)
      }
      if (tabIndex > -1) {
        setSelectedTab(tabIndex)
      }
    } else {
      setSelectedTab(tabIndex > 0 ? tabIndex : 0)
    }
  }, [errorData, routeName])

  const tabsToShow = useMemo(() => {
    return tabsItems
      .filter((item) => !item.isHiddenTab)
      .map((item) => ({
        label: item.label,
        children: item.route === routeName && <Outlet />,
      }))
  }, [routeName])

  return (
    ([
      ROUTES.HOME,
      ROUTES.OVERVIEW,
      ROUTES.ERROR,
      ROUTES.SITE_DETAILS,
      ROUTES.REPORT_GENERATOR,
      ROUTES.REPORT_STATUS,
    ].includes(routeName as ROUTES) && <Outlet />) || (
      <StyledAppTabs
        isActive={fetchDataStatus !== 'loading'}
        feSelected={selectedTab}
        feType="expanded"
        feItems={tabsToShow}
        onClick={(_, index) => {
          if (index !== selectedTab) {
            tabsDispatch(clearError())
            localStorage.removeItem(STORAGE_DARWIN_ERROR)
            if ([ROUTES.TAB_ERROR, ROUTES.ERROR].includes(routeName as ROUTES)) {
              navigate(`${tabsItems[index].route}/${sessionStorage.getItem(STORAGE_ERROR_SID)}`)
            } else {
              navigate(`${tabsItems[index].route}/${sid}`)
            }
          }
        }}
        data-testid={dataTestId.appTabs}
      />
    )
  )
}

export default AppTabs

import { AssetReportFilters, ReportGeneratorApiResponse, StaticReportPartBlob } from '@/models/reportGenerator/types'
import {
  // initialAssetHierarchyState,
  initialReportFiltersState,
  initialGeneratedReportsData,
} from '@/modules/reportGenerator/utils/constants'
import useContextWrapper from '@/shared/hooks/useContextWrapper'
import { createContext, useMemo, useState } from 'react'

export interface IReportGeneratorContext {
  generatedReportData: ReportGeneratorApiResponse
  setGeneratedReportData: React.Dispatch<React.SetStateAction<IReportGeneratorContext['generatedReportData']>>
  showPreview: boolean
  setShowPreview: React.Dispatch<React.SetStateAction<IReportGeneratorContext['showPreview']>>
  assetReportFilters: AssetReportFilters
  setAssetReportFilters: React.Dispatch<React.SetStateAction<IReportGeneratorContext['assetReportFilters']>>
  staticReportPartsBlobs: StaticReportPartBlob
  setStaticReportPartsBlobs: React.Dispatch<React.SetStateAction<IReportGeneratorContext['staticReportPartsBlobs']>>
  previewReportUrl: string | null
  setPreviewReportUrl: React.Dispatch<React.SetStateAction<IReportGeneratorContext['previewReportUrl']>>
  isPdfGenerationInProgress: boolean
  setIsPdfGenerationInProgress: React.Dispatch<
    React.SetStateAction<IReportGeneratorContext['isPdfGenerationInProgress']>
  >
  includedFilteredAssets: string[]
  setIncludedFilteredAssets: React.Dispatch<React.SetStateAction<IReportGeneratorContext['includedFilteredAssets']>>
  loaderProgress: number
  setLoaderProgress: React.Dispatch<React.SetStateAction<IReportGeneratorContext['loaderProgress']>>
}
export const ReportGeneratorContext = createContext<IReportGeneratorContext | null>(null)

export const useReportGeneratorContext = () =>
  useContextWrapper(ReportGeneratorContext, {
    contextName: useReportGeneratorContext.name,
    providerName: ReportGeneratorContextProvider.name,
  })

export const ReportGeneratorContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [assetReportFilters, setAssetReportFilters] = useState<IReportGeneratorContext['assetReportFilters']>(
    () => initialReportFiltersState
  )
  const [generatedReportData, setGeneratedReportData] = useState<IReportGeneratorContext['generatedReportData']>(
    () => initialGeneratedReportsData
  )

  const [showPreview, setShowPreview] = useState<IReportGeneratorContext['showPreview']>(false)

  const [staticReportPartsBlobs, setStaticReportPartsBlobs] = useState<
    IReportGeneratorContext['staticReportPartsBlobs']
  >({} as StaticReportPartBlob)

  const [previewReportUrl, setPreviewReportUrl] = useState<IReportGeneratorContext['previewReportUrl']>(null)

  const [isPdfGenerationInProgress, setIsPdfGenerationInProgress] =
    useState<IReportGeneratorContext['isPdfGenerationInProgress']>(false)

  const [includedFilteredAssets, setIncludedFilteredAssets] = useState<
    IReportGeneratorContext['includedFilteredAssets']
  >([])

  const [loaderProgress, setLoaderProgress] = useState<IReportGeneratorContext['loaderProgress']>(0)

  const value = useMemo(
    () => ({
      generatedReportData,
      assetReportFilters,
      setAssetReportFilters,
      setGeneratedReportData,
      showPreview,
      setShowPreview,
      staticReportPartsBlobs,
      setStaticReportPartsBlobs,
      previewReportUrl,
      setPreviewReportUrl,
      isPdfGenerationInProgress,
      setIsPdfGenerationInProgress,
      includedFilteredAssets,
      setIncludedFilteredAssets,
      loaderProgress,
      setLoaderProgress,
    }),
    [
      assetReportFilters,
      generatedReportData,
      showPreview,
      staticReportPartsBlobs,
      previewReportUrl,
      isPdfGenerationInProgress,
      includedFilteredAssets,
      loaderProgress,
    ]
  )

  return <ReportGeneratorContext value={value}>{children}</ReportGeneratorContext>
}

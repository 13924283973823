import {
  ChannelFaultEnum,
  ChannelStatusEnum,
  ChannelTypeEnum,
  DeviceFaultEnum,
  DeviceStatusEnum,
} from '@/models/devicesAndSensors/types'
import { IconColor, IconName, Color } from '@skf-design-system/ui-components-react'

const channelFaultText: Record<ChannelFaultEnum, string> = {
  cable_fault: 'Broken Connection',
  broken_connection: 'Broken Connection',
  no_measurement_24h: 'No measurement 24h',
  no_measurement_ever: 'No measurement ever',
  invalid: 'Invalid',
}

const deviceFaultText: Record<DeviceFaultEnum, string> = {
  buffer: 'Buffer fault',
  cable_fault: 'Broken Connection',
  broken_connection: 'Broken Connection',
  no_measurement_24h: 'No measurement 24h',
  internal_temperature: 'Internal temperature',
  invalid: 'Invalid',
  channel_fault: 'Channel Fault',
}

const deviceStatusIcon: Record<DeviceStatusEnum, { icon: IconName; color: IconColor }> = {
  normal: {
    color: 'green',
    icon: 'checkCircle',
  },
  notNormal: {
    color: 'purple',
    icon: 'sensor',
  },
}

const deviceStatusString: Record<DeviceStatusEnum, string> = {
  normal: 'Normal',
  notNormal: 'Faulted',
}

const channelStatusString: Record<ChannelStatusEnum, string> = {
  normal: 'Normal',
  notNormal: 'Faulted',
  noSensorData: 'No measurement ever',
  channelNotConnected: 'Channel not connected to any asset',
}

const channelStatusIcon: Record<ChannelStatusEnum, { icon: IconName; color: IconColor }> = {
  normal: {
    color: 'green',
    icon: 'checkCircle',
  },
  notNormal: {
    color: 'purple',
    icon: 'sensor',
  },
  noSensorData: {
    color: 'gray',
    icon: 'error',
  },
  channelNotConnected: {
    icon: 'forbidden',
    color: 'gray',
  },
}

const channelTextColor: Record<ChannelStatusEnum, Color> = {
  normal: 'greenBase',
  notNormal: 'purpleBase',
  noSensorData: 'gray700',
  channelNotConnected: 'gray700',
}

const deviceNumberFormatter = new Intl.NumberFormat('en-US', {
  minimumIntegerDigits: 3,
  useGrouping: false,
})

const channelTypeText: Record<ChannelTypeEnum | 'invalid', string> = {
  invalid: 'Invalid',
  analog: 'Analog',
  digital: 'Digital',
}

export {
  channelStatusIcon,
  channelFaultText,
  deviceFaultText,
  deviceStatusIcon,
  deviceStatusString,
  channelStatusString,
  deviceNumberFormatter,
  channelTextColor,
  channelTypeText,
}

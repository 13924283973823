import useContextWrapper from '@/shared/hooks/useContextWrapper'
import { OpenRecommendationData } from '@/models/openRecommendations/types'

import { createContext, useMemo, useState } from 'react'

interface IOpenRecommendationsContext {
  openRecommendations: OpenRecommendationData
  setOpenRecommendations: React.Dispatch<React.SetStateAction<IOpenRecommendationsContext['openRecommendations']>>
}

export const OpenRecommendationsContext = createContext<IOpenRecommendationsContext | null>(null)

export const useOpenRecommendationsContext = () =>
  useContextWrapper(OpenRecommendationsContext, {
    contextName: useOpenRecommendationsContext.name,
    providerName: OpenRecommendationsContextProvider.name,
  })

export const OpenRecommendationsContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [openRecommendations, setOpenRecommendations] = useState<IOpenRecommendationsContext['openRecommendations']>({
    open: [],
    closed: [],
  })

  const value = useMemo(
    () => ({
      openRecommendations,
      setOpenRecommendations,
    }),
    [openRecommendations]
  )

  return <OpenRecommendationsContext value={value}>{children}</OpenRecommendationsContext>
}

import { Icon } from '@skf-design-system/ui-components-react'
import { FC, useCallback, useRef, useState } from 'react'
import { FaultState, FieldErrorState, UpdateFaultPath } from '@/models/reportStatus/faultsFormStateTypes'
import { dataTestId } from '@/tests/testid'
import TextEllipsis, { TextEllipsisType } from '@/shared/components/textEllipsis'
import { useFaultsFormStateContext } from '@/contexts/moduleContexts/faultsForm'
import useOutsideClick from '@/shared/hooks/useOutsideClick'
import { FaultTypes } from '@/modules/reportStatus/components/multiLevelSelect/types'
import { getDisplayLabel, onComponentChange } from '@/modules/reportStatus/utils/formUtils'
import {
  StyledComponentErrorText,
  StyledComponentLabel,
  StyledMultiLevelSelectWrapper,
  StyledReportStatusComponent,
  StyledSelectToggle,
} from '@/modules/reportStatus/styled'
import MainOptionsList from '@/modules/reportStatus/components/multiLevelSelect/mainOptionsList'

interface MultiLevelSelectProps {
  label: string
  isInEditMode: boolean
  value: string
  text: string
  options: FaultTypes[]
  state: FaultState
  fieldPath: UpdateFaultPath
  isRequired?: boolean
  errorData?: FieldErrorState
}

const MultiLevelSelect: FC<MultiLevelSelectProps> = ({
  label,
  isInEditMode,
  text,
  options,
  isRequired = false,
  errorData,
  state,
  fieldPath: { fieldId, fieldName, parentFieldId },
}) => {
  const [isMainOptionsVisible, setIsMainOptionsVisible] = useState(false)
  const [hoveredMainOption, setHoveredMainOption] = useState<string | null>(null)
  const [selectedMainOption, setSelectedMainOption] = useState<string | undefined>(undefined)
  const [selectedSubOption, setSelectedSubOption] = useState<string | undefined>(undefined)

  const { faultsFormDispatch } = useFaultsFormStateContext()

  const outsideClickRef = useRef<HTMLDivElement>(null)

  useOutsideClick(
    () => {
      setIsMainOptionsVisible(false)
    },
    {
      classNames: [],
      refs: [outsideClickRef],
    }
  )

  const toggleMainOptions = useCallback(() => {
    setIsMainOptionsVisible((prev) => !prev)
  }, [])

  const handleMainOptionClick = (value: string) => {
    const mainOption = options.find((opt) => opt.value === value)
    if (mainOption && mainOption.subTypes && mainOption.subTypes.length === 0) {
      setSelectedMainOption(value)
      setSelectedSubOption(undefined)
      setHoveredMainOption(null)
      setIsMainOptionsVisible(false)
      onComponentChange<string>(value, faultsFormDispatch, fieldId, fieldName, parentFieldId)
    }
  }

  const handleSubOptionClick = (value: string) => {
    setSelectedSubOption(value)
    const mainOption = options.find((opt) => opt.subTypes?.some((sub) => sub.code === value))

    setSelectedMainOption(mainOption?.value)
    setIsMainOptionsVisible(false)

    onComponentChange<string>(value, faultsFormDispatch, fieldId, fieldName, parentFieldId)
  }

  const displayLabel = getDisplayLabel(options, selectedMainOption, selectedSubOption)

  return (
    <StyledReportStatusComponent
      feFlexDirection="column"
      isValid={errorData?.isValid}
      state={state}
      data-testid={dataTestId.multiLevelSelect}
      ref={outsideClickRef}
    >
      <>
        <StyledComponentLabel
          isRequired={isRequired && isInEditMode}
          isValid={errorData?.isValid}
          state={state}
          data-testid={dataTestId.multiLevelSelectLabel}
        >
          {label}
        </StyledComponentLabel>
        {!isInEditMode && <TextEllipsis type={TextEllipsisType.MultiLine} rowsUntilEllipsis={3} value={text} />}
        {isInEditMode && (
          <>
            <StyledMultiLevelSelectWrapper>
              <StyledSelectToggle
                onClick={toggleMainOptions}
                isValid={errorData?.isValid}
                data-testid={dataTestId.selectToggleButton}
              >
                {displayLabel}
                <Icon feIcon="chevronDown" feColor="gray" feSize="md" />
              </StyledSelectToggle>
              <MainOptionsList
                isMainOptionsVisible={isMainOptionsVisible}
                options={options}
                hoveredMainOption={hoveredMainOption}
                selectedMainOption={selectedMainOption}
                selectedSubOption={selectedSubOption}
                setHoveredMainOption={setHoveredMainOption}
                handleMainOptionClick={handleMainOptionClick}
                handleSubOptionClick={handleSubOptionClick}
              />
            </StyledMultiLevelSelectWrapper>
            <StyledComponentErrorText data-testid={dataTestId.multiLevelSelectErrorArea}>
              {state !== FaultState.NEW.toString() && errorData?.error}
            </StyledComponentErrorText>
          </>
        )}
      </>
    </StyledReportStatusComponent>
  )
}

export default MultiLevelSelect

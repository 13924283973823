import { AssetHistorySnapshot, ReconfirmedStatus } from '@/models/reportStatus/types'
import HistoryLinePrefix from '@/modules/reportStatus/components/history/HistoryLinePrefix'
import {
  StyledCollapseRowWrapper,
  StyledReconfirmCollapseContainer,
  StyledReconfirmFlex,
  StyledReconfirmUsernameSpan,
  StyledRowWrapper,
} from '@/modules/reportStatus/styled'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { dataTestId } from '@/tests/testid'
import { Flexbox, Icon, IconName, Image, SPACINGS } from '@skf-design-system/ui-components-react'
import { FC, ReactElement } from 'react'

type HistoryCollapseProps = {
  historyRows: ReactElement[]
  lastReconfirm: AssetHistorySnapshot | ReconfirmedStatus
  dataLength: number
  label: string
  open: boolean
  icon?: IconName
  image?: string
}

const HistoryCollapse: FC<HistoryCollapseProps> = ({
  historyRows,
  dataLength,
  lastReconfirm,
  label,
  open,
  icon,
  image,
}) => {
  const timestamp = useDeepCompareMemo(() => {
    if ('timestamp' in lastReconfirm) {
      return lastReconfirm.timestamp
    }
    if ('time' in lastReconfirm) {
      return lastReconfirm.time
    }
  }, [lastReconfirm])

  const username = useDeepCompareMemo(() => {
    if ('username' in lastReconfirm) {
      return lastReconfirm.username
    }
    if ('userName' in lastReconfirm) {
      return lastReconfirm.userName
    }
  }, [lastReconfirm])

  const SingleOverlapComponent = icon ? StyledReconfirmFlex : StyledRowWrapper

  return (
    <div data-testid={dataTestId.faultHistoryCollapse}>
      <Flexbox feGap="xs">
        {icon && <Icon feIcon={icon} feSize="sm" data-testid={dataTestId.faultHistoryCollapseIcon} />}
        {dataLength > 1 && image && (
          <Image
            data-testid={dataTestId.faultHistoryCollapseImage}
            src={image}
            width={20}
            height={20}
            alt="condensed-image"
          />
        )}
        {dataLength === 1 ? (
          <SingleOverlapComponent feGap="sm" feFlexDirection={icon && 'column'}>
            <span>{formatDate(timestamp, DateFormats.AmericanAMPMFormat)}</span>
            <StyledReconfirmUsernameSpan>{username}</StyledReconfirmUsernameSpan>
          </SingleOverlapComponent>
        ) : open ? (
          <StyledReconfirmFlex feGap="sm">
            <span>{formatDate(timestamp, DateFormats.AmericanAMPMFormat)}</span>
            <StyledReconfirmUsernameSpan>{username}</StyledReconfirmUsernameSpan>
          </StyledReconfirmFlex>
        ) : (
          <StyledReconfirmFlex feGap="sm">
            <span>{formatDate(timestamp, DateFormats.AmericanAMPMFormat)}</span>
            <span>
              ({dataLength} {label})
            </span>
          </StyledReconfirmFlex>
        )}
      </Flexbox>

      {open && dataLength > 1 && (
        <StyledReconfirmCollapseContainer feFlexDirection="row">
          <HistoryLinePrefix marginTop={SPACINGS.xxs} />
          <StyledCollapseRowWrapper feFlexDirection="column">{historyRows}</StyledCollapseRowWrapper>
        </StyledReconfirmCollapseContainer>
      )}
    </div>
  )
}

export default HistoryCollapse

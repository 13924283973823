/* eslint-disable react-compiler/react-compiler */
import { useFilesDropzoneContext } from '@/contexts/moduleContexts/filesDropzone'
import { ERROR_TIMEOUT, MAX_FILES, MAX_TOTAL_SIZE } from '@/shared/components/dropzone/constants'
import {
  StyledDashedBorder,
  StyledErrorParagraph,
  StyledIcon,
  StyledInput,
  StyledParagraph,
  StyledUploadIconContainer,
} from '@/shared/components/dropzone/styled'
import { FileDropzoneImageData } from '@/shared/components/dropzone/types'
import useFilesDropzone from '@/shared/components/dropzone/useFilesDropzone'
import { convertToEvidenceImageData } from '@/shared/components/dropzone/utils'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import useTimeout from '@/shared/hooks/useTimeout'
import { dataTestId } from '@/tests/testid'
import { Flexbox } from '@skf-design-system/ui-components-react'
import { FC, useEffect, useState } from 'react'
import { FileRejection } from 'react-dropzone'

interface FilesDropzoneProps {
  onFilesProcessed: (files: FileDropzoneImageData[]) => void
  shouldReturnFilesWithError?: boolean
}

const FilesDropzone: FC<FilesDropzoneProps> = ({ onFilesProcessed, shouldReturnFilesWithError }) => {
  const [validationError, setValidationError] = useState<string | null>(null)
  const { dropzoneFiles, faultId, category, dropzoneProps, setInputRef, acceptFiles, maxFileSize } =
    useFilesDropzoneContext()

  const { startTimeout: resetError, clearTimeout: clearResetErrorTimeOut } = useTimeout(() => {
    setValidationError(null)
  }, ERROR_TIMEOUT)

  const onDrop = async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    const allFiles = [...acceptedFiles, ...rejectedFiles] as File[] | FileRejection[]
    if (allFiles.length + rejectedFiles.length > MAX_FILES) {
      setValidationError(`You can only upload a maximum of ${MAX_FILES} files.`)
      return
    }

    const convertedValidFiles = await convertToEvidenceImageData(allFiles, faultId, category)
    const totalSize = [...dropzoneFiles[faultId], ...convertedValidFiles].reduce(
      (acc, file) => acc + file.imageInfo.size,
      0
    )

    if (totalSize > MAX_TOTAL_SIZE) {
      setValidationError(`Total file size exceeds ${MAX_TOTAL_SIZE / (1024 * 1024)} MB.`)
      return
    }

    if (
      dropzoneFiles[faultId].some((image) =>
        convertedValidFiles.some((file) => image.imageInfo.content === file.imageInfo.content)
      )
    ) {
      setValidationError('This image was already added as evidence to the fault')
      return
    }

    if (rejectedFiles.length > 0 && !shouldReturnFilesWithError) {
      setValidationError(rejectedFiles[0].errors[0].message)
      return
    }

    setValidationError(null)
    onFilesProcessed(convertedValidFiles)
  }

  const { getRootProps, getInputProps, isDragActive, inputRef } = useFilesDropzone(
    { ...dropzoneProps, onDrop },
    acceptFiles,
    maxFileSize
  )

  useEffect(() => {
    setInputRef(inputRef)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef])

  useEffect(() => {
    resetError()
    return clearResetErrorTimeOut

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationError])

  const isFileLimitExceeded = useDeepCompareMemo(
    () => dropzoneFiles[faultId]?.length >= MAX_FILES,
    [dropzoneFiles, faultId]
  )

  return (
    <Flexbox feFlexDirection="column" data-testid={dataTestId.filesDropzone}>
      <StyledDashedBorder
        data-testid={dataTestId.filesDropzoneBorderContainer}
        {...getRootProps()}
        isFileLimitExceeded={isFileLimitExceeded}
      >
        <StyledUploadIconContainer
          data-testid={dataTestId.filesDropzoneIconContainer}
          isFileLimitExceeded={isFileLimitExceeded}
        >
          <StyledIcon feIcon="upload" feColor={isFileLimitExceeded ? 'gray' : 'brand'} feSize="sm" />
          <StyledInput {...getInputProps()} />
        </StyledUploadIconContainer>
        {isDragActive ? (
          <StyledParagraph>Release to drop the files here</StyledParagraph>
        ) : (
          <StyledParagraph>Drag files here or click to upload</StyledParagraph>
        )}
      </StyledDashedBorder>
      {validationError && (
        <StyledErrorParagraph data-testid={dataTestId.filesDropzoneError}>{validationError}</StyledErrorParagraph>
      )}
    </Flexbox>
  )
}

export default FilesDropzone

import { useFaultsFormStateContext } from '@/contexts/moduleContexts/faultsForm'
import {
  FaultState,
  FieldErrorState,
  RecommendedActionState,
  UpdateFaultPath,
} from '@/models/reportStatus/faultsFormStateTypes'
import {
  StyledComponentErrorText,
  StyledComponentLabel,
  StyledReportStatusComponent,
  StyledTextAreaField,
} from '@/modules/reportStatus/styled'
import { onComponentChange } from '@/modules/reportStatus/utils/formUtils'
import TextEllipsis, { TextEllipsisType } from '@/shared/components/textEllipsis'
import useDeepCompareCallback from '@/shared/hooks/useDeepCompareCallback'
import useInputDebounce from '@/shared/hooks/useInputDebounce'
import { dataTestId } from '@/tests/testid'
import { ChangeEvent, FC, useEffect, useState } from 'react'

interface FormTextAreaProps {
  state: FaultState | RecommendedActionState
  label: string
  isInEditMode: boolean
  value: string
  fieldPath?: UpdateFaultPath
  maxLength?: number
  maxHeight?: string
  maxWidth?: string
  minHeight?: string
  minWidth?: string
  hint?: string
  isRequired?: boolean
  errorData?: FieldErrorState
}

const FormTextArea: FC<FormTextAreaProps> = ({
  label,
  isInEditMode,
  value,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  hint,
  maxLength,
  isRequired = false,
  fieldPath,
  errorData,
  state,
}) => {
  const { faultsFormDispatch } = useFaultsFormStateContext()

  const [inputValue, setInputValue] = useState(() => value)

  const debouncedInputValue = useInputDebounce(inputValue)

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault()
    setInputValue(event.target.value)
  }

  const onTextAreaChange = useDeepCompareCallback(() => {
    fieldPath &&
      onComponentChange(
        debouncedInputValue,
        faultsFormDispatch,
        fieldPath.fieldId,
        fieldPath.fieldName,
        fieldPath.parentFieldId
      )
  }, [fieldPath, debouncedInputValue])

  useEffect(() => {
    onTextAreaChange()
  }, [debouncedInputValue, onTextAreaChange])

  useEffect(() => {
    setInputValue(value)
  }, [value])

  return (
    <StyledReportStatusComponent
      isValid={errorData?.isValid}
      feFlexDirection="column"
      state={state}
      data-testid={dataTestId.formTextArea}
    >
      <StyledComponentLabel
        data-testid={dataTestId.formTextAreaLabel}
        isValid={errorData?.isValid}
        isRequired={isRequired && isInEditMode}
        state={state}
      >
        {label}
      </StyledComponentLabel>
      {!isInEditMode && <TextEllipsis type={TextEllipsisType.MultiLine} rowsUntilEllipsis={3} value={inputValue} />}
      {isInEditMode && (
        <>
          <StyledTextAreaField
            value={inputValue}
            feLabel=""
            maxHeight={maxHeight}
            maxWidth={maxWidth}
            minHeight={minHeight}
            minWidth={minWidth}
            feHint={hint}
            maxLength={maxLength}
            onChange={(event) => handleInputChange(event)}
          />
          <StyledComponentErrorText data-testid={dataTestId.formTextAreaError}>
            {state !== FaultState.NEW.toString() && errorData?.error}
          </StyledComponentErrorText>
        </>
      )}
    </StyledReportStatusComponent>
  )
}

export default FormTextArea

import { COLORS, Flexbox, SPACINGS, styled } from '@skf-design-system/ui-components-react'

export const StyledOpenRecommendationsRoot = styled.div`
  gap: ${SPACINGS.md};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 2rem ${SPACINGS.lg} 0;
  padding: 0 2rem;
`

export const StyledDaysDueSpan = styled.span<{
  daysInDue: number
}>`
  color: ${({ daysInDue }) => ((daysInDue < 1 && daysInDue > -30) || daysInDue <= -30 ? 'white' : 'inherit')};
  background-color: ${({ daysInDue }) =>
    daysInDue < 1 && daysInDue > -30 ? '#bb8ebb' : daysInDue <= -30 ? COLORS.purpleBase : 'inherit'};
  padding: 0.1875rem 0.3125rem 0.1875rem 0.5rem;
  border-radius: 0.3125rem;
`
export const StyledDaysDueContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledOpenRecommendationAssetName = styled(Flexbox)`
  overflow-wrap: anywhere;
`

import {
  StyledTechInfoContainer,
  StyledTechInfoImageContainer,
  StyledTechInfoSectionContainer,
  StyledTechInfoSectionHeading,
  StyledTechInfoGrid,
  StyledTechInfoImage,
  StyledTechInfoTitleContainer,
} from '@/modules/siteDetails/styled'
import { dataTestId } from '@/tests/testid'
import { Text } from '@skf-design-system/ui-components-react'
import React, { FC } from 'react'
import { useSiteDetailsContext } from '@/contexts/siteDetails'
import CustomerLogoCard from '@/modules/siteDetails/components/contractDetails/customerLogoCard'

const TechnicalInformation: FC = () => {
  const {
    siteDetailsData: { databases },
  } = useSiteDetailsContext()

  return (
    <StyledTechInfoGrid data-testid={dataTestId.siteDetailsTechnicalInfo}>
      <StyledTechInfoImageContainer>
        <StyledTechInfoImage>
          <CustomerLogoCard />
        </StyledTechInfoImage>
      </StyledTechInfoImageContainer>

      <StyledTechInfoContainer>
        <StyledTechInfoTitleContainer>
          <Text>Technical information</Text>
        </StyledTechInfoTitleContainer>

        {!!databases.analyst?.length && (
          <StyledTechInfoSectionContainer data-testid={dataTestId.siteDetailsTechnicalInfoAnalystDBContainer}>
            <StyledTechInfoSectionHeading>Analyst DB:</StyledTechInfoSectionHeading>
            {databases.analyst?.map((analystDB) => {
              const { original_db_name, hierarchies } = analystDB
              return (
                <React.Fragment key={analystDB.id}>
                  {hierarchies?.length && hierarchies.length > 0 ? (
                    hierarchies?.map(({ hierarchy_name }, index) => (
                      <Text key={`${analystDB.id}-${index}`}>{`${original_db_name} # ${hierarchy_name}`}</Text>
                    ))
                  ) : (
                    <Text key={`${analystDB.id}`}>{`${original_db_name}`}</Text>
                  )}
                </React.Fragment>
              )
            })}
          </StyledTechInfoSectionContainer>
        )}

        {!!databases.observer?.length && (
          <StyledTechInfoSectionContainer data-testid={dataTestId.siteDetailsTechnicalInfoObserverDBContainer}>
            <StyledTechInfoSectionHeading>Observer DB:</StyledTechInfoSectionHeading>
            {databases.observer?.map((observerDB, index) => <Text key={index}>{observerDB.original_db_name}</Text>)}
          </StyledTechInfoSectionContainer>
        )}

        {!!databases.axios?.length && (
          <StyledTechInfoSectionContainer data-testid={dataTestId.siteDetailsTechnicalInfoAxiosDBContainer}>
            <StyledTechInfoSectionHeading>AXIOS DB:</StyledTechInfoSectionHeading>
            {databases.axios?.map((axiosDB, index) => <Text key={index}>{axiosDB.original_db_name}</Text>)}
          </StyledTechInfoSectionContainer>
        )}
      </StyledTechInfoContainer>
    </StyledTechInfoGrid>
  )
}

export default TechnicalInformation

import { FileDropzoneImageData } from '@/shared/components/dropzone/types'
import {
  StyledFaultHistoryEvidenceHeadingContainer,
  StyledFaultHistoryEvidenceContainer,
  StyledFaultHistoryEvidenceFilesContainer,
  StyledFaultHistoryEvidenceHeadings,
  StyledFaultHistoryEvidenceFilesWrapper,
} from '@/modules/reportStatus/styled'
import { Icon } from '@skf-design-system/ui-components-react'
import { FC } from 'react'
import { FaultEvidenceContextProvider } from '@/contexts/moduleContexts/evidence'
import { dataTestId } from '@/tests/testid'
import EvidenceHistoryContent from '@/modules/reportStatus/components/history/evidenceHistory/EvidenceHistoryContent'
import TextEllipsis, { TextEllipsisType } from '@/shared/components/textEllipsis'

interface EvidenceHistoryProps {
  evidences: FileDropzoneImageData['imageInfo'][]
  faultId: string
}

const EvidenceHistory: FC<EvidenceHistoryProps> = ({ evidences, faultId }) => {
  return (
    <FaultEvidenceContextProvider faultId={faultId}>
      <StyledFaultHistoryEvidenceContainer data-testid={dataTestId.evidenceHistory}>
        <StyledFaultHistoryEvidenceHeadingContainer feAlignItems="top" feGap="xs">
          <Icon feIcon="arrowRight" feSize="sm" />
          <StyledFaultHistoryEvidenceHeadings>Evidence added</StyledFaultHistoryEvidenceHeadings>
        </StyledFaultHistoryEvidenceHeadingContainer>
        <StyledFaultHistoryEvidenceFilesContainer>
          <TextEllipsis
            type={TextEllipsisType.MultiLine}
            label="Evidence added"
            value={`${evidences.length} files`}
            rowsUntilEllipsis={3}
          />
          <StyledFaultHistoryEvidenceFilesWrapper>
            {evidences.map((evidence) => (
              <EvidenceHistoryContent
                evidence={evidence}
                key={evidence.fileName}
                data-testid={dataTestId.evidenceHistoryContent}
              />
            ))}
          </StyledFaultHistoryEvidenceFilesWrapper>
        </StyledFaultHistoryEvidenceFilesContainer>
      </StyledFaultHistoryEvidenceContainer>
    </FaultEvidenceContextProvider>
  )
}
export default EvidenceHistory

import { DarwinErrorDialog } from '@/shared/components/errorBoundary/darwinError'
import { FileDropzoneImageData } from '@/shared/components/dropzone/types'
import { STORAGE_REPORT_STATUS_ORIGIN } from '@/shared/localStorageUtils'
import { applicativeLog, generateUUID, getSidFromPathname } from '@/shared/utils'

/**
 * Handles the paste event on an HTML input element, capturing files from the clipboard.
 * If an image file is pasted, it creates a `File` object with a formatted name and assigns it to the input element.
 *
 * @param {HTMLInputElement} htmlInput - The input element where files are to be set.
 * @param {ClipboardEvent | React.MouseEvent} event - The paste or mouse event triggering the file capture.
 * @returns {Promise<void>} - A promise that resolves when the clipboard files are processed.
 */

const handleInputPasteEvent = async (htmlInput: HTMLInputElement, event: ClipboardEvent | React.MouseEvent) => {
  let files: FileList | null = null
  if (event instanceof ClipboardEvent && event.clipboardData?.files) {
    files = event.clipboardData.files
  } else {
    try {
      const clipboardItems = await navigator.clipboard.read()

      for (const clipboardItem of clipboardItems) {
        if (clipboardItem.types.includes('image/png') || clipboardItem.types.includes('image/jpeg')) {
          const imageType = clipboardItem.types.find((type) => type.startsWith('image/'))
          const blob = await clipboardItem.getType(imageType!)

          const file = new File([blob], `${generateUUID()}.${imageType?.split('/')[1]}`, {
            type: blob.type,
          })

          const dataTransfer = new DataTransfer()
          dataTransfer.items.add(file)
          files = dataTransfer.files
        }
      }
    } catch (err) {
      applicativeLog('Failed to read from clipboard: ', [err], 'error')
    }
  }
  htmlInput.files = files
  htmlInput.dispatchEvent(new Event('change', { bubbles: true }))
}
/**
 * Maps evidence data from a response to the expected `FileDropzoneImageData` format,
 * adding missing information if necessary and throwing an error for corrupted data.
 *
 * @param {FileDropzoneImageData[]} evidences - An array of evidence data objects received from a response.
 * @param {string} nodeID - The ID of the node associated with the evidence.
 * @returns {FileDropzoneImageData[]} A new array of evidence data objects in the expected format.
 *
 * @throws {DarwinErrorDialog} Throws a custom error with specific details if any evidence data is corrupted.
 */
const mapResponseFaultEvidences = (evidences: FileDropzoneImageData[] | undefined, nodeID: string) => {
  return evidences?.map((evidence) => {
    const evidenceToCheck = { ...evidence }

    if (!evidenceToCheck.imageInfo) {
      throw new DarwinErrorDialog({
        message: 'Corrupted image data',
        code: '400',
        isAxiosError: false,
        /* v8 ignore next 1 */
        toJSON: () => ({}),
        name: '',
      })
    }

    if (!evidenceToCheck.imageInfo.uniqueId) {
      evidenceToCheck.imageInfo.uniqueId = evidenceToCheck.imageInfo.content
        ? createUniqueIdFromBase64(evidenceToCheck.imageInfo.content)
        : generateUUID()
    }

    if (!evidenceToCheck.appInfo) {
      evidenceToCheck.appInfo = {
        nodeID,
        siteID: getSidFromPathname(sessionStorage.getItem(STORAGE_REPORT_STATUS_ORIGIN)),
        isUploaded: true,
      }
    }
    if (!evidenceToCheck.bucketInfo) {
      evidenceToCheck.bucketInfo = {
        category: 'evidence',
      }
    }

    return evidenceToCheck
  })
}
/**
 * Creates a unique ID from a base64-encoded string.
 *
 * @param {string} base64String - The base64-encoded string to generate the unique ID from.
 * @returns {string} A unique ID consisting of the first 16 alphanumeric characters from the base64 string.
 */
const createUniqueIdFromBase64 = (base64String: string) => {
  // Encode the base64 string and trim it
  const uniqueId = base64String.replace(/[^a-zA-Z0-9]/g, '') // Remove non-alphanumeric characters
  return uniqueId.substring(0, 16) // Return a substring of the first 16 characters
}

export { handleInputPasteEvent, mapResponseFaultEvidences }

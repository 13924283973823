import { MenuItem } from '@/modules/reportStatus/components/formMenu/types'
import { FaultFormMenuIcon, MenuButton } from '@/modules/reportStatus/styled'
import { dataTestId } from '@/tests/testid'
import { Text } from '@skf-design-system/ui-components-react'
import { FC } from 'react'

const FormMenuItemComponent: FC<MenuItem & { onClosePopover: () => void }> = ({
  icon,
  label,
  onClick,
  onClosePopover,
  disabled,
}) => {
  const handleOnClick = () => {
    onClick()
    onClosePopover()
  }

  return (
    <li>
      <MenuButton disabled={disabled} onClick={handleOnClick} data-testid={dataTestId.formMenuItemComponent}>
        <FaultFormMenuIcon feSize="sm" feIcon={icon} feColor={disabled ? 'gray' : 'brand'} />
        <Text>{label}</Text>
      </MenuButton>
    </li>
  )
}

export default FormMenuItemComponent

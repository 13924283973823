import EditDetailsForm from '@/modules/siteDetails/components/contractDetails/editDetails/editDetailsForm'
import { StyledEditDetailsContainer } from '@/modules/siteDetails/styled'
import { dataTestId } from '@/tests/testid'

const EditDetails = () => {
  return (
    <StyledEditDetailsContainer data-testid={dataTestId.siteDetailsEditDetails}>
      <EditDetailsForm />
    </StyledEditDetailsContainer>
  )
}

export default EditDetails

import { headerConfigSelector } from '@/store/selectors/moduleHeaderSelector'
import { useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { Text } from '@skf-design-system/ui-components-react'

/**
 * HeaderSubtitle is the span that located in the module header
 *
 * HeaderSubtitle configured via headerConfig redux
 *
 * @returns {JSX.Element} The rendered component.
 */
const HeaderSubtitle = () => {
  const { subtitle } = useTypedSelector((store) => headerConfigSelector(store))
  return (
    <Text feFontSize="xl" feFontWeight="bold" data-testid={dataTestId.moduleHeaderSubtitle}>
      {subtitle}
    </Text>
  )
}
export default HeaderSubtitle

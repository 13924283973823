import { StyledReconfirmRowContainer, StyledUserUpdateDateContainer } from '../../styled'
import { Flexbox } from '@skf-design-system/ui-components-react'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { AssetHistorySnapshot } from '@/models/reportStatus/types'
import { dataTestId } from '@/tests/testid'
import TextEllipsis, { TextEllipsisType } from '@/shared/components/textEllipsis'
import { MarkAsNormalHistory } from '@/modules/reportStatus/components/history/markAsNormal'

interface HistoryDataContainer {
  snapshot: AssetHistorySnapshot | AssetHistorySnapshot[]
}

export const HistoryDataContainer = ({ snapshot }: HistoryDataContainer) => {
  return (
    <>
      {!Array.isArray(snapshot) ? (
        <StyledUserUpdateDateContainer feFlexDirection="column" feGap="sm" data-testid={dataTestId.normalContainer}>
          <Flexbox feFlexDirection="row" feAlignItems="center" feGap="xs">
            <div>{formatDate(snapshot.timestamp, DateFormats.AmericanAMPMFormat)}</div>
          </Flexbox>
          <TextEllipsis type={TextEllipsisType.MultiLine} rowsUntilEllipsis={1} value={snapshot.username} />
        </StyledUserUpdateDateContainer>
      ) : (
        <StyledReconfirmRowContainer feFlexDirection="column" data-testid={dataTestId.markAsNormalContainer}>
          <MarkAsNormalHistory snapshot={snapshot} />
        </StyledReconfirmRowContainer>
      )}
    </>
  )
}

import { useFaultsFormStateContext } from '@/contexts/moduleContexts/faultsForm'
import {
  FaultState,
  FieldErrorState,
  RecommendedActionState,
  UpdateFaultPath,
} from '@/models/reportStatus/faultsFormStateTypes'
import {
  StyledComponentErrorText,
  StyledComponentLabel,
  StyledReportStatusComponent,
} from '@/modules/reportStatus/styled'
import { onComponentChange } from '@/modules/reportStatus/utils/formUtils'
import { DateFormats, formatDate, highlightDates, parseDate } from '@/shared/dateUtils'
import { dataTestId } from '@/tests/testid'
import { DatePicker, Text } from '@skf-design-system/ui-components-react'
import { Dayjs } from 'dayjs'
import { FC } from 'react'

interface FormDatePickerProps {
  label: string
  date: string
  isInEditMode: boolean
  state: FaultState | RecommendedActionState
  maxDate: Date
  minDate: Date
  fieldPath?: UpdateFaultPath
  isRequired?: boolean
  errorData?: FieldErrorState
}

const FormDatePicker: FC<FormDatePickerProps> = ({
  label,
  date,
  isInEditMode,
  state,
  isRequired = false,
  fieldPath,
  errorData,
  maxDate,
  minDate,
}) => {
  const {
    faultsFormState: { lastCollectionDate },
    faultsFormDispatch,
  } = useFaultsFormStateContext()

  return (
    <StyledReportStatusComponent
      feFlexDirection="column"
      isValid={errorData?.isValid}
      state={state}
      data-testid={dataTestId.formDatePicker}
    >
      <StyledComponentLabel
        data-testid={dataTestId.formDatePickerLabel}
        isValid={errorData?.isValid}
        isRequired={isRequired && isInEditMode}
        state={state}
      >
        {label}
      </StyledComponentLabel>
      {!isInEditMode && <Text>{formatDate(date, DateFormats.AmericanDateFormat)}</Text>}
      {isInEditMode && (
        <>
          <DatePicker
            feSize="sm"
            placeholder={DateFormats.ISO8601Date}
            value={parseDate(date)}
            cellRender={(currentDate: string | number | Dayjs, info: { originNode: React.ReactNode; type: string }) =>
              highlightDates(currentDate, info, [lastCollectionDate])
            }
            feMaxDate={maxDate}
            feMinDate={minDate}
            feHideLabel={true}
            onChange={(date) =>
              fieldPath &&
              onComponentChange(
                formatDate(date as Date, DateFormats.AmericanDateFormat),
                faultsFormDispatch,
                fieldPath.fieldId,
                fieldPath.fieldName,
                fieldPath.parentFieldId
              )
            }
            feLabel=""
            feRange={false}
            allowClear={false}
          />
          <StyledComponentErrorText data-testid={dataTestId.formDatePickerErrorArea}>
            {state !== FaultState.NEW.toString() && errorData?.error}
          </StyledComponentErrorText>
        </>
      )}
    </StyledReportStatusComponent>
  )
}

export default FormDatePicker

/* eslint-disable react-compiler/react-compiler */
import { useReportGeneratorContext } from '@/contexts/reportGenerator'
import pdf from '@/modules/reportGenerator/assets/pdf_4x.webp'
import GenerateReportButton from '@/modules/reportGenerator/components/generateReportButton'
import ReportPreviewLoader from '@/modules/reportGenerator/components/reportPreviewLoader'
import {
  StyledEmbeddedPdfViewer,
  StyledReportPreviewContainer,
  StyledReportPreviewEmptyState,
  StyledReportPreviewPdfLogo,
  StyledSelectAssetsStartText,
} from '@/modules/reportGenerator/styled'
import { TerminateWorkers } from '@/modules/reportGenerator/types'
import { getReportStartMessage } from '@/modules/reportGenerator/utils'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { setModuleHeaderActionPanelData } from '@/store/moduleHeader/actions'
import { useAppDispatch } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import React, { useEffect, useMemo, useRef } from 'react'
import { useParams } from 'react-router'

const ReportPreview: React.FC = () => {
  const sid = useParams()

  const {
    showPreview,
    setShowPreview,
    assetReportFilters,
    previewReportUrl,
    setPreviewReportUrl,
    isPdfGenerationInProgress,
    includedFilteredAssets,
    setLoaderProgress,
    setIsPdfGenerationInProgress,
  } = useReportGeneratorContext()

  const reportPreviewDispatch = useAppDispatch()
  const generateReportButtonRef = useRef<TerminateWorkers>(null)

  const terminateWorkers = () => {
    generateReportButtonRef.current?.terminateWorkers()
  }

  const resetPreview = () => {
    setShowPreview(false)
    setPreviewReportUrl(null)
    setLoaderProgress(0)
  }

  const terminateReportCreation = () => {
    terminateWorkers()
    setIsPdfGenerationInProgress(false)
    resetPreview()
  }

  useEffect(() => {
    return () => {
      if (previewReportUrl) {
        URL.revokeObjectURL(previewReportUrl)
      }
    }
  }, [previewReportUrl, sid])

  useEffect(() => {
    terminateReportCreation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sid])

  useEffect(() => {
    if (!showPreview) {
      setPreviewReportUrl(null)
      setLoaderProgress(0)
    }
  }, [showPreview, setPreviewReportUrl, setLoaderProgress])

  useEffect(() => {
    reportPreviewDispatch(setModuleHeaderActionPanelData({ isClearFiltersButtonDisabled: isPdfGenerationInProgress }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPdfGenerationInProgress])

  const isGenerateReportDisabled = useDeepCompareMemo(
    () =>
      isPdfGenerationInProgress ||
      assetReportFilters.reportTypes.length === 0 ||
      assetReportFilters.assets.length === 0,
    [assetReportFilters, isPdfGenerationInProgress]
  )

  const isGenerateButtonVisible = useMemo(() => {
    return (
      !showPreview &&
      !isPdfGenerationInProgress &&
      !isGenerateReportDisabled &&
      includedFilteredAssets.length > 0 &&
      assetReportFilters.assetStatuses.length > 0
    )
  }, [
    includedFilteredAssets.length,
    isGenerateReportDisabled,
    isPdfGenerationInProgress,
    showPreview,
    assetReportFilters.assetStatuses.length,
  ])

  return (
    <>
      <StyledReportPreviewContainer
        feFlexDirection="column"
        feAlignItems="center"
        feJustifyContent="center"
        data-testid={dataTestId.generateReportPreview}
        isPreviewVisible={!showPreview}
      >
        {isPdfGenerationInProgress && <ReportPreviewLoader onTerminateProcess={terminateReportCreation} />}
        <StyledReportPreviewEmptyState feFlexDirection="column" feAlignItems="center" feJustifyContent="center">
          {!showPreview && !isPdfGenerationInProgress && (
            <>
              <StyledReportPreviewPdfLogo src={pdf} data-testid={dataTestId.generateReportPreviewPdfLogo} />
              <StyledSelectAssetsStartText data-testid={dataTestId.generateReportSelectAssetsToStart}>
                {getReportStartMessage(includedFilteredAssets.length, assetReportFilters.reportTypes.length)}
              </StyledSelectAssetsStartText>
            </>
          )}
          <GenerateReportButton
            isDisabled={false}
            isVisible={isGenerateButtonVisible}
            ref={generateReportButtonRef}
            label="Generate Report"
          />
        </StyledReportPreviewEmptyState>
      </StyledReportPreviewContainer>
      {showPreview && !isPdfGenerationInProgress && previewReportUrl && (
        <StyledEmbeddedPdfViewer
          src={`${previewReportUrl}#toolbar=0`}
          type="application/pdf"
          data-testid={dataTestId.generateReportPreviewPdfViewer}
        />
      )}
    </>
  )
}

export default ReportPreview

import { ImageFileNameData } from '@/models/reportStatus/evidenceTypes'
import { ACCEPTED_MIME_TYPES } from '@/shared/components/dropzone/constants'

/**
 * Checks if a given string is a valid Base64 encoded string.
 *
 * This function first checks if the string has a Base64 prefix (e.g., "data:image/...;base64,").
 * If the prefix is present, it removes it before performing the Base64 validation.
 *
 * The validation checks if the string length is greater than 3, is a multiple of 4,
 * and can be successfully decoded using the `atob` function.
 *
 * @param str - The string to be checked.
 * @returns `true` if the string is a valid Base64 encoded string, `false` otherwise.
 */
const isBase64Encoded = (str: string): boolean => {
  let result = false

  const base64PrefixRegex = /^data:image\/(.+?);base64,/
  if (base64PrefixRegex.test(str)) {
    str = str.replace(base64PrefixRegex, '')
    //This complex condition checks if:
    //The string is longer than 3 characters
    //The length is divisible by 4 (Base64 strings are always multiples of 4)
    //The string can be decoded using atob() without throwing an error
    result =
      str.length > 3 &&
      !(str.length % 4) &&
      (() => {
        try {
          return atob(str), true
        } catch {
          return false
        }
      })()
  }

  return result
}

/**
 * Checks if the given string is an accepted MIME type.
 *
 * This function verifies whether the provided string matches any of the MIME types
 * specified in the `ACCEPTED_FILE_TYPES` array.
 *
 * @param str - The MIME type string to be checked.
 * @returns `true` if the MIME type is accepted, `false` otherwise.
 */
const isMimeType = (str: string): boolean => {
  return Object.keys(ACCEPTED_MIME_TYPES).includes(str.toLocaleLowerCase())
}

/**
 * Converts a file name to lowercase for its extension while preserving the rest of the name.
 * @param fileName - The original file name with extension.
 * @returns The file name with the extension converted to lowercase.
 */
const convertFileNameExtensionToLowercase = (fileName: string): string => {
  const fileNameParts = fileName.split('.')
  const fileExtension = fileNameParts.pop()?.toLowerCase()
  const fileNameWithoutExtension = fileNameParts.join('.')

  return fileExtension ? `${fileNameWithoutExtension}.${fileExtension}` : fileName
}

/**
 * Extracts the name and extension from a file name string.
 *
 * @param {string} fileName - The full file name including extension.
 * @returns {{ name: string; extension: string }}
 * An object containing:
 * - `name`: The file name without the extension.
 * - `extension`: The file extension (if any), or an empty string if no extension exists.
 *
 * @example
 * extractFileNameAndExtension("example.file.txt");
 * // Returns: { name: "example.file", extension: "txt" }
 *
 * @example
 * extractFileNameAndExtension("example");
 * // Returns: { name: "example", extension: "" }
 *
 * @example
 * extractFileNameAndExtension(".hiddenfile");
 * // Returns: { name: ".hiddenfile", extension: "" }
 */
const extractFileNameAndExtension = (fileName: string): ImageFileNameData => {
  const lastDotIndex = fileName.lastIndexOf('.')
  return lastDotIndex > 0
    ? {
        fileName: fileName.slice(0, lastDotIndex),
        fileExtension: fileName.slice(lastDotIndex + 1),
      }
    : { fileName, fileExtension: '' }
}

/**
 * Converts a given size in bytes to megabytes.
 *
 * @param bytes - The size in bytes to be converted.
 * @returns The size in megabytes.
 *
 * @example
 * ```typescript
 * const sizeInMB = convertToMegaBytes(1048576);
 * console.log(sizeInMB); // 1
 * ```
 */
const convertToMegaBytes = (bytes: number): number => {
  return bytes / (1024 * 1024)
}

/**
 * Downloads a file from the specified URL and saves it with the given file name.
 *
 * This function creates an anchor (`<a>`) element, sets its `href` attribute to the provided URL,
 * and sets the `download` attribute to the provided file name. It then programmatically triggers
 * a click event on the anchor element to initiate the download. The URL object is revoked after
 * 40 seconds to release memory.
 *
 * @param fileName - The name to save the downloaded file as.
 * @param url - The URL from which to download the file.
 *
 * @example
 * ```typescript
 * downloadFile('example.txt', 'https://example.com/file.txt');
 * ```
 *
 * @remarks
 * If the initial click event dispatch fails, a new `MouseEvent` is created with specific properties
 * and dispatched to ensure the download is triggered.
 */
const downloadFile = (fileName: string, url: string) => {
  const link = document.createElement('a')
  link.href = url
  link.download = fileName
  document.body.appendChild(link)
  setTimeout(function () {
    URL.revokeObjectURL(link.href)
  }, 4e4) // 40s
  setTimeout(function () {
    try {
      link.dispatchEvent(new MouseEvent('click'))
    } catch {
      const evt = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
        screenX: 80,
        screenY: 20,
      })
      link.dispatchEvent(evt)
    }
  }, 0)
}

export {
  convertToMegaBytes,
  isBase64Encoded,
  isMimeType,
  convertFileNameExtensionToLowercase,
  extractFileNameAndExtension,
  downloadFile,
}

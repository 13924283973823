import { assetCriticalities } from '@/modules/reportGenerator/utils/constants'

export type MetaTableState = {
  tableState: boolean
}

export enum AssetStatus {
  'never-reported' = 'Never reported',
  normal = 'Normal',
  acceptable = 'Acceptable',
  unsatisfactory = 'Unsatisfactory',
  unacceptable = 'Unacceptable',
  severe = 'Severe',
}

export type AssetStatusString = keyof typeof AssetStatus

export enum RecommendedActionStatus {
  pending = 'Open',
  created = 'Created',
  closed = 'Closed',
  completed = 'Completed',
  rejected = 'Rejected',
}
export type RecommendedActionStatusString = keyof typeof RecommendedActionStatus

export type TupleUnion<U extends string, R extends string[] = []> = {
  [S in U]: Exclude<U, S> extends never ? [...R, S] : TupleUnion<Exclude<U, S>, [...R, S]>
}[U]

export type AllKeysOf<T> = keyof T extends string ? TupleUnion<keyof T> : never

export type Require<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>

/**
 * Utility type to extract the type of specific property values from an array of objects.
 *
 * @template T - A readonly array of objects, where each object contains specific properties.
 * @template K - A key of the objects in the array whose value type you want to extract.
 *
 */
export type ExtractArrayValues<
  T extends readonly { label: string; value: string }[],
  K extends keyof T[number],
> = T[number][K]

export type AssetCriticality = (typeof assetCriticalities)[number]

export type RecommendedActionBase = {
  recommendedAction: string
  dueDate: string
  additionalNotes: string
  workOrder: string
  createdDate: string | null
}

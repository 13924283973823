import {
  Flexbox,
  GridItem,
  Text,
  styled,
  COLORS,
  Grid,
  SPACINGS,
  Divider,
  DIVIDER_COLORS,
} from '@skf-design-system/ui-components-react'

export const StyledDataGrid = styled(Grid)`
  width: 100%;
  grid-template-columns: repeat(2, 1fr) !important;
  gap: 0 !important;
  row-gap: ${SPACINGS.md} !important;
`

export const StyledDevicesAndSensorsModuleContainer = styled(Flexbox)`
  padding-left: 1rem;
  margin: 2rem 0 2rem 1.25rem;
  width: 65%;
`

export const Title = styled(Text)`
  width: 50%;
`

export const StyledChannelDataWrapper = styled(GridItem)<{
  isLastItem: boolean
}>`
  border-bottom: ${({ isLastItem }) => !isLastItem && `1px solid ${COLORS.gray400}`};
  gap: 1rem;
  padding-bottom: 1rem;
  grid-template-columns: 50% 50% !important;
`

export const StyledChannelTitle = styled(Text)`
  min-width: 10.6rem;
  width: 50%;
`

export const StyledTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  min-width: 8.25rem;
`
export const StyledThresholdWrapper = styled.div`
  display: flex;
  gap: 0.625rem;
  white-space: nowrap;
`
export const StyledValuesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`
export const StyledThresholdValuesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -1.25rem;
  background-color: #f6f6f6;
  padding: 0 0.3rem 0.3rem;
  border: 1px solid ${COLORS.gray300};
  border-radius: 0.25rem;
`
export const StyledThresholdValue = styled(Text)`
  margin-bottom: 1rem;
  width: 3.4rem;
`
export const StyledThresholdTitleValue = styled(Text)`
  font-weight: 500;
`
export const StyledColoredSpan = styled.span<{
  value: string
  alarm: string | null
  warning: string | null
}>`
  color: ${({ value, alarm, warning }) => {
    if (alarm === null || warning === null) return `${COLORS.black}`
    if (+value > +alarm) return `${COLORS.redBase}`
    if (+value > +warning) return `${COLORS.orangeDark}`
    return `${COLORS.black}`
  }};
  font-weight: bold;
`
export const StyledThreshold = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
`
export const StyledThresholdsDataWrapper = styled.div`
  display: flex;
  gap: 0.625rem;
`
export const StyledData = styled(StyledDataGrid)`
  margin-top: 2rem;
  grid-template-columns: 50% 25% 25% !important;
`
export const StyledTitleThreshold = styled.div`
  color: #808895;
  font-weight: 700;
  padding: 0.125rem;
  margin-bottom: 0.3rem;
  background-color: ${COLORS.gray050};
  border: 1px solid ${COLORS.gray300};
  width: 75%;
  margin-top: -0.75rem;
  border-radius: 0.25rem;
`

export const StyledImage = styled.img`
  width: 10rem;
`

export const StyledDeviceModel = styled(Text)`
  width: fit-content;
  white-space: nowrap;
`

export const StyledDivider = styled(Divider)`
  width: 100%;
  border: 1px solid;
  border-bottom-color: ${DIVIDER_COLORS.primary};
  opacity: 0.4;
`

export const ViewCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACINGS.lg};
  padding: 0 0 0 1rem;
  margin: 2rem 0 2rem 1.25rem;
`

export const SideMenuCard = styled(ViewCard)`
  width: 35%;
  border-left: 1px solid lightgrey;
  margin-top: 2.75rem;
  overflow-y: auto;
  height: calc(100vh - 12rem);

  @media (width <= 1200px) {
    width: 43%;
  }
`

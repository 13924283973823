import { useFaultsFormStateContext } from '@/contexts/moduleContexts/faultsForm'
import MenuItemComponent from '@/modules/reportStatus/components/formMenu/menuItemComponent'
import { MenuItem } from '@/modules/reportStatus/components/formMenu/types'
import { FaultsFormMenuPopup } from '@/modules/reportStatus/styled'
import useOutsideClick from '@/shared/hooks/useOutsideClick'
import useScrollDetector from '@/shared/hooks/useScrollDetection'
import { dataTestId } from '@/tests/testid'
import { IconButton, useTooltip } from '@skf-design-system/ui-components-react'
import { FC, useEffect, useRef, useState } from 'react'

const FormMenu: FC<{
  items: MenuItem[]
}> = ({ items }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { isScrolling } = useScrollDetector()

  useEffect(() => {
    setIsOpen(false)
  }, [isScrolling])

  const outsideClickRef = useRef<HTMLDivElement>(null)

  useOutsideClick(
    () => {
      setIsOpen(false)
    },
    {
      classNames: [],
      refs: [outsideClickRef],
    }
  )
  const [clickedRef] = useTooltip()

  const {
    faultsFormState: { isSubmitting },
  } = useFaultsFormStateContext()

  const closePopoverCallback = () => setIsOpen(false)

  return (
    <>
      <FaultsFormMenuPopup isHovered={isOpen} triggerElement={clickedRef.current}>
        <div ref={outsideClickRef}>
          <ul data-testid={dataTestId.formMenuItems}>
            {items.map((i) => (
              <MenuItemComponent key={i.label} {...i} onClosePopover={closePopoverCallback} />
            ))}
          </ul>
        </div>
      </FaultsFormMenuPopup>
      <IconButton
        disabled={isSubmitting}
        aria-label="Actions"
        as="button"
        type="button"
        feColor="white"
        feIcon="kebabMore"
        feSize="md"
        ref={clickedRef}
        onClick={() => setIsOpen(!isOpen)}
        data-testid={dataTestId.formMenuIcon}
      />
    </>
  )
}

export default FormMenu

import { ROUTES } from '@/shared/constants'

export const tabsItems = [
  { label: 'Analysis Backlog', route: ROUTES.ANALYSIS_BACKLOG, isHiddenTab: false },
  { label: 'Open Recommendations', route: ROUTES.OPEN_RECOMMENDATIONS, isHiddenTab: false },
  { label: 'Devices & Sensors', route: ROUTES.DEVICES_AND_SENSORS, isHiddenTab: false },
  {
    label: 'Automatic Diagnostics',
    route: ROUTES.AUTOMATIC_DIAGNOSTICS,
    isHiddenTab: false,
  },
  { label: 'Dashboard', route: ROUTES.DASHBOARD, isHiddenTab: false },
  { label: '', route: ROUTES.NOT_FOUND, isHiddenTab: true },
  {
    label: '',
    route: ROUTES.TAB_ERROR,
    isHiddenTab: true,
  },
]

import { ClosedFault, Fault, FaultState, RecommendedActionState } from '@/models/reportStatus/faultsFormStateTypes'
import ClosedRecommendedAction from '@/modules/reportStatus/components/closedRecommendedAction'
import CorrectDiagnosticArea from '@/modules/reportStatus/components/correctDiagnosticArea'
import Evidence from '@/modules/reportStatus/components/evidence'
import EvidenceRow from '@/modules/reportStatus/components/evidence/evidenceRow'
import FaultMenu from '@/modules/reportStatus/components/faultMenu'
import FaultRow from '@/modules/reportStatus/components/faultRow'
import RecommendedActionMenu from '@/modules/reportStatus/components/recommendedActionMenu'
import RecommendedActionRow from '@/modules/reportStatus/components/recommendedActionRow'
import useScrollIntoViewOnce from '@/modules/reportStatus/hooks/useScrollIntoViewOnce'
import {
  StyledRecommendedActionContentContainer,
  StyledRecommendedActionRowHeader,
  StyledReportStatusFaultRow,
  StyledReportStatusFaultRowContainer,
  StyledReportStatusFaultRowTitle,
} from '@/modules/reportStatus/styled'
import { getEntityId } from '@/modules/reportStatus/utils/formUtils'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { useEffectOnce } from '@/shared/hooks/useEffectOnce'
import { dataTestId } from '@/tests/testid'
import { COLORS, css, Flexbox, Global, Heading, Icon } from '@skf-design-system/ui-components-react'
import { FC, useRef } from 'react'

interface FaultsContainer {
  fault: Fault
}

const FaultsContainer: FC<FaultsContainer> = ({ fault }) => {
  const isInEditMode = useDeepCompareMemo(() => {
    return [FaultState.EDIT, FaultState.PENDING_CLOSE, FaultState.NEW].includes(fault.state)
  }, [fault])
  // const { customerNotes: customerNotesData } = useLoaderData() as ReportStatusLoaderData
  // const { assetId } = useParams()

  const rowRef = useRef<HTMLDivElement>(null)

  // const customerNotes: HalCustomerNoteData[] = useDeepCompareMemo(() => {
  //   return findFaultCustomerNotes(getEntityId(fault), customerNotesData, assetId).filter((note) => !note.acknowledgment)
  // }, [assetId, customerNotesData, fault])

  // const {
  //   selectedCustomer: { customerNotesEnabled },
  // } = useTypedSelector((state) => state)

  const scrollToNewFault = useScrollIntoViewOnce(rowRef)

  useEffectOnce(() => {
    if (fault.state === FaultState.NEW) {
      scrollToNewFault()
    }
  })

  return (
    <Flexbox feFlexDirection="column" ref={rowRef} data-testid={dataTestId.faultComponent}>
      <Global
        styles={css`
          /* stylelint-disable-next-line selector-class-pattern */
          .react-datepicker__day--highlighted-dates:not(.react-datepicker__day--selected) {
            background-color: ${COLORS.purpleBase} !important;
            color: ${COLORS.white};
          }
        `}
      />
      <StyledReportStatusFaultRow faultState={fault.state} data-testid={dataTestId.faultsRowContainer}>
        <StyledReportStatusFaultRowTitle status={fault.status}>Fault</StyledReportStatusFaultRowTitle>
        <StyledReportStatusFaultRowContainer feFlexDirection="column">
          <FaultRow fault={fault} isInEditMode={isInEditMode} />
          <CorrectDiagnosticArea fault={fault as ClosedFault} isInEditMode={isInEditMode} />
        </StyledReportStatusFaultRowContainer>
        <FaultMenu fault={fault} isInEditMode={isInEditMode} />
      </StyledReportStatusFaultRow>

      <EvidenceRow data-testid={dataTestId.evidenceRow} status={fault.status} faultId={getEntityId(fault)}>
        <Evidence isInEditMode={isInEditMode} existedEvidences={fault.evidences} />
      </EvidenceRow>

      {fault.recommendedActions.map(
        (recommendedAction, recommendedActionIndex) =>
          ([RecommendedActionState.CLOSED].includes(recommendedAction.state) && (
            <ClosedRecommendedAction
              recommendedAction={recommendedAction}
              recommendedActionIndex={recommendedActionIndex + 1}
              key={getEntityId(recommendedAction)}
            />
          )) || (
            <StyledRecommendedActionContentContainer
              feAlignItems="center"
              key={getEntityId(recommendedAction)}
              data-testid={dataTestId.recommendedActionContainer}
            >
              <StyledRecommendedActionRowHeader status={fault.status}>
                <Icon feSize="md" feIcon="arrowRight" />
              </StyledRecommendedActionRowHeader>
              <Heading as="h2">{recommendedActionIndex + 1}</Heading>
              <RecommendedActionRow fault={fault} recommendedAction={recommendedAction} />
              <RecommendedActionMenu recommendedAction={recommendedAction} parentFault={fault} />
            </StyledRecommendedActionContentContainer>
          )
      )}
      {/* {customerNotesEnabled && customerNotes.length > 0 && <CustomerNote customerNotes={customerNotes} />} */}
    </Flexbox>
  )
}

export default FaultsContainer

import { StyledOverviewCell, StyledOverviewCellData } from '@/modules/overview/styled'
import { ROUTES } from '@/shared/constants'
import useRedirectToModuleWithSelectedCustomer from '@/shared/hooks/useRedirectToModuleWithSelectedCustomer'
import { formatProp } from '@/shared/utils'
import { dataTestId } from '@/tests/testid'
import { Tooltip, useTooltip } from '@skf-design-system/ui-components-react'
import { FC, ReactNode } from 'react'

interface OverviewCellProps {
  value: string | number | null
  siteId: string
  routeTo: ROUTES
  prefixIndicator?: ReactNode
  message?: string
  withTooltip?: boolean
  stringSuffixAdornment?: string
}

const OverviewCell: FC<OverviewCellProps> = ({
  siteId,
  value,
  routeTo,
  message = '',
  withTooltip = true,
  prefixIndicator = <></>,
  stringSuffixAdornment = '',
}) => {
  const redirectToModuleWithSelectedCustomer = useRedirectToModuleWithSelectedCustomer()
  const [hoverRef, isHovered] = useTooltip()
  return (
    <div>
      <StyledOverviewCellData ref={hoverRef}>
        {prefixIndicator}
        <span>
          <StyledOverviewCell
            data-testid={dataTestId.overviewCell}
            onClick={() => redirectToModuleWithSelectedCustomer(routeTo, siteId)}
          >
            {formatProp<string | number | null>(value, (value) => `${value}${stringSuffixAdornment}`, 'N/A')}
          </StyledOverviewCell>
        </span>
      </StyledOverviewCellData>
      <Tooltip isHovered={isHovered && withTooltip && !!message} triggerElement={hoverRef.current}>
        {message}
      </Tooltip>
    </div>
  )
}

export default OverviewCell

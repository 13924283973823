import { RecommendationData, ClosedRaOutcome } from '@/models/openRecommendations/types'
import AssetStatusIcon from '@/shared/components/assetStatusIcon'
import { switchHandleComponentsIds } from '@/shared/constants'
import { AssetStatusString } from '@/shared/models/types'
import { Flexbox } from '@skf-design-system/ui-components-react'
import { FC } from 'react'

interface OpenRecommendationAssetStatusProps {
  assetStatus: RecommendationData['assetStatus']
  openRecommendationOutcome?: RecommendationData['raOutcome'] | null
  rowIndex: number
}

const OpenRecommendationAssetStatus: FC<OpenRecommendationAssetStatusProps> = ({
  openRecommendationOutcome,
  assetStatus,
  rowIndex,
}) => {
  return (
    <Flexbox>
      {openRecommendationOutcome ? (
        <div id={`${switchHandleComponentsIds.switchOnItem}-${rowIndex}`}>
          {ClosedRaOutcome[openRecommendationOutcome]}
        </div>
      ) : (
        <div id={`${switchHandleComponentsIds.switchOffItem}-${rowIndex}`}>
          <AssetStatusIcon value={assetStatus as AssetStatusString} />
        </div>
      )}
    </Flexbox>
  )
}

export default OpenRecommendationAssetStatus

import React from 'react'
import { getStyles, containerStyles, rawBadgeStyles } from '@/shared/components/badge/badgeUtils'
import { dataTestId } from '@/tests/testid'

export interface BadgeProps {
  badgeContent: string | number
  bgColor?: string
  max?: number
  contentColor?: string
  verticalAlignment?: 'top' | 'bottom'
  horizontalAlignment?: 'left' | 'right'
  hideZero?: boolean
  size?: number
}

/**
 * Badge component that displays a badge alongside its children.
 *
 * @param {Object} props - The props for the Badge component.
 * @param {React.ReactNode} props.children - The content to be displayed alongside the badge.
 * @param {number|string} [props.badgeContent] - The content to display within the badge. Can be a number or string.
 * @param {string} [props.bgColor='red'] - The background color of the badge. Defaults to 'red'.
 * @param {string} [props.contentColor] - The color of the badge content (text).
 * @param {number} [props.max=99] - The maximum number to display in the badge. If the badgeContent exceeds this number, it will display 'max+'.
 * @param {React.CSSProperties} [props.style] - Additional styles to be applied to the badge.
 * @param {string} [props.className] - Additional class names to be applied to the badge.
 * @param {'top'|'bottom'} [props.verticalAlignment='top'] - The vertical alignment of the badge.
 * @param {'left'|'right'} [props.horizontalAlignment='right'] - The horizontal alignment of the badge.
 * @param {boolean} [props.hideZero=false] - If true, hides the badge when badgeContent is zero or falsy.
 * @param {string} [props.size] - The size of the badge.
 * @param {React.HTMLAttributes<HTMLSpanElement>} [props.props] - Additional HTML attributes to be passed to the badge element.
 *
 * @returns {JSX.Element} The rendered Badge component.
 */
const Badge: React.FC<BadgeProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>> = ({
  children,
  badgeContent,
  bgColor = 'red',
  contentColor,
  max = 99,
  style,
  className,
  verticalAlignment = 'top',
  horizontalAlignment = 'right',
  hideZero = false,
  size,
  ...props
}) => {
  let modifiedContent = badgeContent
  if (Number.isInteger(badgeContent) && max) {
    modifiedContent = +badgeContent > max ? `${max}+` : badgeContent
  }
  const badgeStyles = getStyles({
    badgeContent,
    bgColor,
    contentColor,
    horizontalAlignment,
    max,
    hideZero,
    verticalAlignment,
    size,
  })
  const hide = hideZero && !badgeContent
  return (
    <div data-testid={dataTestId.badgeComponent} className="container" style={containerStyles}>
      <div>{children}</div>
      {!hide && (
        <span
          className={`${className} badge`}
          style={{
            ...rawBadgeStyles,
            ...badgeStyles,
            ...style,
          }}
          {...props}
        >
          {modifiedContent}
        </span>
      )}
    </div>
  )
}

export default Badge

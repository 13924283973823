import { ReportStatusLoaderData } from '@/app/routes/types'
import { FaultsFormStateContextProvider } from '@/contexts/moduleContexts/faultsForm'
import { useReportStatusContext } from '@/contexts/reportStatus'
import { ResponseAssets } from '@/models/analysisBacklog/types'
import ConflictDialog from '@/modules/reportStatus/components/conflictDialog'
import ReportStatusDialogContent from '@/modules/reportStatus/components/reportStatusDialogContent'
import { getLastCollectedDate } from '@/modules/reportStatus/utils/formUtils'
import { ROUTES } from '@/shared/constants'
import useEscapeKey from '@/shared/hooks/useEscapeKey'
import { STORAGE_REPORT_STATUS_ORIGIN } from '@/shared/localStorageUtils'
import { openToast } from '@/store/genericToast/actions'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { Dialog, DialogProps } from '@skf-design-system/ui-components-react'
import { ToastProps } from '@skf-design-system/ui-components-react/dist/components/toast/Toast'
import { useEffect } from 'react'
import { useLoaderData, useNavigate, useParams } from 'react-router'
import EvidenceModal from '@/modules/reportStatus/components/evidence/evidenceDialog'
import { dataTestId } from '@/tests/testid'
import { pushRedirectData } from '@/store/redirectData/actions'
import { getSidFromPathname } from '@/shared/utils'
import { redirectDataBySidSelector } from '@/store/selectors/redirectDataSelector'
import { asyncPollerSelector } from '@/store/selectors/asyncPollerSelector'
import { genericConfirmationDialogSelector } from '@/store/selectors/genericConfirmationDialogSelector'

const reportStatusDialogProps: Omit<DialogProps, 'children' | 'open'> = {
  feTitle: 'Report Status',
  feNoPadding: true,
  feFullscreen: true,
}

const ReportStatusDialog = () => {
  const { assetId } = useParams()

  const { assetRow, assetFaultsAndHistory } = useLoaderData() as ReportStatusLoaderData

  const reportStatusDialogDispatch = useAppDispatch()

  const storedCustomerId = getSidFromPathname(sessionStorage.getItem(STORAGE_REPORT_STATUS_ORIGIN))

  const {
    reportStatusDialogData: { closeDialog, isOpen },
    assetsData,
    setReportStatusDialogData,
    isConflictDialogOpen,
    isEvidenceFaultImageModalOpen,
  } = useReportStatusContext()

  const navigate = useNavigate()

  const redirectData = useTypedSelector((state) => redirectDataBySidSelector(state, storedCustomerId))

  const { isAsyncPollerActive } = useTypedSelector((state) => asyncPollerSelector(state))

  const { isOpen: isConfirmDialogOpen } = useTypedSelector((state) => genericConfirmationDialogSelector(state))

  useEffect(() => {
    if (assetId && !isOpen) {
      setReportStatusDialogData({
        isOpen: true,
        closeDialog: (toastProps: ToastProps | undefined, assetsToState?: ResponseAssets) => {
          if (toastProps !== undefined) {
            reportStatusDialogDispatch(openToast({ ...toastProps }))
          }
          setReportStatusDialogData({ isOpen: false })
          const backUrl = sessionStorage.getItem(STORAGE_REPORT_STATUS_ORIGIN) as ROUTES

          if (redirectData) {
            reportStatusDialogDispatch(
              pushRedirectData({
                ...redirectData,
                toModule: ROUTES.ANALYSIS_BACKLOG,
                fromModule: ROUTES.REPORT_STATUS,
                assets: assetsToState as unknown as ResponseAssets,
                sid: storedCustomerId,
              })
            )
          }

          navigate(backUrl || ROUTES.HOME)
        },
      })
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId])

  useEffect(() => {
    if (closeDialog && assetRow === undefined) {
      closeDialog({
        feSeverity: 'error',
        children: 'Could not find asset',
      })
    }
  }, [assetRow, closeDialog])

  useEscapeKey(
    () =>
      !isConfirmDialogOpen && !isConflictDialogOpen && !isEvidenceFaultImageModalOpen && closeDialog && closeDialog(),
    [closeDialog]
  )

  return (
    <Dialog
      open={isOpen}
      feCloseButton={{
        disabled: isAsyncPollerActive,
      }}
      feOnClose={() => closeDialog && closeDialog(undefined, assetsData)}
      {...reportStatusDialogProps}
      data-testid={dataTestId.reportStatusDialog}
    >
      <FaultsFormStateContextProvider
        initialFaultsFormState={assetFaultsAndHistory.openFaults.map((v) => v.fault)}
        lastCollectionDate={getLastCollectedDate(assetRow?.lastCollectedDateTime)}
      >
        <ReportStatusDialogContent />
        <ConflictDialog />
        <EvidenceModal />
      </FaultsFormStateContextProvider>
    </Dialog>
  )
}
export default ReportStatusDialog

import useReconfirmReport from '@/modules/reportStatus/hooks/useReconfirmReport'
import GenericConfirmationDialogButton from '@/shared/components/genericConfirmationDialog/genericConfirmationDialogButton'
import { STORAGE_REPORT_STATUS_ORIGIN } from '@/shared/localStorageUtils'
import { getSidFromPathname } from '@/shared/utils'
import { closeGenericConfirmDialog, showGenericConfirmDialog } from '@/store/genericConfirmationDialog/actions'
import { asyncPollerSelector } from '@/store/selectors/asyncPollerSelector'
import { reportStatusReconfirmBySidSelector } from '@/store/selectors/sitesPermissionsSelector'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { Button } from '@skf-design-system/ui-components-react'
import { FC } from 'react'
import { useParams } from 'react-router'

interface ReconfirmButtonInterface {
  assetName: string
  assetParent: string
}

const ReconfirmButton: FC<ReconfirmButtonInterface> = ({ assetName, assetParent }) => {
  // const { assetFaultsAndHistory, /*customerNotes*/ } = useLoaderData() as ReportStatusLoaderData

  const { isAsyncPollerActive } = useTypedSelector((state) => asyncPollerSelector(state))

  const { assetId } = useParams()
  // const hasCustomerNotes = useMemo(
  //   () =>
  //     getOpenFaults(assetFaultsAndHistory, customerNotes, assetId).some(
  //       (v) => v.metadata.customerNotes.filter((c) => c.acknowledgment === false).length > 0
  //     ),
  //   [assetFaultsAndHistory, customerNotes, assetId]
  //)

  const analyticsBacklogControlPanelDispatch = useAppDispatch()
  const sid = getSidFromPathname(sessionStorage.getItem(STORAGE_REPORT_STATUS_ORIGIN))
  const shouldDisableReconfirmButton = useTypedSelector((state) => reportStatusReconfirmBySidSelector(state, sid))

  const { execute: reconfirmReport } = useReconfirmReport(assetId, sid)

  // const [hoverRef, isHovered] = useTooltip()

  if (assetId === undefined) {
    return <></>
  }

  const closeConfirmDialog = () => {
    analyticsBacklogControlPanelDispatch(closeGenericConfirmDialog())
  }

  const handleStatusReconfirmClick = () => {
    analyticsBacklogControlPanelDispatch(
      showGenericConfirmDialog({
        isOpen: true,
        title: 'Reconfirm the current status',
        message: `Reconfirm the current status for asset ${assetName} from ${assetParent}?`,
        buttons: [
          <GenericConfirmationDialogButton
            data-testid={dataTestId.reconfirmButtonConfirmDialogCancelButton}
            type="secondary"
            label="Cancel"
            onClick={() => closeConfirmDialog()}
          />,
          <GenericConfirmationDialogButton
            data-testid={dataTestId.reconfirmButtonConfirmDialogConfirmButton}
            type="primary"
            label="Confirm"
            onClick={async () => {
              closeConfirmDialog()
              await reconfirmReport()
            }}
          />,
        ],
      })
    )
  }

  return (
    <>
      {/* <Tooltip isHovered={isHovered && hasCustomerNotes} triggerElement={hoverRef.current}>
        There is a pending customer note, please update the status
      </Tooltip> */}

      <Button
        feType="primary"
        type="button"
        feLoading={isAsyncPollerActive}
        // disabled={hasCustomerNotes}
        onClick={handleStatusReconfirmClick}
        data-testid={dataTestId.reconfirmButton}
        disabled={!shouldDisableReconfirmButton}
      >
        Reconfirm Status
      </Button>
    </>
  )
}

export default ReconfirmButton

import { uploadFaultImages } from '@/api/paths/documents/files'
import { PostUploadFaultImagesRequest } from '@/api/paths/documents/files/types'
import { useSiteDetailsContext } from '@/contexts/siteDetails'
import { FileDropzoneImageData } from '@/shared/components/dropzone/types'
import { SiteDetailsData } from '@/models/siteDetails/types'
import { useApi } from '@/shared/hooks/useApi'
import { AxiosError } from 'axios'
import { DarwinError } from '@/shared/components/errorBoundary/darwinError'
import { StatusCode } from '@/api/constants'

const useUploadSiteDetailsLogo = () => {
  const { siteDetailsError, setSiteDetailsError, setSiteDetailsEditedFormData, siteDetailsEditedFormData } =
    useSiteDetailsContext()
  return useApi((params) => {
    let uploadRequest = {} as PostUploadFaultImagesRequest
    if (params && params.postData) {
      const imageData = params.postData as FileDropzoneImageData

      uploadRequest = {
        category: imageData.bucketInfo.category,
        content: imageData.imageInfo.content,
        contentType: imageData.imageInfo.contentType,
        fileName: imageData.imageInfo.fileName,
        nodeID: imageData.appInfo.nodeID,
        siteID: imageData.appInfo.siteID,
        tags: imageData.bucketInfo.tags,
      } as PostUploadFaultImagesRequest
    } else {
      throw new DarwinError({
        message: 'Error uploading site details logo',
        status: StatusCode.NotFound,
        isAxiosError: false,
        name: '',
        /* v8 ignore next 1 */
        toJSON: () => ({}),
      })
    }

    return uploadFaultImages(uploadRequest, {
      onSuccess: async (result) => {
        const imageData = params?.postData as FileDropzoneImageData
        setSiteDetailsEditedFormData({
          ...siteDetailsEditedFormData,
          logo: {
            ...siteDetailsEditedFormData?.logo,
            fileURL: result.fileURL,
            fileId: result.fileID,
            fileName: imageData.imageInfo.fileName,
          },
        } as SiteDetailsData)
      },
      onError: async (error: AxiosError) => {
        setSiteDetailsError({
          ...siteDetailsError,
          siteDetailsLogoUploadError: error.message,
        })
      },
    })
  })
}

export default useUploadSiteDetailsLogo

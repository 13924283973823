import { WidgetDataType } from '@/models/widgets/common/types'
import {
  RAstatusOptionalProps,
  ModifiedDataType,
  RAwidgetDataType,
  EChartsFormatterParams,
  DueDatesStatusCounts,
} from '@/models/widgets/recommended-actions/types'
import {
  ColorCompleted,
  ColorConcluded,
  ColorCreated,
  ColorOpen,
  ColorRejected,
  severities,
} from '@/modules/dashboard/utils/constants'
import { truncateChartAxisValue } from '..'
import { EChartsOption, LineSeriesOption, SeriesOption } from 'echarts'
import { Severity } from '@/models/widgets/asset-health-condition/types'
import { COLORS } from '@skf-design-system/ui-components-react'
import { RecommendedActionStatusString } from '@/shared/models/types'
import { severityColor } from '@/shared/utils'

const modifyRecommendationsData = (data: WidgetDataType): WidgetDataType => {
  return data.map((item) => {
    const { period, data } = item
    return {
      data: {
        pending: data.pending,
        created: data.created,
        completed: data.completed,
        rejected: data.rejected,
      },
      period: { year: Number(period.year), month: Number(period.month) },
    }
  })
}

const recommendedActionStatusColor = (status: RecommendedActionStatusString) => {
  switch (status) {
    case 'pending':
      return ColorOpen
    case 'created':
      return ColorCreated
    case 'completed':
      return ColorCompleted
    case 'rejected':
      return ColorRejected
  }
}

const DashedPattern = (color1: string, color2: string, canvasId: string) => {
  const c = document.createElement('canvas')
  c.id = canvasId
  c.width = 270
  c.height = 190
  c.style.border = '0px none'
  c.hidden = true
  const body = document.getElementsByTagName('body')[0]
  body.appendChild(c)
  const ctx = c.getContext('2d')
  if (ctx) {
    ctx.strokeStyle = color1
    ctx.fillStyle = color2
    ctx.lineWidth = 8
    const patternSpread = 16
    ctx?.beginPath()
    for (let index = 0; index <= c.width / patternSpread; index++) {
      ctx.moveTo(c.width - index * patternSpread, 0)
      ctx.lineTo(c.width, index * patternSpread)
    }
    for (let index = 1; index <= c.height / patternSpread; index++) {
      ctx.moveTo(0, index * patternSpread)
      ctx.lineTo(index * patternSpread, c.height)
    }
    ctx?.fillRect(0, 0, c.width, c.height)
    ctx?.stroke()
    return c
  }
  return null
}
const applyDashedPatternToMarkArea = (option: EChartsOption, dashedPattern: HTMLCanvasElement) => {
  if (dashedPattern && option.series && Array.isArray(option.series) && option.series.length > 0) {
    const newColor = { image: dashedPattern, repeat: 'repeat' }
    option.series[0].markArea = option.series[0].markArea || {}
    option.series[0].markArea.itemStyle = option.series[0].markArea.itemStyle || {}
    option.series[0].markArea.itemStyle.color = newColor
  }
}

const roundToPercentageForRAwidget = (dataObject: RAstatusOptionalProps) => {
  const total = Object.values(dataObject).reduce((acc, val) => acc + val, 0)
  const RApercentages: RAstatusOptionalProps = {}

  Object.keys(dataObject).forEach((key) => {
    const percentage = (dataObject[key] / total) * 100
    RApercentages[key] = total !== 0 ? Math.round(percentage * 10) / 10 : 0 // Round to one decimal place
  })
  const remainingPercentage = 100 - Object.values(RApercentages).reduce((acc, val) => acc + val, 0)

  if (remainingPercentage !== 0 && remainingPercentage !== 100) {
    const keys = Object.keys(RApercentages)
    const firstKey = keys.length > 0 ? keys[0] : ''

    RApercentages[firstKey] += remainingPercentage
  }
  return RApercentages
}

const useNoDataMonthColumnsIndices = (slices: RAwidgetDataType, noDataInAllMonth: boolean) => {
  if (!noDataInAllMonth) {
    return slices
      .map((item, index) => (Object.keys(item.data).length === 0 ? index * 2 : null))
      .filter((column) => column !== null) as number[]
  }
  return []
}

const useCalculateYAxisMaxLabel = (data: ModifiedDataType) => {
  const flattenedArray = Object.values(data).flat()
  const largestNumber = Math.max(...flattenedArray)
  const axisMaxValue = largestNumber + Math.ceil(largestNumber * 0.2)

  return axisMaxValue % 2 === 1 ? axisMaxValue + 1 : axisMaxValue
}

const calculateOptions = (
  data: ModifiedDataType,
  columns: string[],
  yAxisMaxVal: number,
  showPercentages: boolean,
  noDataInAllMonth: boolean,
  noDataMonthColumns: number[]
): EChartsOption => {
  const noDataMonthSeries = noDataMonthColumns.map<LineSeriesOption>((index: number) => ({
    name: 'noDataMonthColumn',
    type: 'line',
    label: {
      show: false,
    },
    markArea: {
      data: [
        [
          {
            xAxis: columns[index],
          },
          {
            xAxis: columns[index + 2],
          },
        ],
      ],
      itemStyle: {
        color: COLORS.gray050,
        opacity: 1,
      },
      emphasis: {
        disabled: true,
      },
    },
  }))

  const noDataInAllMonthSeries: LineSeriesOption = {
    name: 'completed',
    lineStyle: {
      color: ColorConcluded,
      width: 3,
    },
    markArea: {
      itemStyle: {
        color: noDataInAllMonth ? COLORS.gray050 : '#bfdbfe99',
      },
      emphasis: {
        disabled: true,
      },
      data: noDataInAllMonth
        ? [
            [
              {
                xAxis: columns[0],
              },
              {
                xAxis: columns[columns.length],
              },
            ],
          ]
        : [
            [
              {
                xAxis: columns[columns.length - 3],
              },
              {
                xAxis: columns[columns.length - 1],
              },
            ],
          ],
    },
    type: 'line',
    data: data?.closed_completed?.map((value, index) => [index * 2 + 1, value]),
    symbol: 'none',
  }

  const createdDataSeries: LineSeriesOption = {
    name: 'Created',
    lineStyle: {
      color: ColorCreated,
      width: 3,
    },
    type: 'line',
    data: data?.created?.map((value, index) => [index * 2 + 1, value]),
    symbol: 'none',
  }

  const pendingDataSeries: LineSeriesOption = {
    name: 'Open',
    lineStyle: {
      color: ColorOpen,
      width: 3,
    },
    type: 'line',
    data: data?.pending?.map((value, index) => [index * 2 + 1, value]),
    symbol: 'none',
  }
  const rejectedDataSeries: LineSeriesOption = {
    name: 'Rejected',
    lineStyle: {
      color: ColorRejected,
      width: 3,
    },
    type: 'line',
    data: data?.closed_rejected?.map((value, index) => [index * 2 + 1, value]),
    symbol: 'none',
  }

  return {
    grid: {
      left: '10%',
      bottom: '40',
      top: '0',
      right: '3%',
      height: '78%',
      containLabel: false,
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: columns,
        axisLabel: {
          interval: (index: number) => index % 2 === 1,
          overflow: 'breakAll',
          rich: {
            year: {
              opacity: 0.9,
              lineHeight: 20,
              fontSize: 10,
            },
          },
        },
        axisTick: {
          length: 0,
        },
      },
    ],
    yAxis: {
      type: 'value',
      axisLabel: {
        showMaxLabel: false,
        formatter: (value) => truncateChartAxisValue(value),
      },
      max: showPercentages ? 120 : yAxisMaxVal,
      splitLine: {
        show: true,
        lineStyle: {
          opacity: 0.6,
        },
      },
    },

    area: {
      enabled: true,
      decal: {
        show: true,
      },
    },
    series: [noDataInAllMonthSeries, ...noDataMonthSeries, pendingDataSeries, createdDataSeries, rejectedDataSeries],
  }
}

/**
 * Generates the ECharts option for the Due Dates Status Bar Chart
 * @param {string[]} xAxisLabels - Labels for the x-axis categories
 * @param {number[]} totals - Array of total counts for each period
 * @param {StatusData[]} alternateData - Data array with status breakdown per severity
 * @returns {EChartsOption} - The configured chart option for ECharts
 */
const getDueDatesStatusBarChartOptions = (
  xAxisLabels: string[],
  totals: number[],
  alteredData: DueDatesStatusCounts[]
): EChartsOption => {
  const maxValue = Math.max(...totals)
  const maxLabel = maxValue === 0 ? 1 : maxValue * 0.32 + maxValue
  const minLabel = maxValue === 0 ? 0.12 : maxValue * 0.15

  return {
    grid: {
      bottom: '12%',
      top: '1%',
    },
    tooltip: {
      trigger: 'item',
      formatter: (params) => {
        // @ts-expect-error its known issue in echarts still not fixed
        return `${params.seriesName}: ${params.value}`
      },
    },
    xAxis: {
      type: 'category',
      data: xAxisLabels,
      axisLabel: {
        interval: 1,
        rotate: 0,
        fontSize: 9.5,
        fontWeight: 'bold',
        margin: -15,
      },
      axisTick: {
        alignWithLabel: true,
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: { show: false },
      axisLine: { show: false },
      splitLine: { show: false },
      min: -minLabel,
      max: maxLabel,
    },
    series: [
      {
        type: 'line',
        markLine: {
          silent: true,
          symbol: ['none', 'none'],
          lineStyle: { color: '#000', type: 'dashed', dashOffset: 5 },
          label: { show: false },
          data: [{ xAxis: 3 }],
        },
      } as SeriesOption,
      ...(severities.slice().map((severity) => ({
        name: severity.charAt(0).toUpperCase() + severity.slice(1),
        type: 'bar',
        stack: 'total',
        emphasis: {
          focus: 'self',
        },
        barWidth: '100%',
        label:
          severity === severities[4]
            ? {
                show: true,
                position: 'top',
                formatter: (params: EChartsFormatterParams) => totals[Math.floor(params.dataIndex / 2)],
                fontSize: 13,
                color: '#000',
                fontWeight: 'bold',
                distance: 5,
                opacity: 1,
              }
            : undefined,
        data: alteredData.map((item, index) => ({
          value: item[severity],
          itemStyle: {
            color: severityColor(severity as Severity),
            opacity: [1, 1, 0.75, 0.75, 0.5, 0.5, 0.25, 0.25][index],
          },
        })),
      })) as SeriesOption[]),
    ],
  }
}

export {
  modifyRecommendationsData,
  recommendedActionStatusColor,
  DashedPattern,
  roundToPercentageForRAwidget,
  useNoDataMonthColumnsIndices,
  useCalculateYAxisMaxLabel,
  calculateOptions,
  applyDashedPatternToMarkArea,
  getDueDatesStatusBarChartOptions,
}

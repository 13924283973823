import { WidgetType } from '@/models/dashboard/types'
import { AxiosError } from 'axios'

export class DarwinError extends AxiosError {
  isDialogError: boolean
  isInTabMode: boolean
  constructor(error: AxiosError) {
    super(error.message, error.code)
    this.status = error.response?.status || error.status
    this.isInTabMode = true
    this.isDialogError = false
    this.toJSON = () => ({
      ...this,
    })
  }
}

export class DarwinWidgetError extends DarwinError {
  isWidgetError: boolean
  widgetType: WidgetType
  caller: DarwinWidgetErrorCaller

  constructor(
    error: AxiosError,
    widgetType: WidgetType,
    caller: DarwinWidgetErrorCaller = DarwinWidgetErrorCaller.REGULAR
  ) {
    super(error)
    this.status = error.response?.status || error.status
    this.isWidgetError = true
    this.isInTabMode = false
    this.widgetType = widgetType
    this.caller = caller
    this.toJSON = () => ({
      ...this,
    })
  }
}

export class DarwinDetachedModuleError extends DarwinError {
  isInDetachedMode: boolean
  constructor(error: AxiosError) {
    super(error)
    this.status = error.response?.status || error.status
    this.isInTabMode = false
    this.isInDetachedMode = true
    this.toJSON = () => ({
      ...this,
    })
  }
}

export class DarwinNotFoundError extends AxiosError {
  isInTabMode: boolean
  constructor(error: AxiosError) {
    super(error.message, error.code)
    this.status = error.response?.status || error.status
    this.isInTabMode = false
    this.toJSON = () => ({
      ...this,
    })
  }
}

export class DarwinErrorDialog extends DarwinError {
  isDialogError: boolean
  isInDetachedMode: boolean
  constructor(error: AxiosError) {
    super(error)
    this.isDialogError = true
    this.isInDetachedMode = true
    this.toJSON = () => ({
      ...this,
    })
  }
}

export type DarwinErrorType =
  | DarwinError
  | DarwinWidgetError
  | DarwinDetachedModuleError
  | DarwinNotFoundError
  | DarwinErrorDialog

export enum DarwinWidgetErrorCaller {
  REGULAR,
  RETRY,
}

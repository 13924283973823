import DOMPurify from 'dompurify'

/**
 * Removes all HTML tags from the given input string.
 *
 * @param input - The string from which HTML tags should be removed.
 * @returns A new string with all HTML tags removed.
 */
const removeHtmlTags = (input: string) => {
  return input.replace(/<[^>]*>/g, '')
}

/**
 * Determines if a value is a string.
 * @param {unknown} x - The value to check.
 * @returns {boolean} - True if the value is a string, false otherwise.
 */
const isString = (x: unknown) => {
  return Object.prototype.toString.call(x) === '[object String]'
}

/**
 * Capitalizes the first letter of a given string.
 *
 * @param str - The input string.
 * @returns The string with the first letter capitalized.
 *
 */
const capitalizeFirstLetter = (str: string = ''): string => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * Converts a boolean value to a string representation, optionally capitalizing the first letter.

 * @param {boolean} booleanValue - The boolean value to convert.
 * @param {boolean} [withCapitalization=false] - Whether to capitalize the first letter of the result.
 * @returns {string} The string representation of the boolean value.
 */
const getBooleanString = (booleanValue: boolean, withCapitalization: boolean = false): string => {
  let result = booleanValue.toString()

  if (withCapitalization) {
    result = result.charAt(0).toUpperCase() + result.slice(1)
  }

  return result
}

/**
 * Escapes or unescapes special characters in a string based on the specified mode.
 *
 * @param value - The value to be processed. If the value is not a string, it will be returned as is.
 * @param mode - The mode of operation, either 'escape' to replace special characters with their HTML entities,
 *               or 'unescape' to replace HTML entities with their corresponding characters.
 *               Valid values are 'escape' and 'unescape'.
 * @returns The processed string with characters escaped or unescaped based on the specified mode.
 *
 * @example
 * ```typescript
 * escapeOrUnescapeRequestDataString('<div>', 'escape'); // returns '&lt;div&gt;'
 * escapeOrUnescapeRequestDataString('&lt;div&gt;', 'unescape'); // returns '<div>'
 * ```
 */
const escapeOrUnescapeRequestDataString = (value: unknown, mode: 'escape' | 'unescape'): unknown => {
  const escapeMap: { [key: string]: string } = {
    '&': '&amp;',
    '"': '&quot;',
    "'": '&#x27;',
    '<': '&lt;',
    '>': '&gt;',
    '/': '&#x2F;',
    '\\': '&#x5C;',
    '`': '&#96;',
  }

  const unescapeMap: { [key: string]: string } = {
    '&amp;': '&',
    '&quot;': '"',
    '&#x27;': "'",
    '&lt;': '<',
    '&gt;': '>',
    '&#x2F;': '/',
    '&#x5C;': '\\',
    '&#96;': '`',
  }

  const map = mode === 'escape' ? escapeMap : unescapeMap

  if (isString(value)) {
    let result = value as string
    for (const [char, entity] of Object.entries(map)) {
      result = result.split(char).join(entity)
    }
    return result.trim()
  }

  return value
}

/**
 * Transforms a given value by sanitizing it and converting any links (src or href attributes)
 * into plain text nodes. The resulting sanitized string is then escaped.
 *
 * @param value - The value to be transformed. Expected to be a string or unknown type.
 * @returns The transformed and escaped string.
 *
 * @remarks
 * This function uses DOMPurify to sanitize the input string and ensure it is safe from XSS attacks.
 * It then searches for any elements with `src` or `href` attributes, converts these attributes
 * into plain text nodes, and removes the original elements. Finally, it sanitizes the modified
 * DOM and escapes the resulting string.
 *
 * @example
 * ```typescript
 * const input = '<a href="http://example.com">Example</a>';
 * const result = stringRequestTransform(input);
 * console.log(result); // Outputs the sanitized and escaped string without the link.
 * ```
 */
const stringRequestTransform = (value: unknown) => {
  const sanitizedDom = DOMPurify.sanitize(value as string, { RETURN_DOM: true }) as Element
  const links = sanitizedDom.querySelectorAll('[src], [href]')

  links.forEach((el) => {
    const link = el.getAttribute('src') || el.getAttribute('href')
    if (link) {
      const text = document.createTextNode(link)
      sanitizedDom.appendChild(text)
      el.remove()
    }
  })

  const sanitizedString = DOMPurify.sanitize(sanitizedDom, {
    FORBID_TAGS: [
      'input',
      'button',
      'textarea',
      'select',
      'option',
      'form',
      'iframe',
      'video',
      'audio',
      'embed',
      'br',
      'marquee',
    ],
    KEEP_CONTENT: false,
  }).trim()
  return escapeOrUnescapeRequestDataString(sanitizedString, 'escape')
}

/**
 * Converts a given string to Start Case, where each word is capitalized
 * and separated by a space. Words are identified by spaces, underscores, or camel case.
 *
 * @param {string} input - The string to convert to Start Case.
 * @returns {string} - The converted string in Start Case.
 *
 * @example
 * startCase('hello_world'); // "Hello World"
 * startCase('--foo-bar--'); // "Foo Bar"
 * startCase('someStringWithCamelCase'); // "Some String With Camel Case"
 */
const toStartCase = (input: string): string => {
  if (!input) return ''

  return (
    input
      // Replace camelCase with spaces
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      // Replace non-alphanumeric characters with spaces
      .replace(/[^a-zA-Z0-9]+/g, ' ')
      // Trim and split into words
      .trim()
      .split(' ')
      // Capitalize each word
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      // Join words with spaces
      .join(' ')
  )
}

export {
  removeHtmlTags,
  isString,
  capitalizeFirstLetter,
  getBooleanString,
  escapeOrUnescapeRequestDataString,
  stringRequestTransform,
  toStartCase,
}

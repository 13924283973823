import { Site } from '@/models/overview/types'
import OverviewCell from '@/modules/overview/components/overviewCell'
import { overviewCellTooltips } from '@/modules/overview/utils/constants'
import { ROUTES } from '@/shared/constants'
import { MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'

const useAnalysisBacklogLevels = () => {
  const analysisBacklogLevels = useMemo<MRT_ColumnDef<Site>[]>(
    () => [
      {
        accessorKey: 'analysisBacklog.level.1-3-days',
        id: '1-3-days',
        header: '1-3-days',
        size: 70,
        Cell: ({ cell, row }) => (
          <OverviewCell
            siteId={row.original.siteId}
            value={cell.getValue<string>()}
            routeTo={ROUTES.ANALYSIS_BACKLOG}
            message={overviewCellTooltips[cell.column.columnDef.id as keyof typeof overviewCellTooltips]}
          />
        ),
      },
      {
        accessorKey: 'analysisBacklog.level.3-7-days',
        id: '3-7-days',
        header: '3-7-days',
        size: 70,
        Cell: ({ cell, row }) => (
          <OverviewCell
            siteId={row.original.siteId}
            value={cell.getValue<string>()}
            routeTo={ROUTES.ANALYSIS_BACKLOG}
            message={overviewCellTooltips[cell.column.columnDef.id as keyof typeof overviewCellTooltips]}
          />
        ),
      },
      {
        accessorKey: 'analysisBacklog.level.>7-days',
        id: '>7-days',
        header: '>7-days',
        size: 70,
        Cell: ({ cell, row }) => (
          <OverviewCell
            siteId={row.original.siteId}
            value={cell.getValue<string>()}
            routeTo={ROUTES.ANALYSIS_BACKLOG}
            message={overviewCellTooltips[cell.column.columnDef.id as keyof typeof overviewCellTooltips]}
          />
        ),
      },
    ],
    []
  )
  return analysisBacklogLevels
}

export default useAnalysisBacklogLevels

import React, { FC, useEffect, useRef, useState } from 'react'
import {
  StyledEditDetailsSearch,
  StyledEditDetailsLogoCardContainer,
  StyledEditDetailsFlexboxWrapper,
  StyledEditDetailsText,
  StyledLabel,
  SuggestionsList,
  SuggestionItem,
  StyledEditDetailsSearchContainer,
  StyledSiteAddressLoader,
  StyledErrorParagraph,
  StyledEditDetailsReadOnlyText,
} from '@/modules/siteDetails/styled'
import useOutsideClick from '@/shared/hooks/useOutsideClick'
import { dataTestId } from '@/tests/testid'
import { Text } from '@skf-design-system/ui-components-react'
import useAWSLocationService from '@/shared/hooks/useAWSLocationService'
import { useSiteDetailsContext } from '@/contexts/siteDetails'
import { useEffectOnce } from '@/shared/hooks/useEffectOnce'
import { SiteDetailsData } from '@/models/siteDetails/types'
import DragAndDropLogo from '@/modules/siteDetails/components/dropzone'
import useTimeout from '@/shared/hooks/useTimeout'
import { ACCEPTED_FILE_TYPES, ERROR_TIMEOUT } from '@/shared/components/dropzone/constants'
import CustomerLogoCard from '@/modules/siteDetails/components/contractDetails/customerLogoCard'
import { FilesDropzoneProvider } from '@/contexts/moduleContexts/filesDropzone'
import { SITE_DETAILS_MAX_FILE_SIZE } from '@/modules/siteDetails/constants'

const EditDetailsForm: FC = () => {
  const {
    siteDetailsData,
    setSiteDetailsEditedFormData,
    siteDetailsEditedFormData,
    setIsSiteDetailsEdited,
    isSiteDetailsEdited,
  } = useSiteDetailsContext()

  const [isSiteAddressEditable, setSiteAddressIsEditable] = useState(false)
  const siteAddressOutsideClickRef = useRef<HTMLInputElement>(null)

  const { searchPlaceIndex, suggestions, isLoading, resetSuggestions, error } = useAWSLocationService({})
  const { startTimeout: resetError, clearTimeout: clearResetErrorTimeOut } = useTimeout(() => {
    resetSuggestions()
  }, ERROR_TIMEOUT)

  useOutsideClick(
    () => {
      if (
        (isSiteDetailsEdited && siteDetailsEditedFormData.siteAddress === '') ||
        (!isSiteDetailsEdited && siteDetailsData.siteAddress === '')
      ) {
        setSiteAddressIsEditable(true)
      } else {
        setSiteAddressIsEditable(false)
      }
    },
    {
      refs: [siteAddressOutsideClickRef],
    }
  )

  const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value
    setIsSiteDetailsEdited(true)
    setSiteDetailsEditedFormData({
      ...siteDetailsData,
      siteAddress: input,
    } as SiteDetailsData)
    await searchPlaceIndex(input)
  }

  const handleSuggestionClick = (suggestion: string) => {
    setIsSiteDetailsEdited(true)
    setSiteDetailsEditedFormData({
      ...siteDetailsData,
      siteAddress: suggestion,
    } as SiteDetailsData)
    setSiteAddressIsEditable(false)
  }

  useEffect(() => {
    if (!isSiteDetailsEdited) {
      resetSuggestions()
    }
  }, [isSiteDetailsEdited, resetSuggestions])

  useEffectOnce(() => {
    setSiteDetailsEditedFormData({ ...siteDetailsData } as SiteDetailsData)
  })

  useEffect(() => {
    if (error) {
      resetError()
    }
    return clearResetErrorTimeOut
  }, [clearResetErrorTimeOut, error, resetError])

  useEffect(() => {
    if (siteDetailsEditedFormData.siteAddress === '' || siteDetailsData.siteAddress === '') {
      setSiteAddressIsEditable(true)
    } else if (!isSiteDetailsEdited) {
      setSiteAddressIsEditable(false)
    }
  }, [siteDetailsEditedFormData, siteDetailsData, isSiteDetailsEdited])

  return (
    <>
      <StyledEditDetailsFlexboxWrapper data-testid={dataTestId.siteDetailsEditDetailsForm}>
        <StyledEditDetailsReadOnlyText>
          <StyledLabel>Company name</StyledLabel>
          <Text>{siteDetailsData.companyName || '-'}</Text>
        </StyledEditDetailsReadOnlyText>
        <StyledEditDetailsReadOnlyText>
          <StyledLabel>Site name</StyledLabel>
          <Text>{siteDetailsData.siteName || '-'}</Text>
        </StyledEditDetailsReadOnlyText>
      </StyledEditDetailsFlexboxWrapper>
      <StyledEditDetailsFlexboxWrapper>
        <StyledEditDetailsLogoCardContainer>
          {siteDetailsEditedFormData.logo?.fileURL ? (
            <CustomerLogoCard showAction={true} />
          ) : (
            <FilesDropzoneProvider
              category="site-logo"
              acceptFiles={ACCEPTED_FILE_TYPES.filter((type) => type !== 'gif')}
              maxFileSize={SITE_DETAILS_MAX_FILE_SIZE}
            >
              <DragAndDropLogo />
            </FilesDropzoneProvider>
          )}
        </StyledEditDetailsLogoCardContainer>

        <StyledEditDetailsSearchContainer feFlexDirection="column">
          {isSiteAddressEditable ? (
            <div ref={siteAddressOutsideClickRef} data-testid={dataTestId.siteDetailsEditDetailsFormInputContainer}>
              {isLoading && (
                <StyledSiteAddressLoader feSize="sm" data-testid={dataTestId.siteDetailsEditDetailsFormLoader} />
              )}
              <StyledEditDetailsSearch
                feLabel="Site address"
                value={isSiteDetailsEdited ? siteDetailsEditedFormData?.siteAddress : siteDetailsData.siteAddress}
                onChange={handleInputChange}
                data-testid={dataTestId.siteDetailsEditDetailsFormInput}
              />
              {suggestions.length > 0 && (
                <SuggestionsList>
                  {suggestions.map((suggestion, index) => (
                    <SuggestionItem key={index} onClick={() => handleSuggestionClick(suggestion)}>
                      {suggestion}
                    </SuggestionItem>
                  ))}
                </SuggestionsList>
              )}
            </div>
          ) : (
            <StyledEditDetailsText
              onClick={() => {
                setSiteAddressIsEditable(true)
              }}
              data-testid={dataTestId.siteDetailsEditDetailsFormText}
            >
              <StyledLabel>Site address</StyledLabel>
              <Text>
                {isSiteDetailsEdited ? siteDetailsEditedFormData?.siteAddress : siteDetailsData.siteAddress || '-'}
              </Text>
            </StyledEditDetailsText>
          )}
          {error && (
            <StyledErrorParagraph data-testid={dataTestId.siteDetailsEditFormError}>{error}</StyledErrorParagraph>
          )}
        </StyledEditDetailsSearchContainer>
      </StyledEditDetailsFlexboxWrapper>
    </>
  )
}

export default EditDetailsForm

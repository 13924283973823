import { msalConfig } from '@/modules/azureAuth/msalConfig'
import { SitePermissions } from '@/modules/azureAuth/types'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { sitePermissionsBySidSelector } from '@/store/selectors/sitesPermissionsSelector'
import { useTypedSelector } from '@/store/store'
import { AccountInfo, IdTokenClaims } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { useCallback, useState } from 'react'
import { PathRouteProps, useParams } from 'react-router'

interface RouteGuardProps extends PathRouteProps {
  roles?: (keyof SitePermissions)[]
}
const RouteGuard: React.FC<RouteGuardProps> = ({ roles, Component }) => {
  const { instance } = useMsal()
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [message, setMessage] = useState('')
  const { sid } = useParams()
  const userSitePermissions = useTypedSelector((state) => sitePermissionsBySidSelector(state, sid))
  const onRouteLoad = useCallback(() => {
    const currentAccount: AccountInfo | null = instance.getActiveAccount()
    if (currentAccount) {
      const idTokenClaims = currentAccount.idTokenClaims as IdTokenClaims
      if (
        idTokenClaims &&
        idTokenClaims.aud == msalConfig.auth.clientId &&
        userSitePermissions &&
        Object.keys(userSitePermissions).length > 0
      ) {
        const hasViewPermission = roles?.every((role) => userSitePermissions[role])
        if (hasViewPermission) {
          setIsAuthorized(true)
        } else {
          setMessage("You don't have the required role to view this page. Please contact site administrator.")
          setIsAuthorized(false)
        }
      } else {
        setMessage('The application you authorized with cannot access this page. Please contact site administrator.')
        setIsAuthorized(false)
      }
    }
  }, [instance, roles, userSitePermissions])

  useDeepCompareEffect(() => {
    onRouteLoad()
  }, [userSitePermissions, instance, sid])

  return (
    <>
      {isAuthorized && Component ? (
        <Component />
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <h4>{message}</h4>
        </div>
      )}
    </>
  )
}
export default RouteGuard

import { getSiteDetails } from '@/api/paths/sites/siteDetails'
import { DarwinDetachedModuleError } from '@/shared/components/errorBoundary/darwinError'
import { useSiteDetailsContext } from '@/contexts/siteDetails'
import { SiteDetailsData } from '@/models/siteDetails/types'
import { useApi } from '@/shared/hooks/useApi'
import { useAsyncThrow } from '@/shared/hooks/useAsyncThrow'
import { AxiosError } from 'axios'
import { useParams } from 'react-router'

const useGetSiteDetails = () => {
  const throwError = useAsyncThrow()
  const { setSiteDetailsData, setIsSiteDetailsEdited, setSiteDetailsEditedFormData } = useSiteDetailsContext()
  const { sid } = useParams()
  return useApi(() => {
    return getSiteDetails(
      {
        onSuccess: async (result: SiteDetailsData) => {
          setSiteDetailsData(result)
          setIsSiteDetailsEdited(false)
          setSiteDetailsEditedFormData(result)
        },
        onError: async (error: AxiosError) => {
          throwError(new DarwinDetachedModuleError(error))
        },
      },
      {
        sid,
      }
    )
  })
}

export default useGetSiteDetails

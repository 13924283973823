import { Site } from '@/models/overview/types'
import useDevicesData from '@/modules/overview/columns/thirdLevel/useDevicesData'
import useSensorsData from '@/modules/overview/columns/thirdLevel/useSensorsData'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { MRT_ColumnDef } from 'material-react-table'

const useDevicesAndSensors = () => {
  const sensorsDataColumn = useSensorsData()
  const devicesDataColumn = useDevicesData()
  const openRecommendationsColumns = useDeepCompareMemo<MRT_ColumnDef<Site>[]>(
    () => [
      {
        id: 'sensorsData',
        header: 'Sensors',
        size: 95,
        columns: sensorsDataColumn,
      },
      {
        id: 'devicesFaultedData',
        header: 'Devices',
        size: 95,
        columns: devicesDataColumn,
      },
    ],
    [sensorsDataColumn, devicesDataColumn]
  )
  return openRecommendationsColumns
}

export default useDevicesAndSensors

import { StyledLink } from '@/modules/overview/styled'
import { ROUTES } from '@/shared/constants'
import useRedirectToModuleWithSelectedCustomer from '@/shared/hooks/useRedirectToModuleWithSelectedCustomer'
import { STORAGE_REPORT_STATUS_ORIGIN } from '@/shared/localStorageUtils'
import { FC } from 'react'

type MenuPopoverContext = {
  siteId: string
  closePopoverContent: () => void
}

const PopoverContent: FC<MenuPopoverContext> = ({ siteId, closePopoverContent }) => {
  const redirectToModuleWithSelectedCustomer = useRedirectToModuleWithSelectedCustomer()

  const onOverviewMenuClick = (route: ROUTES) => {
    closePopoverContent()
    sessionStorage.setItem(STORAGE_REPORT_STATUS_ORIGIN, `${route}/${siteId}`)
    redirectToModuleWithSelectedCustomer(route, siteId)
  }

  return (
    <>
      <StyledLink
        as="button"
        type="button"
        feIcon={{ feIcon: 'paper', position: 'left' }}
        feStretch={true}
        onClick={() => onOverviewMenuClick(ROUTES.REPORT_GENERATOR)}
      >
        Generate Report
      </StyledLink>

      <StyledLink
        as="button"
        type="button"
        feIcon={{ feIcon: 'info', position: 'left' }}
        feStretch={true}
        onClick={() => onOverviewMenuClick(ROUTES.SITE_DETAILS)}
      >
        Site Details
      </StyledLink>
    </>
  )
}

export default PopoverContent

import { COLORS, Icon, styled } from '@skf-design-system/ui-components-react'

export const StyledDashedBorder = styled.div<{ isFileLimitExceeded: boolean }>`
  height: 7.5rem;
  width: 13.75rem;
  border: 0.125rem dashed #c7cad0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  cursor: ${({ isFileLimitExceeded }) => (isFileLimitExceeded ? 'no-drop' : 'pointer')};
`

export const StyledErrorParagraph = styled.p`
  color: ${COLORS.redBase};
  font-size: 0.75rem;
  font-weight: 500;
`
export const StyledIcon = styled(Icon)`
  height: 3.125rem;
  width: 3.125rem;
`

export const StyledInput = styled.input`
  display: none;
`

export const StyledParagraph = styled.p`
  font-size: 0.75rem;
`

export const StyledUploadIconContainer = styled.div<{ isFileLimitExceeded: boolean }>`
  height: 3.4375rem;
  width: 3.4375rem;
  margin-top: 1.25rem;
  border: 0.1875rem solid ${({ isFileLimitExceeded }) => (isFileLimitExceeded ? COLORS.gray600 : COLORS.brand)};
  border-radius: 0.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

import { convertToDisplayValue } from '@/shared/utils'
import { Flexbox } from '@skf-design-system/ui-components-react'
import { FC } from 'react'

interface OpenRecommendationsFaultTypeProps {
  faultType: string
}

const OpenRecommendationsFaultType: FC<OpenRecommendationsFaultTypeProps> = ({ faultType }) => {
  return <Flexbox>{convertToDisplayValue(faultType)}</Flexbox>
}
export default OpenRecommendationsFaultType

import { Fault, FaultsFormState } from '@/models/reportStatus/faultsFormStateTypes'
import { faultsFormReducer } from '@/modules/reportStatus/reducer'
import { FaultsFormAction } from '@/modules/reportStatus/reducer/types'
import { buildFormErrorStruct } from '@/modules/reportStatus/utils/formUtils'
import useContextWrapper from '@/shared/hooks/useContextWrapper'
import { createContext, Dispatch, PropsWithChildren, useReducer } from 'react'

interface IFaultsFormStateContext {
  faultsFormState: FaultsFormState
  faultsFormDispatch: Dispatch<FaultsFormAction>
}

const FaultsFormStateContext = createContext<IFaultsFormStateContext | null>(null)

export const useFaultsFormStateContext = () =>
  useContextWrapper(FaultsFormStateContext, {
    contextName: useFaultsFormStateContext.name,
    providerName: FaultsFormStateContextProvider.name,
  })

interface FaultsFormStateContextProviderProps extends PropsWithChildren {
  initialFaultsFormState: Fault[]
  lastCollectionDate: string
}

export const FaultsFormStateContextProvider: React.FC<FaultsFormStateContextProviderProps> = ({
  children,
  initialFaultsFormState,
  lastCollectionDate,
}) => {
  const [faultsFormState, faultsFormDispatch] = useReducer(faultsFormReducer, {
    faults: initialFaultsFormState,
    initialStateFaults: structuredClone(initialFaultsFormState),
    isSubmitting: false,
    isDirty: false,
    lastCollectionDate,
    isValid: true,
    errors: buildFormErrorStruct(initialFaultsFormState),
  } as FaultsFormState)

  return <FaultsFormStateContext value={{ faultsFormState, faultsFormDispatch }}>{children}</FaultsFormStateContext>
}

import { use } from 'react'

interface IContextConfig {
  contextName: string
  providerName: string
}

/**
 * A custom hook that wraps the use hook to provide additional error handling and context validation.
 * Ensures that the context is used within its associated provider and throws an error if it is not.
 *
 * @template T
 * @param {React.Context<T>} ReactContext - The React context to be accessed.
 * @param {IContextConfig} config - Configuration object for the context and provider.
 * @param {string} config.contextName - The name of the context (used in error messages).
 * @param {string} config.providerName - The name of the provider (used in error messages).
 *
 * @returns {T} - The value provided by the React context.
 *
 * @throws {Error} Will throw an error if the hook is called outside the corresponding provider.
 */

const useContextWrapper = <T>(ReactContext: React.Context<T>, config: IContextConfig) => {
  const context = use(ReactContext)
  const { contextName, providerName } = config

  if (!context) {
    //TODO Navigate to error page, send error log
    throw new Error(`${contextName} must be used within a ${providerName}`)
  }

  return context
}

export default useContextWrapper

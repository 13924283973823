import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import { Severity } from '@/models/widgets/asset-health-condition/types'
import { dataTestId } from '@/tests/testid'
import { pieChartTooltipFormatter } from '@/modules/dashboard/utils'
import { StyledAHCPieChartContainer } from '../styled'
import { severityColor } from '@/shared/utils'
interface CurrentMonthPiChartProps {
  data: { name: string; value: number }[]
  specialLabelIndex: number
  specialLabel: string
  showPercentages: boolean
}

const AssetHealthConditionPieChart: React.FC<CurrentMonthPiChartProps> = ({
  data,
  specialLabelIndex,
  specialLabel,
  showPercentages,
}) => {
  const chartRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const chart = echarts.init(chartRef.current)
    if (chartRef.current) {
      const option: echarts.EChartsOption = {
        tooltip: {
          trigger: 'item',
          formatter: (params) => pieChartTooltipFormatter(params, showPercentages),
        },
        series: [
          {
            type: 'pie',
            data: data.map((item) => ({
              name: item.name,
              value: item.value,
              itemStyle: {
                color: severityColor(item.name as Severity),
              },
              label: {
                show: false,
              },
            })),
          },
        ],
      }
      chart.setOption(option)
    }

    return () => {
      chart.dispose() // Dispose the chart instance on component unmount
    }
  }, [data, specialLabelIndex, specialLabel, showPercentages])

  return (
    <StyledAHCPieChartContainer
      ref={chartRef}
      data-testid={dataTestId.assetHealthConditionCurrentMonthStatusPieChart}
    />
  )
}

export default AssetHealthConditionPieChart

import { getDashboards } from '@/api/paths/dashboard'
import { GetDashboardsResponse } from '@/api/paths/dashboard/types'
import { DarwinError } from '@/shared/components/errorBoundary/darwinError'
import { useDashboardContext } from '@/contexts/dashboard'
import { useApi } from '@/shared/hooks/useApi'
import { useAsyncThrow } from '@/shared/hooks/useAsyncThrow'
import { getDefaultDashboardToShow } from '@/modules/dashboard/utils'
import { AxiosError } from 'axios'

const useGetDashboardsApi = () => {
  const { setDashboards, setSelectedDashboard } = useDashboardContext()
  const throwError = useAsyncThrow()

  return useApi(() => {
    return getDashboards({
      onSuccess: async (response: GetDashboardsResponse) => {
        setDashboards(response)
        setSelectedDashboard(getDefaultDashboardToShow(response))
      },
      onError: async (error: AxiosError) => {
        throwError(new DarwinError(error))
      },
    })
  })
}

export default useGetDashboardsApi

import { ReasonIndicatorFilterProps } from '@/models/analysisBacklog/types'
import { StyledReasonIndicator } from '@/modules/analysisBacklog/styled'
import Badge from '@/shared/components/badge'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { dataTestId } from '@/tests/testid'
import { Checkbox, Icon } from '@skf-design-system/ui-components-react'
import { FC } from 'react'

const ReasonIndicatorFilter: FC<ReasonIndicatorFilterProps> = ({
  reasonType,
  reasonIcon,
  reasonSeverity = '',
  withBadge = false,
  reasonText,
  customStyle,
  onChange,
  checked,
}) => {
  const iconData = useDeepCompareMemo(() => {
    let result: typeof reasonIcon = reasonIcon

    if (!result) {
      switch (reasonSeverity) {
        case 'alarm':
          result = { iconName: 'danger', iconColor: 'red' }
          break
        case 'alert':
          result = { iconName: 'warning', iconColor: 'orange' }
          break
        default:
          result = { iconName: 'error', iconColor: 'red' }
      }
    }
    return result
  }, [reasonIcon])

  const iconElement = useDeepCompareMemo(() => {
    let result = <Icon feIcon={iconData.iconName} feColor={iconData.iconColor} feSize="sm" />
    if (withBadge) {
      result = (
        <Badge badgeContent="" bgColor="red">
          {result}
        </Badge>
      )
    }
    return result
  }, [iconData, withBadge])

  return (
    <StyledReasonIndicator
      style={{ ...customStyle, width: '15rem' }}
      withRedirect={false}
      onClick={() => onChange(reasonType, !checked)}
      feNoPadding
      data-testid={dataTestId.reasonIndicatorFilter}
    >
      <Checkbox feSize="sm" feLabel="" feHideLabel={true} checked={checked} />
      {iconElement}
      <span>{`${reasonText} ${reasonSeverity}`}</span>
    </StyledReasonIndicator>
  )
}

export default ReasonIndicatorFilter

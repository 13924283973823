import { useTypedSelector } from '@/store/store'
import { Flexbox } from '@skf-design-system/ui-components-react'
import HeaderTitle from './title'
import HeaderInfoPanel from '@/shared/components/moduleHeader/infoPanel'
import HeaderSubtitle from '@/shared/components/moduleHeader/subtitle'
import HeaderActionPanel from '@/shared/components/moduleHeader/actionPanel'
import { StyledModuleHeader } from '@/shared/components/moduleHeader/styled'
import { dataTestId } from '@/tests/testid'
import { headerConfigSelector } from '@/store/selectors/moduleHeaderSelector'

/**
 * ModuleHeader is a presentational component that serves as the header for
 * a module within the application. It conditionally displays various
 * elements such as the title, subtitle, information panel, and action panel
 * based on the configuration stored in the Redux state.
 *
 * The header's visibility and content are determined by the properties
 * defined in the headerConfig retrieved from the Redux store.
 *
 * The following elements can be displayed:
 * - HeaderTitle: The main title of the module.
 * - HeaderSubtitle: An optional subtitle beneath the main title.
 * - HeaderInfoPanel: Additional information related to the module.
 * - HeaderActionPanel: Action buttons or controls for interacting with the module.
 *
 * @returns {JSX.Element | null} A Flexbox container that conditionally renders
 * the header components based on the configuration. Returns null if the
 * header is not to be shown.
 */

const ModuleHeader: React.FC = () => {
  const headerConfig = useTypedSelector((store) => headerConfigSelector(store))

  const { showHeader, /*showBackButton,*/ showInfoPanel, showActionPanel, showSubtitle } = headerConfig
  return (
    showHeader && (
      <StyledModuleHeader feFlexDirection="column" data-testid={dataTestId.genericModuleHeader}>
        {/* {showBackButton && <HeaderTitle />} */}
        {/* {showSubtitle && <BackToOverviewButton />} */}
        {showSubtitle && <HeaderTitle />}
        <Flexbox feAlignItems="center" feJustifyContent="space-between">
          {/* {!showSubtitle && ((showBackButton && <BackToOverviewButton />) || <HeaderTitle />)} */}
          {!showSubtitle && <HeaderTitle />}
          {showSubtitle && <HeaderSubtitle />}
          {showInfoPanel && <HeaderInfoPanel />}
          {showActionPanel && <HeaderActionPanel />}
        </Flexbox>
      </StyledModuleHeader>
    )
  )
}

export default ModuleHeader

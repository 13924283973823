import { AutomaticDiagnosticsChartData } from '@/models/automaticDiagnostics/types'
import useContextWrapper from '@/shared/hooks/useContextWrapper'
import { createContext, useMemo, useState } from 'react'

interface IAutomaticsDiagnosticsContext {
  automaticsDiagnosticsData: AutomaticDiagnosticsChartData | undefined
  setAutomaticsDiagnosticsData: React.Dispatch<
    React.SetStateAction<IAutomaticsDiagnosticsContext['automaticsDiagnosticsData']>
  >
}

export const AutomaticsDiagnosticsContext = createContext<IAutomaticsDiagnosticsContext | null>(null)

export const useAutomaticsDiagnosticsContext = () =>
  useContextWrapper(AutomaticsDiagnosticsContext, {
    contextName: useAutomaticsDiagnosticsContext.name,
    providerName: AutomaticsDiagnosticsContextProvider.name,
  })

export const AutomaticsDiagnosticsContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [automaticsDiagnosticsData, setAutomaticsDiagnosticsData] =
    useState<IAutomaticsDiagnosticsContext['automaticsDiagnosticsData']>(undefined)

  const value = useMemo(
    () => ({
      setAutomaticsDiagnosticsData,
      automaticsDiagnosticsData,
    }),
    [automaticsDiagnosticsData]
  )

  return <AutomaticsDiagnosticsContext value={value}>{children}</AutomaticsDiagnosticsContext>
}

import BelongsToNestedList from '@/modules/analysisBacklog/components/cell/assetName/assetHierarchyNestedList'
import { StyledHierarchyPopup, StyledHierarchyWrapper } from '@/modules/analysisBacklog/styled'
import useEscapeKey from '@/shared/hooks/useEscapeKey'
import useOutsideClick from '@/shared/hooks/useOutsideClick'
import useScrollDetector from '@/shared/hooks/useScrollDetection'
import { dataTestId } from '@/tests/testid'
import { Flexbox, IconButton, usePopup } from '@skf-design-system/ui-components-react'
import { FC, useEffect, useRef, useState } from 'react'

interface ShowHierarchyButtonProps {
  assetName: string
  hierarchy: string
}

const ShowHierarchyButton: FC<ShowHierarchyButtonProps> = ({ hierarchy, assetName }) => {
  const [hoverRef] = usePopup()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const hierarchyPaths = (hierarchy.endsWith('/') ? hierarchy.slice(0, -1) : hierarchy).split('/')
  hierarchyPaths.push(assetName)

  useEscapeKey(() => isOpen && setIsOpen(false), [isOpen])

  const outsideClickRef = useRef<HTMLDivElement>(null)

  useOutsideClick(
    () => {
      setIsOpen(false)
    },
    {
      refs: [outsideClickRef],
    }
  )

  const { isScrolling } = useScrollDetector()

  useEffect(() => {
    setIsOpen(false)
  }, [isScrolling])

  return (
    <>
      <StyledHierarchyPopup isHovered={isOpen} triggerElement={hoverRef.current}>
        <Flexbox ref={outsideClickRef} feFlexDirection="column">
          <IconButton
            as="button"
            feIcon="close"
            feSize="xs"
            feColor="gray"
            aria-label="Close hierarchy popup"
            onClick={() => setIsOpen(false)}
            data-testid={dataTestId.analysisBacklogCloseHierarchyButton}
          />
          <StyledHierarchyWrapper>
            <BelongsToNestedList items={hierarchyPaths} />
          </StyledHierarchyWrapper>
        </Flexbox>
      </StyledHierarchyPopup>

      <IconButton
        ref={hoverRef}
        as="button"
        feIcon="hierarchy"
        feSize="sm"
        aria-label="Show hierarchy"
        feColor="brand"
        onClick={() => setIsOpen(true)}
        data-testid={dataTestId.analysisBacklogShowHierarchyButton}
      />
    </>
  )
}

export default ShowHierarchyButton

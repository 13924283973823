const ERROR_TIMEOUT = 4000
const MAX_TOTAL_SIZE = 50 * 1024 * 1024 // 50 MB in bytes
const MAX_FILE_SIZE = 10 * 1024 * 1024 // 10 MB in bytes
const ACCEPTED_FILE_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'image/png', 'webp']
//DO NOT CHANGE THE ORDER OD THE MIME TYPES
const ACCEPTED_MIME_TYPES = {
  'image/png': [],
  'image/jpeg': [],
  'image/jpg': [],
  'image/webp': [],
  'image/gif': [],
}
const MAX_FILES = 5
export { MAX_FILE_SIZE, ACCEPTED_FILE_TYPES, ERROR_TIMEOUT, MAX_TOTAL_SIZE, ACCEPTED_MIME_TYPES, MAX_FILES }

import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { InitialAsset } from '@/models/analysisBacklog/types'
import { calculateInfoPanelData } from '@/modules/analysisBacklog/utils'
import { StyledEmptyTableContainer } from '@/modules/analysisBacklog/styled'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { setModuleHeaderConfigData } from '@/store/moduleHeader/actions'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { EmptyState } from '@skf-design-system/ui-components-react'
import { MRT_RowData, MRT_TableInstance } from 'material-react-table'
import { hasRedirectDataSelector } from '@/store/selectors/redirectDataSelector'
import { headerConfigSelector } from '@/store/selectors/moduleHeaderSelector'

interface EmptyAssetTableState<T extends MRT_RowData> {
  table: MRT_TableInstance<T>
}

const EmptyAssetTableState = <T extends MRT_RowData>({ table }: EmptyAssetTableState<T>) => {
  const emptyAssetTableStateDispatch = useAppDispatch()
  const countRows = useDeepCompareMemo(() => table.getSortedRowModel().rows, [table])
  const isRedirectDataEmpty = useTypedSelector((state) => hasRedirectDataSelector(state))
  const { showInfoPanel } = useTypedSelector((store) => headerConfigSelector(store))

  const { fetchDataStatus } = useFetchDataContext()

  useDeepCompareEffect(() => {
    if (countRows.length === 0 && !isRedirectDataEmpty && fetchDataStatus === 'success' && showInfoPanel) {
      emptyAssetTableStateDispatch(
        setModuleHeaderConfigData({
          infoPanelElement: undefined,
          infoPanelData: calculateInfoPanelData(table as unknown as MRT_TableInstance<InitialAsset>, true),
        })
      )
    }
  }, [countRows.length, isRedirectDataEmpty, fetchDataStatus, showInfoPanel])

  return (
    <>
      {(countRows.length === 0 && !isRedirectDataEmpty && (
        <StyledEmptyTableContainer data-testid={dataTestId.emptyAssetTableState}>
          <EmptyState
            feHeading={{
              children: 'No assets could be found',
            }}
          />
        </StyledEmptyTableContainer>
      )) ||
        null}
    </>
  )
}
export default EmptyAssetTableState

import { dataTestId } from '@/tests/testid'
import { Icon, IconButton } from '@skf-design-system/ui-components-react'
import { FC, useEffect, useRef, useState } from 'react'

interface CopyHierarchyItemButtonProps {
  itemString: string
}

const CopyHierarchyItemButton: FC<CopyHierarchyItemButtonProps> = ({ itemString }) => {
  const [isCopied, setIsCopied] = useState<boolean>(false)
  const timerIdRef = useRef<NodeJS.Timeout | null>(null)

  const handleCopyButtonClick = async (itemString: string) => {
    await navigator.clipboard.writeText(itemString)
    setIsCopied(true)

    if (timerIdRef.current) {
      clearTimeout(timerIdRef.current)
    }

    timerIdRef.current = setTimeout(() => {
      setIsCopied(false)
    }, 1000)
  }

  useEffect(
    () => () => {
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current)
      }
    },
    []
  )

  return isCopied ? (
    <Icon
      feIcon="checkCircle"
      feColor="brand"
      feSize="sm"
      data-testid={dataTestId.analysisBacklogCopiedHierarchyItemButtonIcon}
    />
  ) : (
    <IconButton
      as="button"
      feIcon="copy"
      feSize="sm"
      aria-label="Copy name"
      feColor="brand"
      onClick={() => handleCopyButtonClick(itemString)}
      data-testid={dataTestId.analysisBacklogCopyHierarchyItemButton}
    />
  )
}

export default CopyHierarchyItemButton

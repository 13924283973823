import { ChannelFaultEnum, ChannelStatusEnum } from '@/models/devicesAndSensors/types'
import {
  channelFaultText,
  channelStatusIcon,
  channelStatusString,
  channelTextColor,
} from '@/modules/devicesSensors/utils/constants'
import { dataTestId } from '@/tests/testid'
import { Flexbox, Icon, Text } from '@skf-design-system/ui-components-react'
import { FC } from 'react'

const ChannelDataFault: FC<{
  status: ChannelStatusEnum
  fault?: ChannelFaultEnum
}> = ({ status, fault }) => {
  const iconData = channelStatusIcon[status]
  const isFaulted = fault && status

  return (
    <Flexbox data-testid={dataTestId.channelDataFault} feFlexDirection="row" feGap="xs" feAlignItems="center">
      <Icon feIcon={iconData.icon} feColor={iconData.color} feSize="sm" />
      <Text feColor={channelTextColor[status]} feFontWeight="bold">
        {isFaulted && channelFaultText[fault]}
        {!isFaulted && status !== 'channelNotConnected' && channelStatusString['normal']}
        {!isFaulted && status === 'channelNotConnected' && channelStatusString['channelNotConnected']}
      </Text>
    </Flexbox>
  )
}

export default ChannelDataFault

import GenericLoader, { GenericLoaderType } from '@/shared/components/genericLoader'
import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import HeaderClearFiltersButton from '@/shared/components/moduleHeader/clearFiltersButton'
import HeaderExportCSVButton from '@/shared/components/moduleHeader/exportButton'
import HeaderRefreshButton from '@/shared/components/moduleHeader/refreshButton'
import HeaderTableStateChangeSwitch from '@/shared/components/moduleHeader/stateChangeSwitch'
import { useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { StyledHeaderActionPanel } from '@/shared/components/moduleHeader/styled'
import { headerActionPanelConfigSelector } from '@/store/selectors/moduleHeaderSelector'
import { useLocation, useParams } from 'react-router'
import {
  exportDeviceTableBySidSelector,
  exportAnalysisBacklogTableByPermissionBySidSelector,
  showHandledByPermissionBySidSelector,
  exportOpenRecommendationsTableByPermissionBySidSelector,
  showClosedOpenRecommendations,
} from '@/store/selectors/sitesPermissionsSelector'
import { ROUTES } from '@/shared/constants'
import { useMemo } from 'react'
import { getRouteFromPathname } from '@/shared/utils'

/**
 * HeaderActionPanel is a component that renders action buttons and loaders in the header of a module.
 * It is configurable based on the presence of certain features like filters, table state changes,
 * refresh functionality, and CSV download options.
 *
 * This component uses Redux for state management and context for fetching data status.
 * It displays various action buttons based on the configuration retrieved from the Redux store.
 *
 * The following buttons may be displayed:
 * - Clear Filters Button: Allows users to clear applied filters.
 * - Table State Change Switch: A toggle to change the state of the table.
 * - Refresh Button: Refreshes the data displayed in the table.
 * - CSV Download Button: Enables users to download the current data in CSV format.
 *
 * Additionally, loaders and spinners are shown based on the fetch status of the data.
 *
 * @returns {JSX.Element} A rendered Flexbox component containing action buttons and loaders.
 */

const HeaderActionPanel = () => {
  const {
    withClearFiltersButton,
    withTableStateChangeSwitch,
    withRefreshButton,
    withCSVDownloadButton,
    isWithUserEvents,
  } = useTypedSelector((store) => headerActionPanelConfigSelector(store))

  const { fetchDataStatus } = useFetchDataContext()

  const { sid } = useParams()
  const { pathname } = useLocation()
  /*******Switch visibility */
  const hasDisplayShowHandledPermission = useTypedSelector((state) => showHandledByPermissionBySidSelector(state, sid))
  const hasDisplayShowClosedPermission = useTypedSelector((state) => showClosedOpenRecommendations(state, sid))

  const isHeaderTableStateChangeSwitchVisible = useMemo(() => {
    const moduleName = getRouteFromPathname(pathname)
    if (moduleName === ROUTES.ANALYSIS_BACKLOG) {
      return hasDisplayShowHandledPermission
    }

    if (moduleName === ROUTES.OPEN_RECOMMENDATIONS) {
      return hasDisplayShowClosedPermission
    }

    return true
  }, [hasDisplayShowHandledPermission, hasDisplayShowClosedPermission, pathname])
  /*************** */

  /**** Export button visibility */
  const hasDisplayAnalysisBacklogExportTableButtonPermission = useTypedSelector((state) =>
    exportAnalysisBacklogTableByPermissionBySidSelector(state, sid)
  )

  const hasDisplayDeviceExportTableButtonPermission = useTypedSelector((state) =>
    exportDeviceTableBySidSelector(state, sid)
  )

  const hasDisplayOpenRecommendationsExportTableButtonPermission = useTypedSelector((state) =>
    exportOpenRecommendationsTableByPermissionBySidSelector(state, sid)
  )

  const isExportTableEnabled = useMemo(() => {
    const moduleName = getRouteFromPathname(pathname)
    if (moduleName === ROUTES.ANALYSIS_BACKLOG) {
      return hasDisplayAnalysisBacklogExportTableButtonPermission
    }

    if (moduleName === ROUTES.DEVICES_AND_SENSORS) {
      return hasDisplayDeviceExportTableButtonPermission
    }

    if (moduleName === ROUTES.OPEN_RECOMMENDATIONS) {
      return hasDisplayOpenRecommendationsExportTableButtonPermission
    }

    return true
  }, [
    hasDisplayDeviceExportTableButtonPermission,
    hasDisplayAnalysisBacklogExportTableButtonPermission,
    pathname,
    hasDisplayOpenRecommendationsExportTableButtonPermission,
  ])
  /************ */

  return (
    <StyledHeaderActionPanel
      feJustifyContent="space-evenly"
      feAlignItems="center"
      data-testid={dataTestId.headerActionPanel}
      isWithUserEvents={!!isWithUserEvents}
    >
      <GenericLoader fetchDataStatus={fetchDataStatus} loaderType={GenericLoaderType.HEADER} />
      {withClearFiltersButton && <HeaderClearFiltersButton />}
      {withTableStateChangeSwitch && isHeaderTableStateChangeSwitchVisible && <HeaderTableStateChangeSwitch />}
      {withRefreshButton && <HeaderRefreshButton />}
      {withCSVDownloadButton && isExportTableEnabled && <HeaderExportCSVButton />}
    </StyledHeaderActionPanel>
  )
}

export default HeaderActionPanel

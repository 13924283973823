import ReportCommonCheckbox from '@/modules/reportGenerator/components/reportCheckbox'
import { FC, useMemo } from 'react'
import { ReportTypeString } from '@/models/reportGenerator/types'
import { reportSections } from '@/modules/reportGenerator/utils/constants'
import { StyledDetailedAssetHealthCheckboxContainer, StyledReportTypeContainer } from '@/modules/reportGenerator/styled'
import { dataTestId } from '@/tests/testid'

const ReportTypeSection: FC<{
  reportTypeStatus: ReportTypeString[]
  onChange: (value: ReportTypeString, status: boolean) => void
}> = ({ reportTypeStatus, onChange }) => {
  const computedDisabledStatuses = useMemo(() => {
    return Object.values(reportSections).reduce<Record<string, boolean>>((acc, section) => {
      acc[section] =
        !reportTypeStatus.includes(reportSections.DETAILED_ASSET_HEALTH as ReportTypeString) &&
        section === reportSections.LAST_MEASUREMENTS
      return acc
    }, {})
  }, [reportTypeStatus])

  // Get the sections as an array for easier manipulation
  const sectionValues = Object.values(reportSections)

  //================================START==========================================
  // lastMeasurements, summaryCharts & openRecommendedActions
  // Temporarily disabled until backend implementation is complete
  //=================================END=========================
  return (
    <StyledReportTypeContainer
      data-testid={dataTestId.generateReportFiltersReportType}
      fe-flex-direction="column"
      fe-gap="md"
    >
      {/* Summary Charts checkbox*/}
      <ReportCommonCheckbox
        key={sectionValues[0]}
        status={sectionValues[0] as ReportTypeString}
        checked={reportTypeStatus.includes(sectionValues[0] as ReportTypeString)}
        disabled={computedDisabledStatuses[sectionValues[0]] || true}
        onChange={(_, checked) => onChange(sectionValues[0] as ReportTypeString, checked)}
      />

      {/* Summarized Asset Health checkbox*/}
      <ReportCommonCheckbox
        key={sectionValues[1]}
        status={sectionValues[1] as ReportTypeString}
        checked={reportTypeStatus.includes(sectionValues[1] as ReportTypeString)}
        disabled={computedDisabledStatuses[sectionValues[0]] || false}
        onChange={(_, checked) => onChange(sectionValues[1] as ReportTypeString, checked)}
      />

      <StyledDetailedAssetHealthCheckboxContainer feGap="xs">
        {/* Detailed Asset Health checkbox*/}
        <ReportCommonCheckbox
          key={sectionValues[2]}
          status={sectionValues[2] as ReportTypeString}
          checked={reportTypeStatus.includes(sectionValues[2] as ReportTypeString)}
          disabled={computedDisabledStatuses[sectionValues[0]] || false}
          onChange={(_, checked) => onChange(sectionValues[2] as ReportTypeString, checked)}
        />

        {/* Last Measurements checkbox*/}
        <ReportCommonCheckbox
          key={sectionValues[3]}
          status={sectionValues[3] as ReportTypeString}
          checked={reportTypeStatus.includes(sectionValues[3] as ReportTypeString)}
          disabled={computedDisabledStatuses[sectionValues[0]] || true}
          onChange={(_, checked) => onChange(sectionValues[3] as ReportTypeString, checked)}
        />
      </StyledDetailedAssetHealthCheckboxContainer>

      {/* Open Recommended Actions checkbox*/}
      {sectionValues.slice(4).map((section) => (
        <ReportCommonCheckbox
          key={section}
          status={section as ReportTypeString}
          checked={reportTypeStatus.includes(section as ReportTypeString)}
          disabled={computedDisabledStatuses[sectionValues[0]] || true}
          onChange={(_, checked) => onChange(section as ReportTypeString, checked)}
        />
      ))}
    </StyledReportTypeContainer>
  )
}

export default ReportTypeSection

// Location: index.tsx (TreeNodeContent)
import { Checkbox, Flexbox, Tooltip, useTooltip } from '@skf-design-system/ui-components-react'
import { FC, memo, useCallback } from 'react'
import useTreeController from '@/shared/components/newHierarchyTree/hooks/useTreeController'
import { TreeNodeData } from '@/models/newHierarchyTree/types'
import { StyledTreeNodeContentContainer } from '@/shared/components/newHierarchyTree/styled'
import { dataTestId } from '@/tests/testid'

// Memoized component for tree node content
const TreeNodeContent: FC<{
  nodeData: TreeNodeData
  isRootNode: boolean
}> = memo(({ nodeData, isRootNode }) => {
  const { selected, indeterminate, onToggle } = useTreeController(nodeData.id)

  // Memoized toggle handler
  const handleToggle = useCallback(() => {
    // Only toggle if not disabled
    if (!nodeData.disabled) {
      onToggle()
    }
  }, [onToggle, nodeData.disabled])

  const isDisabled =
    nodeData.children.length > 0 ? nodeData.children.every((child) => !!child.disabled) : !!nodeData.disabled

  const [hoverRef, isHovered] = useTooltip()

  return (
    <>
      <StyledTreeNodeContentContainer
        isRootNode={isRootNode}
        disabled={isDisabled}
        data-testid={dataTestId.hierarchyTreeNodeContentContainer}
      >
        <Flexbox ref={hoverRef} feJustifyContent="center" feAlignItems="center" feGap="xs">
          <Checkbox
            feSize="sm"
            feLabel="checked"
            feHideLabel
            checked={selected}
            indeterminate={indeterminate}
            onChange={handleToggle}
            disabled={isDisabled}
            data-testid={dataTestId.hierarchyTreeNodeContentCheckBox}
          />
          <span>{nodeData.label}</span>
        </Flexbox>
      </StyledTreeNodeContentContainer>
      <Tooltip isHovered={isHovered && isDisabled} triggerElement={hoverRef.current}>
        {nodeData.children.length > 0 ? 'Assets never reported' : 'Asset never reported'}
      </Tooltip>
    </>
  )
})

export default TreeNodeContent

import React from 'react'
import { Icon } from '@skf-design-system/ui-components-react'
import { dataTestId } from '@/tests/testid'
import { SubOption } from './types'
import { StyledSubOption, StyledSubOptions } from '@/modules/reportStatus/styled'

interface SubOptionsListProps {
  subOptions: SubOption[]
  hoveredMainOption: string | null
  selectedSubOption: string | undefined
  option: { value: string }
  handleSubOptionClick: (option: string) => void
}

/**
 * A component that displays a list of sub-options for a hovered main option.
 * @param {SubOptionsListProps} props - The props for the component.
 * @returns {JSX.Element | null} The rendered component or null if no sub-options are visible.
 */

const SubOptionsList: React.FC<SubOptionsListProps> = ({
  subOptions,
  hoveredMainOption,
  selectedSubOption,
  option,
  handleSubOptionClick,
}) => {
  return (
    <>
      {subOptions?.length > 0 && hoveredMainOption === option.value && (
        <StyledSubOptions data-testid={dataTestId.subOptionList}>
          {subOptions.map((subOption) => (
            <StyledSubOption
              key={subOption.en}
              selected={subOption.code === selectedSubOption}
              onClick={(e) => {
                e.stopPropagation()
                handleSubOptionClick(subOption.code)
              }}
            >
              {subOption.code === selectedSubOption && <Icon feIcon="check" feColor="gray" feSize="sm" />}
              <span>{subOption.en}</span>
            </StyledSubOption>
          ))}
        </StyledSubOptions>
      )}
    </>
  )
}

export default SubOptionsList

import {
  AllKeysOf,
  AssetCriticality,
  AssetStatus,
  AssetStatusString,
  ExtractArrayValues,
  RecommendedActionBase,
} from '@/shared/models/types'
import { AssetHealthStatusSelectType } from '@/models/dashboard/globalFiltering/types'
import {
  allAssetTypes,
  criticalityOptions,
  reportGeneratorStatusOptions,
  statusOptions,
} from '@/modules/reportGenerator/utils/constants'
import { Severity as ReportGeneratorAssetStatus } from '@/models/widgets/asset-health-condition/types'
import { FileDropzoneImageData } from '@/shared/components/dropzone/types'

export enum ReportType {
  summaryCharts = 'Summary Charts',
  summarizedAssetHealth = 'Summarized Asset Health',
  detailedAssetHealth = 'Detailed Asset Health',
  lastMeasurements = 'Last Measurements',
  openRecommendedActions = 'Open Recommended Actions',
}

export type ReportTypeString = AllKeysOf<ReportType>

export type StatusOptionValue = ExtractArrayValues<typeof statusOptions, 'value'>
export type ReportGeneratorStatusOptionValue = ExtractArrayValues<typeof reportGeneratorStatusOptions, 'value'>
export type CriticalityOptionValue = ExtractArrayValues<typeof criticalityOptions, 'value'>

export type AssetReportFilters = {
  assets: string[]
  assetStatuses: AssetHealthStatusSelectType[]
  assetCriticalities: AssetCriticality[]
  assetTypes: Array<'all' | (typeof allAssetTypes)[number]>
  reportTypes: ReportTypeString[]
  reportDate: Date
  hierarchySearchText: string
}
export type ReportGeneratorApiPayload = Omit<AssetReportFilters, 'reportDate'> & {
  reportDate: string
}

export interface IReportSelect<R> {
  value: R[]
  onChange: (value: R[]) => void
}

export type ReportAssetHealthDetails = {
  assetId: string
  assetName: string
  assetLocation: { siteName: string; parentName: string }
  assetDescription: string | null
  assetType: string | null
  assetCriticality: AssetCriticality
  assetStatus: AssetStatusString
  currentConditionReported: Date
  lastCollectionDate: Date
  reporterName: string
  statusHistory: AssetStatusHistory
  faults: ReportFault[]
  lastMeasurements: Measurement[]
}

export type Period = {
  startYear: string
  endYear: string
}

export type HistoryEntry = {
  date: string
  assetStatus: AssetStatusString
  reportCount: number
}

export type AssetStatusHistory = {
  period: Period
  history: HistoryEntry[]
}

export type DetailedAssetEvidence = Omit<
  FileDropzoneImageData['imageInfo'],
  'contentType' | 'size' | 'lastModified' | 'uniqueId' | 'originFile' | 'content'
>

export type DetailedAssetRecommendedAction = Omit<RecommendedActionBase, 'additionalNotes' | 'recommendedAction'> & {
  comment: string
  raId: string
  recommendation: string
}

export type ReportFault = {
  faultId: string
  faultType: string
  faultStatus: AssetStatusString
  dateReported: Date
  dataCollectedDate: Date
  observation: string
  evidences: DetailedAssetEvidence[]
  recommendedActions: DetailedAssetRecommendedAction[]
}
export type StatusValue = {
  value: AssetStatusString
  testId: string
}

export type Measurement = {
  pointId: string
  pointName: string
  previousValue: string
  lastValue: string
  units: string
}

type CoverPage = {
  companyName: string
  siteName: string
  address: string
  createdUser: string
  reportedDate: Date
  logoFileURL: string
}

export type ReportGeneratorApiResponse = {
  coverPage: CoverPage
  summaryCharts: Record<string, unknown> //will be updated in specific api integration ticket
  summarizedAssetHealth: SummarizedAssetHealthReportData[]
  detailedAssetHealth: ReportAssetHealthDetails[]
  openRecommendedActions: Record<string, unknown>[] //will be updated in specific api integration ticket
}

export type ReportAssetStatusIconImages = {
  assets: string[]
  assetStatuses: AssetHealthStatusSelectType[]
  assetCriticalities: AssetCriticality[]
  assetTypes: Array<'all' | (typeof allAssetTypes)[number]>
  reportTypes: ReportTypeString[]
  reportDate: Date
}

type ReportGeneratorAsset = {
  id: string
  name: string
  status: ReportGeneratorAssetStatus | null
  criticality: AssetCriticality
  type: string | null
  area: string | null
  tags: string[] | null
  analysisType: string | null
}

type FunctionalLocation = {
  id: string
  name: string
  assets: ReportGeneratorAsset[]
}

export type AssetHierarchy = {
  siteId: string
  siteName: string
  functionalLocations: FunctionalLocation[]
}

export type SummarizedAssetTableHeaderCell = {
  text: string
  width: number
}

type SummarizedAssetRecommendedAction = {
  raId: string
  recommendation: string
}

type SummarizedFault = {
  faultId: string
  observation: string
  recommendedActions: SummarizedAssetRecommendedAction[]
}
type SummarizedAssetHistory = {
  period: string
  isReportedInPeriod: boolean
  assetStatus: AssetStatus
}

export type SummarizedAssetHealthReportData = {
  assetId: string
  assetName: string
  parentName: string
  statusHistory: SummarizedAssetHistory[]
  lastCollectionDate: string
  faults: SummarizedFault[]
}

export type SummarizedAssetDetails = {
  parentName: string
  assetId: string
  assetName: string
  statusHistory: SummarizedAssetHistory[]
  lastCollectionDate: string
  faultId: string
  observation: string
  raId: string
  recommendation: string
  showFaultContent: boolean
  showParentContent: boolean
  isLastFault: boolean
  isLastRA: boolean
}

export type BlobType = 'coverPage' | 'infoPage'
export type StaticReportPartBlob = Partial<Record<BlobType, Blob>>

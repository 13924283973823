import { AxiosError } from 'axios'
import { JwtPayload } from 'jwt-decode'

export enum PathIdentifiers {
  // CustomerNotes = 'customer-notes',
  Dashboards = 'dashboard',
  Customers = 'customers',
  Analytics = 'analytics',
  Widget = 'widget',
  Reporting = 'reporting',
  OverallDashboard = 'overall',
  DevicesAndSensors = 'devices-and-sensors',
  AnalysisBacklog = 'analysis',
  AssetHealthCondition = 'asset-health-condition',
  RecommendedAction = 'recommended-action',
  DueDatesStatus = 'open-ra-due-date',
  OpenFaults = 'open-faults',
  // MockCustomerNote = 'customer_note',
  Documents = 'documents',
  Sites = 'sites',
  Reports = 'reports',
  Users = 'users',
  AssetHierarchy = 'asset-hierarchy',
}

export enum PATHS {
  // ORPHAN_NOTES = `${PathIdentifiers.CustomerNotes}/companies/\${companyId}/orphans`,
  // MARK_AS_ACKNOWLEDGED_ORPHAN_NOTES = `${PathIdentifiers.CustomerNotes}/notes/acknowledged`,
  DASHBOARDS = `${PathIdentifiers.Dashboards}`,
  DASHBOARDS_LAYOUT = `${PathIdentifiers.Dashboards}/\${dashboardId}/layouts`,
  DASHBOARDS_WIDGETS = `${PathIdentifiers.Dashboards}/\${dashboardId}/widgets`,
  UPDATE_DASHBOARD = `${PathIdentifiers.Dashboards}/\${dashboardId}`,
  CREATE_DASHBOARD = `${PathIdentifiers.Dashboards}`,
  UPDATE_DASHBOARD_LAYOUTS = `${PathIdentifiers.Dashboards}/\${dashboardId}/layouts`,
  CREATE_NEW_DASHBOARD_WIDGET = `${PathIdentifiers.Dashboards}/\${dashboardId}/widgets`,
  DELETE_DASHBOARD_WIDGET = `${PathIdentifiers.Dashboards}/\${dashboardId}/widgets/\${widgetId}`,
  POST_DATA_COLLECTION = `${PathIdentifiers.Analytics}/assets/datacollection`,
  GET_DATA_COLLECTION = `${PathIdentifiers.Analytics}/assets/datacollection/\${query_id}`,
  POST_FAULTS = `${PathIdentifiers.Sites}/\${sid}/widget/open-faults`,
  GET_FAULTS = `${PathIdentifiers.Analytics}/faults/\${query_id}`,
  GET_REPORTS = `${PathIdentifiers.Sites}/\${sid}/${PathIdentifiers.Reports}`,
  GET_SITES_DATA = `${PathIdentifiers.Sites}`,
  GET_SITE_DETAILS = `${PathIdentifiers.Sites}/\${sid}`,
  GET_SITE_DETAILS_LOGO = `${PathIdentifiers.Documents}/files?fileIDs=\${fileId}&small=true`,
  POST_ALARMS = `${PathIdentifiers.Analytics}/assets/alarms`,
  GET_ALARMS = `${PathIdentifiers.Analytics}/assets/alarms/\${query_id}`,
  POST_CONDITION_STATUSES = `${PathIdentifiers.Widget}/asset-health-condition/\${company_id}`,
  GET_CONDITION_STATUSES = `${PathIdentifiers.Analytics}/assets/condition-statuses/\${query_id}`,
  POST_RECOMMENDATIONS = `/sites/\${sid}/${PathIdentifiers.Widget}/recommended-actions`,
  GET_RECOMMENDATIONS = `${PathIdentifiers.Analytics}/recommendations/\${query_id}`,
  POST_RA_DUE_DATES_STATUS = `${PathIdentifiers.Sites}/\${sid}/widget/open-ra-due-date`,
  CUSTOMERS = `${PathIdentifiers.Customers}`,
  GET_OPEN_RECOMMENDATIONS = `${PathIdentifiers.Reporting}/sites/\${sid}/unresolved-ra`,
  GET_ASSET_HIERARCHY = `/sites/\${sid}/${PathIdentifiers.AssetHierarchy}`,
  GET_REPORT_FAULT_TYPES = `${PathIdentifiers.Reporting}/sites/\${sid}/fault-types`,
  GET_OVERVIEW_DATA = `${PathIdentifiers.OverallDashboard}`,
  GET_DEVICES_AND_SENSORS_DATA = `${PathIdentifiers.DevicesAndSensors}/sites/\${siteId}/imx`,
  GET_ANALYSIS_BACKLOG_DATA = `${PathIdentifiers.AnalysisBacklog}/\${companyId}/rds-assets`,
  PUT_MARK_AS_NORMAL = `${PathIdentifiers.Reporting}/sites/\${sid}/assets/\${assetId}/reports/normal`,
  GET_AUTOMATIC_DIAGNOSTICS_DATA = `${PathIdentifiers.Reporting}/sites/\${companyId}/automatic-diagnostics`,
  GET_REPORT_HISTORY = `${PathIdentifiers.Reporting}/sites/\${sid}/assets/\${assetId}/reports`,
  // GET_CUSTOMER_NOTES = `${PathIdentifiers.CustomerNotes}/sites/\${sid}/assets/\${assetId}/notes?orphan=\${isOrphan}`,
  GET_RECOMMENDATION_TYPES = `${PathIdentifiers.Reporting}/recommendation-types`,
  POST_PUBLISH_REPORT = `${PathIdentifiers.Reporting}/sites/\${sid}/assets/\${assetId}/reports`,
  POST_RECONFIRM_REPORT = `${PathIdentifiers.Reporting}/sites/\${sid}/assets/\${assetId}/reconfirm`,
  // POST_MOCK_CUSTOMER_NOTE = `${PathIdentifiers.MockCustomerNote}`,
  POST_UPLOAD_EVIDENCE_IMAGES = `${PathIdentifiers.Documents}/files`,
  DELETE_EVIDENCE_IMAGE = `${PathIdentifiers.Documents}/files/\${fileId}`,
  PATCH_UPDATE_EVIDENCE_IMAGE_DATA = `${PathIdentifiers.Documents}/files/\${fileId}/metadata`,
  GET_USERS_PERMISSIONS = `${PathIdentifiers.Users}/me/allowed-actions`,
}

export interface NetworkResult<T> {
  onSuccess?: (result: T) => Promise<void>
  onError?: (error: AxiosError) => Promise<void>
}

export type DarwinJWT = JwtPayload & {
  email: string
}

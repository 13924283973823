import { Site } from '@/models/overview/types'
import OverviewCell from '@/modules/overview/components/overviewCell'
import { StyledOverviewDevicesSensorsColumnWrapper, StyledOverviewTimeBetweenSpan } from '@/modules/overview/styled'
import { calculateIconMarginValue } from '@/modules/overview/utils'
import { overviewCellTooltips } from '@/modules/overview/utils/constants'
import { ROUTES } from '@/shared/constants'
import { MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'

const useAnalysisBacklogTimeBetween = () => {
  const analysisBacklogTimeBetween = useMemo<MRT_ColumnDef<Site>[]>(
    () => [
      {
        accessorKey: 'analysisBacklog.timeBetween',
        id: 'timeBetweenData',
        header: '',
        size: 100,
        Cell: ({ cell, row }) => (
          <OverviewCell
            siteId={row.original.siteId}
            value={cell.getValue<string>()}
            routeTo={ROUTES.ANALYSIS_BACKLOG}
            message={overviewCellTooltips[cell.column.columnDef.id as keyof typeof overviewCellTooltips]}
            prefixIndicator={
              <StyledOverviewDevicesSensorsColumnWrapper marginLeft={calculateIconMarginValue(cell.getValue<string>())}>
                <StyledOverviewTimeBetweenSpan threshold={parseFloat(cell.getValue<string>())} />
              </StyledOverviewDevicesSensorsColumnWrapper>
            }
          />
        ),
      },
    ],
    []
  )
  return analysisBacklogTimeBetween
}

export default useAnalysisBacklogTimeBetween

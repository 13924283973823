import { useFaultEvidenceContext } from '@/contexts/moduleContexts/evidence'
import { FileDropzoneImageData } from '@/shared/components/dropzone/types'
import {
  StyledEvidenceTitleContainer,
  StyledEvidenceTitleSuffix,
  StyledEvidenceWrapper,
} from '@/modules/reportStatus/styled'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { dataTestId } from '@/tests/testid'
import { FC } from 'react'
import EvidenceImagesContainer from '@/modules/reportStatus/components/evidence/evidenceImagesContainer'
import { Flexbox } from '@skf-design-system/ui-components-react'
import { useReportStatusContext } from '@/contexts/reportStatus'
import EvidenceDropzone from '@/modules/reportStatus/components/evidence/dropzone'
import { FilesDropzoneProvider } from '@/contexts/moduleContexts/filesDropzone'
import { ACCEPTED_FILE_TYPES, MAX_FILE_SIZE } from '@/shared/components/dropzone/constants'

interface EvidenceProps {
  isInEditMode: boolean
  existedEvidences: FileDropzoneImageData[]
}
const Evidence: FC<EvidenceProps> = ({ isInEditMode, existedEvidences = [] }) => {
  const { setFaultImages, faultId } = useFaultEvidenceContext()
  const { faultEditModeData, setFaultEditModeData } = useReportStatusContext()

  useDeepCompareEffect(() => {
    setFaultImages(existedEvidences)
    setFaultEditModeData({ ...faultEditModeData, [faultId]: isInEditMode })
  }, [existedEvidences, isInEditMode])

  return (
    <StyledEvidenceWrapper data-testid={dataTestId.reportStatusEvidence}>
      <StyledEvidenceTitleContainer>
        Evidence {existedEvidences.length ? `(${existedEvidences.length})` : ''}
        {isInEditMode && (
          <StyledEvidenceTitleSuffix>
            {'(Add up to 5 images to support your observation. Jpeg, Png or GIF format, up to 10MB per image)'}
          </StyledEvidenceTitleSuffix>
        )}
      </StyledEvidenceTitleContainer>
      <FilesDropzoneProvider category="evidence" acceptFiles={ACCEPTED_FILE_TYPES} maxFileSize={MAX_FILE_SIZE}>
        <Flexbox>
          <EvidenceDropzone />
          <EvidenceImagesContainer />
        </Flexbox>
      </FilesDropzoneProvider>
    </StyledEvidenceWrapper>
  )
}

export default Evidence

import { Site } from '@/models/overview/types'
import useAutoDiagnosticsData from '@/modules/overview/columns/thirdLevel/useAutoDiagnosticsData'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { MRT_ColumnDef } from 'material-react-table'

const useAutoDiagnostics = () => {
  const autoDiagnosticsData = useAutoDiagnosticsData()
  const automaticDiagnosticsColumn = useDeepCompareMemo<MRT_ColumnDef<Site>[]>(
    () => [
      {
        id: 'autoDiagnosticsData',
        header: '',
        columns: autoDiagnosticsData,
        size: 200,
        grow: false,
      },
    ],
    [autoDiagnosticsData]
  )
  return automaticDiagnosticsColumn
}

export default useAutoDiagnostics

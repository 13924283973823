/* eslint-disable react-compiler/react-compiler */
import { useRef } from 'react'

/**
 * A custom hook that stores the previous value of a given state or prop.
 *
 * This hook returns the last value passed to it before the current render.
 *
 * @param {T} value - The current value to track.
 * @returns {T | undefined} The previous value of the input, or undefined if it is the first render.
 * @template T
 */

const usePrevious = <T>(value: T): T | undefined => {
  const currentRef = useRef<T>(value)
  const previousRef = useRef<T>(undefined)
  if (currentRef.current !== value) {
    previousRef.current = currentRef.current
    currentRef.current = value
  }
  return previousRef.current
}
export default usePrevious

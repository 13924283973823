import {
  AssetHealthFault,
  FaultStatusType,
  FaultsApiData,
  RecommendedAction,
  Faults,
} from '@/models/widgets/asset-health-fault/types'
import { formatDate, DateFormats } from '@/shared/dateUtils'
import { FaultTypeSelectOption, Action } from '@/models/dashboard/globalFiltering/types'
import { FaultTypes } from '@/models/reportStatus/types'
import { extractDateFromTimestamp, getMappedValue } from '@/shared/utils'

/**
 * Converts a status string into a `FaultStatusType`.
 *
 * The function takes a status string and returns a corresponding `FaultStatusType`.
 * If the provided status is not recognized, it defaults to 'normal'.
 *
 * @param {string} status - The status string to be converted. Expected values are:
 *                          'severe', 'unacceptable', 'unsatisfactory', 'normal', or 'acceptable'.
 *
 * @returns {FaultStatusType} - The corresponding `FaultStatusType`. Returns 'normal' if the status is not recognized.
 *
 * @example
 * const status = getStatusType('severe'); // returns 'severe'
 * const unknownStatus = getStatusType('unknown'); // returns 'normal'
 */

const getStatusType = (status: string): FaultStatusType => {
  switch (status) {
    case 'severe':
      return 'severe'
    case 'unacceptable':
      return 'unacceptable'
    case 'unsatisfactory':
      return 'unsatisfactory'
    case 'normal':
      return 'normal'
    case 'acceptable':
      return 'acceptable'
    default:
      return 'normal' // Default to Normal if status is unknown
  }
}

/**
 * Modifies an array of fault data to include formatted asset and fault details.
 *
 * This function processes the original fault data by transforming the `assets` array within each `FaultsApiData` object.
 * It reformats asset status, fault creation dates, and recommended action dates to a more readable format.
 *
 * @param {FaultsApiData[]} originalData - An array of `FaultsApiData` objects that contains fault information.
 *
 * @returns {FaultsApiData[]} - A new array of `FaultsApiData` objects with formatted asset and fault details.
 *
 */

const modifyFaultsData = (originalData: FaultsApiData[]): FaultsApiData[] => {
  return originalData.map(({ faultType, count, assets }) => {
    const assetHealthFaults: AssetHealthFault[] = assets.map((asset) => {
      const status = getStatusType(asset.status)
      const lastFaultCreatedDate = asset.lastFaultCreatedDate
        ? formatDate(asset.lastFaultCreatedDate, DateFormats.AmericanDateFormat)
        : '-'
      const faults: Faults[] = asset.faults.map((fault) => {
        const recommendedActions: RecommendedAction[] = fault.recommendedActions.map((action) => {
          return {
            ...action,
            openDate: action.openDate ? formatDate(action.openDate, DateFormats.AmericanDateFormat) : '-',
            dueDate: action.dueDate
              ? formatDate(extractDateFromTimestamp(action.dueDate), DateFormats.AmericanDateFormat)
              : '-',
          }
        })
        return {
          ...fault,
          recommendedActions,
        }
      })
      return {
        ...asset,
        status,
        lastFaultCreatedDate,
        faults,
      }
    })

    return {
      faultType,
      count,
      assets: assetHealthFaults,
    }
  })
}

/**
 * Converts an RGB color string to an RGBA color string with a specified opacity.
 *
 * This function takes an RGB color string (e.g., 'rgb(255, 0, 0)') and an opacity value
 * between 0 and 1, and returns the corresponding RGBA string with the specified opacity (e.g., 'rgba(255, 0, 0, 0.5)').
 *
 * @param {string} color - The RGB color string (e.g., 'rgb(255, 0, 0)').
 * @param {number} opacity - The opacity value, between 0 (fully transparent) and 1 (fully opaque).
 *
 * @returns {string} - The resulting RGBA color string (e.g., 'rgba(255, 0, 0, 0.5)').
 **/

const withOpacity = (color: string, opacity: number) => {
  return color.replaceAll('rgb', 'rgba').replaceAll(')', ', ' + opacity + ')')
}

/**
 * Maps the `openFaults` data to fault type options or modified fault data based on the provided action type.
 *
 * If the action type is `FaultTypeSelectOptions`, the function returns an array of `FaultTypeSelectOption` objects.
 * Otherwise, it returns the `openFaults` data with `faultType` values mapped to their corresponding titles.
 *
 * @param {FaultsApiData[]} openFaults - The list of fault data to be mapped, each containing `faultType` and `count`.
 * @param {FaultTypes} faultTypes - The list of fault types used to map `faultType` codes to titles.
 * @param {Action.FaultTypeSelectOptions} [type] - Optional action type to determine if `FaultTypeSelectOption` should be returned.
 *
 * @returns {FaultsApiData[] | FaultTypeSelectOption[]} - Returns either an array of mapped `FaultsApiData` or `FaultTypeSelectOption` based on the action type.
 *
 * @example
 * const openFaults = [
 *   { faultType: 'typeA', count: 3 },
 *   { faultType: 'typeB', count: 5 },
 * ];
 *
 * const faultTypes = [
 *   { code: 'typeA', text: 'Type A', subTypes: [] },
 *   { code: 'typeB', text: 'Type B', subTypes: [] },
 *   { code: 'typeC', text: 'Type C', subTypes: [{ code: 'subTypeC1', en: 'Sub Type C1' }] },
 * ];
 *
 * const mappedData = mapOpenFaultsData(openFaults, faultTypes, Action.FaultTypeSelectOptions);
 * console.log(mappedData);
 *
 * // Output: [
 * //   { faultType: 'typeA', title: 'Type A', count: 3 },
 * //   { faultType: 'typeB', title: 'Type B', count: 5 },
 * //   { faultType: 'subTypeC1', title: 'Sub Type C1', count: 0 }
 * // ]
 * */

const mapOpenFaultsData = (
  openFaults: FaultsApiData[],
  faultTypes: FaultTypes,
  type?: Action.FaultTypeSelectOptions
): FaultsApiData[] | FaultTypeSelectOption[] => {
  if (type === Action.FaultTypeSelectOptions) {
    const mappedOpenFaults = openFaults.map(({ faultType, count }) => ({
      faultType,
      title: getMappedValue(faultTypes, faultType),
      count,
    }))

    const existingFaultTypes = new Set(mappedOpenFaults.map((f) => f.faultType))

    const remainingFaultTypes = faultTypes.flatMap(({ code, text, subTypes }) => {
      if (subTypes.length > 0) {
        return subTypes
          .filter(({ code: subCode }) => !existingFaultTypes.has(subCode))
          .map(({ code: subCode, en }) => ({
            faultType: subCode,
            title: en,
            count: 0,
          }))
      }

      if (!existingFaultTypes.has(code)) {
        return [
          {
            faultType: code,
            title: text,
            count: 0,
          },
        ]
      }

      return []
    })

    return [...mappedOpenFaults, ...remainingFaultTypes]
  }

  return openFaults.map((faults) => ({
    ...faults,
    faultType: getMappedValue(faultTypes, faults.faultType),
  }))
}

/**
 * Updates the fault type counts in the `faultTypeSelectOptions` based on the modified data.
 * If the count for a fault type in the modified data is greater than the current count,
 * the count will be updated in the corresponding option.
 *
 * @param {FaultsApiData[]} modifiedOpenFaultsData - The list of modified fault data, each containing a `faultType` and `count`.
 * @param {FaultTypeSelectOption[]} faultTypeSelectOptions - The list of fault type options with an optional `count` property to be updated.
 *
 * @returns {FaultTypeSelectOption[]} The updated list of fault type options with counts updated where necessary.
 *
 * @example
 * const modifiedData = [
 *   { faultType: 'typeA', count: 5 },
 *   { faultType: 'typeB', count: 10 },
 * ];
 * const options = [
 *   { faultType: 'typeA', title: 'Fault A', count: 3 },
 *   { faultType: 'typeB', title: 'Fault B', count: 8 },
 * ];
 *
 * const updatedOptions = updateFaultTypeCounts(modifiedData, options);
 * console.log(updatedOptions);
 * // Output: [
 * //   { faultType: 'typeA', title: 'Fault A', count: 5 },
 * //   { faultType: 'typeB', title: 'Fault B', count: 10 }
 * // ]
 */

const updateFaultTypeCounts = (
  modifiedOpenFaultsData: FaultsApiData[],
  faultTypeSelectOptions: FaultTypeSelectOption[]
) => {
  const modifiedDataMap = new Map(modifiedOpenFaultsData.map((item) => [item.faultType, item.count]))

  const updatedOptions = faultTypeSelectOptions.map((option) => {
    const currentCount = option.count ?? 0
    const updatedCount = modifiedDataMap.get(option.faultType)

    return updatedCount !== undefined && updatedCount > currentCount ? { ...option, count: updatedCount } : option
  })

  return updatedOptions
}

/**
 * Updates the count of fault types in the faultTypeSelectOptions array based on modified data.
 *
 * This function takes two arrays as input: `faultOptions` which contains fault type options and
 * `modifiedOpenFaultsData` which contains the modified fault data. It updates the `count` property
 * of the matching fault types between these two arrays. If any counts have been updated, the
 * updated list of fault options is returned. Otherwise, an empty array is returned.
 *
 * @param {FaultTypeSelectOption[]} faultOptions - The list of fault type options to be updated.
 *                                                Each item in this array should have at least a `faultType` and `count` property.
 *                                                Defaults to an empty array if not provided.
 *
 * @param {FaultsApiData[]} modifiedOpenFaultsData - The list of modified fault data containing the updated `count` for each fault type.
 *                                                   Defaults to an empty array if not provided.
 *
 * @returns {FaultTypeSelectOption[]} - The updated list of fault options if any count was updated.
 *                                      Returns an empty array if no count updates were made.
 *
 * @example
 * const updatedOptions = updateFaultTypeSelectOptions(faultTypeSelectOptions, modifiedFaultsData);
 * // Returns an updated array with modified counts, or an empty array if no changes.
 */

const updateFaultTypeSelectOptions = (
  faultOptions: FaultTypeSelectOption[] = [],
  modifiedOpenFaultsData: FaultsApiData[] = []
) => {
  let countUpdated = false
  const faultTypeMap = new Map(faultOptions?.map((item) => [item.faultType, item]))
  modifiedOpenFaultsData?.forEach((modifiedItem) => {
    const faultTypeItem = faultTypeMap.get(modifiedItem.faultType)
    if (faultTypeItem && faultTypeItem.count !== modifiedItem.count) {
      faultTypeItem.count = modifiedItem.count
      countUpdated = true
    }
  })
  if (countUpdated) {
    return Array.from(faultTypeMap.values())
  } else return []
}

export { modifyFaultsData, withOpacity, mapOpenFaultsData, updateFaultTypeSelectOptions, updateFaultTypeCounts }

import networkClient from '@/api/NetworkClient'
import { PATHS } from '@/api/types'
import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { DashboardContextProvider, useDashboardContext } from '@/contexts/dashboard'
import { SelectFilterType } from '@/models/dashboard/globalFiltering/types'
import DashboardHeader from '@/modules/dashboard/components/header'
import WidgetErrorHandler from '@/modules/dashboard/components/widgetErrorHandler'
import WidgetErrorSilentRetry from '@/modules/dashboard/components/widgetErrorSilentRetry'
import useGetDashboardsApi from '@/modules/dashboard/hooks/useGetDashboardsApi'
import useWidgets from '@/modules/dashboard/hooks/widgets/useWidgetsHook'
import { StyledDashboardRoot } from '@/modules/dashboard/styled'
import DataRefresher from '@/shared/components/dataRefresher'
import { DarwinWidgetError, DarwinWidgetErrorCaller } from '@/shared/components/errorBoundary/darwinError'
import { ToastContent } from '@/shared/components/toastContent'
import { ERR_CANCELED } from '@/shared/constants'
import { LoadingType } from '@/shared/hooks/useApi'
import { AsyncStatus } from '@/shared/hooks/useAsync'
import useCleanupStorage from '@/shared/hooks/useCleanupStorage'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { useEffectOnce } from '@/shared/hooks/useEffectOnce'
import usePrevious from '@/shared/hooks/usePrevious'
import { openToast } from '@/store/genericToast/actions'
import { stopLoading } from '@/store/loading/action'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { memo } from 'react'
import { Outlet, useParams } from 'react-router'
import { clearDashboardGlobalFilters } from './utils'

const Dashboard = () => (
  <DashboardContextProvider>
    <DashboardModule />
  </DashboardContextProvider>
)

const DashboardModule = () => {
  const {
    faultTypeSelectOptions,
    setDashboardFilters,
    setFaultTypeSelectOptions,
    setModifiedOpenFaultsData,
    setWidgetAssetHealthFaultData,
    dashboardFilters,
    widgetErrors,
    setWidgetErrors,
  } = useDashboardContext()
  const { setFetchDataFn, setFetchDataStatus, fetchDataStatus } = useFetchDataContext()
  const dashboardDispatch = useAppDispatch()

  const reportFaultTypes = useTypedSelector((state) => state.reportFaultTypes)

  const { sid } = useParams()
  const { execute, status } = useGetDashboardsApi()
  const { widgetsExecutionFunctionsData } = useWidgets()
  const prevDashboardFilters = usePrevious<SelectFilterType>(dashboardFilters)

  useEffectOnce(() => {
    execute({ loaderType: LoadingType.GLOBAL })
  })

  const executeWidgetsApi = async (loaderType: LoadingType = LoadingType.NONE) => {
    const promises = await Promise.allSettled(widgetsExecutionFunctionsData.map((data) => data.fetchFn({ loaderType })))
    const errors = promises.filter(
      (promise): promise is PromiseRejectedResult =>
        promise.status === 'rejected' && promise.reason.code !== ERR_CANCELED
    )

    if (errors.length > 0) {
      setWidgetErrors(errors.map((error) => error.reason as DarwinWidgetError))
    }
  }

  useDeepCompareEffect(() => {
    setFetchDataFn(() => executeWidgetsApi)
    const currentStatuses = widgetsExecutionFunctionsData.map((data) => data.fetchFnStatus)
    let widgetsExecuteStatus: AsyncStatus = 'not-executed'
    if (currentStatuses.some((status) => status === 'loading')) {
      widgetsExecuteStatus = 'loading'
    } else if (currentStatuses.some((status) => status === 'error')) {
      widgetsExecuteStatus = 'error'
    } else if (currentStatuses.every((status) => status === 'success')) {
      widgetsExecuteStatus = 'success'
    }

    setFetchDataStatus(widgetsExecuteStatus)
  }, [widgetsExecutionFunctionsData])

  const cancelWidgetsApi = async () => {
    await Promise.all([
      networkClient.cancelRequest(PATHS.POST_FAULTS),
      networkClient.cancelRequest(PATHS.POST_CONDITION_STATUSES),
      networkClient.cancelRequest(PATHS.POST_RECOMMENDATIONS),
      networkClient.cancelRequest(PATHS.POST_RA_DUE_DATES_STATUS),
    ])
  }

  useDeepCompareEffect(() => {
    if (
      !(
        (prevDashboardFilters?.faultType.length === 0 &&
          dashboardFilters.faultType.length > 0 &&
          fetchDataStatus === 'success') ||
        widgetErrors.length > 0
      )
    ) {
      if (reportFaultTypes.length) {
        setWidgetAssetHealthFaultData([])
        executeWidgetsApi()
      }
    }
    return () => {
      cancelWidgetsApi()
    }
  }, [dashboardFilters])

  useDeepCompareEffect(() => {
    setFaultTypeSelectOptions([])
    setModifiedOpenFaultsData([])
    clearDashboardGlobalFilters(
      faultTypeSelectOptions.map((option) => option.faultType),
      setDashboardFilters
    )
    if (reportFaultTypes.length) {
      setWidgetAssetHealthFaultData([])
      executeWidgetsApi()
    }
  }, [sid, reportFaultTypes])

  useDeepCompareEffect(() => {
    if (
      widgetErrors.length > 0 &&
      widgetErrors[0].status &&
      widgetErrors.every((error) => error.caller !== DarwinWidgetErrorCaller.RETRY)
    ) {
      dashboardDispatch(
        openToast({
          children: (
            <ToastContent
              iconName="refresh"
              iconColor="blue"
              startTextContent="Data retrieval problem. Please click "
              endTextContent="icon to refresh"
            />
          ),
          feSeverity: 'error',
          timeout: 5,
        })
      )
    }
  }, [widgetErrors])

  useDeepCompareEffect(() => {
    if (faultTypeSelectOptions.length > 0 && fetchDataStatus === 'success' && status === 'success') {
      dashboardDispatch(stopLoading())
    }
  }, [fetchDataStatus, faultTypeSelectOptions, status])

  useCleanupStorage()

  return (
    <StyledDashboardRoot>
      <DashboardHeader />
      <>
        <Outlet />
        <DataRefresher<void> functionToExecute={() => executeWidgetsApi(LoadingType.NONE)} />
        <WidgetErrorHandler widgetErrors={widgetErrors} />
        <WidgetErrorSilentRetry widgetErrors={widgetErrors} />
      </>
    </StyledDashboardRoot>
  )
}
export default memo(Dashboard)

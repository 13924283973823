import { AssetStatusString } from '@/shared/models/types'
import {
  AssetReportFilters,
  ReportGeneratorApiResponse,
  StatusValue,
  ReportTypeString,
} from '@/models/reportGenerator/types'
import { AssetStatus } from '@/shared/models/types'
import { getEnumKeys } from '@/shared/utils'
import { Font } from '@react-pdf/renderer'
import { COLORS } from '@skf-design-system/ui-components-react'
import { dataTestId } from '@/tests/testid'
import neverReportedPNG from '@/modules/reportGenerator/assets/never_reported_icon.png'
import unsatisfactoryPNG from '@/modules/reportGenerator/assets/unsatisfactory_icon.png'
import acceptablePNG from '@/modules/reportGenerator/assets/acceptable_icon.png'
import unacceptablePNG from '@/modules/reportGenerator/assets/unacceptable_icon.png'
import normalPNG from '@/modules/reportGenerator/assets/normal_icon.png'
import severePNG from '@/modules/reportGenerator/assets/severe_icon.png'
import { NodeType, TreeNodeData } from '@/models/newHierarchyTree/types'

export const mappedAssetStatusImages: Record<AssetStatusString, string | null> = {
  normal: normalPNG,
  acceptable: acceptablePNG,
  unsatisfactory: unsatisfactoryPNG,
  unacceptable: unacceptablePNG,
  severe: severePNG,
  ['never-reported']: neverReportedPNG,
}

// Register Font
Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
})

export const reportSections = {
  SUMMARY_CHARTS: 'summaryCharts',
  SUMMARIZED_ASSET_HEALTH: 'summarizedAssetHealth',
  DETAILED_ASSET_HEALTH: 'detailedAssetHealth',
  LAST_MEASUREMENTS: 'lastMeasurements',
  OPEN_RECOMMENDED_ACTIONS: 'openRecommendedActions',
} as const

export const statusOptions = [
  { label: 'All', value: 'all' },
  { label: 'Hide normal', value: 'hideNormal' },
  {
    label: 'Unsatisfactory, unacceptable, severe',
    value: 'unsatisfactoryUnacceptableSevere',
  },
  { label: 'Unsatisfactory, severe', value: 'unsatisfactorySevere' },
  { label: 'Severe', value: 'severe' },
] as const

export const reportGeneratorStatusOptions = [
  { label: 'Normal', value: 'normal' },
  {
    label: 'Acceptable',
    value: 'acceptable',
  },
  {
    label: 'Unsatisfactory',
    value: 'unsatisfactory',
  },
  {
    label: 'Unacceptable',
    value: 'unacceptable',
  },
  { label: 'Severe', value: 'severe' },
] as const

export const criticalityOptions = [
  { label: 'All', value: 'all' },
  { label: 'A', value: 'a' },
  { label: 'A-B', value: 'ab' },
  { label: 'B-C', value: 'bc' },
] as const

export const assetTypeOptions = [
  { label: 'All', value: 'all' },
  { label: 'Vibration', value: 'vibration' },
  { label: 'AssetType1', value: 'assetType1' },
  { label: 'AssetType2', value: 'assetType2' },
] as const

export const allAssetTypes = assetTypeOptions.map((option) => option.value).filter((value) => value !== 'all')

export const assetCriticalities = ['A', 'B', 'C'] as const

export const initialReportFiltersState: AssetReportFilters = {
  assets: [],
  assetStatuses: getEnumKeys(AssetStatus, ['never-reported']),
  assetCriticalities: [...assetCriticalities],
  assetTypes: allAssetTypes,
  reportDate: new Date(),
  reportTypes: ['summarizedAssetHealth', 'detailedAssetHealth'] as ReportTypeString,
  hierarchySearchText: '',
}

export const assetAnnualStatusIconMappings: Record<AssetStatusString, string> = {
  'never-reported': COLORS.gray700,
  normal: 'rgb(70,150,43)', // green
  acceptable: 'rgb(136,192,8)', // light green
  unsatisfactory: 'rgb(255,221,97)', // yellow
  unacceptable: 'rgb(255,128,4)', // orange
  severe: 'rgb(204,0,0)', // red
}

export const initialGeneratedReportsData: ReportGeneratorApiResponse = {
  coverPage: {
    companyName: '',
    siteName: '',
    address: '',
    createdUser: '',
    reportedDate: new Date(),
    logoFileURL: '',
  },
  summaryCharts: {}, // will be updated in specific api integration ticket
  summarizedAssetHealth: [], // will be updated in specific api integration ticket
  detailedAssetHealth: [
    {
      assetId: '',
      assetName: '',
      assetLocation: {
        siteName: '',
        parentName: '',
      },
      assetDescription: '',
      assetType: allAssetTypes[0],
      assetCriticality: 'A',
      assetStatus: 'normal' as AssetStatusString,
      currentConditionReported: new Date(),
      lastCollectionDate: new Date(),
      reporterName: '',
      statusHistory: {
        period: {
          startYear: new Date().getFullYear().toString(),
          endYear: new Date().getFullYear().toString(),
        },
        history: [
          {
            date: '',
            assetStatus: 'normal',
            reportCount: 0,
          },
        ],
      },
      faults: [],
      lastMeasurements: [],
    },
  ],
  openRecommendedActions: [], // will be updated in specific api integration ticket
}

export const statusValues: StatusValue[] = [
  { value: 'normal', testId: dataTestId.PdfIconNormal },
  { value: 'acceptable', testId: dataTestId.PdfIconAcceptable },
  { value: 'unsatisfactory', testId: dataTestId.PdfIconUnsatisfactory },
  { value: 'unacceptable', testId: dataTestId.PdfIconUnacceptable },
  { value: 'severe', testId: dataTestId.PdfIconSevere },
]

// export const initialAssetHierarchyState: AssetHierarchy = {
//   siteId: '',
//   siteName: '',
//   functionalLocations: [
//     {
//       id: '',
//       name: '',
//       assets: [
//         {
//           id: '',
//           name: '',
//           status: 'acceptable',
//           criticality: 'A',
//           type: null,
//           area: null,
//           tags: null,
//           analysisType: null,
//         },
//       ],
//     },
//   ],
// }

export const summarizedAssetTableHeaderCells = [
  { text: 'Belongs To', width: 54 },
  { text: 'Asset', width: 80 },
  { text: 'Asset status', width: 140 },
  { text: 'Collection date', width: 60 },
  { text: 'Observation', width: 170 },
  { text: 'Recommended Action', width: 209 },
]

export const stripeCount = 70

export const emptyTreeNodeData: TreeNodeData = {
  id: '',
  children: [],
  label: '',
  type: NodeType.Company,
}

import { ChannelInterface, DeviceInterface } from '@/models/devicesAndSensors/types'
import useChannelTable from '@/modules/devicesSensors/tables/useChannelsTable'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { groupBy } from '@/shared/utils'
import { dataTestId } from '@/tests/testid'
import { MaterialReactTable } from 'material-react-table'
import { FC } from 'react'

interface ChannelsPaneProps {
  channels: ChannelInterface[]
  device: DeviceInterface
}
const ChannelsPane: FC<ChannelsPaneProps> = ({ channels, device }) => {
  /**
   * Memoized computation of channels data, grouped by type and sorted.
   *
   * This hook uses `useDeepCompareMemo` to memoize the computation of channels data.
   * It groups the channels by their type, sorts the groups alphabetically by type,
   * and then sorts the channels within each group by their number.
   *
   * @param {Array} channels - The array of channel objects to be processed.
   * @returns {Array} - The processed and sorted array of channels.
   *
   * The memoization ensures that the computation is only re-executed when the `channels` array changes.
   * The `groupBy` function is used to group the channels by their type.
   * The `Object.keys` and `localeCompare` functions are used to sort the groups alphabetically by type.
   * The `reduce` function is used to sort the channels within each group by their number.
   * Finally, the `flat` function is used to flatten the grouped and sorted channels into a single array.
   */
  const channelsData = useDeepCompareMemo(() => {
    const groupedChannels = groupBy(channels, 'type')

    return Object.values(
      Object.keys(groupedChannels)
        .sort((a, b) => a.localeCompare(b))
        .reduce(
          (obj, key) => {
            obj[key] = groupedChannels[key]
            obj[key].sort((a, b) => a.number - b.number)
            return obj
          },
          {} as typeof groupedChannels
        )
    ).flat()
  }, [channels])

  const table = useChannelTable({ channelsData, parentDevice: device })

  // useEffect(() => {
  //   if (channelIndex !== undefined) {
  //     const selectedChannel = tableRows[channelIndex].original
  //     setDevicesAndSensorsSelectedItemsData({
  //       ...devicesAndSensorsSelectedItemsData,
  //       device,
  //       channel: selectedChannel,
  //     })
  //   }
  //   else {
  //     setDevicesAndSensorsSelectedItemsData({
  //       ...devicesAndSensorsSelectedItemsData,
  //       channel: undefined,
  //     })
  //   }
  // }, [channelIndex])

  return <MaterialReactTable table={table} data-testid={dataTestId.channelsPane} />
}

export default ChannelsPane

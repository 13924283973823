import { StyledBarChartContainer, StyledBarChartWrapper } from '@/modules/dashboard/styled'
import { dataTestId } from '@/tests/testid'
import useComponentSize from '@rehooks/component-size'
import { ECElementEvent, ECharts, EChartsOption, SetOptionOpts, init } from 'echarts'
import { CSSProperties, FC, useEffect, useRef, type JSX } from 'react'

interface ReactEChartsProps {
  option: EChartsOption
  style?: CSSProperties
  settings?: SetOptionOpts
  loading?: boolean
  theme?: 'light' | 'dark'
  onClick?: (event: ECElementEvent) => void
  className?: string
}

export const ReactEChartsRenderer: FC<ReactEChartsProps> = ({
  option,
  loading,
  theme,
  onClick,
  className,
}: ReactEChartsProps): JSX.Element => {
  const instanceRef = useRef<ECharts>(undefined)
  const containerRef = useRef<HTMLDivElement>(null)
  const rulerRef = useRef<HTMLDivElement>(null)
  const componentSize = useComponentSize(rulerRef)

  useEffect(() => {
    let chartInstance: ECharts | undefined

    const initializeChart = () => {
      if (containerRef.current) {
        chartInstance = init(containerRef.current, theme, { renderer: 'svg' })
        instanceRef.current = chartInstance
      }
    }

    const resizeChart = () => {
      chartInstance?.resize()
    }

    initializeChart()
    window.addEventListener('resize', resizeChart)

    return () => {
      chartInstance?.dispose()
      window.removeEventListener('resize', resizeChart)
    }
  }, [theme])

  useEffect(() => {
    instanceRef.current?.resize({
      width: componentSize.width,
      height: componentSize.height,
    })
  }, [componentSize])

  useEffect(() => {
    instanceRef.current?.setOption(option)
  }, [option])

  useEffect(() => {
    const chart = instanceRef.current

    if (chart) {
      if (loading) {
        chart.showLoading()
      } else {
        chart.hideLoading()
      }
    }
  }, [loading])

  useEffect(() => {
    if (!onClick) {
      return
    }

    const chart = instanceRef.current
    if (chart === undefined) {
      return
    }

    chart.on('click', (params) => {
      onClick(params)
    })

    return () => {
      if (!chart.isDisposed()) {
        chart.off('click')
      }
    }
  }, [onClick])

  return (
    <StyledBarChartWrapper ref={rulerRef} className={className}>
      <StyledBarChartContainer ref={containerRef} data-testid={dataTestId.eChartsRenderer} />
    </StyledBarChartWrapper>
  )
}

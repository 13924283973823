import React from 'react'
import { createRoot } from 'react-dom/client'
import 'react-grid-layout/css/styles.css'
import { App } from './app'
import { store } from './store/store'
import { Provider } from 'react-redux'
import ErrorBoundary from '@/shared/components/errorBoundary'
import GenericError from '@/shared/components/genericError'
import { ENV } from '@/shared/constants'
import { initializeMsal } from '@/modules/azureAuth/initializeMsal'

const initApp = async () => {
  try {
    const msalInstance = await initializeMsal()
    const app = (
      <Provider store={store}>
        <ErrorBoundary fallbackComponent={<GenericError />}>
          <App authInstance={msalInstance} />
        </ErrorBoundary>
      </Provider>
    )

    const rootElement = document.getElementById('root') as HTMLElement
    const root = createRoot(rootElement)

    if (import.meta.env.MODE !== ENV.DEV) {
      root.render(<React.StrictMode>{app}</React.StrictMode>)
    } else {
      root.render(app)
    }
  } catch (error) {
    //TODO REDIRECT TO 500 PAGE
    console.error('Failed to initialize MSAL', error)
  }
}
initApp()

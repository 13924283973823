import { FC, memo, useCallback } from 'react'
import { StyledExpandButtonStyled } from '@/shared/components/newHierarchyTree/styled'
import { TreeNodeData } from '@/models/newHierarchyTree/types'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { dataTestId } from '@/tests/testid'

// Memoized component for expand/collapse button
const TreeNodeExpandButton: FC<{
  nodeData: TreeNodeData
  setExpanded: (expanded: boolean) => void
  expanded: boolean
}> = memo(({ nodeData, expanded, setExpanded }) => {
  // Memoized toggle handler
  const handleClick = useCallback(() => {
    setExpanded(!expanded)
  }, [expanded, setExpanded])

  return (
    <StyledExpandButtonStyled
      onClick={handleClick}
      aria-label={expanded ? `collapse ${nodeData.label}` : `expand ${nodeData.label}`}
      type="button"
      data-testid={dataTestId.hierarchyTreeNodeExpandButton}
    >
      {expanded ? (
        <RemoveCircleOutlineIcon data-testid={dataTestId.hierarchyTreeExpandedIcon} />
      ) : (
        <AddCircleOutlineIcon data-testid={dataTestId.hierarchyTreeCollapsedIcon} />
      )}
    </StyledExpandButtonStyled>
  )
})

TreeNodeExpandButton.displayName = 'TreeNodeExpandButton'

export default TreeNodeExpandButton

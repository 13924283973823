import { Button, Dialog, TextAreaField } from '@skf-design-system/ui-components-react'
import { dataTestId } from '@/tests/testid'
import { useState } from 'react'
import {
  StyledModalButtonGroup,
  StyledModalCommentContainer,
  StyledModalCommentText,
  StyledModalContainer,
  StyledModalEditContainer,
  StyledModalFooterContainer,
  StyledModalImage,
  StyledModalImageContainer,
  StyledModalTextFieldContainer,
} from '@/modules/reportStatus/styled'
import { useFaultsFormStateContext } from '@/contexts/moduleContexts/faultsForm'
import { UPDATE_EVIDENCE_IMAGE_DATA } from '@/modules/reportStatus/reducer/actions.types'
import { useReportStatusContext } from '@/contexts/reportStatus'
import useEscapeKey from '@/shared/hooks/useEscapeKey'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'

const EvidenceDialog = () => {
  const {
    faultEditModeData,
    evidenceDialogData,
    setIsEvidenceFaultImageModalOpen,
    isEvidenceFaultImageModalOpen,
    setEvidenceDialogData,
  } = useReportStatusContext()

  const { faultsFormDispatch } = useFaultsFormStateContext()

  const [editedFileComment, setEditedFileComment] = useState<string>('')

  const { imageInfo, appInfo } = evidenceDialogData

  useDeepCompareEffect(() => {
    if (imageInfo?.comment) {
      setEditedFileComment(imageInfo?.comment)
    }
    return () => {
      setEditedFileComment('')
    }
  }, [evidenceDialogData])

  const submitEditedFileComment = () => {
    const updatedEvidenceDialogData = {
      ...evidenceDialogData,
      imageInfo: {
        ...evidenceDialogData.imageInfo,
        comment: editedFileComment,
      },
    }
    setEvidenceDialogData(updatedEvidenceDialogData)

    faultsFormDispatch({
      type: UPDATE_EVIDENCE_IMAGE_DATA,
      payload: updatedEvidenceDialogData,
    })
    closeDialog()
  }

  const closeDialog = () => {
    setIsEvidenceFaultImageModalOpen(false)
  }

  useEscapeKey(() => !isEvidenceFaultImageModalOpen && closeDialog(), [closeDialog])

  return (
    <Dialog
      cssHeight="50rem"
      cssWidth="94rem"
      feNoPadding={true}
      feTitle={imageInfo?.fileName}
      open={isEvidenceFaultImageModalOpen}
      feOnClose={() => closeDialog()}
      data-testid={dataTestId.evidenceImagePopupModal}
    >
      <StyledModalContainer>
        <StyledModalImageContainer>
          <StyledModalImage
            src={imageInfo?.fileURL}
            alt={`Uploaded image ${imageInfo?.fileID}`}
            data-testid={dataTestId.evidenceModalImage}
          />
        </StyledModalImageContainer>

        <StyledModalFooterContainer>
          {faultEditModeData[appInfo?.nodeID] === true ? (
            <StyledModalEditContainer>
              <StyledModalTextFieldContainer>
                <TextAreaField
                  feLabel="Add comment"
                  feSize="sm"
                  value={editedFileComment}
                  placeholder="Type here"
                  data-testid={dataTestId.evidenceModalImageNameInput}
                  onChange={(e) => setEditedFileComment(e.target.value)}
                  maxLength={256}
                />
              </StyledModalTextFieldContainer>
              <StyledModalButtonGroup>
                <Button
                  feType="secondary"
                  feSize="sm"
                  onClick={closeDialog}
                  data-testid={dataTestId.evidenceModalImageNameCancelButton}
                >
                  Cancel
                </Button>
                <Button
                  feSize="sm"
                  onClick={submitEditedFileComment}
                  data-testid={dataTestId.evidenceModalImageNameSaveButton}
                >
                  Save
                </Button>
              </StyledModalButtonGroup>
            </StyledModalEditContainer>
          ) : (
            <StyledModalCommentContainer>
              {imageInfo?.comment && <StyledModalCommentText>Comments</StyledModalCommentText>}
              {imageInfo?.comment}
            </StyledModalCommentContainer>
          )}
        </StyledModalFooterContainer>
      </StyledModalContainer>
    </Dialog>
  )
}

export default EvidenceDialog

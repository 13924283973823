import { CustomersListState } from '@/store/customersList/types'
import { RootState } from '@/store/store'
import { createSelector } from '@reduxjs/toolkit'

const _selectCustomersList = (state: RootState) => state.customersList
const _selectCustomerBySid = (_: RootState, sid: string | undefined) => sid

const customerBySidSelector = createSelector(_selectCustomersList, _selectCustomerBySid, (customersList, sid) => {
  return sid && customersList.find((customer) => customer.id === sid)
})

const currentCustomerListSelector = createSelector(_selectCustomersList, (customersList) => {
  let customersListResult = [] as CustomersListState
  if (customersList) {
    customersListResult = [...customersList]
  }
  return customersListResult
})

export { customerBySidSelector, currentCustomerListSelector }

import { dataTestId } from '@/tests/testid'
import { StyledHistoryRowRightContainer } from '../../styled'
import { AssetHistorySnapshot } from '@/models/reportStatus/types'
import FaultHistory from '@/modules/reportStatus/components/history/FaultHistory'

interface FaultHistoryContainerProps {
  snapshot: AssetHistorySnapshot | AssetHistorySnapshot[]
  assetId: string
}

export const FaultHistoryContainer = ({ snapshot, assetId }: FaultHistoryContainerProps) => {
  return (
    <StyledHistoryRowRightContainer feGap="sm" feAlignItems="center" feFlexDirection="column">
      {!Array.isArray(snapshot) &&
        snapshot.faults.map((fault, i) => (
          <FaultHistory
            key={i}
            fault={fault}
            assetId={assetId}
            // reportTimestamp={snapshot.timestamp}
            // userName={snapshot.username}
            data-testid={dataTestId.faultHistoryObjectContainer}
          />
        ))}
      {Array.isArray(snapshot) &&
        snapshot.map((item) =>
          item.faults.map((fault, i: number) => (
            <FaultHistory
              key={i}
              fault={fault}
              assetId={assetId}
              // reportTimestamp={item.timestamp}
              // userName={item.username}
              data-testid={dataTestId.faultHistoryArrayContainer}
            />
          ))
        )}
    </StyledHistoryRowRightContainer>
  )
}

import AssetStatusCheckbox from '@/shared/components/tableComponents/genericTableFilter/filterHelpComponents/AssetStatusCheckbox'
import { FilterContentProps } from '@/shared/components/tableComponents/genericTableFilter/types'
import { ROUTES } from '@/shared/constants'
import { AssetStatus, AssetStatusString } from '@/shared/models/types'
import { dataTestId } from '@/tests/testid'
import { Flexbox } from '@skf-design-system/ui-components-react'
import { FC, useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router'

/**
 * A component for filtering assets based on their status.

 * @param {FilterContentProps<AssetStatus[]>} props - The component props.
 * @property {AssetStatus[]} [props.initialValue] - The initial selected asset statuses.
 * @property {(newFilterValue: AssetStatus[] | undefined) => void} props.onFilterValueChange - Callback function to handle changes in selected statuses.
 * @returns {JSX.Element} The rendered AssetStatusFilterContent component.
 */
const AssetStatusFilterContent: FC<FilterContentProps<AssetStatusString[]>> = ({
  initialValue,
  onFilterValueChange,
}) => {
  const { pathname } = useLocation()

  const statusesToRender = useMemo<string[]>(() => {
    let result = Object.keys(AssetStatus)
    if (pathname.includes(ROUTES.OPEN_RECOMMENDATIONS)) {
      const openRecommendationsStatuses = ['acceptable', 'unacceptable', 'unsatisfactory', 'severe']
      result = result.filter((status) => openRecommendationsStatuses.includes(status))
    }
    return result
  }, [pathname])

  const [filteredStatus, setFilteredStatus] = useState<AssetStatusString[]>(initialValue || [])

  const findStatusValue = useCallback(
    (value: AssetStatusString): boolean => filteredStatus.includes(value),
    [filteredStatus]
  )

  const handleOnChange = (status: AssetStatusString, checked: boolean) => {
    let newFilterValue: AssetStatusString[] | undefined = undefined

    if (checked) {
      newFilterValue = [...filteredStatus, status]
      setFilteredStatus(newFilterValue)
    } else {
      newFilterValue = filteredStatus.filter((v) => v !== status)
      setFilteredStatus(newFilterValue)
    }

    if (newFilterValue.length === 0) {
      newFilterValue = undefined
    }

    onFilterValueChange(newFilterValue)
  }

  return (
    <Flexbox
      feJustifyContent="center"
      feFlexDirection="column"
      feGap="md"
      data-testid={dataTestId.assetStatusFilterContent}
    >
      {statusesToRender.map((status) => (
        <AssetStatusCheckbox
          onChange={handleOnChange}
          checked={findStatusValue(status as AssetStatusString)}
          status={status as AssetStatusString}
          key={status}
        />
      ))}
    </Flexbox>
  )
}

export default AssetStatusFilterContent

import { RegisteredSeriesOption } from 'echarts'
import {
  ConditionStatusType,
  ConditionStatusWidgetDataType,
  Padding,
  Severity,
  StackBarDecalObjType,
} from '@/models/widgets/asset-health-condition/types'
import { COLORS, IconColor, IconName } from '@skf-design-system/ui-components-react'
import { machineHealthBarWidth, severities } from '@/modules/dashboard/utils/constants'
import { truncateChartAxisValue } from '..'
import { EChartsOption, BarSeriesOption } from 'echarts'
import { severityColor } from '@/shared/utils'

const severityToIconName = (severity: Severity): IconName => {
  switch (severity) {
    case 'normal':
      return 'checkCircle'
    case 'acceptable':
      return 'refresh'
    case 'unsatisfactory':
      return 'error'
    case 'unacceptable':
      return 'warning'
    case 'severe':
      return 'danger'
  }
}

const severityToIconColor = (severity: Severity): IconColor => {
  switch (severity) {
    case 'normal':
      return 'green'
    case 'acceptable':
      return 'green'
    case 'unsatisfactory':
      return 'yellow'
    case 'unacceptable':
      return 'orange'
    case 'severe':
      return 'red'
  }
}

const severityToSeries = (
  severity: Severity,
  data: number[] | StackBarDecalObjType[]
): RegisteredSeriesOption['bar'] => {
  const lastIndex = data.length - 1

  if (typeof data[lastIndex] === 'number' || typeof data[lastIndex] === 'string') {
    data[lastIndex] = {
      value: data[lastIndex] as number,
      itemStyle: {
        decal: {
          color: 'rgba(255, 255, 255, 0.8)',
          dashArrayX: [1, 0],
          dashArrayY: [2, 6],
          symbolSize: 1,
          rotation: Math.PI / 6,
        },
      },
    } as StackBarDecalObjType
  }

  return {
    name: severity,
    color: severityColor(severity),
    data: data,
    barWidth: machineHealthBarWidth,
    type: 'bar',
    stack: 'total',
    label: {
      show: false,
    },
  }
}

const roundToPercentageForConditionWidget = (dataObject: ConditionStatusType) => {
  if (Object.keys(dataObject).length === 0) {
    return {}
  }
  const total = Object.values(dataObject).reduce((acc, val) => acc + Number(val), 0)
  const percentages: ConditionStatusType = { ...dataObject }

  let sumRounded = 0
  const keys = Object.keys(dataObject)

  keys.forEach((key) => {
    const statusValue = Number(dataObject[key as keyof ConditionStatusType])
    let percentage = 0
    if (statusValue !== 0) {
      percentage = (statusValue / total) * 100
    }
    const roundedPercentage = Math.round(percentage * 10) / 10
    percentages[key] = roundedPercentage
    sumRounded += roundedPercentage
  })

  // Adjust to ensure total is 100%
  const diff = Math.round((100 - sumRounded) * 10) / 10
  const sortedKeys = keys.sort((a, b) => (percentages[b] % 1) - (percentages[a] % 1))

  if (diff !== 0 && diff !== 100) {
    percentages[sortedKeys[0]] += diff
  }

  return percentages
}

const useCalculateAHCYAxisMaxLabel = (slices: ConditionStatusWidgetDataType) => {
  const maxSum = Math.max(
    ...slices.map((slice) => Object.values(slice.data).reduce((acc, value) => acc + Number(value), 0))
  )
  const axisMaxValue = maxSum + Math.ceil(maxSum * 0.2)
  return axisMaxValue % 2 === 1 ? axisMaxValue + 1 : axisMaxValue
}

const generateOptions = (
  data: Record<Severity, number[]>,
  columns: string[],
  chartPadding: Padding,
  noDataMonthColumns: string[],
  noDataInAllMonth: boolean,
  yAxisMaxVal: number,
  showPercentages: boolean
): EChartsOption => {
  const baseSeries: BarSeriesOption[] = severities.map((severity) => severityToSeries(severity, data[severity]))

  const noDataSeries = noDataMonthColumns.map<BarSeriesOption>((noDataColumn) => ({
    name: 'noDataMonthColumn',
    type: 'bar',
    label: {
      show: false,
    },
    markArea: {
      data: [[{ coord: [noDataColumn] }, { coord: [noDataColumn] }]],
      itemStyle: {
        color: COLORS.gray050,
        opacity: 1,
      },
      emphasis: {
        disabled: true,
      },
    },
  }))

  const selectedColumnSeries: BarSeriesOption = {
    name: 'Asset Health Condition',
    type: 'bar',
    label: {
      show: false,
    },
    markArea: {
      data: noDataInAllMonth ? [[{ coord: [0] }, { coord: [12] }]] : [[{ coord: [11] }, { coord: [12] }]],
      itemStyle: {
        color: noDataInAllMonth ? COLORS.gray050 : '#bfdbfe99',
        opacity: 1,
      },
      emphasis: {
        disabled: true,
      },
    },
  }

  return {
    grid: {
      left: chartPadding.left,
      bottom: chartPadding.bottom,
      height: '76%',
      right: chartPadding.right,
      containLabel: false,
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        showMaxLabel: false,
        formatter: (value) => truncateChartAxisValue(value),
      },
      max: showPercentages ? 120 : yAxisMaxVal,
      splitLine: {
        show: true,
        lineStyle: {
          opacity: 0.6,
        },
      },
    },
    xAxis: {
      type: 'category',
      data: columns,
      axisLabel: {
        interval: 0,
        overflow: 'breakAll',
        rich: {
          year: {
            opacity: 0.9,
            lineHeight: 12,
            fontSize: 9,
          },
        },
      },
      axisTick: {
        length: 0,
      },
    },
    series: [selectedColumnSeries, ...noDataSeries, ...baseSeries],
  }
}

export {
  severityToIconName,
  severityToIconColor,
  severityToSeries,
  roundToPercentageForConditionWidget,
  useCalculateAHCYAxisMaxLabel,
  generateOptions,
}

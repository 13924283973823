import { StyledAllProcessedStateContainer } from '@/shared/components/tableComponents/styled'
import { dataTestId } from '@/tests/testid'
import { Text } from '@skf-design-system/ui-components-react'

const AllProcessedState = () => {
  return (
    <StyledAllProcessedStateContainer
      feFlexDirection="column"
      feAlignItems="center"
      feGap="xs"
      data-testid={dataTestId.allProcessedState}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="230" height="140" viewBox="0 0 230 140" fill="none">
        <g id="good">
          <g id="bg-1">
            <path
              id="Background1"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M40.2393 105.697C40.2393 111.692 43.0957 116.552 49.9057 116.552H56.2355C56.2355 116.552 50.811 117.156 50.811 124.151C50.811 131.147 56.2355 132 56.2355 132H117.17H178.093C178.093 132 183.518 132.065 183.518 124.151C183.518 117.113 179.228 116.584 178.278 116.552H203.406C207.6 116.552 211 113.151 211 105.697C211 98.2432 202.321 94.842 202.321 94.842C198.127 93.3867 197.068 89.9819 197.068 83.9867C197.068 77.9915 203.023 73.1315 207.217 73.1315H222.406C226.6 73.1315 230 68.2714 230 62.2762C230 56.281 226.6 51.421 222.406 51.421H198.538C202.732 51.421 206.132 46.5609 206.132 40.5657C206.132 34.5706 202.732 29.7105 198.538 29.7105H161.651C165.845 29.7105 169.245 24.8504 169.245 18.8552C169.245 12.8601 165.845 8 161.651 8H67.2642C63.0699 8 59.6698 12.8601 59.6698 18.8552C59.6698 24.8504 63.0699 29.7105 67.2642 29.7105H23.8679C19.6737 29.7105 15.1887 34.5706 15.1887 40.5657C15.1887 46.5609 19.6737 51.421 23.8679 51.421H29.2925C33.4867 51.421 36.8868 56.281 36.8868 62.2762C36.8868 68.2714 33.4867 73.1315 29.2925 73.1315H7.59434C3.4001 73.1315 0 77.9915 0 83.9867C0 89.9819 3.4001 94.842 7.59434 94.842H49.9057C45.7114 94.842 40.2393 99.702 40.2393 105.697ZM178.093 116.552C178.093 116.552 178.159 116.549 178.278 116.552H178.093ZM220.5 97C225.747 97 230 92.7467 230 87.5C230 82.2533 225.747 78 220.5 78C215.253 78 211 82.2533 211 87.5C211 92.7467 215.253 97 220.5 97Z"
              fill="#F1F5FC"
            />
          </g>
          <g id="Hand">
            <g id="Path 3">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M97.8804 66.5875C97.8804 66.5875 105.76 56.2485 112.217 52.136C118.674 48.0234 123.502 45.341 126.471 37.0333C129.44 28.7257 130.112 23.4123 130.112 21.3085C130.112 19.2047 133.614 16.3442 140.185 19.2047C146.757 22.0652 146.757 30.6054 145.733 38.2758C144.708 45.9461 144.708 53.8359 145.733 55.3613C146.757 56.8866 156.989 56.5533 162.335 56.5533C167.681 56.5533 171.004 64.1671 166.494 68.4592C161.984 72.7513 167.686 70.7055 167.686 77.4283C167.686 84.1512 162.335 88.4994 162.335 88.4994C162.335 88.4994 166.494 91.6989 167.044 97.2126C167.593 102.726 160.913 104.788 160.913 105.598C160.913 106.409 163.382 110.674 163.382 115.293C163.382 119.913 152.93 123.341 133.954 119.73C114.979 116.12 110.201 115.254 97.8804 111.655"
                fill="white"
              />
              <path
                d="M97.8804 66.5875C97.8804 66.5875 105.76 56.2485 112.217 52.136C118.674 48.0234 123.502 45.341 126.471 37.0333C129.44 28.7257 130.112 23.4123 130.112 21.3085C130.112 19.2047 133.614 16.3442 140.185 19.2047C146.757 22.0652 146.757 30.6054 145.733 38.2758C144.708 45.9461 144.708 53.8359 145.733 55.3613C146.757 56.8866 156.989 56.5533 162.335 56.5533C167.681 56.5533 171.004 64.1671 166.494 68.4592C161.984 72.7513 167.686 70.7055 167.686 77.4283C167.686 84.1512 162.335 88.4994 162.335 88.4994C162.335 88.4994 166.494 91.6989 167.044 97.2126C167.593 102.726 160.913 104.788 160.913 105.598C160.913 106.409 163.382 110.674 163.382 115.293C163.382 119.913 152.93 123.341 133.954 119.73C114.979 116.12 110.201 115.254 97.8804 111.655"
                stroke="#808895"
                stroke-width="2.5"
              />
            </g>
            <path
              id="Rectangle"
              d="M62.8804 58.7065H97.4185C97.7007 58.7065 97.8533 58.9163 97.8533 59.0826V116.809C97.8533 116.975 97.7007 117.185 97.4185 117.185H62.8804C62.5982 117.185 62.4456 116.975 62.4456 116.809V59.0826C62.4456 58.9163 62.5982 58.7065 62.8804 58.7065Z"
              fill="white"
              stroke="#808895"
              stroke-width="2.5"
            />
            <path
              id="Path 5"
              d="M146.757 55.9968C144.247 55.9968 141.388 56.072 138.542 55.9968C134.921 55.9011 131.274 55.8287 127.89 57.9493C121.848 61.7363 126.208 68.7649 129.44 70.1036C132.672 71.4422 153.523 73.8096 165.982 69.5689"
              stroke="#808895"
              stroke-width="2"
            />
            <path
              id="Path 8"
              d="M133.245 70.5045C133.245 70.5045 122.945 80.771 133.245 87.0284C135.666 88.4994 142.545 88.4994 145.426 88.4994C151.786 88.4994 154.428 88.4994 162.335 88.4994"
              stroke="#808895"
              stroke-width="2"
            />
            <path
              id="Path 8 Copy"
              d="M136.5 87.9538C136.5 87.9538 125.825 96.8906 135.112 103.148C137.295 104.619 143.497 105.605 146.096 105.605C151.83 105.605 154.212 105.605 161.341 105.605"
              stroke="#808895"
              stroke-width="2"
            />
            <path
              id="Path 9"
              d="M139.253 104.555C139.253 104.555 129.44 109.039 136.785 119.73"
              stroke="#808895"
              stroke-width="2"
            />
          </g>
          <g id="bling">
            <path
              id="Line"
              d="M175.5 14V20"
              stroke="#0F58D6"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Line_2"
              d="M175.5 28V34"
              stroke="#0F58D6"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Line_3"
              d="M165.5 24H171.5"
              stroke="#0F58D6"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Line_4"
              d="M179.5 24H185.5"
              stroke="#0F58D6"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <g id="bling_2">
            <path
              id="Line_5"
              d="M41.5 109.043V113.043"
              stroke="#0F58D6"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Line_6"
              d="M41.5 121.043V125.043"
              stroke="#0F58D6"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Line_7"
              d="M33.5 117.043H37.5"
              stroke="#0F58D6"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Line_8"
              d="M45.5 117.043H49.5"
              stroke="#0F58D6"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </g>
      </svg>
      <Text feFontWeight="bold">Great! No current assets to handle</Text>
    </StyledAllProcessedStateContainer>
  )
}
export default AllProcessedState

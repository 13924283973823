import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { LoadingType } from '@/shared/hooks/useApi'
import { headerActionPanelConfigSelector } from '@/store/selectors/moduleHeaderSelector'
import { useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { IconButton, Tooltip, useTooltip } from '@skf-design-system/ui-components-react'

/**
 * HeaderRefreshButton is a button component that triggers a data refresh operation
 * when clicked. It uses a tooltip to provide a visual indication of the action.
 *
 * The button displays a refresh icon and is disabled while data is being fetched,
 * unless the asynchronous indicator is shown.
 *
 * The button uses the `fetchDataFn` function from the context to perform the refresh action.
 *
 * @returns {JSX.Element} A button that refreshes the data when clicked.
 */

const HeaderRefreshButton = () => {
  const [refreshHoveredRef, isRefreshHovered] = useTooltip()
  const { fetchDataStatus, fetchDataFn } = useFetchDataContext()

  const { isAsyncIndicatorShown } = useTypedSelector((store) => headerActionPanelConfigSelector(store))

  return (
    <>
      <Tooltip isHovered={isRefreshHovered} triggerElement={refreshHoveredRef.current}>
        Refresh
      </Tooltip>
      <IconButton
        disabled={fetchDataStatus === 'loading' && !isAsyncIndicatorShown}
        feIcon="refresh"
        as="button"
        aria-label="Refresh"
        feColor="brand"
        onClick={() => {
          fetchDataFn({ loaderType: LoadingType.HEADER })
        }}
        ref={refreshHoveredRef}
        data-testid={dataTestId.headerRefreshButton}
      />
    </>
  )
}
export default HeaderRefreshButton

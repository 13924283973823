import { deviceNumberFormatter } from '@/modules/devicesSensors/utils/constants'
import useGetCharactersLength from '@/modules/devicesSensors/hooks/useGetCharactersLength'
import TextEllipsis, { TextEllipsisType } from '@/shared/components/textEllipsis'
import { dataTestId } from '@/tests/testid'
import { Flexbox } from '@skf-design-system/ui-components-react'
import { FC } from 'react'

interface ChannelNameCellProps {
  sensorNumber: number
  sensorName: string
}

const ChannelNameCell: FC<ChannelNameCellProps> = ({ sensorNumber, sensorName }) => {
  const characterLengths = useGetCharactersLength()
  return (
    <Flexbox feGap="sm" data-testid={dataTestId.channelNameCell}>
      <TextEllipsis
        type={TextEllipsisType.SingleLine}
        characterLength={characterLengths.number}
        value={deviceNumberFormatter.format(sensorNumber)}
      />
      <TextEllipsis type={TextEllipsisType.SingleLine} characterLength={characterLengths.name} value={sensorName} />
    </Flexbox>
  )
}

export default ChannelNameCell

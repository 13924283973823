import { COLORS, Flexbox, Icon, styled } from '@skf-design-system/ui-components-react'
export const StyledModuleHeader = styled.div`
  margin-left: 1.625rem;
  margin-top: 3.125rem;
  font-size: 3rem;
`
export const StyledModuleContainer = styled.div`
  background-color: ${COLORS.white};
  margin: 2rem 1.625rem 0;
  padding: 0 2rem;
  overflow: auto;
  position: relative;
  max-height: calc(100vh - 15rem);
`

export const StyledEChartContainer = styled(Flexbox)`
  height: 610px;
  width: 1070px;
  flex-direction: column;
  min-width: 0;
`
export const StyledAutomaticsDiagnosticsRightPanel = styled(Flexbox)`
  margin-left: 2rem;
`
export const StyledAutomaticsDiagnosticsSpan = styled.span`
  margin-bottom: 3rem;
`
export const StyledAutomaticsDiagnosticsNoWrapSpan = styled(StyledAutomaticsDiagnosticsSpan)`
  white-space: nowrap;
`

export const StyledFooterSpan = styled.span`
  margin-left: 2rem;
`
export const StyledBoldFooterSpan = styled(StyledFooterSpan)`
  font-weight: bolder;
`
export const StyledTopFooterSpan = styled(StyledBoldFooterSpan)`
  margin-top: 3.125rem;
`
export const StyledBottomFooterSpan = styled(StyledFooterSpan)`
  margin-top: 0.75rem;
`
export const StyledAutomaticDiagnosticsAverageBox = styled(Flexbox)`
  background-color: ${COLORS.gray050};
  margin: 3rem 0 4.2rem;
  padding: 1.8rem 6rem;
  max-width: 21rem;
  height: fit-content;
  border-radius: 0.5rem;
  align-items: center;
  flex-direction: column;

  > div:first-of-type {
    white-space: nowrap;
  }

  @media (width >= 1600px) and (width <= 1800px) {
    padding: 1.5rem;
    margin: 3.2rem 0 5rem;
  }

  @media (width < 1600px) {
    max-width: 15.5rem;
    margin: 2.75rem 0 4.5rem;
    padding: 1.5rem;
  }

  @media (width <= 1400px) {
    max-width: 15.5rem;
    margin: 2.75rem 0 4.25rem;
    padding: 1.5rem 0;
  }
`
export const StyledAverageNumber = styled(Flexbox)`
  font-size: 2.875rem;
  align-items: center;
`

export const StyledAverageLabel = styled.div`
  margin-top: 2.5rem;
  text-align: center;
  min-width: 240px;

  @media (width < 1700px) {
    margin-top: 2rem;
    min-width: 0;
  }
`
export const StyledAverageMonthlyTrendDirectionIcon = styled(Icon)`
  margin-right: 1rem;
`

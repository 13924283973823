import { useActionMenuContext } from '@/contexts/moduleContexts/actionMenu'
import useMarkReportAsNormal from '@/modules/analysisBacklog/hooks/useMarkReportAsNormal'
import { AnalysisBacklogMenuProps } from '@/modules/analysisBacklog/components/cell/actionMenu/types'
import { StyledActionMenuLink } from '@/modules/analysisBacklog/styled'
import GenericConfirmationDialogButton from '@/shared/components/genericConfirmationDialog/genericConfirmationDialogButton'
import { ROUTES } from '@/shared/constants'
import { STORAGE_REPORT_STATUS_ORIGIN } from '@/shared/localStorageUtils'
import { closeGenericConfirmDialog, showGenericConfirmDialog } from '@/store/genericConfirmationDialog/actions'
import { pushRedirectData } from '@/store/redirectData/actions'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import useScrollDetector from '@/shared/hooks/useScrollDetection'
import { headerActionPanelConfigSelector } from '@/store/selectors/moduleHeaderSelector'

const AnalysisBacklogPopoverContent: FC<AnalysisBacklogMenuProps> = ({
  elements,
  assetRow,
  table,
  currentSelectedRowIndex,
}) => {
  const { id, belongsTo, name } = assetRow

  const navigate = useNavigate()
  const { sid } = useParams()

  const { setIsOpen } = useActionMenuContext()

  const actionsMenuDispatch = useAppDispatch()

  const closeDialog = () => {
    actionsMenuDispatch(closeGenericConfirmDialog())
  }

  const { execute } = useMarkReportAsNormal(id, sid as string)

  const { tableStateChangeSwitchCurrentState } = useTypedSelector((state) => headerActionPanelConfigSelector(state))

  const { position, isScrolling } = useScrollDetector(table.refs.tableContainerRef.current ?? undefined)
  const { columnFilters, sorting, pagination } = table.getState()

  useEffect(() => {
    if (!isScrolling) return
    setIsOpen(false)
  }, [isScrolling, setIsOpen])

  const handleClick = () => {
    sessionStorage.setItem(STORAGE_REPORT_STATUS_ORIGIN, `${ROUTES.ANALYSIS_BACKLOG}/${sid}`)
    actionsMenuDispatch(
      pushRedirectData({
        sid: sid as string,
        toModule: ROUTES.REPORT_STATUS,
        fromModule: ROUTES.ANALYSIS_BACKLOG,
        columnFilters,
        sorting,
        tablePaginationState: pagination,
        tableScrollOffset: position,
        tableSelectedRowIndex: currentSelectedRowIndex,
        tableSwitchCurrentState: tableStateChangeSwitchCurrentState,
        assetId: id,
      })
    )
    setIsOpen(false)
    navigate(`${ROUTES.REPORT_STATUS}/${id}`)
  }

  const handleConfirmClick = () => {
    actionsMenuDispatch(
      showGenericConfirmDialog({
        isOpen: true,
        title: 'Mark Asset as Normal',
        message: `Mark asset ${name} from ${belongsTo.name} as Normal status?`,
        buttons: [
          <GenericConfirmationDialogButton
            data-testid={dataTestId.analysisBacklogPopoverCancelButton}
            type="secondary"
            label="Cancel"
            onClick={() => closeDialog()}
          />,
          <GenericConfirmationDialogButton
            type="primary"
            label="Confirm"
            data-testid={dataTestId.analysisBacklogPopoverConfirmButton}
            onClick={() => {
              actionsMenuDispatch(
                pushRedirectData({
                  sid: sid as string,
                  tableScrollOffset: position,
                  tableSelectedRowIndex: currentSelectedRowIndex,
                  tablePaginationState: pagination,
                  tableSwitchCurrentState: tableStateChangeSwitchCurrentState,
                  assetId: id,
                })
              )
              execute()
              closeDialog()
            }}
          />,
        ],
      })
    )
  }

  return (
    <div>
      {elements.includes('mark-as-normal') && (
        <StyledActionMenuLink
          as="button"
          type="button"
          feIcon={{ feIcon: 'checkCircle', position: 'left' }}
          feStretch={true}
          data-testid={dataTestId.analysisBacklogActionMenuMarkAsNormal}
          onClick={() => {
            handleConfirmClick()
            setIsOpen(false)
          }}
        >
          Mark as Normal
        </StyledActionMenuLink>
      )}
      {elements.includes('report-status') && (
        <StyledActionMenuLink
          as="button"
          type="button"
          feIcon={{ feIcon: 'recAction', position: 'left' }}
          feStretch={true}
          data-testid={dataTestId.analysisBacklogActionReportStatus}
          onClick={handleClick}
        >
          Report status
        </StyledActionMenuLink>
      )}
    </div>
  )
}

export default AnalysisBacklogPopoverContent

import { Customer } from '@/models/customer/types'
import { ROUTES } from '@/shared/constants'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { getRouteFromPathname } from '@/shared/utils'
import { setCustomersList } from '@/store/customersList/actions'
import { setSelectedCustomer } from '@/store/selectedCustomer/actions'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { useIsAuthenticated } from '@azure/msal-react'
import { PropsWithChildren } from 'react'
import { useLoaderData, useLocation, useParams } from 'react-router'
import { DarwinDetachedModuleError } from '../errorBoundary/darwinError'
import { ApplicationInitialData } from '@/app/routes/types'
import { setReportFaultTypes } from '@/store/reportFaultTypes/actions'
import { customerBySidSelector } from '@/store/selectors/customersListSelector'
import { batchActions } from '@/store/batch/actions'
import { setSitesPermissions } from '@/store/sitesPermissions/actions'

/**
 * AppDataInitializer component initializes the application data related to customers
 * and manages the selected customer based on the loaded customer list and authentication state.
 *
 * @component
 * @param {PropsWithChildren} props - The props for the component.
 * @param {ReactNode} props.children - The child components to be rendered within the initializer.
 *
 * @throws {DarwinDetachedModuleError} Throws an error if the selected customer ID is not found
 * in the customer list, and the current route is not one of the specified routes (OVERVIEW, ERROR, HOME, REPORT_STATUS).
 *
 * @example
 * <AppDataInitializer>
 *   <YourChildComponent />
 * </AppDataInitializer>
 */

const AppDataInitializer = ({ children }: PropsWithChildren) => {
  const {
    customersList: loadedCustomersList,
    reportFaultTypes,
    sitesPermissions,
  } = useLoaderData() as ApplicationInitialData
  const appDispatch = useAppDispatch()
  const isAuthenticated = useIsAuthenticated()
  const { sid } = useParams()
  const { pathname } = useLocation()
  const customerBySid = useTypedSelector((state) => customerBySidSelector(state, sid))

  useDeepCompareEffect(() => {
    customerBySid && appDispatch(setSelectedCustomer(customerBySid))
  }, [customerBySid])

  const initCustomerReducers = (listOfCustomers: Customer[]) => {
    const customerListWithOverview = [
      {
        id: 'overview',
        name: 'All sites overview',
        // customerNotesEnabled: false,
      },
      ...listOfCustomers,
    ]

    appDispatch(
      batchActions([
        setCustomersList(customerListWithOverview),
        setReportFaultTypes(reportFaultTypes),
        setSitesPermissions(sitesPermissions),
      ])
    )

    const customer = listOfCustomers.find((customer) => customer.id === sid)
    const routeName = getRouteFromPathname(pathname)

    if (customer) {
      appDispatch(setSelectedCustomer(customer))
    } else if (![ROUTES.OVERVIEW, ROUTES.ERROR, ROUTES.HOME, ROUTES.REPORT_STATUS].includes(routeName as ROUTES)) {
      throw new DarwinDetachedModuleError({
        name: '',
        status: 404,
        isAxiosError: false,
        /* v8 ignore next 1 */
        toJSON: () => ({}),
        message: 'Company Id not found',
      })
    }
  }

  useDeepCompareEffect(() => {
    if (isAuthenticated) {
      initCustomerReducers(loadedCustomersList)
    }
  }, [loadedCustomersList, isAuthenticated])
  return <>{children}</>
}

export default AppDataInitializer

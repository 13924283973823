import TextEllipsis, { TextEllipsisType } from '@/shared/components/textEllipsis'
import { FC } from 'react'
import useGetCharactersLength from '@/modules/devicesSensors/hooks/useGetCharactersLength'
import { UniqueChannelsAssets } from '@/models/devicesAndSensors/types'

interface DeviceConnectedAssetsCell {
  connectedAssets: UniqueChannelsAssets[]
}

const DeviceConnectedAssetsCell: FC<DeviceConnectedAssetsCell> = ({ connectedAssets }) => {
  const characterLengths = useGetCharactersLength()
  if (connectedAssets.length === 0) {
    return <TextEllipsis type={TextEllipsisType.SingleLine} characterLength={characterLengths.assetName} value="-" />
  }

  if (connectedAssets.length > 1) {
    return (
      <TextEllipsis
        type={TextEllipsisType.SingleLine}
        characterLength={characterLengths.assetName}
        value={`${connectedAssets.length} assets connected`}
      />
    )
  }

  return (
    <>
      {connectedAssets.map((asset) => (
        <TextEllipsis
          key={asset.ID}
          type={TextEllipsisType.SingleLine}
          characterLength={characterLengths.assetName}
          value={asset.name}
        />
      ))}
    </>
  )
}

export default DeviceConnectedAssetsCell

import { AssetHistorySnapshot } from '@/models/reportStatus/types'
import HistoryCollapse from '@/modules/reportStatus/components/history/common/HistoryCollapse'
import HistoryRow from '@/modules/reportStatus/components/history/common/HistoryRow'
import HistoryLinePrefix from '@/modules/reportStatus/components/history/HistoryLinePrefix'
import { StyledReconfirmRowInnerContainerChildren } from '@/modules/reportStatus/styled'
import { compareDates } from '@/shared/dateUtils'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { dataTestId } from '@/tests/testid'
import { Flexbox, IconButton, SPACINGS } from '@skf-design-system/ui-components-react'
import { FC, useMemo, useState } from 'react'

interface HistoryReconfirmSectionProps {
  reconfirms: AssetHistorySnapshot['reconfirms']
}

const HistoryReconfirmSection: FC<HistoryReconfirmSectionProps> = ({ reconfirms }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const lastReconfirm = useMemo(() => {
    return reconfirms.reduce((latest, current) => {
      return compareDates(current.time, latest.time) < 0 ? latest : current
    })
  }, [reconfirms])

  const historyRows = useDeepCompareMemo(() => {
    return reconfirms
      .filter((v) => v != lastReconfirm)
      .sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime())
      .map((item, i) => <HistoryRow timestamp={item.time} username={item.userName} key={`${i}-reconfirm-row`} />)
  }, [lastReconfirm, reconfirms])

  return (
    <StyledReconfirmRowInnerContainerChildren
      feJustifyContent="space-start"
      feGap="xxl"
      data-testid={dataTestId.faultHistoryReconfirmSection}
    >
      <HistoryLinePrefix marginTop={SPACINGS.xxs} />
      <HistoryCollapse
        historyRows={historyRows}
        dataLength={reconfirms.length}
        lastReconfirm={lastReconfirm}
        label="reconfirms"
        open={isOpen}
        icon="history"
        data-testid={dataTestId.faultHistoryReconfirmSectionCollapse}
      />
      <Flexbox>
        {reconfirms.length > 1 && (
          <IconButton
            as="button"
            type="button"
            feSize="sm"
            aria-label={isOpen ? 'Open' : 'Close'}
            onClick={() => setIsOpen((lastState) => !lastState)}
            feIcon={isOpen ? 'chevronUp' : 'chevronDown'}
            data-testid={dataTestId.faultHistoryReconfirmSectionCollapseButton}
          />
        )}
      </Flexbox>
    </StyledReconfirmRowInnerContainerChildren>
  )
}

export default HistoryReconfirmSection

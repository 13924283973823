import { FC, useEffect } from 'react'
import TreeNode from '@/shared/components/newHierarchyTree/components/treeNode'
import { TreeNodeData } from '@/models/newHierarchyTree/types'
import useTreeSelector from '@/shared/components/newHierarchyTree/hooks/useTreeSelector'
import { StyledHierarchyTreeContainer } from '@/shared/components/newHierarchyTree/styled'
import { HierarchyTreeContextProvider } from '@/contexts/moduleContexts/hierarchyTree'
import { dataTestId } from '@/tests/testid'

const NewHierarchyTree: FC<{
  className?: string
  nodeData: TreeNodeData
  value: string[]
  onChange: (value: string[]) => void
}> = ({ className, nodeData, value, onChange }) => {
  const treeSelector = useTreeSelector()

  useEffect(() => {
    treeSelector.load(nodeData)
    treeSelector.deselect(nodeData.id)
  }, [nodeData, treeSelector])

  useEffect(() => {
    treeSelector.deselect(nodeData.id)
    value.forEach((nodeId) => {
      treeSelector.select(nodeId)
    })
  }, [value, nodeData.id, treeSelector])

  return (
    <HierarchyTreeContextProvider treeSelector={treeSelector} onChange={onChange}>
      <StyledHierarchyTreeContainer
        className={className}
        role="tree"
        aria-multiselectable
        data-testid={dataTestId.hierarchyTreeNodeContainer}
      >
        <TreeNode
          nodeData={nodeData}
          isRootNode
          isLastChild={nodeData.children.length === 0}
          ariaLevel={1}
          ariaPosinset={1}
          ariaSetsize={1}
          data-testid={dataTestId.hierarchyTreeNode}
        />
      </StyledHierarchyTreeContainer>
    </HierarchyTreeContextProvider>
  )
}

export default NewHierarchyTree

import { Select, SelectItem } from '@skf-design-system/ui-components-react'
import { FC, useCallback, useMemo } from 'react'
import { RecommendedActionStatusSelectType, SelectFilterType } from '@/models/dashboard/globalFiltering/types'
import { dataTestId } from '@/tests/testid'
import { RecommendedActionStatus, RecommendedActionStatusString } from '@/shared/models/types'
import { StyledRASelectContainer } from '@/modules/dashboard/styled'
interface IRecommendedActionSelect {
  className?: string
  value: RecommendedActionStatusSelectType[]
  onChange: (value: RecommendedActionStatusSelectType[]) => void
  allValues: RecommendedActionStatusString[]
}
const RecommendedActionSelect: FC<IRecommendedActionSelect> = ({ className, value, onChange, allValues }) => {
  const options = useMemo<SelectItem<RecommendedActionStatusSelectType>[]>(
    () => [
      { label: 'All', value: 'all' as RecommendedActionStatusSelectType },
      ...allValues.map((status) => ({
        label: RecommendedActionStatus[status as keyof typeof RecommendedActionStatus] || status,
        value: status as RecommendedActionStatusSelectType,
      })),
    ],
    [allValues]
  )

  const handleOnChange = useCallback(
    (currentValue: SelectFilterType['raStatus']) => {
      if (value.length === allValues.length && currentValue.length === allValues.length - 1) {
        const unselectedValue = allValues.find(
          (val) => !currentValue.includes(val as RecommendedActionStatusSelectType)
        )
        onChange([unselectedValue] as SelectFilterType['raStatus'])
      } else {
        onChange(currentValue)
      }
    },
    [value, allValues, onChange]
  )

  return (
    <StyledRASelectContainer>
      <Select<RecommendedActionStatusSelectType>
        data-testid={dataTestId.globalFilteringRecommendedActionSelect}
        feSize="sm"
        className={className}
        value={value}
        onChange={handleOnChange}
        feItems={options}
        feLabel="Recommended action status"
        feMultiSelectionText={allValues.length === value.length ? 'All' : `${value.length} Selected`}
        feHideSelectionTags
        multiple
      />
    </StyledRASelectContainer>
  )
}
export default RecommendedActionSelect

import { FC } from 'react'
import { AssetStatusString } from '@/shared/models/types'
import { getAssetStatusType as getStatusType, mapIconPropsToAssetStatus } from '@/shared/utils'
import { Icon, Tooltip, useTooltip } from '@skf-design-system/ui-components-react'
import { dataTestId } from '@/tests/testid'
import { StyledAssetStatusIconContainer } from '@/shared/components/assetStatusIcon/styled'

interface IAssetStatusIconProps {
  value: AssetStatusString
  className?: string
}

/**
 * Displays an icon representing the asset's status, with a tooltip providing additional information.
 *
 * @component
 * @param {IAssetStatusIconProps} props - The component props.
 * @param {AssetStatusString} props.value - The status value of the asset.
 * @param {string} [props.className] - Optional additional CSS class for custom styling.
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <AssetStatusIcon value="Operational" />
 */

const AssetStatusIcon: FC<IAssetStatusIconProps> = ({ value }) => {
  const [hoverRef, isHovered] = useTooltip()
  return (
    <StyledAssetStatusIconContainer data-testid={dataTestId.assetStatusIcon} ref={hoverRef}>
      <Icon {...mapIconPropsToAssetStatus(value)} feSize="sm" />
      <Tooltip isHovered={isHovered} triggerElement={hoverRef.current}>
        {getStatusType(value)}
      </Tooltip>
    </StyledAssetStatusIconContainer>
  )
}

export default AssetStatusIcon

import useContextWrapper from '@/shared/hooks/useContextWrapper'
import { createContext, useMemo, useState } from 'react'
import { SiteDetailsData } from '@/models/siteDetails/types'

interface ISiteDetailsContext {
  siteDetailsData: SiteDetailsData
  setSiteDetailsData: React.Dispatch<React.SetStateAction<ISiteDetailsContext['siteDetailsData']>>
  isSiteDetailsEdited: boolean
  setIsSiteDetailsEdited: React.Dispatch<React.SetStateAction<ISiteDetailsContext['isSiteDetailsEdited']>>
  siteDetailsEditedFormData: SiteDetailsData
  setSiteDetailsEditedFormData: React.Dispatch<React.SetStateAction<ISiteDetailsContext['siteDetailsEditedFormData']>>
  siteDetailsError: Record<string, string | null> & {
    siteDetailsLogoUploadError: string | null
  }
  setSiteDetailsError: React.Dispatch<React.SetStateAction<ISiteDetailsContext['siteDetailsError']>>
}
export const SiteDetailsContext = createContext<ISiteDetailsContext | null>(null)

export const useSiteDetailsContext = () =>
  useContextWrapper(SiteDetailsContext, {
    contextName: useSiteDetailsContext.name,
    providerName: SiteDetailsProvider.name,
  })

export const SiteDetailsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [siteDetailsData, setSiteDetailsData] = useState<ISiteDetailsContext['siteDetailsData']>({
    siteId: '',
    companyName: '',
    siteName: '',
    siteAddress: '',
    databases: {
      analyst: null,
      observer: null,
      axios: null,
    },
    logo: null,
  })
  const [siteDetailsEditedFormData, setSiteDetailsEditedFormData] = useState<ISiteDetailsContext['siteDetailsData']>({
    siteId: '',
    companyName: '',
    siteName: '',
    siteAddress: '',
    databases: {
      analyst: null,
      observer: null,
      axios: null,
    },
    logo: null,
  })
  const [isSiteDetailsEdited, setIsSiteDetailsEdited] = useState<ISiteDetailsContext['isSiteDetailsEdited']>(false)
  const [siteDetailsError, setSiteDetailsError] = useState<ISiteDetailsContext['siteDetailsError']>({
    siteDetailsLogoUploadError: null,
  })
  const value = useMemo(
    () => ({
      siteDetailsData,
      setSiteDetailsData,
      siteDetailsEditedFormData,
      setSiteDetailsEditedFormData,
      isSiteDetailsEdited,
      setIsSiteDetailsEdited,
      siteDetailsError,
      setSiteDetailsError,
    }),
    [siteDetailsData, isSiteDetailsEdited, siteDetailsEditedFormData, siteDetailsError]
  )

  return <SiteDetailsContext value={value}>{children}</SiteDetailsContext>
}

import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { OverviewContextProvider } from '@/contexts/overview'
import { OverviewResponse } from '@/models/overview/types'
import OverviewHeader from '@/modules/overview/components/header'
import useGetOverviewData from '@/modules/overview/hooks/useGetOverviewData'
import { StyledOverviewContainer, StyledViewFlexbox } from '@/modules/overview/styled'
import useOverviewTable from '@/modules/overview/table/useOverviewTable'
import DataRefresher from '@/shared/components/dataRefresher'
import ThemeConsumer from '@/shared/components/themeConsumer'
import { LoadingType } from '@/shared/hooks/useApi'
import useCleanupStorage from '@/shared/hooks/useCleanupStorage'
import { useEffectOnce } from '@/shared/hooks/useEffectOnce'
import { dataTestId } from '@/tests/testid'
import { MaterialReactTable } from 'material-react-table'
import { FC, useEffect } from 'react'

const Overview: FC = () => {
  return (
    <OverviewContextProvider>
      <StyledOverviewContainer>
        <InnerOverview />
      </StyledOverviewContainer>
    </OverviewContextProvider>
  )
}

const InnerOverview = () => {
  const { execute: getOverviewData, status: getOverviewDataStatus } = useGetOverviewData()

  useEffectOnce(() => {
    getOverviewData({ loaderType: LoadingType.NONE })
  })

  const { setFetchDataFn, setFetchDataStatus } = useFetchDataContext()

  useEffect(() => {
    setFetchDataFn(() => getOverviewData)
  }, [getOverviewData, setFetchDataFn])

  useEffect(() => {
    setFetchDataStatus(getOverviewDataStatus)
  }, [getOverviewDataStatus, setFetchDataStatus])

  useCleanupStorage()

  const table = useOverviewTable()

  return (
    <StyledViewFlexbox feGap="lg" data-testid={dataTestId.overviewModule}>
      <OverviewHeader />
      <ThemeConsumer>
        <MaterialReactTable table={table} />
      </ThemeConsumer>
      <DataRefresher<OverviewResponse> functionToExecute={getOverviewData} />
    </StyledViewFlexbox>
  )
}

export default Overview

import { Site } from '@/models/overview/types'
import OverviewCell from '@/modules/overview/components/overviewCell'
import { overviewCellTooltips } from '@/modules/overview/utils/constants'
import { ROUTES } from '@/shared/constants'
import { MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'

const useOverdueRecommendationData = () => {
  const overdueRecommendation = useMemo<MRT_ColumnDef<Site>[]>(
    () => [
      {
        accessorKey: 'openRecommendations.overdue',
        id: 'overdueRecommendationsData',
        header: '',
        size: 80,
        Cell: ({ cell, row }) => (
          <OverviewCell
            siteId={row.original.siteId}
            value={cell.getValue<string>()}
            routeTo={ROUTES.OPEN_RECOMMENDATIONS}
            message={overviewCellTooltips[cell.column.columnDef.id as keyof typeof overviewCellTooltips]}
          />
        ),
      },
    ],
    []
  )
  return overdueRecommendation
}

export default useOverdueRecommendationData

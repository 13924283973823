import { FC } from 'react'
import {
  StyledCustomerLogoCardContainer,
  StyledCustomerLogoCardFooter,
  StyledCustomerLogoComponent,
  StyledLabel,
  StyledCustomerLogoComponentContainer,
} from '@/modules/siteDetails/styled'
import placeholderLogoSVG from '@/modules/siteDetails/assets/attachment_failed_4x.webp'
import { COLORS, IconButton } from '@skf-design-system/ui-components-react'
import { dataTestId } from '@/tests/testid'
import { useSiteDetailsContext } from '@/contexts/siteDetails'
import { SiteDetailsData } from '@/models/siteDetails/types'
import { useAppDispatch } from '@/store/store'
import { closeGenericConfirmDialog, showGenericConfirmDialog } from '@/store/genericConfirmationDialog/actions'
import GenericConfirmationDialogButton from '@/shared/components/genericConfirmationDialog/genericConfirmationDialogButton'
import TextEllipsis, { TextEllipsisType } from '@/shared/components/textEllipsis'

interface CustomerLogoCardProps {
  showAction?: boolean
}

const CustomerLogoCard: FC<CustomerLogoCardProps> = ({ showAction = false }) => {
  const {
    siteDetailsData,
    setIsSiteDetailsEdited,
    setSiteDetailsEditedFormData,
    siteDetailsEditedFormData,
    isSiteDetailsEdited,
  } = useSiteDetailsContext()
  const siteLogoCardDispatch = useAppDispatch()

  const closeDialog = () => {
    siteLogoCardDispatch(closeGenericConfirmDialog())
  }

  const deleteLogo = () => {
    setIsSiteDetailsEdited(true)
    setSiteDetailsEditedFormData({
      ...siteDetailsEditedFormData,
      logo: {
        ...siteDetailsEditedFormData?.logo,
        fileURL: '',
        fileId: '',
        fileName: '',
      },
    } as SiteDetailsData)
  }

  const handleDeleteClick = () => {
    siteLogoCardDispatch(
      showGenericConfirmDialog({
        isOpen: true,
        title: 'Delete Site Logo',
        message: `Are you sure you want to delete site logo?`,
        buttons: [
          <GenericConfirmationDialogButton
            data-testid={dataTestId.siteDetailsCustomerLogoConfirmCardCancelButton}
            type="secondary"
            label="Cancel"
            onClick={() => closeDialog()}
          />,
          <GenericConfirmationDialogButton
            data-testid={dataTestId.siteDetailsCustomerLogoCardConfirmDeleteButton}
            type="primary"
            label="Confirm"
            onClick={() => {
              deleteLogo()
              closeDialog()
            }}
          />,
        ],
      })
    )
  }

  return (
    <StyledCustomerLogoCardContainer data-testid={dataTestId.siteDetailsCustomerLogoCard} feGap="sm">
      {showAction && <StyledLabel>Company logo</StyledLabel>}
      <StyledCustomerLogoComponentContainer backgroundColor={COLORS.gray050}>
        <StyledCustomerLogoComponent
          data-testid={dataTestId.siteDetailsCustomerLogoCardImage}
          src={
            isSiteDetailsEdited
              ? siteDetailsEditedFormData?.logo?.fileURL || placeholderLogoSVG
              : siteDetailsData?.logo?.fileURL || placeholderLogoSVG
          }
        />
      </StyledCustomerLogoComponentContainer>
      {showAction && (
        <StyledCustomerLogoCardFooter>
          <TextEllipsis
            type={TextEllipsisType.SingleLine}
            characterLength={20}
            fontSizeInRem={'0.875rem'}
            value={isSiteDetailsEdited ? siteDetailsEditedFormData?.logo?.fileName : siteDetailsData?.logo?.fileName}
            data-testid={dataTestId.siteDetailsCustomerLogoCardFileName}
          />
          <IconButton
            feIcon="deleteOutline"
            as="button"
            name="delete-logo-button"
            aria-label="delete-logo-button"
            onClick={handleDeleteClick}
            data-testid={dataTestId.siteDetailsCustomerLogoCardDeleteButton}
          />
        </StyledCustomerLogoCardFooter>
      )}
    </StyledCustomerLogoCardContainer>
  )
}

export default CustomerLogoCard

import { FC, useState, useCallback, memo } from 'react'
import useTreeController from '@/shared/components/newHierarchyTree/hooks/useTreeController'
import TreeNodeContent from '@/shared/components/newHierarchyTree/components/treeNodeContent'
import TreeNodeExpandButton from '@/shared/components/newHierarchyTree/components/treeNodeExpandButton'
import TreeBranchIcon from '@/shared/components/newHierarchyTree/assets/tree-branch.svg'
import TreeLastBranchIcon from '@/shared/components/newHierarchyTree/assets/tree-last.svg'
import {
  StyledTreeNodeBranch,
  StyledTreeNodeContent,
  StyledTreeNodeList,
} from '@/shared/components/newHierarchyTree/styled'
import { TreeNodeData } from '@/models/newHierarchyTree/types'
import { dataTestId } from '@/tests/testid'

// Memoized tree node component for better performance
const TreeNode: FC<{
  nodeData: TreeNodeData
  isRootNode: boolean
  isLastChild: boolean
  ariaLevel: number
  ariaSetsize: number
  ariaPosinset: number
}> = memo(({ nodeData, isRootNode, isLastChild, ariaLevel, ariaSetsize, ariaPosinset }) => {
  const expandable = nodeData.children.length > 0
  const [expanded, setExpanded] = useState(isRootNode)
  const { selected } = useTreeController(nodeData.id)

  // Callback for toggling expansion
  const toggleExpanded = useCallback((value: boolean) => {
    setExpanded(value)
  }, [])

  return (
    <StyledTreeNodeList
      role="treeitem"
      isRootNode={isRootNode}
      aria-level={ariaLevel}
      aria-posinset={ariaPosinset}
      aria-setsize={ariaSetsize}
      aria-label={nodeData.label}
      aria-expanded={expandable ? expanded : undefined}
      aria-selected={selected}
      data-testid={dataTestId.hierarchyTreeNodeList}
    >
      {!isRootNode && (
        <StyledTreeNodeBranch data-testid={dataTestId.hierarchyTreeNodeBranch}>
          {isLastChild ? (
            <TreeLastBranchIcon data-testid={dataTestId.hierarchyTreeLastBranchIcon} />
          ) : (
            <TreeBranchIcon data-testid={dataTestId.hierarchyTreeBranchIcon} />
          )}
          {expandable && (
            <TreeNodeExpandButton
              nodeData={nodeData}
              expanded={expanded}
              setExpanded={toggleExpanded}
              data-testid={dataTestId.hierarchyTreeNodeExpandButton}
            />
          )}
        </StyledTreeNodeBranch>
      )}
      <StyledTreeNodeContent data-testid={dataTestId.hierarchyTreeNodeContentContainer}>
        <TreeNodeContent
          isRootNode={isRootNode}
          nodeData={nodeData}
          data-testid={dataTestId.hierarchyTreeNodeContent}
        />
        {expanded && nodeData.children.length > 0 && (
          <ul role="group">
            {nodeData.children.map((child, i) => (
              <TreeNode
                key={child.id}
                nodeData={child}
                isRootNode={false}
                isLastChild={i === nodeData.children.length - 1}
                ariaLevel={ariaLevel + 1}
                ariaSetsize={nodeData.children.length}
                ariaPosinset={i + 1}
                data-testid={dataTestId.hierarchyTreeNode}
              />
            ))}
          </ul>
        )}
      </StyledTreeNodeContent>
    </StyledTreeNodeList>
  )
})

TreeNode.displayName = 'TreeNode'

export default TreeNode

import { COLORS, IconProps } from '@skf-design-system/ui-components-react'
import { getEnumKeys } from './utils'
import { AssetStatus, AssetStatusString } from './models/types'

export enum ENV {
  DEV = 'development',
  BUILD_DEV = 'development-deploy',
  QA = 'qa-deploy',
  STAGING = 'staging-deploy',
  PROD = 'prod-deploy',
}

export const ERR_CANCELED = 'ERR_CANCELED'

export enum ROUTES {
  HOME = '/',
  ANALYSIS_BACKLOG = '/analysisBacklog',
  //ORPHAN_NOTES = '/orphan-notes',
  DASHBOARD = '/dashboard',
  OVERVIEW = '/overview',
  SITE_DETAILS = '/siteDetails',
  OPEN_RECOMMENDATIONS = '/open-recommendations',
  REPORT_STATUS = '/reportStatus',
  DEVICES_AND_SENSORS = '/devicesSensors',
  AUTOMATIC_DIAGNOSTICS = '/automaticDiagnostics',
  REPORT_GENERATOR = '/reportGenerator',
  LOG_OUT = '/auth/sign-out',
  TAB_ERROR = '/tab-error',
  ERROR = '/error',
  NOT_FOUND = '*',
}

export const DATE_REFRESHER_TIMEOUT = 60000 * 5 // 5 minutes

export enum Roundness {
  Sm = '0.125rem',
  Base = '0.25rem',
  Md = '0.375rem',
  Lg = '0.5rem',
}

export enum Boldness {
  Light = 300,
  Regular = 400,
  Normal = 500,
  Bold = 700,
}

export const allAssetStatuses = () => getEnumKeys(AssetStatus, ['never-reported'])

export type iconMapping = Pick<IconProps, 'feIcon' | 'feColor' | 'feSize'>

export const iconMappings: Record<AssetStatusString, iconMapping> = {
  'never-reported': { feIcon: 'circleStatus', feColor: 'gray' },
  normal: { feIcon: 'checkCircle', feColor: 'green' },
  acceptable: { feIcon: 'refresh', feColor: 'green' },
  unsatisfactory: { feIcon: 'error', feColor: 'yellow' },
  unacceptable: { feIcon: 'warning', feColor: 'orange' },
  severe: { feIcon: 'danger', feColor: 'red' },
}

export const switchHandleComponentsIds = {
  switchOnItem: 'switch-on-item',
  switchOffItem: 'switch-off-item',
}

export const AssetColors: { [key in AssetStatusString]: string } = {
  'never-reported': COLORS.gray700,
  normal: COLORS.greenDark,
  acceptable: COLORS.greenBase,
  unsatisfactory: COLORS.yellowDarker,
  unacceptable: COLORS.orangeDark,
  severe: COLORS.redBase,
} as const

export type LogSeverity = 'log' | 'info' | 'warn' | 'error'

export const ROLE = 'Compass'

import styled from '@emotion/styled'
import { COLORS, SPACINGS } from '@skf-design-system/ui-components-react'
import { recommendationStatuses } from '@/modules/dashboard/utils/constants'
import { recommendedActionStatusColor } from '@/modules/dashboard/utils/recommended-actions'
import { Boldness } from '@/shared/constants'
import { RecommendedActionStatusString } from '@/shared/models/types'

export const StyledRAWidgetTable = styled.table`
  width: 98%;
  table-layout: fixed;
  font-weight: ${Boldness.Bold};
  font-size: 0.875rem;
  text-align: center;
  padding-right: 0.5rem;
  border-spacing: 0;

  & th {
    width: 5.0625rem;
  }

  & td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
`
export const StyledRAWidgetContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${SPACINGS.md};
`

export const StyledRAWidgetContent = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: 4fr 2fr;
`

export const StyledRANoDataContainer = styled.div`
  display: flex;
  height: 50%;
  background-color: ${COLORS.gray050};
  justify-content: center;
  font-weight: ${Boldness.Bold};
  font-size: 14px;
  color: #aaafb8;
  margin-left: 78px;
  margin-right: 24px;
  padding-top: 40px;
`

export const StyledRATendViewContainer = styled.div`
  display: flex;
  width: 50.9rem;
  height: 19.5rem;
`

export const StyledRATendViewContent = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`

export const StyledRACurrentMonthStatusContainer = styled.div`
  padding: 2rem;
  background-color: #e6eefb;
  height: 17.2rem;
  width: 24.5rem;
  margin-left: 1rem;
`

const graphTitleHeight = SPACINGS.md
const graphHeight = SPACINGS.xxl
const spacing = SPACINGS.xs

export const StyledRACurrentMonthStatusContent = styled.div`
  display: grid;
  column-gap: ${SPACINGS.xs};
  grid-template-columns: 1fr 3fr;
  grid-template-rows:
    repeat(${recommendationStatuses.length - 1}, ${graphTitleHeight} ${graphHeight} ${spacing})
    ${graphTitleHeight} ${graphHeight};
`
export const StyledRAContentRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 1.2rem;
  column-gap: ${SPACINGS.xs};
`

export const StyledRACurrentMonthStatusIcons = styled.span<{ name: RecommendedActionStatusString }>`
  width: 0.75rem;
  height: 0.75rem;
  background-color: ${(props) => recommendedActionStatusColor(props.name)};
`

export const StyledRAContentStatusRow = styled.div`
  font-size: 0.875rem;
  font-weight: bolder;
`

export const StyledRAContentStatusRowName = styled.div`
  font-size: 0.625rem;
  font-weight: bold;
`

export const StyledRAPieChartContainer = styled.div`
  width: 100%;
  height: 13.125rem;
`

export const StyledRecommendedActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
export const StyledDueDatesStatusWrapper = styled.div`
  padding: 1rem;
  background-color: #e6eefb;
  height: 19.2rem;
  width: 25.5rem;
`

export const StyledDueDatesStatusTitle = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: 1rem;
  font-weight: 600;
`

export const StyledDueDatesStatusLabels = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: 1rem;
  font-weight: 600;
  position: relative;
  top: 1.5rem;
`

export const StyledRATableHeader = styled.th<{ status: RecommendedActionStatusString }>`
  color: ${(props) => recommendedActionStatusColor(props.status)};
  text-align: right;

  h4 {
    padding-right: 0.2rem;
  }
`

export const StyledRATableCell = styled.td<{ hasData: boolean; status: RecommendedActionStatusString }>`
  background-color: ${(props) => (!props.hasData ? COLORS.gray050 : 'transparent')};
  color: ${(props) => (props.hasData ? recommendedActionStatusColor(props.status) : '#aaafb8')};
  text-align: center;
`
export const StyledHighlightColumn = styled.col`
  background-color: #bfdbfe99;
`

import networkClient from '@/api/NetworkClient'
import { AxiosError } from 'axios'
import { PATHS } from '@/api/types'
import {
  UpdateFaultImageDataRequest,
  UpdateFaultImageDataResponse,
  UpdateFaultImageDataResult,
  PostUploadFaultImagesRequest,
  PostUploadFaultImagesResponse,
  PostUploadFaultImagesResult,
  FetchFaultImageResult,
  FetchFaultImageRequest,
  FetchFaultImageResponse,
} from '@/api/paths/documents/files/types'

import { FileDropzoneImageData } from '@/shared/components/dropzone/types'
import { generateUUID } from '@/shared/utils'
// import { generateUUID } from '@/shared/utils'

const uploadFaultImages = async (
  request: PostUploadFaultImagesRequest,
  handlers: PostUploadFaultImagesResult
): Promise<FileDropzoneImageData['imageInfo'] | void> => {
  try {
    const response = await networkClient.post<PostUploadFaultImagesRequest, PostUploadFaultImagesResponse>(
      PATHS.POST_UPLOAD_EVIDENCE_IMAGES,
      request
    )
    handlers.onSuccess && (await handlers.onSuccess(response.data))
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

const updateEvidenceImageData = async (
  request: UpdateFaultImageDataRequest,
  handlers: UpdateFaultImageDataResult,
  params: Record<string, string>
): Promise<UpdateFaultImageDataResponse | void> => {
  try {
    const response = await networkClient.patch<UpdateFaultImageDataRequest, UpdateFaultImageDataResponse>(
      PATHS.PATCH_UPDATE_EVIDENCE_IMAGE_DATA,
      request,
      { params }
    )
    handlers.onSuccess && (await handlers.onSuccess(response.data))
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

// const deleteFaultImage = async (
//   handlers: DeleteUploadFaultImageResult,
//   params: Record<string, string | undefined>
// ): Promise<UploadFaultImagesResponse | void> => {
//   try {
//     const response = await networkClient.delete<DeleteUploadFaultImageRequest, DeleteUploadFaultImageResponse>(
//       PATHS.DELETE_EVIDENCE_IMAGE,
//       { params }
//     )
//     handlers.onSuccess && (await handlers.onSuccess(response.data))
//   } catch (error) {
//     handlers.onError && (await handlers.onError(error as AxiosError))
//   }
// }

// const getSiteDetailsLogo = async (
//   handlers: GetSiteDetailsLogoResult,
//   params: Record<string, string | undefined>
// ): Promise<SiteDetailsLogoResponse | void> => {
//   try {
//     const response = await networkClient.get<GetSiteDetailsLogoRequest, GetSiteDetailsLogoResponse>(
//       PATHS.GET_SITE_DETAILS_LOGO,
//       {
//         params,
//       }
//     )
//     handlers.onSuccess && (await handlers.onSuccess(response.data))
//   } catch (error) {
//     handlers.onError && (await handlers.onError(error as AxiosError))
//   }
// }

const fetchFaultEvidenceImage = async (
  request: FetchFaultImageRequest,
  handlers: FetchFaultImageResult
): Promise<FetchFaultImageResponse | void> => {
  try {
    const response = await networkClient.get<FetchFaultImageRequest, FetchFaultImageResponse>(
      request.imageUrl as PATHS,
      { responseType: 'blob' }
    )

    const blob = response.data
    // Gets URL data and read to blob

    const mime = blob.type
    const ext = mime.slice(mime.lastIndexOf('/') + 1, mime.length)
    // Gets blob MIME type (e.g. image/png) and extracts extension

    const file = new File([blob], `${generateUUID()}.${ext}`, {
      type: mime,
    })

    const fileData = {
      file,
      blob,
      url: URL.createObjectURL(blob),
    } as FetchFaultImageResponse

    handlers.onSuccess && (await handlers.onSuccess(fileData))
    return fileData
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

export {
  uploadFaultImages,
  /*getSiteDetailsLogo,*/ updateEvidenceImageData,
  fetchFaultEvidenceImage /* deleteFaultImage*/,
}

import { IconButton, styled } from '@skf-design-system/ui-components-react'

export const StyledFlexibleCollapseToggleButton = styled(IconButton)<{
  toggleButtonBackgroundColor: string
}>`
  background-color: ${({ toggleButtonBackgroundColor }) => toggleButtonBackgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
`

import { getDevicesAndSensorsData } from '@/api/paths/devicesAndSensors'
import { useDevicesAndSensorsContext } from '@/contexts/devicesAndSensors'
import { DevicesAndSensorsData } from '@/models/devicesAndSensors/types'
import { DarwinError } from '@/shared/components/errorBoundary/darwinError'
import { useApi } from '@/shared/hooks/useApi'
import { useAsyncThrow } from '@/shared/hooks/useAsyncThrow'
import { AxiosError } from 'axios'
import { useParams } from 'react-router'

const useGetDevicesAndSensorsData = () => {
  const { setDevicesAndSensorsData } = useDevicesAndSensorsContext()

  const { sid } = useParams()

  const throwError = useAsyncThrow()

  return useApi(() => {
    return getDevicesAndSensorsData(
      {
        onSuccess: async (result: DevicesAndSensorsData) => {
          setDevicesAndSensorsData(result.devices)
        },
        onError: async (error: AxiosError) => {
          throwError(new DarwinError(error))
        },
      },
      {
        siteId: sid,
      }
    )
  })
}

export default useGetDevicesAndSensorsData

import { dataTestId } from '@/tests/testid'
import { Icon, IconColor, IconName } from '@skf-design-system/ui-components-react'
import { FC } from 'react'

interface ToastContentProps {
  startTextContent: string
  iconName?: IconName
  iconColor?: IconColor
  endTextContent?: string
}

/**
 * ToastContent component.
 * Displays a flexible content section for a toast notification, including an optional icon with customizable color,
 * and start and end text content.
 *
 * @param {ToastContentProps} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered toast content including optional icon and text.
 *
 * @example
 * <ToastContent
 *   iconName="checkCircle"
 *   iconColor="green"
 *   startTextContent="Success"
 *   endTextContent="Operation completed."
 * />
 */

export const ToastContent: FC<ToastContentProps> = ({ iconName, iconColor, startTextContent, endTextContent }) => (
  <div style={{ display: 'flex', alignItems: 'center' }} data-testid={dataTestId.toastContent}>
    {startTextContent}
    {iconName && iconColor && (
      <Icon
        feIcon={iconName}
        feColor={iconColor}
        feSize="sm"
        style={{ marginLeft: '5px', marginRight: '5px' }}
        data-testid="toast-icon"
      />
    )}
    {endTextContent}
  </div>
)

import { dataTestId } from '@/tests/testid'
import { Flexbox, Icon, IconProps, SPACINGS, Tooltip, css, useTooltip } from '@skf-design-system/ui-components-react'
import { FC } from 'react'

export interface HeaderInfoPanelIconData {
  count: number
  icon: IconProps['feIcon']
  iconColor: IconProps['feColor']
  hoverText: string
}
/**
 * HeaderInfoPanelIcon is the flexbox with icons that located in header info panel
 * and it holds icons and title that represents passed to component data
 *
 * @param {number} props.count - The count to display next to the icon.
 * @param {IconName} props.icon - The name of the icon to display (e.g., 'asset', 'warning').
 * @param {Color} props.iconColor - The color of the icon.
 * @param {string} props.hoverText - The tooltip text to display on hover.
 * @returns {JSX.Element} The rendered HeaderInfoPanelIcon component.
 */

const HeaderInfoPanelIcon: FC<HeaderInfoPanelIconData> = ({ count, icon, iconColor, hoverText }) => {
  const [hoverRef, isHovered] = useTooltip()
  return (
    <>
      <Flexbox
        feAlignItems="center"
        css={css`
          gap: ${SPACINGS.xs};
        `}
        ref={hoverRef}
        data-testid={dataTestId.headerInfoPanelIcon}
      >
        <Icon feIcon={icon} feColor={iconColor} />
        <div>{count}</div>
      </Flexbox>
      <Tooltip isHovered={isHovered} triggerElement={hoverRef.current}>
        {hoverText}
      </Tooltip>
    </>
  )
}

export default HeaderInfoPanelIcon

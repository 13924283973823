import { applicativeLog } from '@/shared/utils'
import { useRouteError } from 'react-router'

const ReportStatusErrorElement = () => {
  const error = useRouteError()
  applicativeLog(`RoutesErrorBoundary!!!!!!!!!!!!!!!!! ${error}`)
  throw error
}

export default ReportStatusErrorElement

import {
  AssetHierarchy,
  AssetReportFilters,
  CriticalityOptionValue,
  ReportGeneratorApiResponse,
  ReportType,
  ReportTypeString,
  SummarizedAssetDetails,
  SummarizedAssetHealthReportData,
} from '@/models/reportGenerator/types'
import { SelectItem } from '@skf-design-system/ui-components-react'
import { mappedAssetStatusImages } from './constants'
import { AssetCriticality, AssetStatusString } from '@/shared/models/types'
import { getMappedValue } from '@/shared/utils'
import { FaultTypes } from '@/models/reportStatus/types'
import { NodeType, TreeNodeData } from '@/models/newHierarchyTree/types'
// import { NodeType, TreeNodeData } from '@/shared/components/hierarchyTree/types'
import { AssetHealthStatusSelectType } from '@/models/dashboard/globalFiltering/types'

/**
 * Returns an array of asset criticality types based on the given criticality option value.
 * @param {CriticalityOptionValue} value - The selected criticality option.
 * @returns {AssetCriticality[]} - An array of criticality types matching the given value.
 */
const getCriticality = (value: CriticalityOptionValue): AssetCriticality[] => {
  switch (value) {
    case 'all':
      return ['A', 'B', 'C']
    case 'a':
      return ['A']
    case 'ab':
      return ['A', 'B']
    case 'bc':
      return ['B', 'C']
    default:
      return []
  }
}

/**
 * Maps an array of asset criticality types to a specific criticality option value.
 * @param {AssetCriticality[]} criticality - An array of asset criticality types to map.
 * @returns {CriticalityOptionValue} - The criticality option value that represents the provided criticality types.
 */
const mapCriticalityOption = (criticality: AssetCriticality[]): CriticalityOptionValue => {
  switch (true) {
    case criticality.length === 1 && criticality.every((status) => ['A'].includes(status)):
      return 'a'
    case criticality.length === 2 && criticality.every((status) => ['A', 'B'].includes(status)):
      return 'ab'
    case criticality.length === 2 && criticality.every((status) => ['B', 'C'].includes(status)):
      return 'bc'
    default:
      return 'all'
  }
}

/**
 * Returns the report type enum value based on the provided report type string.
 * @param {ReportTypeString} reportType - The string representation of a report type.
 * @returns {ReportType} - The corresponding report type enum value.
 */
const getReportType = (reportType: ReportTypeString): ReportType => {
  switch (reportType) {
    case 'summaryCharts':
      return ReportType.summaryCharts
    case 'summarizedAssetHealth':
      return ReportType.summarizedAssetHealth
    case 'detailedAssetHealth':
      return ReportType.detailedAssetHealth
    case 'lastMeasurements':
      return ReportType.lastMeasurements
    default:
      return ReportType.openRecommendedActions
  }
}

/**
 * Utility function to transform options into SelectItem format.
 * @param options - The array of options with `label` and `value`.
 * @returns Transformed array of SelectItem objects.
 */
const mapToSelectItems = <T extends string>(options: ReadonlyArray<{ label: string; value: T }>): SelectItem<T>[] => {
  return options.map((option) => ({
    label: option.label,
    value: option.value,
  }))
}

/**
 * Clears all report generator filters by resetting them to the initial state.
 *
 * @param {React.Dispatch<React.SetStateAction<AssetReportFilters>>} setAssetReportFilters -
 * The state setter function for updating asset report filters.
 *
 */

// const clearReportGeneratorFilters = (
//   setAssetReportFilters: React.Dispatch<React.SetStateAction<AssetReportFilters>>
// ) => {
//   setAssetReportFilters(initialReportFiltersState)
// }

/**
 * Updates the report type in the asset report filters based on the provided status and its checked state.
 * If the status is 'lastMeasurements' and it is checked, it will automatically add 'detailedAssetHealth'
 * to the report type list if not already included. Similarly, if 'detailedAssetHealth' is unchecked,
 * 'lastMeasurements' will be removed if it exists in the list.
 *
 * @param {React.Dispatch<React.SetStateAction<AssetReportFilters>>} setAssetReportFilters -
 * A React state setter function for updating the asset report filters. It allows updating the `reportType` in
 * the state based on the previous state.
 *
 * @param {ReportTypeString} status - The report type status to add or remove. Can be any valid `ReportTypeString`.
 *
 * @param {boolean} checked - A boolean value indicating whether the status should be added (`true`) or removed (`false`).
 * If `true`, the status will be added to the report types. If `false`, it will be removed.
 *
 * @returns {void} - This function does not return anything, it updates the state of the `reportType` in the
 * `assetReportFilters`.
 */

const updateReportType = (prevState: AssetReportFilters, status: ReportTypeString, checked: boolean) => {
  let updatedReportType = [...prevState.reportTypes]

  if (checked) {
    updatedReportType.push(status)

    if (status === 'lastMeasurements' && !updatedReportType.includes('detailedAssetHealth' as ReportTypeString)) {
      updatedReportType.push('detailedAssetHealth' as ReportTypeString)
    }
  } else {
    updatedReportType = updatedReportType.filter((type) => type !== status)

    if (status === 'detailedAssetHealth') {
      updatedReportType = updatedReportType.filter((type) => type !== 'lastMeasurements')
    }
  }

  return { ...prevState, reportTypes: updatedReportType }
}

/**
 * Returns the corresponding PNG image source for a given asset status.
 *
 * This function maps an asset status string to its respective PNG image path.
 * If the status is not found in the mapping, it returns `null`.
 *
 * @param {AssetStatusString} status - The asset status string (e.g. "normal", "severe", "acceptable").
 * @returns {string | null} The image source URL if found, otherwise `null`.
 */

const getAssetStatusImageSrc = (status: AssetStatusString): string | null => {
  return mappedAssetStatusImages[status] || null
}

/**
 * Maps asset health data fault types to their corresponding values using the provided mapping.
 *
 * @param {ReportGeneratorApiResponse['detailedAssetHealth']} data - The asset health data array.
 * @param {FaultTypes} reportFaultTypes - An object mapping fault type keys to their corresponding values.
 * @returns {Array} The transformed asset health data with mapped fault types.
 */

const mapAssetHealthDataFaultTypes = (
  data: ReportGeneratorApiResponse['detailedAssetHealth'],
  reportFaultTypes: FaultTypes
) => {
  return data.map((asset) => ({
    ...asset,
    faults: asset.faults.map((fault) => ({
      ...fault,
      faultType: getMappedValue(reportFaultTypes, fault.faultType),
    })),
  }))
}

/**
 * Transforms the hierarchical asset data from the API into a tree structure compatible with TreeNodeData.
 * Uses optimized array operations and minimizes object creation.
 *
 * @param {AssetHierarchy} apiData - The hierarchical asset data from the API
 * @returns {TreeNodeData} A tree structure where each node contains label, id, type, and children properties
 *
 * @example
 * const apiData = {
 *   siteId: "site1",
 *   siteName: "Main Site",
 *   functionalLocations: [{
 *     id: "fl1",
 *     name: "Location 1",
 *     assets: [{
 *       id: "asset1",
 *       name: "Asset 1",
 *       status: "normal",
 *       criticality: "A"
 *     }]
 *   }]
 * };
 * const treeData = transformToTreeNodeData(apiData);
 */
const transformToTreeNodeData = (apiData: AssetHierarchy): TreeNodeData => {
  const transformAsset = (asset: AssetHierarchy['functionalLocations'][0]['assets'][0]): TreeNodeData => ({
    label: asset.name,
    id: asset.id,
    type: NodeType.Asset,
    children: [],
    disabled: asset.status === null ? true : false,
  })

  const transformFunctionalLocation = (fl: AssetHierarchy['functionalLocations'][0]): TreeNodeData => ({
    label: fl.name,
    id: fl.id,
    type: NodeType.FunctionalLocation,
    children: fl.assets.reduce((acc, asset) => {
      acc.push(transformAsset(asset))
      return acc
    }, [] as TreeNodeData[]),
  })

  return {
    label: apiData.siteName,
    id: apiData.siteId,
    type: NodeType.Site,
    children: apiData.functionalLocations.reduce((acc, fl) => {
      acc.push(transformFunctionalLocation(fl))
      return acc
    }, [] as TreeNodeData[]),
  }
}

/**
 * Checks if an asset matches the specified filter criteria based on its status and criticality.
 * Uses Set for O(1) lookup performance.
 *
 * @param {string} assetStatus - Current status of the asset
 * @param {AssetCriticality} assetCriticality - Criticality level of the asset
 * @param {AssetHealthStatusSelectType[]} selectedStatuses - Array of selected status filters
 * @param {AssetCriticality[]} selectedCriticalities - Array of selected criticality filters
 * @returns {boolean} True if asset matches both status and criticality criteria
 *
 * @example
 * const matches = matchesFilterCriteria(
 *   "normal",
 *   "A",
 *   ["normal", "warning"],
 *   ["A", "B"]
 * );
 */
const matchesFilterCriteria = (
  assetStatus: string | null,
  assetCriticality: AssetCriticality,
  selectedStatuses: AssetHealthStatusSelectType[],
  selectedCriticalities: AssetCriticality[]
): boolean => {
  const statusSet = new Set(selectedStatuses)
  const criticalitySet = new Set(selectedCriticalities)

  return (
    (statusSet.size === 0 || statusSet.has(assetStatus as AssetHealthStatusSelectType)) &&
    (criticalitySet.size === 0 || criticalitySet.has(assetCriticality))
  )
}

/**
 * Filters assets from a hierarchy based on specified criteria in asset report filters.
 * Uses Set for efficient lookups and single-pass reduction for better performance.
 *
 * @param {AssetReportFilters} assetReportFilters - Object containing filter criteria
 * @param {AssetHierarchy} assetHierarchyData - Complete hierarchy data structure
 * @param {boolean} isReportFiltersApplied - Flag indicating whether to apply filters
 * @returns {string[]} Array of asset IDs that match the filter criteria
 *
 * @example
 * const filteredAssets = filterAssetsByCriteria(
 *   {
 *     assets: ["asset1", "asset2"],
 *     assetStatuses: ["normal"],
 *     assetCriticalities: ["A"]
 *   },
 *   hierarchyData,
 *   true
 * );
 */
const filterAssetsByCriteria = (
  assetReportFilters: AssetReportFilters,
  assetHierarchyData: AssetHierarchy
  // isReportFiltersApplied: boolean
): string[] => {
  // if (!isReportFiltersApplied) {
  //   return assetReportFilters.assets
  // }

  const selectedAssetIds = new Set(assetReportFilters.assets)
  // const statusSet = new Set(assetReportFilters.assetStatuses)
  // const criticalitySet = new Set(assetReportFilters.assetCriticalities)

  return assetHierarchyData.functionalLocations.flatMap((location) =>
    location.assets
      .filter(
        (asset) =>
          selectedAssetIds.has(asset.id) &&
          matchesFilterCriteria(
            asset.status,
            asset.criticality,
            assetReportFilters.assetStatuses,
            assetReportFilters.assetCriticalities
          )
      )
      .map((asset) => asset.id)
  )
}

/**
 * Generates an array of labels for the last 12 months in the format "MMM-YY".
 *
 * @param {Date} date - The reference date from which to calculate the last 12 months.
 * @returns {string[]} An array of month-year labels for the last 12 months, ordered from oldest to newest.
 */

const generateSummarizedAssetStatusLast12MonthsLabel = (date: Date) => {
  const months = []

  for (let i = 11; i >= 0; i--) {
    const tempDate = new Date(date)
    tempDate.setMonth(tempDate.getMonth() - i)

    const month = tempDate.toLocaleString('default', { month: 'short' })
    const year = tempDate.getFullYear().toString().slice(-2)
    months.push(`${month}-${year}`)
  }

  return months
}

/**
 * Converts nested summarized asset health report data into a flat structure suitable for PDF reporting.
 *
 * @param {SummarizedAssetHealthReportData[]} data - The nested summarized asset health report data.
 * @returns {SummarizedAssetDetails[]} - The flattened summarized asset details.
 */
const convertSummarizedAssetDataToPdfReport = (data: SummarizedAssetHealthReportData[]): SummarizedAssetDetails[] => {
  return data.flatMap((asset) => {
    if (asset.faults.length === 0) {
      return [
        {
          parentName: asset.parentName,
          assetId: asset.assetId,
          assetName: asset.assetName,
          statusHistory: asset.statusHistory,
          lastCollectionDate: asset.lastCollectionDate,
          faultId: '',
          observation: '',
          raId: '',
          recommendation: '',
          showFaultContent: true, // Show since it's the only entry
          showParentContent: true, // No RA, so show at this level
          isLastFault: true, // Since it's the only entry, it's the last fault too
          isLastRA: true, // Same for RA
        },
      ]
    }

    const middleFaultIndex = Math.floor(asset.faults.length / 2)
    const lastFaultIndex = asset.faults.length - 1 // Last fault index

    return asset.faults.flatMap((fault, faultIndex) => {
      const showParentContent = faultIndex === middleFaultIndex
      const isLastFault = faultIndex === lastFaultIndex
      const middleRAIndex = Math.floor(fault.recommendedActions.length / 2)
      const lastRAIndex = fault.recommendedActions.length - 1 // Last RA index

      return fault.recommendedActions.map((recommendedAction, raIndex) => {
        const showFaultContent = raIndex === middleRAIndex
        const isLastRA = raIndex === lastRAIndex

        return {
          parentName: asset.parentName,
          assetId: asset.assetId,
          assetName: asset.assetName,
          statusHistory: asset.statusHistory,
          lastCollectionDate: asset.lastCollectionDate,
          faultId: fault.faultId,
          observation: fault.observation,
          raId: recommendedAction.raId,
          recommendation: recommendedAction.recommendation,
          showFaultContent, // `true` for the middle fault
          showParentContent, // `true` for the middle RA
          isLastFault, // `true` for the last fault
          isLastRA, // `true` for the last RA
        }
      })
    })
  })
}

/**
 * Recursively counts the number of leaf nodes in a tree structure.
 *
 * A leaf node is defined as a node with no children.
 * The function returns 0 immediately if the node has an empty `id`, treating it as invalid or non-countable.
 * For nodes with children, the function recursively sums the counts of leaf nodes from all child nodes.
 *
 * @param {TreeNodeData} node - The tree node to evaluate. Each node is expected to have an `id` and a `children` array.
 * @returns {number} The total count of leaf nodes in the tree.
 *
 * @example
 * const count = countLeafOrSingleNodesAssetHierarchy(treeData);
 * console.log("Leaf nodes count:", count);
 */
const countLeafOrSingleNodesAssetHierarchy = (node: TreeNodeData): number => {
  if (node.id === '') {
    return 0
  }
  if (node.children.length === 0) {
    return 1
  }
  return node.children.reduce((total, child) => total + countLeafOrSingleNodesAssetHierarchy(child), 0)
}

/***************************** */

/**
 * Builds maps for tree nodes and their associated assets.
 *
 * @param {TreeNodeData} root - The root node of the tree.
 * @returns {Object} An object containing:
 *  - `nodeMap`: A Map where the key is the node ID and the value is the TreeNodeData.
 *  - `assetMap`: A Map where the key is the node ID and the value is a Set of asset IDs associated with that node.
 *
 * The function traverses the tree starting from the root node, and for each node, it:
 *  - Adds the node to `nodeMap`.
 *  - If the node is of type `Asset`, adds the node ID to a Set of assets.
 *  - Recursively traverses the children of the node, collecting their assets.
 *  - Updates `assetMap` with the node ID and the collected Set of assets.
 */
const buildTreeMaps = (root: TreeNodeData) => {
  const assetMap = new Map<string, Set<string>>() // Maps node ID -> Set of asset IDs

  const traverseTree = (node: TreeNodeData): Set<string> => {
    const assets = new Set<string>()

    if (node.type === NodeType.Asset) {
      assets.add(node.id)
    }

    for (const child of node.children) {
      const childAssets = traverseTree(child)
      childAssets.forEach((asset) => assets.add(asset))
    }

    assetMap.set(node.id, assets)
    return assets
  }

  traverseTree(root)

  return { assetMap }
}

/**
 * Filters a tree structure based on a search term, returning a new tree that includes:
 * - Nodes that match the search term.
 * - Ancestors of matching nodes (to preserve hierarchy).
 * - All children of a matching parent node (ensuring completeness).
 *
 * @param {TreeNodeData} treeData - The root node of the tree to filter.
 * @param {string} searchTerm - The term to search for within the tree nodes' labels.
 * @returns {TreeNodeData | null} - A new tree structure containing only the nodes that match
 *                                  the search term or have matching descendants. Returns `null`
 *                                  if no matches are found.
 *
 * @example
 * const treeData = {
 *   label: 'root',
 *   children: [
 *     { label: 'child1', children: [] },
 *     { label: 'child2', children: [
 *       { label: 'grandchild1', children: [] }
 *     ]}
 *   ]
 * };
 * const searchTerm = 'child2';
 * const filteredTree = getFilteredTreeData(treeData, searchTerm);
 * // filteredTree will include 'child2' and all its descendants.
 *
 * @example
 * const searchTerm2 = 'grandchild1';
 * const filteredTree2 = getFilteredTreeData(treeData, searchTerm2);
 * // filteredTree2 will include 'root', 'child2', and 'grandchild1' (to maintain hierarchy).
 */
const getFilteredTreeData = (treeData: TreeNodeData, searchTerm: string): TreeNodeData | null => {
  const matchesSearch = treeData.label.toLowerCase().includes(searchTerm.toLowerCase())

  // Recursively filter children
  const filteredChildren = treeData.children
    .map((child) => getFilteredTreeData(child, searchTerm))
    .filter((child): child is TreeNodeData => child !== null)

  // If this node matches the search term, include all of its children unfiltered
  if (matchesSearch) {
    return { ...treeData, children: treeData.children }
  }

  // If any children match, return the node with only the matching children
  if (filteredChildren.length > 0) {
    return { ...treeData, children: filteredChildren }
  }

  // If neither this node nor its children match, return null
  return null
}

/**
 * Retrieves the IDs of visible selected assets from a tree structure.
 *
 * This function traverses a tree of nodes and collects the IDs of assets that are both visible and selected.
 * It first precomputes the asset IDs for each node to avoid redundant traversal, then it collects the IDs
 * of nodes that meet the criteria.
 *
 * @param root - The root node of the tree structure.
 * @param selectedAssets - A set of asset IDs that are selected.
 * @returns An array of IDs of visible selected assets.
 *
 * @example
 * ```typescript
 * const root: TreeNodeData = {
 *   id: 'root',
 *   type: NodeType.Folder,
 *   children: [
 *     { id: 'asset1', type: NodeType.Asset, children: [] },
 *     { id: 'folder1', type: NodeType.Folder, children: [
 *       { id: 'asset2', type: NodeType.Asset, children: [] }
 *     ]}
 *   ]
 * };
 * const selectedAssets = new Set(['asset1', 'asset2']);
 * const visibleSelectedIds = getVisibleSelectedIds(root, selectedAssets);
 * console.log(visibleSelectedIds); // Output: ['asset1', 'asset2']
 * ```
 */
const getVisibleSelectedIds = (root: TreeNodeData, selectedAssets: Set<string>): string[] => {
  const ids = new Set<string>()
  const assetMap = new Map<string, Set<string>>() // Cache asset IDs per node
  const stack: TreeNodeData[] = [root]

  // Precompute asset IDs for each node (avoids redundant traversal)
  while (stack.length) {
    const node = stack.pop()!
    assetMap.set(node.id, collectAssetIds(node))
    stack.push(...node.children)
  }

  // Traverse tree and collect visible selected IDs
  const collectIds = (node: TreeNodeData): void => {
    const assetIds = assetMap.get(node.id) ?? new Set()

    if (node.type === NodeType.Asset && selectedAssets.has(node.id)) {
      ids.add(node.id)
    } else if (assetIds.size > 0 && [...assetIds].every((id) => selectedAssets.has(id))) {
      ids.add(node.id)
    }

    node.children.forEach(collectIds)
  }

  collectIds(root)
  return Array.from(ids)
}

/**
 * Collects all asset IDs from a tree structure starting from the given node.
 *
 * This function traverses the tree using an iterative approach to avoid potential
 * stack overflow issues with deep recursion. It collects IDs of nodes that are of type `Asset`.
 *
 * @param {TreeNodeData} node - The root node from which to start collecting asset IDs.
 * @returns {Set<string>} A set containing all unique asset IDs found in the tree.
 */
const collectAssetIds = (node: TreeNodeData): Set<string> => {
  const assetIds = new Set<string>()
  const stack: TreeNodeData[] = [node] // Iterative approach for efficiency

  while (stack.length) {
    const currentNode = stack.pop()!
    if (currentNode.type === NodeType.Asset) {
      assetIds.add(currentNode.id)
    }
    stack.push(...currentNode.children) // Push children to process
  }

  return assetIds
}

/**
 * Returns a message based on the state of included assets and report types.
 *
 * @param {number} includedAssetsLength - The number of included assets.
 * @param {number} reportTypesLength - The number of selected report types.
 * @returns {string | null} The appropriate message or `null` if no message is required.
 */
const getReportStartMessage = (includedAssetsLength: number, reportTypesLength: number): string | null => {
  if (includedAssetsLength === 0 && reportTypesLength === 0) {
    return 'Select assets and report type to start'
  } else if (includedAssetsLength === 0) {
    return 'Select assets to start'
  } else if (reportTypesLength === 0) {
    return 'Select report type to start'
  }

  return null
}

/**
 * Splits a string by spaces. If no spaces are present or a substring exceeds the given length,
 * it splits the substring to fit the given length.
 *
 * @param str - The input string to split.
 * @param length - The maximum length of each part after splitting.
 * @returns A formatted string with substrings split according to the given length.
 *
 * @example
 * ```ts
 * splitString('as we know wearetestingthistextfortestpurpose it is', 10)
 * // Output: 'as we know wearetesti ngthistext fortestpur pose it is'
 * ```
 */
const splitStringByLength = (str: string, length: number): string => {
  const words = str.split(' ')
  const result: string[] = []

  for (const word of words) {
    if (word.length > length) {
      // Split long words into chunks of the specified length
      for (let i = 0; i < word.length; i += length) {
        result.push(word.slice(i, i + length))
      }
    } else {
      result.push(word)
    }
  }

  return result.join(' ')
}
export {
  getCriticality,
  mapCriticalityOption,
  getReportType,
  mapToSelectItems,
  //clearReportGeneratorFilters,
  updateReportType,
  getAssetStatusImageSrc,
  mapAssetHealthDataFaultTypes,
  transformToTreeNodeData,
  filterAssetsByCriteria,
  matchesFilterCriteria,
  generateSummarizedAssetStatusLast12MonthsLabel,
  convertSummarizedAssetDataToPdfReport,
  countLeafOrSingleNodesAssetHierarchy,
  buildTreeMaps,
  getFilteredTreeData,
  getVisibleSelectedIds,
  collectAssetIds,
  getReportStartMessage,
  splitStringByLength,
}

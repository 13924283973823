import AssetStatusIcon from '@/shared/components/assetStatusIcon'
import { AssetStatusString } from '@/shared/models/types'
import { FC } from 'react'

interface AssetStatusCellProps {
  assetStatus: AssetStatusString
}

const AssetStatusCell: FC<AssetStatusCellProps> = ({ assetStatus }) => {
  return <AssetStatusIcon value={assetStatus} />
}

export default AssetStatusCell

import { createContext, FC, ReactNode, use } from 'react'
import { SelectionState } from '@/models/newHierarchyTree/types'

export interface HierarchyTreeContextValue {
  getState: (nodeId: string) => SelectionState
  select: (nodeId: string) => void
  deselect: (nodeId: string) => void
  isDisabled?: (nodeId: string) => boolean
}

const HierarchyTreeContext = createContext<HierarchyTreeContextValue | null>(null)

export const useHierarchyTreeContext = () => use(HierarchyTreeContext)

export const HierarchyTreeContextProvider: FC<{
  treeSelector: {
    getState: (nodeId: string) => SelectionState
    select: (nodeId: string) => void
    deselect: (nodeId: string) => void
    getSelectedSubtreeRoots: () => string[]
    isDisabled?: (nodeId: string) => boolean
  }
  onChange: (value: string[]) => void
  children: ReactNode
}> = ({ treeSelector, onChange, children }) => {
  const handleSelect = (nodeId: string) => {
    treeSelector.select(nodeId)
    const selectedNodes = treeSelector.getSelectedSubtreeRoots()
    onChange(selectedNodes)
  }

  const handleDeselect = (nodeId: string) => {
    treeSelector.deselect(nodeId)
    const selectedNodes = treeSelector.getSelectedSubtreeRoots()
    onChange(selectedNodes)
  }

  const value = {
    getState: treeSelector.getState,
    select: handleSelect,
    deselect: handleDeselect,
    isDisabled: treeSelector.isDisabled,
  }

  return <HierarchyTreeContext value={value}>{children}</HierarchyTreeContext>
}

import { useTypedSelector } from '@/store/store'
import { FC, useRef, useState } from 'react'
import { ErrorDisplay } from '@/shared/components/genericError/errorDisplay'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { DarwinDetachedModuleError, DarwinError } from '@/shared/components/errorBoundary/darwinError'
import { STORAGE_DARWIN_ERROR, STORAGE_ERROR_COUNT, STORAGE_MSAL_KEYS_PREFIX } from '@/shared/localStorageUtils'
import { clearMsalSessionStorageKeys, clearSessionStorageItems, clearSessionStorageKeys } from '@/shared/utils'
import { ROUTES } from '@/shared/constants'
import { useMsal } from '@azure/msal-react'
import { errorDataSelector } from '@/store/selectors/errorDataSelector'

/**
 * GenericError component displays different error messages based on the error status.
 *
 * @returns {JSX.Element} The rendered GenericError component.
 *
 * @example
 * <GenericError />
 *
 * The component uses the following hooks and components:
 * - `useTypedSelector`: To select error data from the Redux store.
 * - `useNavigate`: To navigate between routes.
 * - `ErrorDisplay`: To display error messages.
 * - `ROUTES`: Contains the route constants.
 * - `useIsAuthenticated`: To check if the user is authenticated.
 * - `useEffectOnce`: Custom hook to run an effect only once.
 */

const GenericError: FC = () => {
  const errorData = useTypedSelector((state) => errorDataSelector(state))
  const [errorElement, setErrorElement] = useState(<></>)

  const storedError = useRef<DarwinError | DarwinDetachedModuleError>(
    JSON.parse(localStorage.getItem(STORAGE_DARWIN_ERROR) || '{}') as DarwinError
  )

  const errorCount = sessionStorage.getItem(STORAGE_ERROR_COUNT) || '0'
  const { instance: msalInstance } = useMsal()
  const scopes = import.meta.env.VITE_AZURE_SILENCE_REQUEST_SCOPES.split(',')

  useDeepCompareEffect(() => {
    if (errorData || Object.keys(storedError.current).length > 0) {
      const error = errorData || storedError.current

      switch (error.status) {
        case 404:
          setErrorElement(<ErrorDisplay text="404 Page not found" type="access" hasRole />)
          break
        case undefined:
          setErrorElement(<ErrorDisplay text="500 Internal Server Error" type="access" hasRole />)
          break
        case 401:
        case 977:
          if (parseInt(errorCount) > 1) {
            msalInstance.clearCache()

            clearSessionStorageItems()
            msalInstance.loginRedirect({
              scopes,
              redirectStartPage: ROUTES.HOME,
            })
          } else {
            setErrorElement(<ErrorDisplay text="401 Unauthorized" type="no-access" hasRole />)
          }
          break
        case 901:
          clearSessionStorageKeys(STORAGE_MSAL_KEYS_PREFIX)
          setErrorElement(<ErrorDisplay text="401 Unauthorized" type="no-access" hasRole={false} />)
          break
        case 907:
          msalInstance.clearCache()
          clearMsalSessionStorageKeys()
          break
        default:
          setErrorElement(<ErrorDisplay text="500 Internal Server Error" type="access" hasRole />)
          break
      }
    }
  }, [errorData, storedError, errorCount])

  return errorElement
}

export default GenericError

import { StatusCode } from '@/api/constants'
import CustomerSelector from '@/app/mainHeader/customerSelector'
import { StyledLogo, StyledLogoutArea, StyledMainHeader } from '@/app/mainHeader/styled'
import { DarwinError } from '@/shared/components/errorBoundary/darwinError'
import { ENV, ROUTES } from '@/shared/constants'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { STORAGE_DARWIN_ERROR, STORAGE_GLOBAL_FILTERS_KEY } from '@/shared/localStorageUtils'
import { errorDataSelector } from '@/store/selectors/errorDataSelector'
import { useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { useMsal } from '@azure/msal-react'
import { IconButton, Logo, Text } from '@skf-design-system/ui-components-react'
import { useRef, useState } from 'react'
import { useLocation } from 'react-router'
import { EnvTitle } from './envTitle'

const MainHeader = () => {
  const { instance: msalInstance } = useMsal()
  const errorData = useTypedSelector((state) => errorDataSelector(state))
  const [isHeaderShown, setIsHeaderShown] = useState<boolean>(true)
  const isProd = import.meta.env.MODE === ENV.PROD
  const storedError = useRef<DarwinError>(JSON.parse(localStorage.getItem(STORAGE_DARWIN_ERROR) || '{}') as DarwinError)

  const { pathname } = useLocation()

  useDeepCompareEffect(() => {
    const darwinError = errorData || storedError.current
    if (
      (darwinError?.status === StatusCode.Unauthorized || darwinError?.status === undefined) &&
      pathname === ROUTES.ERROR
    ) {
      setIsHeaderShown(false)
    } else {
      setIsHeaderShown(true)
    }
  }, [errorData, pathname])

  const signOut = async () => {
    await msalInstance.logoutRedirect()
  }

  const onClick = () => {
    localStorage.removeItem(STORAGE_GLOBAL_FILTERS_KEY)
    signOut()
  }

  return (
    isHeaderShown && (
      <StyledMainHeader data-testid={dataTestId.appHeader}>
        <StyledLogo href="/">
          <Logo feWidth="6.5rem" feColor="secondary" title="SKF" />

          {!isProd && <EnvTitle />}
        </StyledLogo>
        <CustomerSelector />
        <StyledLogoutArea onClick={() => onClick()} data-testid={dataTestId.logoutButton}>
          <IconButton feIcon="logout" feColor="white" as="button" aria-label="logout button" />
          <Text feColor="white" feFontWeight="bold">
            Log out
          </Text>
        </StyledLogoutArea>
      </StyledMainHeader>
    )
  )
}

export default MainHeader

import { StyledBottomFooterSpan, StyledTopFooterSpan } from '@/modules/automaticDiagnostics/styled'
import { dataTestId } from '@/tests/testid'

const AutomaticDiagnosticsFooter = () => {
  return (
    <>
      <StyledTopFooterSpan data-testid={dataTestId.automaticDiagnosticsFooter}>
        Automatic Diagnosis Principles
      </StyledTopFooterSpan>
      <StyledBottomFooterSpan>
        If the last status of an asset was Normal and the new data arrived with no alarms - the automatic diagnosis is
        Normal.
      </StyledBottomFooterSpan>
    </>
  )
}
export default AutomaticDiagnosticsFooter

import { useDashboardContext } from '@/contexts/dashboard'
import GlobalFiltering from '@/modules/dashboard/components/actionPanel/global-filtering'
import { clearDashboardGlobalFilters } from '@/modules/dashboard/utils'
import {
  severities as allAssetStatusValues,
  globalFiltersRecommendationStatuses,
} from '@/modules/dashboard/utils/constants'
import ModuleHeader from '@/shared/components/moduleHeader'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { batchActions } from '@/store/batch/actions'
import { setModuleHeaderActionPanelData, setModuleHeaderConfigData } from '@/store/moduleHeader/actions'
import { useAppDispatch } from '@/store/store'
import { useMemo } from 'react'

const DashboardHeader = () => {
  const { selectedDashboard, faultTypeSelectOptions, dashboardFilters, setDashboardFilters } = useDashboardContext()
  const dashboardHeaderDispatch = useAppDispatch()

  const hasDefaultGlobalFilters = useMemo(() => {
    return (
      dashboardFilters.raStatus.length === globalFiltersRecommendationStatuses.length &&
      dashboardFilters.faultType.length === faultTypeSelectOptions.map((option) => option.faultType).length &&
      dashboardFilters.assetStatus.length === allAssetStatusValues.length
    )
  }, [dashboardFilters, faultTypeSelectOptions])

  useDeepCompareEffect(() => {
    dashboardHeaderDispatch(
      batchActions([
        setModuleHeaderConfigData({
          showHeader: true,
          title: selectedDashboard.title || 'Insight Dashboard',
          showInfoPanel: true,
          infoPanelElement: <GlobalFiltering />,
          infoPanelData: undefined,
          showActionPanel: true,
          showSubtitle: false,
        }),
        setModuleHeaderActionPanelData({
          withLoader: false,
          withClearFiltersButton: true,
          withRefreshButton: true,
          withCSVDownloadButton: false,
          withTableStateChangeSwitch: false,
          isClearFiltersButtonDisabled: hasDefaultGlobalFilters,
        }),
      ])
    )

    if (selectedDashboard !== undefined && Object.keys(selectedDashboard).length > 0) {
      dashboardHeaderDispatch(
        setModuleHeaderActionPanelData({
          clearFiltersButtonFn: () =>
            clearDashboardGlobalFilters(
              faultTypeSelectOptions.map((option) => option.faultType),
              setDashboardFilters
            ),
        })
      )
    }
  }, [selectedDashboard, hasDefaultGlobalFilters, faultTypeSelectOptions])

  return <ModuleHeader />
}

export default DashboardHeader

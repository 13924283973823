import { createContext, FC, ReactNode, use, useMemo, useState } from 'react'

interface IActionMenuContextProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<IActionMenuContextProps['isOpen']>>
}

export const useActionMenuContext = () => {
  const context = use(ActionMenuContext)
  if (!context) {
    throw new Error('useActionMenu must be used within an ActionMenuProvider')
  }
  return context
}

const ActionMenuContext = createContext<IActionMenuContextProps | null>(null)

export const ActionMenuProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)

  const value = useMemo(
    () => ({
      isOpen,
      setIsOpen,
    }),
    [isOpen]
  )

  return <ActionMenuContext value={value}>{children}</ActionMenuContext>
}

import { useReportGeneratorContext } from '@/contexts/reportGenerator'
import { AssetHealthDetailsReportProps } from '@/modules/reportGenerator/components/reports/detailedAssetHealth'
import { SummarizedAssetHealthPdfReportProps } from '@/modules/reportGenerator/components/reports/summarizedAssetHealth'
import useGenerateReports from '@/modules/reportGenerator/hooks/usePostGenerateReports'
import { StyledGenerateReportButton } from '@/modules/reportGenerator/styled'
import { TerminateWorkers, WorkerInvokeType } from '@/modules/reportGenerator/types'
import { mergePdf, splitPdfDataArrayIntoChunks } from '@/modules/reportGenerator/utils/pdfUtils'
import type { WorkerType as CoverPageWorkerType } from '@/modules/reportGenerator/workers/coverPageWorker/coverPage.pdf.worker'
import CoverPageWorker from '@/modules/reportGenerator/workers/coverPageWorker/coverPage.pdf.worker?worker'
import type { WorkerType as DetailedAssetHealthWorkerType } from '@/modules/reportGenerator/workers/detailedAssetHealthWorker/detailedAssetHealth.pdf.worker'
import DetailedAssetHealthWorker from '@/modules/reportGenerator/workers/detailedAssetHealthWorker/detailedAssetHealth.pdf.worker?worker'
import type { WorkerType as SummarizedAssetHealthWorkerType } from '@/modules/reportGenerator/workers/summarizedAssetHealthWorker/summarizedAssetHealth.pdf.worker'
import SummarizedAssetHealthWorker from '@/modules/reportGenerator/workers/summarizedAssetHealthWorker/summarizedAssetHealth.pdf.worker?worker'
import { useWorker } from '@/modules/reportGenerator/workers/useWorker'
import useDeepCompareCallback from '@/shared/hooks/useDeepCompareCallback'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { applicativeLog } from '@/shared/utils'
import { openToast } from '@/store/genericToast/actions'
import { useAppDispatch } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { FC, useImperativeHandle, useRef } from 'react'

interface GenerateReportButtonProps {
  label: string
  isVisible: boolean
  isDisabled: boolean
  ref?: React.Ref<TerminateWorkers>
}

const GenerateReportButton: FC<GenerateReportButtonProps> = ({ label, isVisible, isDisabled, ref }) => {
  const {
    setShowPreview,
    staticReportPartsBlobs,
    setStaticReportPartsBlobs,
    setPreviewReportUrl,
    setIsPdfGenerationInProgress,
    setLoaderProgress,
    generatedReportData,
    assetReportFilters,
  } = useReportGeneratorContext()

  const progressBarUnit = useRef(0)

  const generateButtonDispatch = useAppDispatch()

  const { coverPage, detailedAssetHealth, summarizedAssetHealth } = generatedReportData
  const { logoFileURL: logoUrl, reportedDate: reportDate, siteName } = coverPage
  const { execute: generateReports, status: generateReportsStatus, reset: generateReportReset } = useGenerateReports()

  const { execute: generateCoverPagePdf } = useWorker<CoverPageWorkerType, 'renderCoverPagePdfInWorker'>({
    WorkerClass: CoverPageWorker,
    methodName: 'renderCoverPagePdfInWorker',
    props: {
      logoUrl,
      coverPageDetails: coverPage,
      reportDate,
    },
  })

  const {
    restart: restartGenerateDetailedAssetHealthReportWorker,
    execute: generateDetailedAssetHealthReportPdf,
    terminate: terminateDetailedAssetHealthPdfWorker,
  } = useWorker<DetailedAssetHealthWorkerType, 'renderDetailedAssetHealthPdfInWorker'>({
    WorkerClass: DetailedAssetHealthWorker,
    methodName: 'renderDetailedAssetHealthPdfInWorker',
  })

  const {
    restart: restartSummarizedAssetHealthReportWorker,
    execute: generateSummarizedAssetHealthReportPdf,
    terminate: terminateSummarizedAssetHealthPdfWorker,
  } = useWorker<SummarizedAssetHealthWorkerType, 'renderSummarizedAssetHealthPdfInWorker'>({
    WorkerClass: SummarizedAssetHealthWorker,
    methodName: 'renderSummarizedAssetHealthPdfInWorker',
  })

  const workerTerminationAndCleanup = () => {
    generateReportReset()
    setStaticReportPartsBlobs({ ...staticReportPartsBlobs, coverPage: undefined })
    terminateDetailedAssetHealthPdfWorker()
    terminateSummarizedAssetHealthPdfWorker()
    restartGenerateDetailedAssetHealthReportWorker()
    restartSummarizedAssetHealthReportWorker()
  }

  useImperativeHandle(ref, () => {
    return {
      terminateWorkers: workerTerminationAndCleanup,
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staticReportPartsBlobs])

  const invokePdfWorker = async <T,>(
    chunks: T[][],
    workerToInvoke: WorkerInvokeType,
    propKeyName: 'reportSummarizedAssetHealthData' | 'assetHealthDetails',
    reportType: string
  ) => {
    let succeedBlobs = []

    const workerResults = await Promise.allSettled(
      chunks.map(
        (chunk) =>
          new Promise<Blob>((resolve, reject) => {
            let workerProps = {}
            if (propKeyName === 'reportSummarizedAssetHealthData') {
              workerProps = {
                postData: { reportSummarizedAssetHealthData: chunk, reportDate } as SummarizedAssetHealthPdfReportProps,
              }
            }
            if (propKeyName === 'assetHealthDetails') {
              workerProps = {
                postData: { assetHealthDetails: chunk } as AssetHealthDetailsReportProps,
              }
            }

            workerToInvoke(workerProps)
              .then((response) => {
                setTimeout(() => {
                  setLoaderProgress((prev) => prev + progressBarUnit.current)
                }, 0)
                resolve(response)
              })
              .catch(reject)
          })
      )
    )
    succeedBlobs = workerResults
      .filter((result): result is PromiseFulfilledResult<Blob> => result.status === 'fulfilled')
      .map((result) => result.value)

    if (succeedBlobs.length === 0) {
      applicativeLog(`All pdf generations failed: ${reportType}`, [], 'error')
      generateButtonDispatch(
        openToast({ children: `We encountered an issue while generating ${reportType} report`, feSeverity: 'error' })
      )
    }
    return succeedBlobs
  }

  const generatePdfBlob = useDeepCompareCallback(async () => {
    const startTime = performance.now()
    const pdfBlobs: Blob[] = []
    const summarizedAssetHealthChunks = splitPdfDataArrayIntoChunks(summarizedAssetHealth, 1)
    const detailedAssetHealthChunks = splitPdfDataArrayIntoChunks(detailedAssetHealth)
    progressBarUnit.current = 70 / (summarizedAssetHealthChunks.length + detailedAssetHealthChunks.length)
    if ((assetReportFilters.reportTypes as string[]).includes('summarizedAssetHealth')) {
      const summarizedAssetHealthBlobs = await invokePdfWorker(
        summarizedAssetHealthChunks,
        generateSummarizedAssetHealthReportPdf,
        'reportSummarizedAssetHealthData',
        'SummarizedAssetHealth'
      )

      pdfBlobs.push(...summarizedAssetHealthBlobs)
    }

    if ((assetReportFilters.reportTypes as string[]).includes('detailedAssetHealth')) {
      const detailedAssetHealthBlobs = await invokePdfWorker(
        detailedAssetHealthChunks,
        generateDetailedAssetHealthReportPdf,
        'assetHealthDetails',
        'DetailedAssetHealth'
      )
      pdfBlobs.push(...detailedAssetHealthBlobs)
    }

    if (staticReportPartsBlobs.infoPage) {
      pdfBlobs.unshift(staticReportPartsBlobs.infoPage)
    }
    if (staticReportPartsBlobs.coverPage) {
      pdfBlobs.unshift(staticReportPartsBlobs.coverPage)
    }

    const mergedPdf = await mergePdf(pdfBlobs)

    const endTime = performance.now()
    applicativeLog(`pdf generation for ${siteName} took ${(endTime - startTime) / 1000} seconds`)
    setPreviewReportUrl(URL.createObjectURL(mergedPdf))
    setShowPreview(true)
    setIsPdfGenerationInProgress(false)
    workerTerminationAndCleanup()
    generateButtonDispatch(openToast({ children: `Report was successfully exported`, feSeverity: 'success' }))
  }, [
    assetReportFilters,
    detailedAssetHealth,
    generateDetailedAssetHealthReportPdf,
    generateSummarizedAssetHealthReportPdf,
    reportDate,
    siteName,
    staticReportPartsBlobs,
    summarizedAssetHealth,
  ])

  const resetReportScreenState = () => {
    setShowPreview(false)
    setPreviewReportUrl(null)
    setLoaderProgress(0)
  }

  const handleGenerateReport = async () => {
    resetReportScreenState()
    setIsPdfGenerationInProgress(true)
    setLoaderProgress(5)
    await generateReports()
  }

  useDeepCompareEffect(() => {
    async function updateCoverPagePdf() {
      const coverPagePdf = await generateCoverPagePdf()
      setStaticReportPartsBlobs({
        ...staticReportPartsBlobs,
        coverPage: coverPagePdf,
      })
      setLoaderProgress(20)
    }

    if (siteName && reportDate) {
      updateCoverPagePdf()
    }
  }, [coverPage])

  useDeepCompareEffect(() => {
    // When report data is successfully fetched, generate the pdf blob
    if (generateReportsStatus === 'success' && staticReportPartsBlobs.coverPage) {
      setLoaderProgress(30)
      generatePdfBlob()
    }
  }, [generateReportsStatus, generatedReportData, staticReportPartsBlobs])

  return (
    <StyledGenerateReportButton
      isVisible={isVisible}
      disabled={isDisabled}
      onClick={handleGenerateReport}
      feSize="md"
      data-testid={dataTestId.generateReportButton}
    >
      {label}
    </StyledGenerateReportButton>
  )
}

export default GenerateReportButton

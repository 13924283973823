import { Site } from '@/models/overview/types'
import useOpenRecommendationData from '@/modules/overview/columns/thirdLevel/useOpenRecommendationData'
import useOverdueRecommendationData from '@/modules/overview/columns/thirdLevel/useOverdueRecommendationData'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { MRT_ColumnDef } from 'material-react-table'

const useOpenRecommendations = () => {
  const openRecommendationsColumn = useOpenRecommendationData()
  const overdueRecommendationsColumn = useOverdueRecommendationData()
  const openRecommendationsColumns = useDeepCompareMemo<MRT_ColumnDef<Site>[]>(
    () => [
      {
        id: 'openRecommendations',
        header: 'Open',
        size: 80,
        columns: openRecommendationsColumn,
      },
      {
        id: 'overdueRecommendations',
        header: 'Overdue',
        size: 80,
        columns: overdueRecommendationsColumn,
      },
    ],
    [openRecommendationsColumn, overdueRecommendationsColumn]
  )
  return openRecommendationsColumns
}

export default useOpenRecommendations

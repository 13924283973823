import { AssetHistoryClosedRecommendedAction, AssetHistoryOpenRecommendedAction } from '@/models/reportStatus/types'
import { RecommendedActionDateDisplay } from '@/modules/reportStatus/components/history/DateDisplay'
import {
  StyledFaultHistoryRecommendedAction,
  StyledFaultHistoryRecommendedActionAdditionalComments,
  StyledFaultHistoryRecommendedActionContainer,
  StyledFaultHistoryRecommendedActionDate,
  StyledFaultHistoryRecommendedActionText,
  StyledFaultHistoryRecommendedActionWorkOrder,
} from '@/modules/reportStatus/styled'
import TextEllipsis, { TextEllipsisType } from '@/shared/components/textEllipsis'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { dataTestId } from '@/tests/testid'
import { Icon } from '@skf-design-system/ui-components-react'
import { FC } from 'react'

const recommendedActionStateLabel = (
  recommendedAction: AssetHistoryOpenRecommendedAction | AssetHistoryClosedRecommendedAction
): string => {
  switch (recommendedAction.state) {
    case 'open':
      return 'Open'
    case 'closed':
      switch (recommendedAction.outcome) {
        case 'completed':
          return 'Completed'
        case 'rejected':
          return 'Rejected'
      }
  }
}

const RecommendedActionHistory: FC<{
  recommendedAction: AssetHistoryOpenRecommendedAction | AssetHistoryClosedRecommendedAction
}> = ({ recommendedAction }) => {
  const { recommendedAction: action, additionalNotes, dueDate, state, workOrder } = recommendedAction

  return (
    <StyledFaultHistoryRecommendedActionContainer
      feJustifyContent="space-between"
      feGap="lg"
      data-testid={dataTestId.recommendedActionHistory}
    >
      <StyledFaultHistoryRecommendedAction feAlignItems="center" feGap="xs">
        <Icon feIcon="arrowRight" feSize="sm" />
        <TextEllipsis
          type={TextEllipsisType.MultiLine}
          label="Recommended Action"
          value={action === '' ? 'No Recommended Action' : action}
          rowsUntilEllipsis={3}
        />
      </StyledFaultHistoryRecommendedAction>
      <StyledFaultHistoryRecommendedActionText feFontWeight="bold">
        {recommendedActionStateLabel(recommendedAction)}
      </StyledFaultHistoryRecommendedActionText>
      <StyledFaultHistoryRecommendedActionAdditionalComments
        label="Additional comments"
        value={additionalNotes === '' ? 'No additional comments' : additionalNotes}
        rowsUntilEllipsis={3}
        type={TextEllipsisType.MultiLine}
      />
      <StyledFaultHistoryRecommendedActionDate>
        <RecommendedActionDateDisplay label="Due Date" value={formatDate(dueDate, DateFormats.AmericanDateFormat)} />
      </StyledFaultHistoryRecommendedActionDate>
      <StyledFaultHistoryRecommendedActionWorkOrder
        type={TextEllipsisType.MultiLine}
        label="Work order"
        value={workOrder}
        rowsUntilEllipsis={3}
      />
      <StyledFaultHistoryRecommendedActionDate>
        {state === 'closed' && recommendedAction.closeDate !== null && (
          <RecommendedActionDateDisplay
            label="Closed Date"
            value={formatDate(recommendedAction.closeDate, DateFormats.AmericanDateFormat)}
          />
        )}
      </StyledFaultHistoryRecommendedActionDate>
    </StyledFaultHistoryRecommendedActionContainer>
  )
}
export default RecommendedActionHistory

import { AnalysisReasonStringType, InitialAsset, ReasonIndicatorData } from '@/models/analysisBacklog/types'
import { DeviceInterface } from '@/models/devicesAndSensors/types'
import { RecommendationData } from '@/models/openRecommendations/types'
import { DateFilterValue, FilterRowType } from '@/shared/components/tableComponents/genericTableFilter/types'
import { AssetStatus, AssetStatusString } from '@/shared/models/types'
import { MRT_Row, MRT_RowData } from 'material-react-table'

/**
 * Filters rows based on a text search string.

 * @template T - The type of the row data.
 * @param {MRT_Row<T>} row - The current row being filtered.
 * @param {string} columnId - The ID of the column to filter.
 * @param {string} searchString - The search string to match against.
 * @returns {boolean} - `true` if the row matches the search string, `false` otherwise.
 */
const textSearchFilterFn = <T extends FilterRowType>(
  row: MRT_Row<T>,
  columnId: string,
  searchString: string
): boolean => {
  const data = row.getValue<string | T[]>(columnId)
  if (Array.isArray(data)) {
    return data?.some(
      (item) => 'name' in item && (item.name as string).toLowerCase().includes(searchString.trim().toLowerCase())
    )
  }
  return !!data?.toString().toLowerCase().includes(searchString.toString().trim().toLowerCase())
}
/**
 * Filters rows based on a date range and filter type.

 * @template T - The type of the row data.
 * @param {MRT_Row<T>} row - The current row being filtered.
 * @param {string} columnId - The ID of the column to filter.
 * @param {DateFilterValue} dateFilterValue - An object containing the filter type, start date, and end date.
 * @returns {boolean} - `true` if the row matches the date filter criteria, `false` otherwise.
 */
const dateFilterFn = <T extends MRT_RowData>(
  row: MRT_Row<T>,
  columnId: string,
  { dateFilterType, startDate, endDate }: DateFilterValue
): boolean => {
  const columnValue = new Date(row.getValue(columnId))
  const dateWithoutHours = new Date(columnValue)
  dateWithoutHours.setHours(0, 0, 0, 0)

  /* v8 ignore next 3 */
  if (dateWithoutHours.getTime() === 0) {
    return false
  }

  switch (dateFilterType) {
    case 'before':
      return startDate ? dateWithoutHours.getTime() <= startDate.getTime() : false
    case 'between':
      return startDate && endDate
        ? dateWithoutHours.getTime() >= startDate.getTime() && dateWithoutHours.getTime() <= endDate.getTime()
        : false
    case 'after':
      return startDate ? dateWithoutHours.getTime() >= startDate.getTime() : false
    default:
      return false
  }
}
/**
 * Filters rows based on a list of status values.

 * @template T - The type of the row data, either `AssetRow` or `RecommendationData`.
 * @param {MRT_Row<T>} row - The current row being filtered.
 * @param {string} columnId - The ID of the column to filter.
 * @param {AssetStatus[]} filteredStatus - An array of status values to filter by.
 * @returns {boolean} - `true` if the row matches one of the filtered statuses, `false` otherwise.
 */
const statusFilterFn = <T extends InitialAsset | RecommendationData>(
  row: MRT_Row<T>,
  columnId: string,
  filteredStatus: AssetStatusString[]
): boolean => {
  const columnValue = row.getValue<AssetStatus>(columnId)
  return filteredStatus.some((v) => v.toLowerCase() === columnValue.toLowerCase())
}
/**
 * Filters rows based on a list of status values, specifically for open recommendations
 * that don't have an raOutcome.
 *
 * @template T - The type of the row data is `RecommendationData`.
 * @param {MRT_Row<T>} row - The current row being filtered.
 * @param {string} columnId - The ID of the column to filter.
 * @param {AssetStatusString[]} filteredStatus - An array of status values to filter by.
 * @returns {boolean} - `true` if the row matches one of the filtered statuses and has no raOutcome, `false` otherwise.
 */
const openRecommendationStatusFilterFn = <T extends RecommendationData>(
  row: MRT_Row<T>,
  columnId: string,
  filteredStatus: AssetStatusString[]
): boolean => {
  const columnValue = row.getValue<AssetStatus>(columnId)
  return filteredStatus.some((v) => v.toLowerCase() === columnValue.toLowerCase() && !row.original.raOutcome)
}
/**
 * Filters rows based on a list of analysis reason values.

 * @param {MRT_Row<InitialAsset>} row - The current row being filtered.
 * @param {string} columnId - The ID of the column to filter.
 * @param {AnalysisReasonStringType[]} filteredAnalysisReasons - An array of analysis reason values to filter by.
 * @returns {boolean} - `true` if the row matches one of the filtered analysis reasons, `false` otherwise.
 */
const analysisReasonFilterFn = (
  row: MRT_Row<InitialAsset>,
  columnId: string,
  filteredAnalysisReasons: AnalysisReasonStringType[]
): boolean => {
  const rowValue = row.getValue<ReasonIndicatorData[] | undefined>(columnId)

  if (!rowValue) {
    return false
  }

  return filteredAnalysisReasons.every((filteredAnalysisReasonValue) =>
    rowValue.some((rowValue) => {
      const reasonType = rowValue.type
      if ('severity' in rowValue && filteredAnalysisReasonValue === `${reasonType}_${rowValue.severity}`) {
        return true
      } else if (!('severity' in rowValue) && filteredAnalysisReasonValue === reasonType) {
        return true
      }
    })
  )
}
/**
 * Filters rows based on a list of device status values.

 * @param {MRT_Row<DeviceInterface>} row - The current row being filtered.
 * @param {string} columnId - The ID of the column to filter.
 * @param {DeviceInterface['status'][]} filteredStatus - An array of device status values to filter by.
 * @returns {boolean} - `true` if the row matches one of the filtered statuses, `false` otherwise.
 */
const deviceStatusFilterFn = (
  row: MRT_Row<DeviceInterface>,
  columnId: string,
  filteredStatus: DeviceInterface['status'][]
): boolean => {
  const columnValue = row.getValue<typeof filteredStatus>(columnId)
  return filteredStatus.some((v) => v.toString() === columnValue.toString())
}

/**
 * Filters a row based on a numeric search value or an array of numeric search values.
 *
 * @template T - The type of the row data.
 * @param {MRT_Row<T>} row - The row to be filtered.
 * @param {string} columnId - The ID of the column to be filtered.
 * @param {number | number[]} searchValue - The numeric value or array of numeric values to filter by.
 * @returns {boolean} - Returns `true` if the row's column value matches the search value or is included in the array of search values, otherwise `false`.
 */
const numberFilterFn = <T extends FilterRowType>(
  row: MRT_Row<T>,
  columnId: string,
  searchValue: number | number[]
): boolean => {
  const data = row.getValue<number>(columnId)
  let result = false
  if (Array.isArray(searchValue)) {
    result = searchValue.includes(data)
  } else {
    result = data === searchValue
  }

  return result
}

export {
  textSearchFilterFn,
  dateFilterFn,
  statusFilterFn,
  analysisReasonFilterFn,
  deviceStatusFilterFn,
  numberFilterFn,
  openRecommendationStatusFilterFn,
}

import { convertToDisplayValue } from '@/shared/utils'
import { Flexbox } from '@skf-design-system/ui-components-react'
import { FC } from 'react'

interface OpenRecommendationBelongsToProps {
  belongsTo: string
}

const OpenRecommendationBelongsTo: FC<OpenRecommendationBelongsToProps> = ({ belongsTo }) => {
  return <Flexbox>{convertToDisplayValue(belongsTo)}</Flexbox>
}

export default OpenRecommendationBelongsTo

import { useFaultsFormStateContext } from '@/contexts/moduleContexts/faultsForm'
import { Fault, FaultState, NonNormalStatus } from '@/models/reportStatus/faultsFormStateTypes'
import FormDatePicker from '@/modules/reportStatus/components/formDatePicker'
import FormSelect from '@/modules/reportStatus/components/formSelect'
import FromTextArea from '@/modules/reportStatus/components/formTextArea'
// import DebugMockCustomerNote from '@/modules/reportStatus/components/mockCustomerNote'
import { StyledReportStatusFaultRowContent } from '@/modules/reportStatus/styled'
import { getEntityId } from '@/modules/reportStatus/utils/formUtils'
import { epochDate } from '@/shared/dateUtils'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { AssetStatus } from '@/shared/models/types'
import { capitalizeFirstLetter } from '@/shared/stringUtils'
import { useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { FC } from 'react'
import MultiLevelSelect from '../multiLevelSelect'

interface FaultRowProps {
  fault: Fault
  isInEditMode: boolean
}

const FaultRow: FC<FaultRowProps> = ({ fault, isInEditMode }) => {
  const faultId = getEntityId(fault)

  // const [searchParams] = useSearchParams()

  const {
    // selectedCustomer: { customerNotesEnabled },
    reportFaultTypes,
  } = useTypedSelector((state) => state)

  // const debugMode = convertToBoolean(searchParams.get('debug')) && customerNotesEnabled

  const {
    faultsFormState: { errors },
  } = useFaultsFormStateContext()

  const errorData = useDeepCompareMemo(() => {
    return errors?.find((faultError) => faultError.id === faultId)
  }, [errors])

  const getFaultValueAndText = () => {
    const faultType = reportFaultTypes.flatMap((type) => type.subTypes).find((subType) => subType.code === fault.fault)

    const parentType = !faultType ? reportFaultTypes.find((type) => type.code === fault.fault) : null

    const faultText = faultType ? faultType.en : parentType ? parentType.text : ''

    return {
      value: fault.fault,
      text: `${faultId} ${faultText}`,
    }
  }

  return (
    <>
      <StyledReportStatusFaultRowContent
        state={fault.state}
        feJustifyContent="flex-start"
        data-testid={dataTestId.faultFormRow}
      >
        <MultiLevelSelect
          {...getFaultValueAndText()}
          label="Fault"
          isInEditMode={fault.state === FaultState.NEW}
          isRequired={fault.state === FaultState.NEW}
          fieldPath={{
            fieldName: 'fault',
            fieldId: faultId,
          }}
          errorData={errorData?.fault}
          options={reportFaultTypes
            .filter((type) => !type.deprecated)
            .map(({ code, text, subTypes }) => ({
              value: code,
              label: `${text}`,
              subTypes: subTypes.filter((subType) => !subType.deprecated),
            }))}
          state={fault.state}
          data-testid={dataTestId.multiLevelSelect}
        />
        {fault.createdDate && ![FaultState.NEW, FaultState.EDIT].includes(fault.state) && (
          <FormDatePicker
            label="Created date"
            date={fault.createdDate}
            isInEditMode={false}
            state={fault.state}
            minDate={new Date()}
            maxDate={new Date()}
            data-testid={dataTestId.faultFormDatePicker}
          />
        )}
        <FormDatePicker
          label="Collection date"
          date={fault.collectionDate}
          isInEditMode={isInEditMode}
          state={fault.state}
          isRequired={true}
          fieldPath={{
            fieldName: 'collectionDate',
            fieldId: faultId,
          }}
          errorData={errorData?.collectionDate}
          maxDate={new Date()}
          minDate={epochDate().toDate()}
          data-testid={dataTestId.faultFormDatePicker}
        />
        <FormSelect
          label="Status"
          isInEditMode={![FaultState.PENDING_CLOSE, FaultState.OPEN].includes(fault.state)}
          value={fault.status}
          text={capitalizeFirstLetter(fault.status)}
          options={Object.keys(AssetStatus)
            .filter((status) => !['normal', 'never-reported'].includes(status)) //Excludes normal and never-reported
            .map((text) => ({
              value: text as NonNormalStatus,
              label: capitalizeFirstLetter(text),
            }))}
          state={fault.state}
          fieldPath={{
            fieldName: 'status',
            fieldId: faultId,
          }}
          isRequired={isInEditMode}
          errorData={errorData?.status}
          data-testid={dataTestId.faultFormSelect}
        />
        <FromTextArea
          label="Observation"
          isInEditMode={isInEditMode}
          value={fault.observation}
          maxHeight="150px"
          minHeight="50px"
          hint="Please provide a detailed description or any other information which may provide context."
          maxLength={1000}
          isRequired={true}
          fieldPath={{
            fieldName: 'observation',
            fieldId: faultId,
          }}
          errorData={errorData?.observation}
          state={fault.state}
          data-testid={dataTestId.faultFormArea}
        />
      </StyledReportStatusFaultRowContent>
      {/* {debugMode && <DebugMockCustomerNote faultId={faultId.current} data-testid={dataTestId.mockCustomerNote} />} */}
    </>
  )
}

export default FaultRow

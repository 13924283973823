import { RootState } from '@/store/store'
import { createSelector } from '@reduxjs/toolkit'

const _selectSitesPermissions = (state: RootState) => state.sitesPermissions
const _selectSitePermissionBySid = (_: RootState, sid: string | undefined) => sid

const sitePermissionsBySidSelector = createSelector(
  _selectSitesPermissions,
  _selectSitePermissionBySid,
  (sitesPermissions, sid) => {
    return sid && sitesPermissions[sid]
  }
)

const copyAssetPathPermissionBySidSelector = createSelector(
  _selectSitesPermissions,
  _selectSitePermissionBySid,
  (sitesPermissions, sid) => {
    return !!(sid && sitesPermissions[sid]?.analysis_backlog_copy_asset_path)
  }
)

const showHandledByPermissionBySidSelector = createSelector(
  _selectSitesPermissions,
  _selectSitePermissionBySid,
  (sitesPermissions, sid) => {
    return !!(sid && sitesPermissions[sid]?.analysis_backlog_show_handled)
  }
)

const exportAnalysisBacklogTableByPermissionBySidSelector = createSelector(
  _selectSitesPermissions,
  _selectSitePermissionBySid,
  (sitesPermissions, sid) => {
    return !!(sid && sitesPermissions[sid]?.analysis_backlog_export_table)
  }
)

const showDeviceChannelsBySidSelector = createSelector(
  _selectSitesPermissions,
  _selectSitePermissionBySid,
  (sitesPermissions, sid) => {
    return !!(sid && sitesPermissions[sid]?.devices_view_channels)
  }
)

const exportDeviceTableBySidSelector = createSelector(
  _selectSitesPermissions,
  _selectSitePermissionBySid,
  (sitesPermissions, sid) => {
    return !!(sid && sitesPermissions[sid]?.devices_export_table)
  }
)

const showDeviceDetailsBySidSelector = createSelector(
  _selectSitesPermissions,
  _selectSitePermissionBySid,
  (sitesPermissions, sid) => {
    return !!(sid && sitesPermissions[sid]?.devices_details)
  }
)

const showMarkAsNormalBySidSelector = createSelector(
  _selectSitesPermissions,
  _selectSitePermissionBySid,
  (sitesPermissions, sid) => {
    return !!(sid && sitesPermissions[sid]?.status_report_mark_as_normal)
  }
)

const reportStatusPublishBySidSelector = createSelector(
  _selectSitesPermissions,
  _selectSitePermissionBySid,
  (sitesPermissions, sid) => {
    return !!(sid && sitesPermissions[sid]?.status_report_publish)
  }
)
const reportStatusReconfirmBySidSelector = createSelector(
  _selectSitesPermissions,
  _selectSitePermissionBySid,
  (sitesPermissions, sid) => {
    return !!(sid && sitesPermissions[sid]?.status_report_reconfirm)
  }
)

const showClosedOpenRecommendations = createSelector(
  _selectSitesPermissions,
  _selectSitePermissionBySid,
  (sitesPermissions, sid) => {
    return !!(sid && sitesPermissions[sid]?.open_recommendation_show_closed)
  }
)

const exportOpenRecommendationsTableByPermissionBySidSelector = createSelector(
  _selectSitesPermissions,
  _selectSitePermissionBySid,
  (sitesPermissions, sid) => {
    return !!(sid && sitesPermissions[sid]?.open_recommendation_export_table)
  }
)

export {
  sitePermissionsBySidSelector,
  copyAssetPathPermissionBySidSelector,
  showHandledByPermissionBySidSelector,
  exportAnalysisBacklogTableByPermissionBySidSelector,
  showDeviceChannelsBySidSelector,
  exportDeviceTableBySidSelector,
  showDeviceDetailsBySidSelector,
  showMarkAsNormalBySidSelector,
  reportStatusPublishBySidSelector,
  reportStatusReconfirmBySidSelector,
  exportOpenRecommendationsTableByPermissionBySidSelector,
  showClosedOpenRecommendations,
}

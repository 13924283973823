import { degrees, grayscale, PDFDocument } from 'pdf-lib'

/**
 * Splits an array into a specified number of chunks.
 *
 * @template T - The type of elements in the array.
 * @param {T[]} array - The array to be split into chunks.
 * @param {number} [chunkCount=10] - The number of chunks to split the array into. Defaults to 10 if not provided.
 * @returns {T[][]} - A two-dimensional array where each sub-array is a chunk of the original array.
 *
 * @example
 * ```typescript
 * const data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
 * const chunks = splitPdfDataArrayIntoChunks(data, 3);
 * // chunks will be [[1, 2, 3, 4], [5, 6, 7], [8, 9, 10]]
 * ```
 */
const splitPdfDataArrayIntoChunks = <T>(array: T[], chunkCount: number = 10): T[][] => {
  const chunkSize = Math.ceil(array.length / chunkCount)
  return Array.from({ length: Math.ceil(array.length / chunkSize) }, (_, index) =>
    array.slice(index * chunkSize, (index + 1) * chunkSize)
  )
}

/**
 * Merges multiple PDF blobs into a single PDF blob and adds page numbers to each page.
 * For portrait pages, page numbers are centered at the bottom.
 * For landscape pages, page numbers are positioned at the center of the right edge.
 *
 * @param {Blob[]} pdfBlobs - An array of PDF blobs to be merged.
 * @returns {Promise<Blob>} A promise that resolves to a single merged PDF blob.
 *
 * @example
 * const pdfBlobs = [blob1, blob2, blob3];
 * mergePdf(pdfBlobs).then((mergedBlob) => {
 *   // Do something with the merged PDF blob
 * });
 *
 * @throws {Error} If there is an issue with loading or merging the PDF documents.
 */
const mergePdf = async (pdfBlobs: Blob[]): Promise<Blob> => {
  const mergedPdf = await PDFDocument.create()

  for (const blob of pdfBlobs) {
    const pdfBytes = await blob.arrayBuffer()
    const pdfDoc = await PDFDocument.load(pdfBytes)
    const copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices())
    copiedPages.forEach((page) => mergedPdf.addPage(page))
  }

  const pageCount = mergedPdf.getPageCount()
  for (let i = 0; i < pageCount; i++) {
    const page = mergedPdf.getPage(i)
    const pageSize = page.getSize()
    const isLandscape = pageSize.width > pageSize.height
    const text = `${i + 1} / ${pageCount}`

    if (isLandscape) {
      // For landscape pages - position on the right side center
      const centerY = pageSize.height / 2 - 15
      page.drawText(text, {
        x: pageSize.width - 20,
        y: centerY,
        size: 10,
        rotate: degrees(90),
        color: grayscale(0.5),
      })
    } else {
      // For portrait pages
      const centerX = pageSize.width / 2
      page.drawText(text, {
        x: centerX,
        y: 20,
        size: 10,
        color: grayscale(0.5),
      })
    }
  }

  const mergedBytes = await mergedPdf.save()
  return new Blob([mergedBytes], { type: 'application/pdf' })
}

export { splitPdfDataArrayIntoChunks, mergePdf }

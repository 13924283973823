import { StyledPriorityColumnIcon } from '@/modules/analysisBacklog/styled'
import { switchHandleComponentsIds } from '@/shared/constants'
import { dataTestId } from '@/tests/testid'
import { Flexbox, Tooltip, useTooltip } from '@skf-design-system/ui-components-react'
import { FC } from 'react'

interface PriorityUnhandledCellColumnProps {
  isInaccuratePriority: boolean
  priority: number
  rowIndex: number
}

const PriorityUnhandledCellColumn: FC<PriorityUnhandledCellColumnProps> = ({
  isInaccuratePriority,
  priority,
  rowIndex,
}) => {
  const [hoverRef, isHovered] = useTooltip()

  return (
    <Flexbox
      feJustifyContent="center"
      feAlignItems="center"
      data-testid={dataTestId.analysisBacklogPriorityCellContent}
      id={`${switchHandleComponentsIds.switchOffItem}-${rowIndex}`}
    >
      {isInaccuratePriority && (
        <>
          <StyledPriorityColumnIcon
            feIcon="exclamation"
            feSize="md"
            as="button"
            aria-label="Invalid Input"
            ref={hoverRef}
            data-testid={dataTestId.analysisBacklogPriorityCellInaccurateIcon}
          />
          <Tooltip isHovered={isHovered} triggerElement={hoverRef.current}>
            Invalid Input
          </Tooltip>
        </>
      )}
      {priority}
    </Flexbox>
  )
}

export default PriorityUnhandledCellColumn

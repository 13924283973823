// eslint-disable-next-line react-compiler/react-compiler
/* eslint-disable react-hooks/exhaustive-deps */
import { useReportGeneratorContext } from '@/contexts/reportGenerator'
import { AssetHealthStatusSelectType, AssetHealthStatusType } from '@/models/dashboard/globalFiltering/types'
import { AssetReportFilters, ReportGeneratorStatusOptionValue, ReportTypeString } from '@/models/reportGenerator/types'
import GenerateReportButton from '@/modules/reportGenerator/components/generateReportButton'
import ReportTypeSection from '@/modules/reportGenerator/components/reportFilters/reportTypeSection'
import ReportSelect from '@/modules/reportGenerator/components/reportSelect'
import {
  StyledAssetStatusSelectWrap,
  StyledGenerateReportButtonContainer,
  StyledIconContainer,
  StyledReportDateWrap,
  StyledReportDetailsSection,
  StyledReportFiltersContainer,
  StyledReportTypeHeading,
  StyledReportTypeSection,
  StyledReportTypeSectionContainer,
  StyledSelectFiltersSection,
  StyledSelectSection,
} from '@/modules/reportGenerator/styled'
import { mapToSelectItems, updateReportType } from '@/modules/reportGenerator/utils'
import { reportGeneratorStatusOptions } from '@/modules/reportGenerator/utils/constants'
import { addToDate, epochDate, parseDate } from '@/shared/dateUtils'
import { AssetStatusString } from '@/shared/models/types'
import { mapIconPropsToAssetStatus } from '@/shared/utils'
import { dataTestId } from '@/tests/testid'
import { DatePicker, Icon } from '@skf-design-system/ui-components-react'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'

const ReportFilters: FC = () => {
  const [hasFiltersChanged, setHasFiltersChanged] = useState(false)
  const { showPreview, assetReportFilters, setAssetReportFilters, isPdfGenerationInProgress, includedFilteredAssets } =
    useReportGeneratorContext()

  const handleAssetStatusSelect = useCallback((assetStatuses: AssetReportFilters['assetStatuses']) => {
    setAssetReportFilters((prevState) => ({ ...prevState, assetStatuses }))
  }, [])

  // const handleAssetCriticalitySelect = useCallback((assetCriticalities: AssetReportFilters['assetCriticalities']) => {
  //   setAssetReportFilters((prevState) => ({ ...prevState, assetCriticalities }))
  // }, [])

  // const handleAssetTypeSelect = useCallback((assetTypes: AssetReportFilters['assetTypes']) => {
  //   if (assetTypes.includes('all') || assetTypes.length === 0) {
  //     assetTypes = allAssetTypes
  //   }
  //   setAssetReportFilters((prevState) => ({ ...prevState, assetTypes }))
  // }, [])

  const handleReportDateSelect = useCallback((reportDate: Date) => {
    setAssetReportFilters((prevState) => ({ ...prevState, reportDate }))
  }, [])

  const handleReportType = useCallback((status: ReportTypeString, checked: boolean) => {
    setAssetReportFilters((prevState) => updateReportType(prevState, status, checked))
  }, [])

  useEffect(() => {
    if (showPreview) {
      setHasFiltersChanged(true)
    }
  }, [assetReportFilters, includedFilteredAssets])

  useEffect(() => {
    if (isPdfGenerationInProgress) {
      setHasFiltersChanged(false)
    }
  }, [isPdfGenerationInProgress])

  const isGenerateReportDisabled = useMemo(
    () =>
      assetReportFilters.reportTypes.length === 0 ||
      assetReportFilters.assets.length === 0 ||
      includedFilteredAssets.length === 0 ||
      !hasFiltersChanged,
    [assetReportFilters, includedFilteredAssets, hasFiltersChanged]
  )

  return (
    <StyledReportFiltersContainer
      isPdfGenerationInProgress={isPdfGenerationInProgress}
      data-testid={dataTestId.generateReportFilters}
    >
      <StyledSelectSection>
        <StyledSelectFiltersSection>
          <StyledAssetStatusSelectWrap>
            <ReportSelect<ReportGeneratorStatusOptionValue, AssetHealthStatusSelectType>
              multiple={true}
              value={assetReportFilters.assetStatuses as AssetHealthStatusType[]}
              // transformPassedValueFn={(value) => mapAssetStatusesOption(value as AssetHealthStatusType[])}
              onChange={handleAssetStatusSelect}
              // transformSelectedValueFn={(selectedValue) => getAssetStatuses(selectedValue)}
              items={mapToSelectItems(reportGeneratorStatusOptions)}
              label="Asset status"
              data-testid={dataTestId.generateReportFiltersAssetStatusSelect}
              allValues={[...Object.values(reportGeneratorStatusOptions)] as unknown as AssetHealthStatusType[]}
            />
          </StyledAssetStatusSelectWrap>
          {/* //=============================START======================================= 
          // Asset Criticality & Asset Type Filter Section 
          // Temporarily hidden until backend implementation is complete
          //============================================================================= */}
          {/* <div style={{ display: 'none' }}>
            <StyledFlexItem>
              <ReportSelect<CriticalityOptionValue, AssetCriticality>
                multiple={false}
                value={assetReportFilters.assetCriticalities}
                transformPassedValueFn={(value) => mapCriticalityOption(value)}
                onChange={handleAssetCriticalitySelect}
                transformSelectedValueFn={(selectedValue) => getCriticality(selectedValue)}
                items={mapToSelectItems(criticalityOptions)}
                label="Asset criticality"
                data-testid={dataTestId.generateReportFiltersAssetCriticalitySelect}
              />
            </StyledFlexItem>
            <StyledFlexItem>
              <ReportSelect
                multiple={true}
                value={assetReportFilters.assetTypes}
                onChange={handleAssetTypeSelect}
                items={mapToSelectItems(assetTypeOptions)}
                label="Asset Type"
                allValues={allAssetTypes}
                data-testid={dataTestId.generateReportFiltersAssetTypeSelect}
              />
            </StyledFlexItem>
          </div> */}
          {/* //========================================================================= 
          // Asset Criticality & Asset Type Filter Section 
          // Temporarily hidden until backend implementation is complete
          //================================END========================================== */}
          <StyledReportDateWrap>
            <DatePicker
              feSize={'sm'}
              feLabel="Report date"
              onChange={(date) => handleReportDateSelect(date as Date)}
              value={assetReportFilters.reportDate}
              feMinDate={epochDate().toDate()}
              feMaxDate={parseDate(addToDate(new Date(), 0, 'years'))}
              placeholder={'Select'}
              allowClear={false}
              required={true}
            />
          </StyledReportDateWrap>
        </StyledSelectFiltersSection>
        <StyledReportDetailsSection>
          <StyledIconContainer data-testid={dataTestId.generateReportFiltersAssetStatusIcons}>
            {assetReportFilters.assetStatuses.map((iconStatus) => {
              const { feIcon, feColor } = mapIconPropsToAssetStatus(iconStatus as AssetStatusString)
              return <Icon feIcon={feIcon} feColor={feColor} feSize="sm" key={feIcon} />
            })}
          </StyledIconContainer>
        </StyledReportDetailsSection>
      </StyledSelectSection>
      <StyledReportTypeSection>
        <StyledReportTypeHeading feFlexDirection="row">Selections to include:</StyledReportTypeHeading>
        <StyledReportTypeSectionContainer feGap="lg">
          <ReportTypeSection
            reportTypeStatus={assetReportFilters.reportTypes}
            onChange={handleReportType}
            data-testid={dataTestId.generateReportFiltersReportTypeSection}
          />
          <StyledGenerateReportButtonContainer>
            <GenerateReportButton isDisabled={isGenerateReportDisabled} isVisible={true} label="Regenerate" />
          </StyledGenerateReportButtonContainer>
        </StyledReportTypeSectionContainer>
      </StyledReportTypeSection>
    </StyledReportFiltersContainer>
  )
}

export default ReportFilters

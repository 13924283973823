import { useReportGeneratorContext } from '@/contexts/reportGenerator'
import {
  StyledCancelBtnContainer,
  StyledProgressBar,
  StyledProgressBarContainer,
  StyledProgressText,
  StyledReportPreviewLoaderContainer,
  StyledReportPreviewLoaderOverlay,
} from '@/modules/reportGenerator/styled'

import { dataTestId } from '@/tests/testid'
import { IconButton, Text } from '@skf-design-system/ui-components-react'
import { FC } from 'react'
interface ReportPreviewLoaderProps {
  onTerminateProcess: () => void
}
const ReportPreviewLoader: FC<ReportPreviewLoaderProps> = ({ onTerminateProcess }) => {
  const { loaderProgress } = useReportGeneratorContext()

  return (
    <StyledReportPreviewLoaderOverlay>
      <StyledReportPreviewLoaderContainer data-testid={dataTestId.generateReportPreviewLoader}>
        <StyledProgressBarContainer>
          <StyledProgressText>{`${loaderProgress.toFixed(0)}% complete`}</StyledProgressText>
          <StyledProgressBar progress={loaderProgress} />
        </StyledProgressBarContainer>
        <StyledCancelBtnContainer
          progress={loaderProgress}
          data-testid={dataTestId.generateReportPreviewLoaderAbortButton}
          onClick={onTerminateProcess}
        >
          <IconButton feIcon="closeAlt" as="button" name="cancelPreview" aria-label="cancel-preview-button" />
          <Text>Abort</Text>
        </StyledCancelBtnContainer>
      </StyledReportPreviewLoaderContainer>
    </StyledReportPreviewLoaderOverlay>
  )
}

export default ReportPreviewLoader

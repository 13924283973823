import { Site } from '@/models/overview/types'
import OverviewCell from '@/modules/overview/components/overviewCell'
import { ROUTES } from '@/shared/constants'
import { MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'

const useSiteName = () => {
  const companySiteName = useMemo<MRT_ColumnDef<Site>[]>(
    () => [
      {
        accessorFn: (row) => `${row.companyName} - ${row.siteName}`,
        id: 'siteName',
        header: '',
        grow: false,
        size: 240,
        Cell: ({ cell, row }) => (
          <OverviewCell
            siteId={row.original.siteId}
            value={cell.getValue<string>()}
            routeTo={ROUTES.DASHBOARD}
            withTooltip={false}
          />
        ),
      },
    ],
    []
  )
  return companySiteName
}

export default useSiteName

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Remote, wrap } from 'comlink'
import { useApi } from '@/shared/hooks/useApi'
import { useEffect, useMemo, useCallback, useRef } from 'react'

interface UseWorkerProps<
  T extends Record<string, (...args: any) => any>,
  K extends keyof T,
  P = Parameters<T[K]>[0],
  R = Awaited<ReturnType<T[K]>>,
> {
  WorkerClass: new () => Worker
  methodName: K
  props?: P
  onDone?: (result?: R) => void
}

export function useWorker<
  T extends Record<string, (...args: any) => any>,
  K extends keyof T,
  P = Parameters<T[K]>[0],
  R = Awaited<ReturnType<T[K]>>,
>({ WorkerClass, methodName, props, onDone }: UseWorkerProps<T, K>) {
  const workerRef = useRef<{ worker: Worker; api: Remote<T> } | null>(null)

  // Function to create a new worker instance
  const initializeWorker = useCallback(() => {
    if (workerRef.current) {
      workerRef.current.worker.terminate()
    }

    const worker = new WorkerClass()
    const api = wrap<T>(worker)

    workerRef.current = { worker, api }
    return workerRef.current
  }, [WorkerClass])

  // Memoized worker API (initialized once)
  // eslint-disable-next-line react-compiler/react-compiler
  useMemo(() => initializeWorker(), [initializeWorker])

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      workerRef.current?.worker.terminate()
      workerRef.current = null
    }
  }, [])

  // API function using useApi
  const useApiResult = useApi<R>(async (params) => {
    if (!workerRef.current) {
      throw new Error('Worker not initialized')
    }

    let dataToWork = props
    if (params?.postData && !props) {
      dataToWork = params.postData as P
    }

    const workerResult = (await workerRef.current.api[methodName](dataToWork)) as R

    if (onDone) {
      onDone()
    }

    return workerResult
  })

  // Properly terminate the worker
  const terminate = useCallback(() => {
    workerRef.current?.worker.terminate()
    workerRef.current = null
  }, [])

  return { ...useApiResult, terminate, restart: initializeWorker }
}

import { useFaultEvidenceContext } from '@/contexts/moduleContexts/evidence'
import { useFaultsFormStateContext } from '@/contexts/moduleContexts/faultsForm'
import { useFilesDropzoneContext } from '@/contexts/moduleContexts/filesDropzone'
import { useReportStatusContext } from '@/contexts/reportStatus'
import { ADD_EVIDENCE_UPLOADED_IMAGE } from '@/modules/reportStatus/reducer/actions.types'
import { StyledPasteImageButton, StyledUploadImageCard } from '@/modules/reportStatus/styled'
import { handleInputPasteEvent } from '@/modules/reportStatus/utils/evidenceUtils'
import { ACCEPTED_MIME_TYPES, MAX_FILE_SIZE, MAX_FILES, MAX_TOTAL_SIZE } from '@/shared/components/dropzone/constants'
import FilesDropzone from '@/shared/components/dropzone'
import { FileDropzoneImageData } from '@/shared/components/dropzone/types'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { dataTestId } from '@/tests/testid'
import { Flexbox } from '@skf-design-system/ui-components-react'
import { MouseEvent as ReactMouseEvent, useCallback } from 'react'

const EvidenceDropzone = () => {
  const { faultImages, faultId } = useFaultEvidenceContext()

  const { faultsFormDispatch } = useFaultsFormStateContext()

  const { isEvidenceFaultImageModalOpen, faultEditModeData } = useReportStatusContext()

  const { setDropzoneFiles, setFaultId, setDropzoneProps, dropzoneFiles, inputRef } = useFilesDropzoneContext()

  useDeepCompareEffect(() => {
    setDropzoneFiles({ ...dropzoneFiles, [faultId]: faultImages })
    setFaultId(faultId)
    setDropzoneProps({
      multiple: true,
      maxSize: MAX_TOTAL_SIZE,
      maxFiles: MAX_FILE_SIZE - faultImages.length,
      disabled: faultImages.length >= MAX_FILES,
      accept: ACCEPTED_MIME_TYPES,
    })
  }, [faultId, faultImages])

  const onFilesProcessed = (convertedValidFiles: FileDropzoneImageData[]) => {
    faultsFormDispatch({
      type: ADD_EVIDENCE_UPLOADED_IMAGE,
      payload: {
        evidences: [...faultImages, ...convertedValidFiles],
        faultId,
      },
    })
  }

  const handlePasteFile = useCallback(
    (event: ClipboardEvent | ReactMouseEvent) => {
      const processPasteData = async () => {
        const htmlInput = inputRef.current as unknown as HTMLInputElement
        await handleInputPasteEvent(htmlInput, event)
      }

      !isEvidenceFaultImageModalOpen && processPasteData()
    },
    [inputRef, isEvidenceFaultImageModalOpen]
  )

  useDeepCompareEffect(() => {
    document.addEventListener('paste', handlePasteFile)
    return () => document.removeEventListener('paste', handlePasteFile)
  }, [inputRef, isEvidenceFaultImageModalOpen])

  useDeepCompareEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => faultImages.forEach((file) => file.imageInfo.fileURL && URL.revokeObjectURL(file.imageInfo.fileURL))
  }, [faultImages])

  return (
    <Flexbox>
      {faultEditModeData[faultId] && (
        <StyledUploadImageCard>
          <StyledPasteImageButton
            feType="secondary"
            feIcon={{ feIcon: 'duplicate', position: 'left' }}
            onClick={(event) => handlePasteFile(event)}
            data-testid={dataTestId.evidencePasteFromClipboardButton}
            disabled={faultImages.length >= 5}
          >
            Paste from clipboard
          </StyledPasteImageButton>
          <FilesDropzone onFilesProcessed={onFilesProcessed} shouldReturnFilesWithError={true} />
        </StyledUploadImageCard>
      )}
    </Flexbox>
  )
}

export default EvidenceDropzone

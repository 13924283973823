import {
  COLORS,
  Flexbox,
  FONT_SIZES,
  FONT_WEIGHTS,
  Icon,
  Link,
  Popup,
  SPACINGS,
  styled,
} from '@skf-design-system/ui-components-react'

export const StyledOverviewCellData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledOverviewCell = styled.a`
  text-align: center;
  font-size: ${FONT_SIZES.sm};
  font-weight: ${FONT_WEIGHTS.normal};
  line-height: normal;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  cursor: pointer;
`

export const StyledOverviewTimeBetweenSpan = styled.span<{ threshold: number }>`
  height: 1rem;
  width: 1rem;
  background-color: ${(props) =>
    isNaN(props.threshold) || props.threshold > 7
      ? COLORS.redBase
      : props.threshold <= 3
        ? COLORS.greenBase
        : COLORS.yellowBase};
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.5rem;
`

export const StyledPopover = styled.div`
  width: max-content;
  background-color: rgb(255 255 255);
  border-radius: 0.125rem;
  font-size: 1rem;
  padding: 0;
`

export const StyledActionColumnPopup = styled(Popup)`
  padding: 0 !important;
`

export const StyledOverviewDevicesSensorsColumnIcon = styled(Icon)`
  background-color: #781e93;
  border-radius: 50%;
  height: 1rem !important;
  width: 1rem !important;
  margin-right: 0.5rem;
`

export const StyledOverviewDevicesSensorsColumnWrapper = styled(Flexbox)<{ marginLeft: number }>`
  margin-left: ${(props) => props.marginLeft}rem;
`

export const StyledOverviewAutomaticDiagnosticsTooltip = styled.span<{
  threshold: number
}>`
  height: 1rem;
  width: 1rem;
  background-color: ${(props) =>
    isNaN(props.threshold) || props.threshold < 50
      ? COLORS.redBase
      : props.threshold > 70
        ? COLORS.greenBase
        : COLORS.yellowBase};
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.5rem;
`
export const StyledOverviewContainer = styled(Flexbox)`
  flex-direction: column;
  margin: ${SPACINGS.lg};
`
export const StyledViewFlexbox = styled(Flexbox)`
  margin: 0 ${SPACINGS.lg};
  flex-grow: 1;
  flex-direction: column;
`

export const StyledLink = styled(Link)`
  padding: 0.625rem;

  &:hover {
    background-color: rgb(231 238 250);
  }
`

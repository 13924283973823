import PriorityUnhandledCellColumn from '@/modules/analysisBacklog/components/cell/priorityCell/priorityCellContent'
import { switchHandleComponentsIds } from '@/shared/constants'
import { dataTestId } from '@/tests/testid'
import { Icon } from '@skf-design-system/ui-components-react'
import { FC } from 'react'

interface PriorityCellProps {
  isHandled: boolean
  isInaccuratePriority: boolean
  priority: number
  rowIndex: number
}

const PriorityCell: FC<PriorityCellProps> = (props) => {
  if (props.isHandled) {
    return (
      <Icon
        id={`${switchHandleComponentsIds.switchOnItem}-${props.rowIndex}`}
        feIcon="asset"
        feColor="gray"
        feSize="sm"
        data-testid={dataTestId.priorityCellHandledIcon}
      />
    )
  } else {
    return <PriorityUnhandledCellColumn {...props} />
  }
}
export default PriorityCell

import AssetStatusIcon from '@/shared/components/assetStatusIcon'
import { FilterCheckboxProps } from '@/shared/components/tableComponents/genericTableFilter/types'
import { AssetStatusString } from '@/shared/models/types'
import { getAssetStatusType } from '@/shared/utils'
import { dataTestId } from '@/tests/testid'
import { Checkbox, Flexbox } from '@skf-design-system/ui-components-react'
import { FC } from 'react'
import { StyledAssetStatusCheckboxIconContainer } from './styled'

/**
 * A checkbox component for filtering assets by status.

 * @param {AssetStatusString} status - The status to filter by.
 * @param {boolean} checked - Whether the checkbox is checked or not.
 * @param {(status: AssetStatusString, checked: boolean) => void} onChange - The callback function to handle checkbox changes.
 * @returns {JSX.Element} The rendered AssetStatusCheckbox component.
 */
const AssetStatusCheckbox: FC<FilterCheckboxProps<AssetStatusString>> = ({ status, checked, onChange }) => {
  return (
    <Flexbox feAlignItems="center" feJustifyContent="space-between" feGap="md">
      <Checkbox
        name={status}
        checked={checked}
        feLabel={getAssetStatusType(status)}
        onChange={(_, checked) => onChange(status, checked)}
        data-testid={dataTestId.filterStatusCheckbox}
      />
      <StyledAssetStatusCheckboxIconContainer>
        <AssetStatusIcon value={status} />
      </StyledAssetStatusCheckboxIconContainer>
    </Flexbox>
  )
}

export default AssetStatusCheckbox

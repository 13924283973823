import { styled } from '@skf-design-system/ui-components-react'

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
`

export const StyledSpinnerWrapper = styled.div`
  width: 9.3rem;
  height: 9.3rem;
  position: absolute;
`

import { AssetHistorySnapshot } from '@/models/reportStatus/types'
import HistoryCollapse from '@/modules/reportStatus/components/history/common/HistoryCollapse'
import HistoryRow from '@/modules/reportStatus/components/history/common/HistoryRow'
import { MarkAsNormalWrapper, StyledMarkAsNormalRowInnerContainerChildren } from '@/modules/reportStatus/styled'
import { compareDates } from '@/shared/dateUtils'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { dataTestId } from '@/tests/testid'
import { IconButton } from '@skf-design-system/ui-components-react'
import { FC, useState } from 'react'
import CondensedImg from './assets/markAsNormal.png'

interface MarkAsNormal {
  snapshot: AssetHistorySnapshot[]
}

export const MarkAsNormalHistory: FC<MarkAsNormal> = ({ snapshot }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleClick = () => {
    setIsOpen((lastState) => !lastState)
  }

  const lastReconfirm = useDeepCompareMemo(() => {
    return snapshot.reduce((latest, current) => {
      return compareDates(current.timestamp, latest.timestamp) < 0 ? latest : current
    })
  }, [snapshot])

  const historyRows = useDeepCompareMemo(() => {
    return snapshot
      .filter((v) => v != lastReconfirm)
      .reverse()
      .map((item, i) => <HistoryRow timestamp={item.timestamp} username={item.username} key={`${i}-reconfirm-row`} />)
  }, [lastReconfirm, snapshot])

  return (
    <StyledMarkAsNormalRowInnerContainerChildren feJustifyContent="space-start" feGap="sm">
      {/* <Collapse collapsedData={snapshot} open={isOpen} /> */}
      <HistoryCollapse
        historyRows={historyRows}
        dataLength={snapshot.length}
        lastReconfirm={lastReconfirm}
        label="marks as normal"
        open={isOpen}
        image={CondensedImg}
      />
      <MarkAsNormalWrapper>
        {snapshot.length > 1 && (
          <IconButton
            as="button"
            type="button"
            feSize="sm"
            aria-label={isOpen ? 'Open' : 'Close'}
            onClick={handleClick}
            feIcon={isOpen ? 'chevronUp' : 'chevronDown'}
            style={{ zIndex: 10 }}
            data-testid={dataTestId.iconCondensedAsNormal}
          />
        )}
      </MarkAsNormalWrapper>
    </StyledMarkAsNormalRowInnerContainerChildren>
  )
}

import { dataTestId } from '@/tests/testid'
import BearingSpinner from '../bearingSpinner'
import { StyledContainer, StyledSpinnerWrapper } from './style'
import { loadersSelector } from '@/store/selectors/loadersSelector'
import { useTypedSelector } from '@/store/store'

const FallbackLoader = () => {
  const { isGlobalLoading } = useTypedSelector(loadersSelector)

  return (
    (!isGlobalLoading && (
      <StyledContainer>
        <StyledSpinnerWrapper data-testid={dataTestId.fallbackLoader}>
          <BearingSpinner large={true} />
        </StyledSpinnerWrapper>
      </StyledContainer>
    )) || <></>
  )
}

export default FallbackLoader

import { Card, COLORS, Icon, IconButton, Link, Popup, SPACINGS, styled } from '@skf-design-system/ui-components-react'

export const StyledAnalysisBacklogRoot = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 2rem ${SPACINGS.lg} 0;
  padding: 0 2rem;
`
export const StyledPriorityColumnIcon = styled(IconButton)`
  color: ${COLORS.white};
  background-color: ${COLORS.orangeDarker};
  border-radius: 50%;
  margin-right: ${SPACINGS.xxs};
  cursor: default;
`
export const StyledBelongsToText = styled.span`
  cursor: pointer;
`

export const StyledHierarchyListItem = styled.li<{ level: number }>`
  padding-left: ${(props) => props.level > 1 && props.level * 10}px;
`

export const StyledHierarchyLine = styled.span`
  display: flex;
  align-items: center;
  gap: ${SPACINGS.xxs};
  padding-block: 0.1rem;
`

export const StyledHierarchyPopup = styled(Popup)`
  & > div {
    width: fit-content;
    display: flex;
    flex-direction: column;

    & button:first-of-type {
      align-self: flex-end;
    }
  }
`

export const StyledHierarchyWrapper = styled.div`
  padding: 0 ${SPACINGS.xs};
`

export const StyledHierarchyText = styled.span<{ bold?: boolean }>`
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
`

export const StyledActionMenuLink = styled(Link)`
  padding: 0.625rem;

  &:hover {
    background-color: rgb(231 238 250);
  }
`
export const StyledEmptyTableContainer = styled.div`
  width: '100%';
  height: 'calc(100% - 2.25rem)';
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
`

export const StyledReasonIndicator = styled(Card)<{ withRedirect: boolean }>`
  padding: ${SPACINGS.xs} ${SPACINGS.sm};
  gap: ${SPACINGS.xs};
  align-items: center;
  height: 2.375rem;
  font-size: 1rem;
  display: flex;
  white-space: nowrap;
  cursor: ${({ withRedirect }) => (withRedirect ? 'pointer' : 'auto')};

  & > [data-comp='divider'] {
    margin-inline: -${SPACINGS.xxs};
  }

  & > .container {
    align-self: baseline;
  }
`

export const StyledFaultedAlarmsPopup = styled(Popup)`
  background-color: ${COLORS.gray050};
  font-size: 1rem;
  width: fit-content;
`

export const StyledLevelIcon = styled(Icon)`
  transform: rotate(180deg);
`

export const StyledAssetName = styled.span`
  overflow: hidden;
  overflow-wrap: break-word;
`

import { useFaultEvidenceContext } from '@/contexts/moduleContexts/evidence'
import UploadedImageCard from '@/modules/reportStatus/components/evidence/uploadedImageCard'
import {
  StyledEmptyEvidenceImage,
  StyledEmptyEvidenceImageContainer,
  StyledNoEvidenceText,
  StyledUploadImageCardContainer,
} from '@/modules/reportStatus/styled'
import { Fragment } from 'react'
import emptyEvidenceImage from '@/modules/reportStatus/components/evidence/assets/empty-evidence.webp'
import { useReportStatusContext } from '@/contexts/reportStatus'

const EvidenceImagesContainer = () => {
  const { faultImages, faultId } = useFaultEvidenceContext()
  const { faultEditModeData } = useReportStatusContext()

  return (
    <StyledUploadImageCardContainer>
      {faultImages.map((image) => (
        <Fragment key={image.imageInfo.fileID}>
          <UploadedImageCard imageDetails={image} />
        </Fragment>
      ))}
      {faultImages.length == 0 && !faultEditModeData[faultId] && (
        <StyledEmptyEvidenceImageContainer>
          <StyledEmptyEvidenceImage src={emptyEvidenceImage} />
          <StyledNoEvidenceText>No evidence</StyledNoEvidenceText>
        </StyledEmptyEvidenceImageContainer>
      )}
    </StyledUploadImageCardContainer>
  )
}

export default EvidenceImagesContainer

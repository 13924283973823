import { Severities } from '@/models/widgets/asset-health-condition/types'
import { AssetStatus, RecommendedActionStatus } from '@/shared/models/types'
import { getEnumKeys } from '@/shared/utils'
import { COLORS } from '@skf-design-system/ui-components-react'

export const barGraphTotalWidth = '450px'

export const barHeadingColors = {
  Green: 'rgb(60, 135, 74)',
  Gray: 'rgb(105, 111, 123)',
  Red: 'rgb(187, 39, 26)',
}

export const emptyRaDueDatesStatus = {
  normal: 0,
  acceptable: 0,
  unsatisfactory: 0,
  unacceptable: 0,
  severe: 0,
}

export const barColors = {
  Gray: 'rgb(199, 202, 208)',
  DarkGray: 'rgb(105, 111, 123)',
  Red: 'rgb(187, 39, 26)',
}

export const barWidth = '40px'
/********************************* */
export const alarmBarWidth = '75px'

export const EmergencyColor = COLORS.redBase
export const AlarmColor = COLORS.orangeDark
export const AlertColor = COLORS.yellowDarker
export const NormalColor = COLORS.greenBase
/********************************* */
export const machineHealthBarWidth = '35%'
export const stackedBarLeftMargin = '50px'

export const severities: Severities = getEnumKeys(AssetStatus, ['never-reported']) as Severities
/********************************* */
export const recommendationStatuses = getEnumKeys(RecommendedActionStatus, ['closed'])

export const globalFiltersRecommendationStatuses = getEnumKeys(RecommendedActionStatus, ['completed', 'rejected'])

export const ColorPending = COLORS.redBase
export const ColorPendingLight = COLORS.redDark
export const ColorOpenLight = COLORS.blueBase
export const ColorConcluded = COLORS.greenBase
export const ColorConcludedLight = COLORS.greenBase
export const ColorOpen = COLORS.purpleBase
export const ColorCreated = COLORS.blueDark
export const ColorCompleted = COLORS.greenBase
export const ColorRejected = COLORS.gray600

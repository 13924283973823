import GenericTableFilter from '@/shared/components/tableComponents/genericTableFilter'
import { CustomFilterMeta } from '@/shared/components/tableComponents/genericTableFilter/types'
import TextEllipsis, { TextEllipsisType } from '@/shared/components/textEllipsis'
import { MRT_Column, MRT_RowData } from 'material-react-table'
import { useCallback } from 'react'

interface TableColumnProps<T extends MRT_RowData> {
  characterLengths?: number
  column: MRT_Column<T, unknown>
}

const TableColumn = <T extends MRT_RowData>({ characterLengths, column }: TableColumnProps<T>) => {
  const HeaderColumnFilter = useCallback((column: MRT_Column<T, unknown>) => {
    const columnMeta = column.columnDef.meta as CustomFilterMeta
    const columnFilterType = columnMeta.filterType
    return <GenericTableFilter<T> column={column} filterType={columnFilterType} />
  }, [])
  return (
    <>
      {column.getCanFilter() && <HeaderColumnFilter {...column} />}
      <TextEllipsis
        value={column.columnDef.header}
        characterLength={characterLengths || column.columnDef.header.length}
        type={TextEllipsisType.SingleLine}
      />
    </>
  )
}

export default TableColumn
